<main class="main" role="main">
  <section class="page">
    <app-separador label="Unidades Especializadas"></app-separador>
    <div class="row">
      <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 the_content">
        <h3>PROCURADORIA DE BRASILIA (PB)</h3>
        <p class="procurador"><strong>Procurador(a) Chefe</strong>:<br /></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">LEONARDO COCCHIERI LEITE CHAVES</p>
        </div>
        <p class="endereco"><strong>Endereço</strong>: Setor de Autarquias Sul (SAUS), Quadra 01, Bloco G, 4º andar, Asa Sul, Brasília-DF, 70070-901.</p>
        <p class="tel"><strong>Telefone</strong>: (61) 3328-0244</p>
        <br />
        <p><strong>Procuradores lotados na unidade</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">PEDRO LUIZ TIZIOTTI</p>
          <p class="demais-procuradores">PAULO HENRIQUE PROCOPIO FLORENCIO</p>
          <p class="demais-procuradores">RODRIGO TRINDADE CASTANHEIRA MENICUCCI</p>
          <p class="demais-procuradores">DANIEL HENRIQUE FERREIRA TOLENTINO</p>
          <p class="demais-procuradores">NATALIA KALIL CHAD SOMBRA</p>
          <p class="demais-procuradores">RENATA PASSOS PINHO MARTINS</p>
          <p class="demais-procuradores">WALDIR FRANCISCO HONORATO JUNIOR</p>
          <p class="demais-procuradores">ANDRE BRAWERMAN</p>
          <p class="demais-procuradores">CELSO ALVES DE RESENDE JUNIOR</p>
          <p class="demais-procuradores">RAFAEL SOUZA DE BARROS</p>
        </div>
        <br />
        <hr />
        <br />
        <h3>Procuradoria Judicial (PJ)</h3>
        <p class="procurador"><strong>Procurador(a) Chefe</strong>:<br /></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">MARIA CAROLINA CARVALHO</p>
        </div>
        <p class="endereco"><strong>Endereço</strong>: Rua Maria Paula, 67, , Centro, São Paulo-SP, 01319-000.</p>
        <p class="tel"><strong>Telefone</strong>: (11) 3130-9000 , FAX(11)3242-9069</p>
        <p class="tel"><strong>E-mail:</strong> atendimentopj&#64;sp.gov.br</p>
        <br />
        <p><strong>Procuradores Assistentes</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">CAIO AUGUSTO NUNES DE CARVALHO</p>
          <p class="demais-procuradores">JESSICA LORENCETTE GODOY</p>
          <p class="demais-procuradores">JOAQUIM PEDRO MENEZES DE JESUS LISBOA</p>
          <p class="demais-procuradores">LAISA ARRUDA MANDU</p>
          <p class="demais-procuradores">RODRIGO LEITE ORLANDELLI</p>
        </div>
      </div>
    </div>
    <br />
    <hr />
    <br />
    <h3>Procuradoria do Contencioso Ambiental e Imobiliário (PCAI)</h3>
    <p class="procurador"><strong>Procurador(a) Chefe</strong>:<br /></p>
    <div class="pge-unidade">
      <p class="demais-procuradores">ANGELICA MAIALE VELOSO</p>
    </div>
    <p class="endereco"><strong>Endereço</strong>: Rua Maria Paula, 67, Centro, São Paulo-SP, 01319-000.</p>
    <p class="tel"><strong>Telefone</strong>: (11) 3130-9018</p>
    <p class="email"><strong>Email</strong>: protocolopcai&#64;sp.gov.br e atendimentopcai&#64;sp.gov.br</p>
    <br />
    <hr />
    <br />
    <div class="row">
      <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 the_content">
        <h3>Núcleos Especializados</h3>
        <br />
        <br />
        <h3>Núcleos Pessoal e Previdenciário</h3>
        <br />
        <br />
        <h3>Núcleos Estratégico de Pessoal e Previdenciário - NEPP</h3>
        <div class="pge-unidade">
          <p class="demais-procuradores"><strong>Coordenador(a):</strong> Jéssica Lorencette Godo</p>
          <p class="demais-procuradores">Eduardo Henrique Santos Cunha</p>
          <p class="demais-procuradores">Marcel Felipe Moitinho Torres</p>
          <p class="demais-procuradores">João Marcelo Gomes</p>
          <p class="demais-procuradores">Marcos Frederico Frazão Lopes</p>
        </div>
        <br />
        <hr />
        <br />
        <h3>Núcleo Previdenciário</h3>
        <div class="pge-unidade">
          <p class="demais-procuradores"><strong>Coordenador(a):</strong> Leonardo Castro de Sá Vintena</p>
          <p class="demais-procuradores">Tatiana Iazzetti Figueiredo Lima</p>
          <p class="demais-procuradores">Ana Paula Antunes</p>
          <p class="demais-procuradores">Danilo Albuquerque Dias</p>
          <p class="demais-procuradores">Francisco Maia Braga</p>
          <p class="demais-procuradores">Guilherme Arruda Mendes Carneiro</p>
          <p class="demais-procuradores">Marcos Prado Leme Ferreira</p>
          <p class="demais-procuradores">Mika Cristina Tsuda</p>
          <p class="demais-procuradores">Tathiana de Haro Sanches Peixoto</p>
          <p class="demais-procuradores">Victor Fava Arruda</p>
        </div>
        <br />
        <hr />
        <br />
        <h3>Núcleo de Pessoal Carreiras de Estado</h3>
        <div class="pge-unidade">
          <p class="demais-procuradores"><strong>Coordenador(a):</strong> Thomaz Komatsu Vicentini</p>
          <p class="demais-procuradores">Américo Andrade Pinho</p>
          <p class="demais-procuradores">Ana Carolina Izidoro Davies</p>
          <p class="demais-procuradores">Bruna Helena Alvarez de Faria E Oliveira</p>
          <p class="demais-procuradores">Carla Pitelli Paschoal</p>
          <p class="demais-procuradores">Cristiane Vieira Batista de Nazaré</p>
          <p class="demais-procuradores">Deise Carolina Muniz Rebello</p>
          <p class="demais-procuradores">Dulce Ataliba Nogueira Leite</p>
          <p class="demais-procuradores">Eliane Bastos Martins</p>
          <p class="demais-procuradores">Fábio Imbernom Nascimento</p>
          <p class="demais-procuradores">Felipe Orletti Penedo</p>
          <p class="demais-procuradores">Fernando Franco</p>
          <p class="demais-procuradores">Helia Rubia Giglioli</p>
          <p class="demais-procuradores">Isabelle Maria Verza</p>
          <p class="demais-procuradores">Juliana Cristina Lopes Filippi</p>
          <p class="demais-procuradores">Luciana Regina Micelli Lupinacci</p>
          <p class="demais-procuradores">Luciano Carlos de Melo</p>
          <p class="demais-procuradores">Luis Gustavo Santoro</p>
          <p class="demais-procuradores">Marco Antonio Duarte de Azevedo</p>
          <p class="demais-procuradores">Marcos Rogério Venanzi</p>
          <p class="demais-procuradores">Marialice Dias Gonçalves</p>
          <p class="demais-procuradores">Marina Grisanti Reis Mejias</p>
          <p class="demais-procuradores">Mário Diniz Ferreira Filho</p>
          <p class="demais-procuradores">Marta Rodrigues Sangirardi</p>
          <p class="demais-procuradores">Patrícia Leika Sakai</p>
          <p class="demais-procuradores">Paulo Henrique Moura Leite</p>
          <p class="demais-procuradores">Raquel Cristina Marques Tobias</p>
          <p class="demais-procuradores">Renata de Oliveira Martins Cantanhede</p>
          <p class="demais-procuradores">Renato Kenji Higa</p>
          <p class="demais-procuradores">Rogério Ramos Batista</p>
          <p class="demais-procuradores">Sandra Yuri Nanba</p>
          <p class="demais-procuradores">Tatiana de Faria Bernardi</p>
          <p class="demais-procuradores">Vladimir Bononi</p>
        </div>
        <br />
        <hr />
        <br />
        <h3>Núcleo de Pessoal Educação</h3>
        <div class="pge-unidade">
          <p class="demais-procuradores"><strong>Coordenador(a):</strong> Claudia Andrade Freitas</p>
          <p class="demais-procuradores">André Domingues Figaro</p>
          <p class="demais-procuradores">Eduardo Fronzaglia Ferreira</p>
          <p class="demais-procuradores">Eva Baldonedo Rodrigues</p>
          <p class="demais-procuradores">Fernando Wagner Fernandes Marinho</p>
          <p class="demais-procuradores">Luisa Nóbrega Passos</p>
          <p class="demais-procuradores">Marcelo Augusto Fabri de Carvalho</p>
          <p class="demais-procuradores">Maria Helena da Silva Fernandes dos Santos</p>
          <p class="demais-procuradores">Paula Ferraresi Santos</p>
          <p class="demais-procuradores">Priscila Regina dos Ramos</p>
          <p class="demais-procuradores">Roberta Callijão Boareto</p>
          <p class="demais-procuradores">Vinicius Wanderley</p>
          <p class="demais-procuradores">Vitor Tilieri</p>
          <p class="demais-procuradores">Carlos Caran Kalil</p>
          <p class="demais-procuradores">Carlos Moura de Melo</p>
          <p class="demais-procuradores">Daniela Rodrigues Valentim Angelotti</p>
          <p class="demais-procuradores">Dirce Felipin Nardin</p>
          <p class="demais-procuradores">Flávio Marcelo Gomes</p>
          <p class="demais-procuradores">Marco Antonio Baroni Gianvecchio</p>
          <p class="demais-procuradores">Renato Silveira Bueno Bianco</p>
          <p class="demais-procuradores">Thiago Camargo Garcia</p>
          <p class="demais-procuradores">Vinícius Lima de Castro</p>
        </div>
        <br />
        <hr />
        <br />
        <h3>Núcleo de Pessoal Militar - NPM</h3>
        <div class="pge-unidade">
          <p class="demais-procuradores"><strong>Coordenador(a):</strong> Marcelo Felipe da Costa</p>
          <p class="demais-procuradores">Amanda de Nardi Duran</p>
          <p class="demais-procuradores">Ana Carla Malheiros Ribeiro</p>
          <p class="demais-procuradores">Ana Carolina Daldegan Serraglia</p>
          <p class="demais-procuradores">Antônio Carlos Piantino Neto</p>
          <p class="demais-procuradores">Arthur Felipe Torres Trindade da Silva</p>
          <p class="demais-procuradores">Carla Paiva</p>
          <p class="demais-procuradores">Carlos Henrique de Lima Vita</p>
          <p class="demais-procuradores">Cristiane Guidorizzi Sanchez</p>
          <p class="demais-procuradores">Daniel Girardi Vieira</p>
          <p class="demais-procuradores">Daniela Fernandes Anselmo Gonçalves Rodrigues</p>
          <p class="demais-procuradores">Fabio Luciano de Campos</p>
          <p class="demais-procuradores">Fernanda Buendia Damasceno Paiva</p>
          <p class="demais-procuradores">Fernanda Paulino</p>
          <p class="demais-procuradores">Fernanda Vissoto Biscaia</p>
          <p class="demais-procuradores">Jorge Kuranaka</p>
          <p class="demais-procuradores">Juliana Leme Souza Goncalves</p>
          <p class="demais-procuradores">Karla Viviane Loureiro Tozim Spinardi</p>
          <p class="demais-procuradores">Luiz Henrique Tamaki</p>
          <p class="demais-procuradores">Marco Aurelio Funck Savoia</p>
          <p class="demais-procuradores">Marcos Rogério Venanzi</p>
          <p class="demais-procuradores">Maria Luiza Cordeiro Soubhia Fleury</p>
          <p class="demais-procuradores">Mauricio de Almeida Henarias</p>
          <p class="demais-procuradores">Natalia Pereira Covale</p>
          <p class="demais-procuradores">Nathalia Maria Pontes Farina</p>
          <p class="demais-procuradores">Nayara Crispim da Silva</p>
          <p class="demais-procuradores">Paulo Braga Neder</p>
          <p class="demais-procuradores">Renan Teles Campos de Carvalho</p>
          <p class="demais-procuradores">Roberto Mendes Mandelli Junior</p>
          <p class="demais-procuradores">Rodrigo Manoel Carlos Cilla</p>
          <p class="demais-procuradores">Rogerio Pereira da Silva</p>
          <p class="demais-procuradores">Simone Massilon Bezerra</p>
          <p class="demais-procuradores">Thiago de Paula Leite</p>
          <p class="demais-procuradores">Vera Fernanda Medeiros Martins</p>
        </div>
        <br />
        <hr />
        <br />
        <h3>Núcleo de Gestão e Prevenção de Demandas Repetitivas - GPDR</h3>
        <div class="pge-unidade">
          <p class="demais-procuradores"><strong>Coordenador(a):</strong> Marisa Mitiyo Nakayama Leon Anibal</p>
          <p class="demais-procuradores">Ana Clara Quintas David</p>
          <p class="demais-procuradores">Arilson Garcia Gil</p>
          <p class="demais-procuradores">Beatriz Couto Tancredo</p>
          <p class="demais-procuradores">Clara Angélica do Carmo Lima</p>
          <p class="demais-procuradores">Elaine Cristina de Antônio Faria</p>
          <p class="demais-procuradores">Fabio Alexandre Coelho</p>
          <p class="demais-procuradores">Gibran Nobrega Zeraik Abdalla</p>
          <p class="demais-procuradores">Guilherme Fonseca Tadini</p>
          <p class="demais-procuradores">Isadora Carvalho Bueno</p>
          <p class="demais-procuradores">Jorge Antonio Dias Romero</p>
          <p class="demais-procuradores">Junia Giglio Takaes</p>
          <p class="demais-procuradores">Lair Aroni</p>
          <p class="demais-procuradores">Maria do Carmo Acosta Giovanini Gasparoto</p>
          <p class="demais-procuradores">Paulo Sergio Almeida da Cunha</p>
          <p class="demais-procuradores">Pedro Tiago Alvez Schuwarten</p>
          <p class="demais-procuradores">Renata Passos Pinho Martins</p>
          <p class="demais-procuradores">Vivian Alves Carmichael de Souza</p>
        </div>
        <br />
        <hr />
        <br />
        <h3>Núcleo de Pessoal Residual</h3>
        <div class="pge-unidade">
          <p class="demais-procuradores"><strong>Coordenador(a):</strong> Rafael Politi Esposito Gomes</p>
          <p class="demais-procuradores">Abner Alcantara Samha Santos</p>
          <p class="demais-procuradores">Ana Paula Dompieri</p>
          <p class="demais-procuradores">Cesar Carvalho de Paula Cortes</p>
          <p class="demais-procuradores">Cinhia Tamara Araújo da Silva</p>
          <p class="demais-procuradores">Claudio Barbosa Camar de Souza</p>
          <p class="demais-procuradores">Denner Pereira</p>
          <p class="demais-procuradores">Dimitri Feo Machado de Carvalho Fernandes</p>
          <p class="demais-procuradores">Elpídio Mario Dantas Fonseca</p>
          <p class="demais-procuradores">Emanuel Fonseca Lima</p>
          <p class="demais-procuradores">Felipe Castelo Branco De Abreu</p>
          <p class="demais-procuradores">Francimar Soares da Silva Junior</p>
          <p class="demais-procuradores">Gislaene Placa Lopes</p>
          <p class="demais-procuradores">Gustavo Justus do Amarante</p>
          <p class="demais-procuradores">Henrique José de Agostinho Cintra</p>
          <p class="demais-procuradores">Lucas de Faria Santos</p>
          <p class="demais-procuradores">Luisa de Oliveira Drumond</p>
          <p class="demais-procuradores">Luiz Gustavo Andrade dos Santos</p>
          <p class="demais-procuradores">Manoel José de Paula Filho</p>
          <p class="demais-procuradores">Marcelo Trefiglio Marçal Vieira</p>
          <p class="demais-procuradores">Maria Eduarda Mureb Sobrino Porto</p>
          <p class="demais-procuradores">Milena Gomes Martins</p>
          <p class="demais-procuradores">Monica Arilena Clemente Nespoli</p>
          <p class="demais-procuradores">Pedro Camera Pacheco</p>
          <p class="demais-procuradores">Rafael Leite Cairo</p>
          <p class="demais-procuradores">Rafael de Moraes Brandão</p>
          <p class="demais-procuradores">Renato Barbosa Monteiro de Castro</p>
          <p class="demais-procuradores">Sandro Marcelo Paris Franzoi</p>
          <p class="demais-procuradores">Stela Cristina Furtado</p>
          <p class="demais-procuradores">Vanderlei Aníbal Júnior</p>
        </div>
        <br />
        <hr />
        <br />
        <h3>Núcleo TRT2</h3>
        <div class="pge-unidade">
          <p class="demais-procuradores"><strong>Coordenador(a):</strong> Bruno Barrozo Herkenhoff Vieira</p>
          <p class="demais-procuradores">Felipe Goncalves Fernandes</p>
          <p class="demais-procuradores">Gustavo Lacerda Anello</p>
          <p class="demais-procuradores">Luis Augusto de Deus Silva</p>
          <p class="demais-procuradores">Mario Henrique Dutra Nunes</p>
          <p class="demais-procuradores">Rafael Sodre Ghattas</p>
          <p class="demais-procuradores">Rodolfo Breciani Penna</p>
          <p class="demais-procuradores">Rodrigo Farah Reis</p>
        </div>
        <br />
        <hr />
        <br />
        <h3>Núcleo TRT15</h3>
        <div class="pge-unidade">
          <p class="demais-procuradores"><strong>Coordenador(a):</strong> Fernando Henrique Medici</p>
          <p class="demais-procuradores">Aline Castro de Carvalho</p>
          <p class="demais-procuradores">Flavia Regina Valença</p>
          <p class="demais-procuradores">Henrique Silveira Melo</p>
          <p class="demais-procuradores">Jose Carlos Candido da Silva</p>
          <p class="demais-procuradores">Marcio Rogerio Licerre</p>
          <p class="demais-procuradores">Renata Danella Polli</p>
          <p class="demais-procuradores">Rodrigo Peixoto Medeiros</p>
          <p class="demais-procuradores">Vangelis Rodrigues Alves</p>
        </div>
        <br />
        <hr />
        <br />
        <h3>Núcleo de responsabilidade Subsidiária de Serviços Terceirizados - NRST</h3>
        <div class="pge-unidade">
          <p class="demais-procuradores"><strong>Coordenador(a):</strong> Alexander Silva Guimarães Pereira</p>
          <p class="demais-procuradores">Alexander Silva Guimarães Pereira</p>
          <p class="demais-procuradores">Camila de Brito Brandão</p>
          <p class="demais-procuradores">Flavia Maria Silveira Souza Ferro</p>
          <p class="demais-procuradores">Guilherme Silveira da Rosa Wurch Duarte</p>
          <p class="demais-procuradores">Ilanna Sofia Santos Soeiro</p>
          <p class="demais-procuradores">Lucas Pessôa Moreira</p>
          <p class="demais-procuradores">Marcia Martins Muniz Rodrigues</p>
          <p class="demais-procuradores">Renan Oliveira e Rainho Cunha</p>
          <p class="demais-procuradores">Thalita Pinheiro Matos Siqueira</p>
        </div>
        <br />
        <hr />
        <br />
        <h3>Hospital das Clínicas - HCFMUSP</h3>
        <div class="pge-unidade">
          <p class="demais-procuradores"><strong>Coordenador(a):</strong> Claudio Porpino Cabral de Melo</p>
          <p class="demais-procuradores">Caio Leão Câmara Felga</p>
          <p class="demais-procuradores">Lenita Leite Pinho</p>
          <p class="demais-procuradores">Giulia Dandara Pinheiro Martins</p>
        </div>
      </div>
    </div>
    <br />
    <hr />
    <br />
    <div class="row">
      <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 the_content">
        <h3>Núcleos Serviços Públicos e Residual</h3>
        <br />
        <br />
        <h3>Núcleos de Regulação e Contratações Públicas</h3>
        <div class="pge-unidade">
          <p class="demais-procuradores"><strong>Coordenador(a):</strong> Rômulo Duarte Silva</p>
          <p class="demais-procuradores">Gerson Dalle Grave</p>
          <p class="demais-procuradores">Graziella Moliterni Benvenuti</p>
          <p class="demais-procuradores">Gustavo Henrique Willrich</p>
          <p class="demais-procuradores">Lannara Cavalcante Alves</p>
          <p class="demais-procuradores">Lucas Leite Alves</p>
          <p class="demais-procuradores">Patrícia de Lacerda Baptista</p>
          <p class="demais-procuradores">Rafael Santos de Jesus</p>
          <p class="demais-procuradores">Rômulo Silva Duarte</p>
          <p class="demais-procuradores">Vitor Gomes Moreira</p>
        </div>
        <br />
        <hr />
        <br />
        <h3>Núcleo de Responsabilidade Civil</h3>
        <div class="pge-unidade">
          <p class="demais-procuradores"><strong>Coordenador(a):</strong> Luisa Baran de Alvarenga</p>
          <p class="demais-procuradores">Adler Chiquezi</p>
          <p class="demais-procuradores">Caio Gentil Ribeiro</p>
          <p class="demais-procuradores">Claudio Henrique de Oliveira</p>
          <p class="demais-procuradores">Cristiane de Abreu Bergmann</p>
          <p class="demais-procuradores">Daniela Valim da Silviera</p>
          <p class="demais-procuradores">Doclácio Dias Barbosa</p>
          <p class="demais-procuradores">Fernando Cesar Gonçalves Pedrini</p>
          <p class="demais-procuradores">Frederico José Fernandes de Athayde</p>
          <p class="demais-procuradores">Ismael Nedehf do Vale Correa</p>
          <p class="demais-procuradores">José Paulo Martins Gruli</p>
          <p class="demais-procuradores">Maria Cecília Claro Silva</p>
          <p class="demais-procuradores">Murilo Rodrigues Junior</p>
          <p class="demais-procuradores">Rafael de Paixa Krauss Silva</p>
          <p class="demais-procuradores">Ricardo Martins Zaupa</p>
          <p class="demais-procuradores">Rui de Salles Oliveira</p>
          <p class="demais-procuradores">Washington Luiz Janis Junior</p>
        </div>
        <br />
        <hr />
        <br />
        <h3>Núcleo de Poder de Polícia</h3>
        <div class="pge-unidade">
          <p class="demais-procuradores"><strong>Coordenador(a):</strong> Heloise Wittman</p>
          <p class="demais-procuradores">Bettina Monteiro Buelau Cogo</p>
          <p class="demais-procuradores">Amanda Cristina Viselli</p>
          <p class="demais-procuradores">Fernando Humberto Parolo Caravita</p>
          <p class="demais-procuradores">Fernando Marques de Jesus</p>
          <p class="demais-procuradores">Gabriel Japiassu Viana</p>
          <p class="demais-procuradores">Marcio de Oliveira Jacob</p>
          <p class="demais-procuradores">Paulo André Lopes Pontes Caldas</p>
          <p class="demais-procuradores">Reinaldo Caetano da Silveira Filho</p>
          <p class="demais-procuradores">Sara Dinardi</p>
        </div>
        <br />
        <hr />
        <br />
        <h3>Núcleo de Políticas Públicas</h3>
        <div class="pge-unidade">
          <p class="demais-procuradores"><strong>Coordenador(a):</strong> Igor Fortes Catta Preta</p>
          <p class="demais-procuradores">Alexandre Ferrari Vidotti</p>
          <p class="demais-procuradores">Ana Vendramini</p>
          <p class="demais-procuradores">Arthur da Motta Trigueiros Neto</p>
          <p class="demais-procuradores">Enio Moraes Silva</p>
          <p class="demais-procuradores">Josiane Cristina Cremonizi Gonçales</p>
          <p class="demais-procuradores">Juliana Guedes Matos</p>
          <p class="demais-procuradores">Marcelo Bianchi</p>
          <p class="demais-procuradores">Nara Cibele Neves</p>
          <p class="demais-procuradores">Nilton Carlos de Almeida Coutinho</p>
          <p class="demais-procuradores">Tatiana Capochin Paes Leme</p>
          <p class="demais-procuradores">Thais Carvalho de Souza</p>
        </div>
        <br />
        <hr />
        <br />
        <h3>Núcleo de Saúde Pública</h3>
        <div class="pge-unidade">
          <p class="demais-procuradores"><strong>Coordenador(a):</strong> Zillá Oliva Roma</p>
          <p class="demais-procuradores">Fernanda Augusta Hernandes Carrenho</p>
          <p class="demais-procuradores">Danilo Gaiotto</p>
          <p class="demais-procuradores">Carlos Henrique Dias</p>
          <p class="demais-procuradores">Gabriel da Silveira Mendes</p>
          <p class="demais-procuradores">Wagner Manzato de Castro</p>
          <p class="demais-procuradores">Roberto Pereira Perez</p>
          <p class="demais-procuradores">Renato Oliveira de Araújo</p>
          <p class="demais-procuradores">Jose Renato Rocco Roland Gomes</p>
          <p class="demais-procuradores">Rodrigo Soares Reis Lemos Freire</p>
          <p class="demais-procuradores">Eduardo Canizella Junior</p>
          <p class="demais-procuradores">Carlos Roberto Marques Junior</p>
          <p class="demais-procuradores">Izabella Sanna Werner</p>
          <p class="demais-procuradores">Daniela D’Andrea Vaz Ferreira</p>
          <p class="demais-procuradores">Paula Costa de Paiva</p>
          <p class="demais-procuradores">Marcelo Gutierrez</p>
          <p class="demais-procuradores">Ana Paula Ferreira dos Santos</p>
          <p class="demais-procuradores">Claudia Beatriz Maia Silva</p>
          <p class="demais-procuradores">Paulo Henrique Silva Godoy</p>
          <p class="demais-procuradores">Patrícia Ulson Zappa Lodi</p>
          <p class="demais-procuradores">João César Barbieri Bedran de Castro</p>
          <p class="demais-procuradores">Fabiana Mello Mulato</p>
          <p class="demais-procuradores">Thais de Lima Batista Pereira Zanovelo</p>
          <p class="demais-procuradores">Glaucia Buldo da Silva</p>
          <p class="demais-procuradores">Gisele Bechara Espinoza</p>
          <p class="demais-procuradores">Marcus Vinicius Armani Alves</p>
          <p class="demais-procuradores">Carolina Quaggio Vieira</p>
          <p class="demais-procuradores">Orlando Gonçalves de Castro Júnior</p>
          <p class="demais-procuradores">Márcio Aparecido de Oliveira</p>
          <p class="demais-procuradores">Wladimir Novaes</p>
          <p class="demais-procuradores">Mara Cilene Baglie</p>
          <p class="demais-procuradores">Claudio Takeshi Tuda</p>
          <p class="demais-procuradores">Eduardo da Silveira Guskuma</p>
          <p class="demais-procuradores">Thiago Pucci Bego</p>
          <p class="demais-procuradores">Luciana Nigoghossian dos Santos</p>
          <p class="demais-procuradores">Roberto Ramos</p>
          <p class="demais-procuradores">Daniel Carmelo Pagliusi Rodrigues</p>
          <p class="demais-procuradores">Delton Croce Junior</p>
          <p class="demais-procuradores">Alcina Mara Russi Nunes</p>
          <p class="demais-procuradores">Carlos Henrique Giunco</p>
          <p class="demais-procuradores">Eduardo Bordini Novato</p>
          <p class="demais-procuradores">Hugo Vechiato Betoni</p>
        </div>
        <br />
        <hr />
        <br />
        <h3>Núcleo de Contencioso PGE/DETRAN-SP - NCPD</h3>
        <div class="pge-unidade">
          <p class="demais-procuradores"><strong>Coordenador(a):</strong> André Serafim Bernardi</p>
          <p class="demais-procuradores">Ana Paula Fernanda Fonseca Maciel</p>
          <p class="demais-procuradores">André Lima Bezerra</p>
          <p class="demais-procuradores">Carlos Martins Tavelin</p>
          <p class="demais-procuradores">Eduardo Rauber Wilcieski</p>
          <p class="demais-procuradores">Emanuela Sousa Rodrigues Fortes</p>
          <p class="demais-procuradores">Laura de Araujo da Silva</p>
          <p class="demais-procuradores">Renan William Mendes</p>
        </div>
        <br />
        <hr />
        <br />
        <h3>Núcleo de propositura de Ações</h3>
        <div class="pge-unidade">
          <p class="demais-procuradores"><strong>Coordenador(a):</strong> Sueine Patrícia Cunha de Souza</p>
          <p class="demais-procuradores">Ana Karina Silveira D´Elboux</p>
          <p class="demais-procuradores">Bruno Fonseca de Andrade</p>
          <p class="demais-procuradores">Camilla Rocha Lessa Bomfim Marques</p>
          <p class="demais-procuradores">Henrique Martini Monteiro</p>
          <p class="demais-procuradores">Rafael Modesto Rigato</p>
        </div>
        <br />
        <hr />
        <br />
        <h3>Coordenadoria de Execuções Contra a Fazenda Pública - CEFAP</h3>
        <div class="pge-unidade">
          <p class="demais-procuradores"><strong>Coordenador(a):</strong> Jacqueline Schroeder de Freitas Araujo</p>
          <p class="demais-procuradores">Mauro Oliveira Magalhaes</p>
          <p class="demais-procuradores">César Trama</p>
          <p class="demais-procuradores">Cláudio Henrique de Oliveira Junior</p>
          <p class="demais-procuradores">Eduardo Belas Pereira Junior</p>
          <p class="demais-procuradores">Gabriel Ribeiro Perlingeiro Mendes</p>
          <p class="demais-procuradores">Heloisa de Paula Fiod Costa Osada</p>
          <p class="demais-procuradores">Idya Mendonça Tupinambá</p>
          <p class="demais-procuradores">João Carlos Mettlach Pinter</p>
          <p class="demais-procuradores">Laura Deprá Martins</p>
          <p class="demais-procuradores">Pedro Oliveira Mathias</p>
          <p class="demais-procuradores">Raphael Barbosa dos Santos Teixeira</p>
          <p class="demais-procuradores">Rodrigo Pansanato Osada</p>
          <p class="demais-procuradores">Thais Felix</p>
          <p class="demais-procuradores">Weyder Amorim Silva</p>
        </div>
        <br />
        <hr />
        <br />
      </div>
      <div class="row">
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 the_content">
          <h3>NúcleosAmbiental e Imobiliário</h3>
          <br />
          <br />
          <h3>Núcleos Ambiental</h3>
          <div class="pge-unidade">
            <p class="demais-procuradores"><strong>Coordenador(a):</strong> Julia Cara Giovannetti</p>
            <p class="demais-procuradores">Anna Luiza Mortari</p>
            <p class="demais-procuradores">Clerio Rodrigues da Costa</p>
            <p class="demais-procuradores">Jessica Guerra Serra</p>
            <p class="demais-procuradores">Marco Antonio Gomes</p>
            <p class="demais-procuradores">Paula Nelly Dionigi</p>
            <p class="demais-procuradores">Paulo Roberto Fernandes de Andrade</p>
            <p class="demais-procuradores">Plinio Back Silva</p>
            <p class="demais-procuradores">Tiago Antonio Paulosso Anibal</p>
          </div>
          <br />
          <hr />
          <br />
          <h3>Núcleo de Desapropriações</h3>
          <div class="pge-unidade">
            <p class="demais-procuradores"><strong>Coordenador(a):</strong> Ligia Mara Marques da Silva</p>
            <p class="demais-procuradores">Danielle Gonçalves Pinheiro</p>
            <p class="demais-procuradores">Fagner Vilas Boas Souza</p>
            <p class="demais-procuradores">Giselle Cristina Nassif Elias</p>
            <p class="demais-procuradores">José Ângelo Remédio Junior</p>
            <p class="demais-procuradores">Rafael Augusto Freire Franco</p>
            <p class="demais-procuradores">Ricardo Gouvea Guasco</p>
          </div>
          <br />
          <hr />
          <br />
          <h3>Núcleo de Imobiliário Residual</h3>
          <div class="pge-unidade">
            <p class="demais-procuradores"><strong>Coordenador(a):</strong> Paulo Henrique Marques de Oliveira</p>
            <p class="demais-procuradores">Carolina Jia Jia Liang</p>
            <p class="demais-procuradores">José Carlos Novais Junior</p>
            <p class="demais-procuradores">Maico Hentz</p>
            <p class="demais-procuradores">José Galbio de Oliveira Junior</p>
          </div>
          <br />
          <hr />
          <br />
        </div>
      </div>
    </div>
  </section>
</main>
