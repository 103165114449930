import { Component, OnInit } from '@angular/core';
import { WebServiceInfosService } from 'src/app/shared/services/infos-webservice.service';
import { SeparadorComponent } from '../../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-assessorias-gab',
  templateUrl: './assessorias.component.html',
  styleUrl: './assessorias.component.sass',
  standalone: true,
  imports: [SeparadorComponent]
})
export class AssessoriasComponent implements OnInit {
  informacoes: string[] = [];
  codigoUnidades: number[] = [
    40110300017094, 40110300017014, 40520200000092, 40520200000012, 40510100000092, 40510100000012, 40111000000094, 40111000000080, 40111500000080,
    40110300014094
  ];

  constructor(private service: WebServiceInfosService) {}

  ngOnInit(): void {
    this.carregarConteudo();
  }

  carregarConteudo() {
    const array: string[] = [];
    this.codigoUnidades.forEach((element) => {
      this.service.getInfosPorCodigo(element).subscribe(
        (response) => {
          const parser = new DOMParser();
          const xml = parser.parseFromString(response, 'text/xml');
          xml.querySelectorAll('NOME_PROCURADOR, NOME_SUBSTITUTO, NOME_SUBSTITUTO2').forEach((element) => {
            if (element.textContent !== '') {
              array.push(element.textContent.trim());
            }
          });
        },
        (error) => {
          console.log(error);
        }
      );
    });

    this.informacoes = array;
  }

  findInArray(nome: string): string {
    const result = this.informacoes.find((element) => element === nome);
    return result ? result : 'Nome não encontrado';
  }
}
