import { Component } from '@angular/core';
import { SeparadorComponent } from '../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-relatorios',
  templateUrl: './relatorios.component.html',
  standalone: true,
  imports: [SeparadorComponent]
})
export class RelatoriosComponent {}
