import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConversorValidadorService {
  base64ToBlob(base64: string) {
    if (base64) {
      const binaryData = atob(base64);
      const arrayBuffer = new ArrayBuffer(binaryData.length);
      const byteArray = new Uint8Array(arrayBuffer);

      for (let i = 0; i < binaryData.length; i++) {
        byteArray[i] = binaryData.charCodeAt(i);
      }

      const tipoArquivo = this.getFileType(byteArray);
      const blob = new Blob([byteArray], { type: `${tipoArquivo}` });
      const url = URL.createObjectURL(blob);

      window.open(url, '_blank');
    } else {
      console.log('Nenhum arquivo disponível.', 'Erro');
    }
  }

  private getFileType(byteArray: Uint8Array): string {
    const signature = byteArray.slice(0, 4);
    let signatureHex = '';
    for (const sig of signature) {
      signatureHex = signatureHex + '' + sig;
    }

    const fileTypeMap = new Map<string, string>([
      ['25504446', 'application/pdf'],
      ['37806870', 'application/pdf'],
      ['1378079', 'image/png'],
      ['255216255224', 'image/jpeg'],
      ['255216255225', 'image/jpg'],
      ['42477', 'image/bmp']
    ]);

    const fileType = fileTypeMap.get(signatureHex);

    if (fileType) {
      return fileType;
    } else {
      return 'unknown';
    }
  }

  // Tipos a serem usados no futuro

  // JPEG/JPG: Assinatura: FFD8FF
  // PNG: Assinatura: 89504E47
  // GIF: Assinatura: 47494638
  // TIFF: Assinatura: 49492A00 ou 4D4D002A
  // BMP: Assinatura: 424D
  // ZIP: Assinatura: 504B0304
  // MP3: Assinatura: 494433
  // MP4: Assinatura: 0000002066747970 (início de um arquivo MP4)
  // DOCX: Assinatura: 504B0304 (um arquivo ZIP que contém um arquivo DOCX tem essa assinatura)
  // XLSX: Assinatura: 504B0304 (um arquivo ZIP que contém um arquivo XLSX tem essa assinatura)
  // PPTX: Assinatura: 504B0304 (um arquivo ZIP que contém um arquivo PPTX tem essa assinatura)
  // PDF: Assinatura: 25504446

  // JPEG/JPG: image/jpeg
  // PNG: image/png
  // GIF: image/gif
  // TIFF: image/tiff
  // BMP: image/bmp
  // ZIP: application/zip
  // MP3: audio/mpeg
  // MP4: video/mp4
  // DOCX: application/vnd.openxmlformats-officedocument.wordprocessingml.document
  // XLSX: application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
  // PPTX: application/vnd.openxmlformats-officedocument.presentationml.presentation
  // PDF: application/pdf

  validarCPF(cpf: string | undefined): boolean {
    // Trata o caso em que cpf é undefined, atribuindo um array vazio
    const cpfArray: number[] = cpf ? cpf.split('').map(Number) : [];

    // Verifique se todos os dígitos são iguais
    if (cpfArray.every((digito) => digito === cpfArray[0])) {
      return true;
    }

    // Calcula os dígitos verificadores
    let soma = 0;
    let resto;

    for (let i = 1; i <= 9; i++) {
      soma += cpfArray[i - 1] * (11 - i);
    }

    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) {
      resto = 0;
    }

    if (resto !== cpfArray[9]) {
      return true;
    }

    soma = 0;

    for (let i = 1; i <= 10; i++) {
      soma += cpfArray[i - 1] * (12 - i);
    }

    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) {
      resto = 0;
    }

    if (resto !== cpfArray[10]) {
      return true;
    }

    return false;
  }
}
