<div class="background-container">
  <div class="content">
    <div class="container">
      <div class="row row-spacer">
        <div class="col">
          <div class="left-content">
            <table class="table-brasao">
              <tr>
                <div class="back-logo-brasao"></div>
              </tr>
              <tr>
                <p class="fonte-titulo-brasao">Procuradoria Geral do</p>
              </tr>
              <tr>
                <p class="fonte-titulo-brasao">Estado de São Paulo</p>
              </tr>
            </table>
          </div>
        </div>
        <div class="col" style="display: flex; align-items: center">
          <div class="card-right" style="max-width: 600px">
            <ng-content></ng-content>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
