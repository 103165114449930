<main class="main" role="main">
  <section class="page">
    <app-separador label="ATENDIMENTOS SOBRE PROTESTO/ICMS/IPVA/ITCMD/TAXAS/CERTIDÃO NEGATIVA" />
    <table>
      <tbody>
        <tr class="tituloTable">
          <th>Unidades</th>
          <th>Endereços</th>
        </tr>
        <tr>
          <td rowspan="2">
            Procuradoria Fiscal- SP<br />
            Procuradoria Regional da Grande São Paulo
          </td>
          <td>(11) 32433541</td>
        </tr>
        <tr>
          <td>
            <a href="mailto:pfatendimento@sp.gov.br">pfatendimento&#64;sp.gov.br</a>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="barra"></td>
        </tr>
        <tr>
          <td rowspan="2">Procuradoria Regional de Santos</td>
          <td>(13) 3285-9825</td>
        </tr>
        <tr>
          <td>
            <a href="mailto:pr2atendimento@sp.gov.br">pr2atendimento&#64;sp.gov.br</a><br />
            <a href="mailto:pr2sapfiscal@sp.gov.br">pr2sapfiscal&#64;sp.gov.br</a>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="barra"></td>
        </tr>
        <tr>
          <td rowspan="2">Procuradoria Regional de Taubaté</td>
          <td>(12) 3621-4861</td>
        </tr>
        <tr>
          <td>
            <a href="mailto:pr3atendimento@sp.gov.br">pr3atendimento&#64;sp.gov.br</a>
          </td>
        </tr>
        <tr class="barra">
          <td colspan="2"></td>
        </tr>
        <tr>
          <td rowspan="2">Procuradoria Regional de Sorocaba</td>
          <td>(15) 3231-3000</td>
        </tr>
        <tr>
          <td>
            <a href="mailto:pr4atendimento@sp.gov.br">pr4atendimento&#64;sp.gov.br</a>
          </td>
        </tr>
        <tr class="barra">
          <td colspan="2"></td>
        </tr>
        <tr>
          <td rowspan="2">Procuradoria Regional de Campinas</td>
          <td>(19) 3775-8040 | 8042 | 8086</td>
        </tr>
        <tr>
          <td>
            <a href="protocolo-pr5@sp.gov.br">protocolo-pr5&#64;sp.gov.br</a>
          </td>
        </tr>
        <tr class="barra">
          <td colspan="2"></td>
        </tr>
        <tr>
          <td rowspan="2">Seccional de Bragança Paulista</td>
          <td>(11) 4892-6501</td>
        </tr>
        <tr>
          <td>
            <a href="mailto:pr5atendimento@sp.gov.br">pr5atendimento&#64;sp.gov.br</a>
          </td>
        </tr>
        <tr class="barra">
          <td colspan="2"></td>
        </tr>
        <tr>
          <td rowspan="2">Seccional de Piracicaba</td>
          <td>(19) 3447-7802</td>
        </tr>
        <tr>
          <td>
            <a href="mailto:pr5atendimento@sp.gov.br">pr5atendimento&#64;sp.gov.br</a>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="barra"></td>
        </tr>
        <tr>
          <td rowspan="2">Seccional de Jundiaí</td>
          <td>(11) 4523-2002 /2004 /2005 /2008</td>
        </tr>
        <tr>
          <td>
            <a href="mailto:pr5atendimento@sp.gov.br">pr5atendimento&#64;sp.gov.br</a>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="barra"></td>
        </tr>
        <tr>
          <td rowspan="2">Seccional de Limeira</td>
          <td>(19) 3446-5451 | 5455</td>
        </tr>
        <tr>
          <td>
            <a href="mailto:pr5atendimento@sp.gov.br">pr5atendimento&#64;sp.gov.br</a>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="barra"></td>
        </tr>
        <tr>
          <td rowspan="2">Seccional de São João da Boa Vista</td>
          <td>(19) 3681-9601 | 9602 | 9612</td>
        </tr>
        <tr>
          <td>
            <a href="mailto:pr5atendimento@sp.gov.br">pr5atendimento&#64;sp.gov.br</a>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="barra"></td>
        </tr>
        <tr>
          <td rowspan="2">Seccional de Casa Branca</td>
          <td>(11) 2650-5685</td>
        </tr>
        <tr>
          <td>
            <a href="mailto:pr5atendimento@sp.gov.br">pr5atendimento&#64;sp.gov.br</a>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="barra"></td>
        </tr>
        <tr>
          <td rowspan="2">Seccional de Rio Claro</td>
          <td>(19) 3531-6801 | 6802</td>
        </tr>
        <tr>
          <td>
            <a href="mailto:pr5atendimento@sp.gov.br">pr5atendimento&#64;sp.gov.br</a>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="barra"></td>
        </tr>
        <tr>
          <td rowspan="2">Procuradoria Regional de Ribeirão Preto</td>
          <td>(16) 3610-1889</td>
        </tr>
        <tr>
          <td>
            <a href="mailto:pr6atendimento@sp.gov.br">pr6atendimento&#64;sp.gov.br</a>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="barra"></td>
        </tr>
        <tr>
          <td rowspan="2">Procuradoria Regional de Bauru</td>
          <td>(14) 3878-4023 | (14) 3878-4024</td>
        </tr>
        <tr>
          <td>
            <a href="mailto:pr7atendimento@sp.gov.br">pr7atendimento&#64;sp.gov.br</a>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="barra"></td>
        </tr>
        <tr>
          <td rowspan="2">Procuradoria Regional de São José do Rio Preto</td>
          <td>(17) 2138 -8221</td>
        </tr>
        <tr>
          <td>
            <a href="mailto:pr8atendimento@sp.gov.br">pr8atendimento&#64;sp.gov.br</a>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="barra"></td>
        </tr>
        <tr>
          <td rowspan="2">Procuradoria Regional de Araçatuba</td>
          <td>(18) 3623–6920 /1031 | (18) 3625-2961</td>
        </tr>
        <tr>
          <td>
            <a href="mailto:pr9atendimento@sp.gov.br">pr9atendimento&#64;sp.gov.br</a>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="barra"></td>
        </tr>
        <tr>
          <td rowspan="2">Procuradoria Regional de Presidente Prudente</td>
          <td>(18) 3222–7233 | (18) 3223–6770</td>
        </tr>
        <tr>
          <td>
            <a href="mailto:pr10atendimento@sp.gov.br">pr10atendimento&#64;sp.gov.br</a>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="barra"></td>
        </tr>
        <tr>
          <td rowspan="2">Procuradoria Regional de Marília</td>
          <td>(14) 3433-9699</td>
        </tr>
        <tr>
          <td>
            <a href="mailto:pr11atendimento@sp.gov.br">pr11atendimento&#64;sp.gov.br</a>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="barra"></td>
        </tr>
        <tr>
          <td rowspan="2">Procuradoria Regional de São Carlos</td>
          <td>(16) 3371-9268 / 2879</td>
        </tr>
        <tr>
          <td>
            <a href="mailto:pr12atendimento@sp.gov.br">pr12atendimento&#64;sp.gov.br</a>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="barra"></td>
        </tr>
      </tbody>
    </table>
    <hr />
  </section>
</main>
