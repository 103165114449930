<app-separador label="Ex-Ouvidores"></app-separador>
<div style="margin-top: 15px">
  @for (ouvidores of obtemListaOrdenada(exouvidores); track ouvidores) {
    <div>
      <p>
        <b>{{ ouvidores.nome }}</b>
        <br />
        Nomeação em {{ ouvidores.nomeacao }}
        <br />
        Termino do Mandato em {{ ouvidores.termino }}
      </p>
    </div>
  }
</div>
