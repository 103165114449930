<div class="container">
  <br />
  <h1 class="titulo">Visualizar Noticia</h1>
  <br />
  <div class="container-campos">
    <div class="d-flex row">
      <div class="col-md-12">
        <label for="titulo">Título da Notícia:</label>
        <mat-form-field class="mat-form-control">
          <input id="titulo" matInput type="text" [value]="noticias.titulo" [disabled]="true" />
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex row">
      <div class="col-md-3">
        <label for="dataPublicacao">Data de Publicação:</label>
        <mat-form-field class="mat-form-control">
          <input id="dataPublicacao" type="text" matInput [value]="noticias.dataPublicacao" [disabled]="true" />
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <label for="categoria">Categoria:</label>
        <mat-form-field class="mat-form-control">
          <input id="categoria" type="text" matInput [value]="noticias.categoria.nome" [disabled]="true" />
        </mat-form-field>
      </div>
      @if (campoDestaque) {
        <div class="col-md-3">
          <label for="destaque">Destaque:</label>
          <mat-form-field class="mat-form-control">
            <input id="destaque" type="text" matInput [value]="noticias.destaque !== null ? noticias.destaque : 'Nenhuma'" [disabled]="true" />
          </mat-form-field>
        </div>
      }
    </div>
    @if (campoAcesso) {
      <div class="col-md-9">
        <label for="acesso" class="label-acesso">Acesso Registro:</label>
        <mat-radio-group id="acesso" [(ngModel)]="acessoSelecionado" aria-label="Select an option">
          <mat-radio-button [value]="1" [disabled]="true">Geral</mat-radio-button>
          <mat-radio-button [value]="2" [disabled]="true">Procuradores</mat-radio-button>
          <mat-radio-button [value]="3" [disabled]="true">Servidores</mat-radio-button>
        </mat-radio-group>
      </div>
    }
    <p-editor [style]="{ 'height.px': '500', 'width.%': '100' }" class="texto" [(ngModel)]="noticias.texto" [readonly]="true"></p-editor>
    <br />
    <div class="alinhar-colunas">
      @if (campoCapa && noticias?.imagem) {
        <div class="div-pdf">
          <label for="imagem">Capa da Notícia:</label>
          <img id="imagem" [src]="getImagem()" alt="Imagem da Notícia" class="imagem-noticia" />
        </div>
      }
      <div>
        <label for="pdfs">PDFs:</label>
        <div id="pdfs">
          <table>
            <tbody>
              @for (item of arquivos; track item; let i = $index) {
                <tr>
                  <td>
                    <a href="#" (click)="openPDF($event, item)"><mat-icon fontIcon="cloud_download"></mat-icon>{{ item.nome }}</a>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <br />
    <div class="alinhar-colunas">
      <div class="espacamento-imagem">
        <label for="pdfs">Imagens:</label>
        <div id="pdfs">
          <table>
            <tbody>
              @for (item of listaImagens; track item; let i = $index) {
                <tr>
                  <td>
                    <a href="#" (click)="openPDF($event, item)"><mat-icon fontIcon="cloud_download"></mat-icon>{{ item.nome }}</a>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
      @if (noticias?.palavraChave) {
        <div>
          <mat-label>Palavras-chave:</mat-label>
          <mat-chip-set>
            @for (palavraChave of palavrasChaves(); track $index) {
              <mat-chip-row>
                {{ palavraChave }}
              </mat-chip-row>
            }
          </mat-chip-set>
        </div>
      }
    </div>
    <br />
    <div class="campos-direita"><button type="button" (click)="voltar()" class="btn btn-outline-dark">Voltar</button>&nbsp;</div>
    <br />
  </div>
</div>
