<div class="conteudo">
  <br />
  <h1 class="card-title titulo">Analisar Pedido de Auxílio Saúde</h1>
  <br />

  <div class="d-flex flex-row justify-content-center">
    <div class="col-md-12">
      <ngx-file-drop
        [dropZoneLabel]="dropZoneLabel"
        (onFileDrop)="onFileDrop($event)"
        [showBrowseBtn]="true"
        browseBtnLabel="Browse files"
        browseBtnClassName="send-files btn btn-secondary m-2 gray-file-drop"
      >
      </ngx-file-drop>
    </div>
  </div>
  <div class="d-flex flex-row justify-content-end">
    <div class="col-md-2"></div>
    <div class="col-md-10">
      @if (envioApesp) {
        <button mat-button class="envio-button" class="btn btn-dark me-2" (click)="envioBotaoApesp()">Enviar dados</button>
      }
      @if (!envioApesp && voltarPagina) {
        <button mat-button type="button" id="voltartelaanterior" name="voltartelaanterior" class="btn btn-dark me-2" routerLink="/area-restrita/analise-pedidos-auxilio-saude">
          Voltar a tela anterior
        </button>
      }
    </div>
  </div>
  <button mat-button (click)="openHelp()"><mat-icon style="color: cadetblue">help</mat-icon></button>
  <table>
    <thead>
      <tr>
        <th style="width: 5%">&nbsp;&nbsp;Processados&nbsp;&nbsp;</th>
        <th>Nome</th>
        <th>CPF</th>
        <th>Mês / Ano</th>
        <th>Valor</th>
      </tr>
    </thead>
    <tbody>
      @for (a of itensPaginados; track a) {
        <tr>
          <td style="text-align: center">
            <input type="checkbox" disabled [checked]="a.status === 1" />
          </td>
          <td style="text-align: start">{{ a.nome }}</td>
          <td style="text-align: center">{{ a.cpf }}</td>
          <td style="text-align: center">{{ a.mes_ano | date: "MMMM/y" }}</td>
          <td style="text-align: end">{{ a.total | currency }}</td>
        </tr>
      }
    </tbody>
  </table>
  <mat-paginator [length]="listaApesp.length" [pageSize]="itensPorPagina" [pageSizeOptions]="[5, 10, 20]" (page)="mudarPagina($event.pageIndex, $event.pageSize)"> </mat-paginator>
</div>
