import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { PrimeTemplate } from 'primeng/api';
import { CarouselModule } from 'primeng/carousel';
import { ImagemTratamento } from 'src/app/area-aberta/features/home/shared/services/imagem-tratamento';
import { CategoriaNoticia } from 'src/app/area-aberta/shared/enums/categoria-noticia';
import { ConversorValidadorService } from 'src/app/area-restrita/shared/services/conversor-validador.service';
import { Noticias, pdfAnexo } from 'src/app/shared/models/noticia.interface';
import { Page } from 'src/app/shared/models/paginacao/page.model';
import { NoticiaService } from 'src/app/shared/services/noticia.service';
import { SeparadorComponent } from '../../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-uma-noticia',
  templateUrl: './uma-noticia.component.html',
  styleUrl: './uma-noticia.component.sass',
  providers: [ImagemTratamento],
  standalone: true,
  imports: [MatIcon, SeparadorComponent, MatButton, DatePipe, CarouselModule, PrimeTemplate]
})
export class UmaNoticiaComponent implements OnInit, OnDestroy {
  noticia: Noticias | undefined;
  objNoticias?: Noticias[] = [];
  noticiaId: number;
  arquivos: pdfAnexo[] = [];
  listaImagens: pdfAnexo[] = [];
  urlsImagens: string[] = [];

  constructor(
    private noticiaService: NoticiaService,
    private route: ActivatedRoute,
    private router: Router,
    private imagemTratamento: ImagemTratamento,
    private base64ToPdfService: ConversorValidadorService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.noticiaId = Number.parseInt(params.get('id'));

      if (this.noticiaId !== null) {
        this.noticiaService.getOneNoticia(this.noticiaId).subscribe((noticia) => {
          this.noticia = noticia;
          this.obterArquivos(this.noticiaId);
          this.obterNoticiasDestaque();
        });
        //TODO: Redirect para o login caso não o ID seja invalido
      }
    });
  }
  retornaListaNoticias(): void {
    this.router.navigate(['/noticias-diversas']);
  }

  obterArquivos(idNoticia: number): void {
    this.noticiaService.obterArquivosNoticias(idNoticia).subscribe(
      (response) => {
        response?.forEach((arquivo) => {
          if (arquivo.imagem === true) {
            this.listaImagens.push(arquivo);
          } else {
            this.arquivos.push(arquivo);
          }
        });
        if (this.listaImagens.length > 0) {
          this.processarImagens(this.listaImagens);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  obterNoticiasDestaque(): void {
    this.noticiaService.getNoticia(0, 4, CategoriaNoticia.NOTICIA_AREA_ABERTA).subscribe((response: Page<Noticias>) => {
      this.objNoticias = response.content.filter((noticia) => noticia.ativo === true && noticia.id !== this.noticiaId).slice(0, 4);
    });
  }

  abrirUmaNoticia(noticiaId: number) {
    this.router.navigate(['uma-noticia', noticiaId]);
    this.obterArquivos(noticiaId);
  }

  getImagem(noticiaImagem: string): string | undefined {
    return this.imagemTratamento.getImagemBlob(noticiaImagem);
  }

  processarImagens(lista: pdfAnexo[]) {
    const imagens = lista.map((imagem) => this.getImagem(imagem.arquivo));
    this.urlsImagens = imagens;
  }

  processarTexto(texto: string): string {
    if (texto) {
      return texto.replace(/<img\b[^>]*>/, (match) => match.replace('>', ' width="60%">'));
    } else {
      return '';
    }
  }

  openPDF(event: MouseEvent, pdf: pdfAnexo): void {
    event.preventDefault();
    this.base64ToPdfService.base64ToBlob(pdf.arquivo);
  }

  ngOnDestroy(): void {
    this.imagemTratamento.revogarUrlBlob();
  }
}
