<main class="main" role="main">
  <section class="conselho mb50">
    <div class="container">
      <app-separador label="Galeria de Fotos dos Ex-Procuradores Gerais" />
      <div>
        <div class="box">
          <h4>Ex-Procuradores Gerais do Estado</h4>
          <div class="ctMembros row">
            <div class="membro image col-md-4 col-lg-4 col-sm-12 col-xl-4 col-xs-12">
              <img
                width="100"
                height="125"
                src="images/ex-procuradores/maria-lia.jpg"
                data-lazy-type="image"
                data-lazy-src="images/ex-procuradores/maria-lia.jpg"
                class="lazy attachment-125x125 size-125x125 lazy-loaded"
                alt="Maria Lia P. Porto Corona"
                loading="lazy"
                title="Maria Lia P. Porto Corona"
                data-lazy-srcset="images/ex-procuradores/maria-lia.jpg 100w, images/ex-procuradores/maria-lia-47x59.jpg 47w"
                data-lazy-sizes="(max-width: 100px) 100vw, 100px"
                srcset="images/ex-procuradores/maria-lia.jpg 100w, images/ex-procuradores/maria-lia-47x59.jpg 47w"
                sizes="(max-width: 100px) 100vw, 100px"
              /><noscript
                ><img
                  width="100"
                  height="125"
                  src="images/ex-procuradores/maria-lia.jpg"
                  class="attachment-125x125 size-125x125"
                  alt="Maria Lia P. Porto Corona"
                  loading="lazy"
                  title="Maria Lia P. Porto Corona"
                  srcset="images/ex-procuradores/maria-lia.jpg 100w, images/ex-procuradores/maria-lia-47x59.jpg 47w"
                  sizes="(max-width: 100px) 100vw, 100px"
              /></noscript>
              <div class="descMembro">
                <h4>Maria Lia P. Porto Corona</h4>
                <p>
                  01 de Janeiro de 2019<br />
                  a<br />
                  26 de Abril de 2022
                </p>
              </div>
            </div>
            <div class="membro image col-md-4 col-lg-4 col-sm-12 col-xl-4 col-xs-12">
              <img
                width="100"
                height="125"
                src="images/ex-procuradores/juan-francisco-carpenter.jpg"
                data-lazy-type="image"
                data-lazy-src="images/ex-procuradores/juan-francisco-carpenter.jpg"
                class="lazy attachment-125x125 size-125x125 lazy-loaded"
                alt=" Juan Francisco Carpenter"
                loading="lazy"
                title=" Juan Francisco Carpenter"
                data-lazy-srcset="images/ex-procuradores/juan-francisco-carpenter.jpg 100w, images/ex-procuradores/juan-francisco-carpenter-47x59.jpg 47w"
                data-lazy-sizes="(max-width: 100px) 100vw, 100px"
                srcset="images/ex-procuradores/juan-francisco-carpenter.jpg 100w, images/ex-procuradores/juan-francisco-carpenter-47x59.jpg 47w"
                sizes="(max-width: 100px) 100vw, 100px"
              /><noscript
                ><img
                  width="100"
                  height="125"
                  src="images/ex-procuradores/juan-francisco-carpenter.jpg"
                  class="attachment-125x125 size-125x125"
                  alt=" Juan Francisco Carpenter"
                  loading="lazy"
                  title=" Juan Francisco Carpenter"
                  srcset="images/ex-procuradores/juan-francisco-carpenter.jpg 100w, images/ex-procuradores/juan-francisco-carpenter-47x59.jpg 47w"
                  sizes="(max-width: 100px) 100vw, 100px"
              /></noscript>
              <div class="descMembro">
                <h4>Juan Francisco Carpenter</h4>
                <p>
                  10 de Abril de 2018<br />
                  a<br />
                  01 de Janeiro de 2019
                </p>
              </div>
            </div>
            <div class="membro image col-md-4 col-lg-4 col-sm-12 col-xl-4 col-xs-12">
              <img
                width="100"
                height="125"
                src="images/ex-procuradores/jose-renato-ferreira-pires.jpg"
                data-lazy-type="image"
                data-lazy-src="images/ex-procuradores/jose-renato-ferreira-pires.jpg"
                class="lazy attachment-125x125 size-125x125 lazy-loaded"
                alt=" José Renato Ferreira Pires"
                loading="lazy"
                title=" José Renato Ferreira Pires"
                data-lazy-srcset="images/ex-procuradores/jose-renato-ferreira-pires.jpg 100w, images/ex-procuradores/jose-renato-ferreira-pires-47x59.jpg 47w"
                data-lazy-sizes="(max-width: 100px) 100vw, 100px"
                srcset="images/ex-procuradores/jose-renato-ferreira-pires.jpg 100w, images/ex-procuradores/jose-renato-ferreira-pires-47x59.jpg 47w"
                sizes="(max-width: 100px) 100vw, 100px"
              /><noscript
                ><img
                  width="100"
                  height="125"
                  src="images/ex-procuradores/jose-renato-ferreira-pires.jpg"
                  class="attachment-125x125 size-125x125"
                  alt=" José Renato Ferreira Pires"
                  loading="lazy"
                  title=" José Renato Ferreira Pires"
                  srcset="images/ex-procuradores/jose-renato-ferreira-pires.jpg 100w, images/ex-procuradores/jose-renato-ferreira-pires-47x59.jpg 47w"
                  sizes="(max-width: 100px) 100vw, 100px"
              /></noscript>
              <div class="descMembro">
                <h4>José Renato Ferreira Pires</h4>
                <p>
                  02 de Março de 2018<br />
                  a<br />
                  09 de Abril de 2018
                </p>
              </div>
            </div>
            <div class="membro image col-md-4 col-lg-4 col-sm-12 col-xl-4 col-xs-12">
              <img
                width="100"
                height="125"
                src="images/ex-procuradores/marcos-fabio-de-oliveira-nusdeo.jpg"
                data-lazy-type="image"
                data-lazy-src="images/ex-procuradores/marcos-fabio-de-oliveira-nusdeo.jpg"
                class="lazy attachment-125x125 size-125x125 lazy-loaded"
                alt=" Marcos Fábio de Oliveira Nusdeo"
                loading="lazy"
                title=" Marcos Fábio de Oliveira Nusdeo"
                data-lazy-srcset="images/ex-procuradores/marcos-fabio-de-oliveira-nusdeo.jpg 100w, images/ex-procuradores/marcos-fabio-de-oliveira-nusdeo-47x59.jpg 47w"
                data-lazy-sizes="(max-width: 100px) 100vw, 100px"
                srcset="images/ex-procuradores/marcos-fabio-de-oliveira-nusdeo.jpg 100w, images/ex-procuradores/marcos-fabio-de-oliveira-nusdeo-47x59.jpg 47w"
                sizes="(max-width: 100px) 100vw, 100px"
              /><noscript
                ><img
                  width="100"
                  height="125"
                  src="images/ex-procuradores/marcos-fabio-de-oliveira-nusdeo.jpg"
                  class="attachment-125x125 size-125x125"
                  alt=" Marcos Fábio de Oliveira Nusdeo"
                  loading="lazy"
                  title=" Marcos Fábio de Oliveira Nusdeo"
                  srcset="images/ex-procuradores/marcos-fabio-de-oliveira-nusdeo.jpg 100w, images/ex-procuradores/marcos-fabio-de-oliveira-nusdeo-47x59.jpg 47w"
                  sizes="(max-width: 100px) 100vw, 100px"
              /></noscript>
              <div class="descMembro">
                <h4>Marcos Fábio de Oliveira Nusdeo</h4>
                <p>
                  01 de Janeiro de 2007<br />
                  a<br />
                  02 de Janeiro de 2011
                </p>
              </div>
            </div>
            <div class="membro image col-md-4 col-lg-4 col-sm-12 col-xl-4 col-xs-12">
              <img
                width="100"
                height="125"
                src="images/ex-procuradores/elival-da-silva-ramos.jpg"
                data-lazy-type="image"
                data-lazy-src="images/ex-procuradores/elival-da-silva-ramos.jpg"
                class="lazy attachment-125x125 size-125x125 lazy-loaded"
                alt=" Elival da Silva Ramos"
                loading="lazy"
                title=" Elival da Silva Ramos"
                data-lazy-srcset="images/ex-procuradores/elival-da-silva-ramos.jpg 100w, images/ex-procuradores/elival-da-silva-ramos-47x59.jpg 47w"
                data-lazy-sizes="(max-width: 100px) 100vw, 100px"
                srcset="images/ex-procuradores/elival-da-silva-ramos.jpg 100w, images/ex-procuradores/elival-da-silva-ramos-47x59.jpg 47w"
                sizes="(max-width: 100px) 100vw, 100px"
              /><noscript
                ><img
                  width="100"
                  height="125"
                  src="images/ex-procuradores/elival-da-silva-ramos.jpg"
                  class="attachment-125x125 size-125x125"
                  alt=" Elival da Silva Ramos"
                  loading="lazy"
                  title=" Elival da Silva Ramos"
                  srcset="images/ex-procuradores/elival-da-silva-ramos.jpg 100w, images/ex-procuradores/elival-da-silva-ramos-47x59.jpg 47w"
                  sizes="(max-width: 100px) 100vw, 100px"
              /></noscript>
              <div class="descMembro">
                <h4>Elival da Silva Ramos</h4>
                <p>
                  22 de Dezembro de 2001<br />
                  a<br />
                  31 de Dezembro de 2006<br />
                  <br />
                  03 de Janeiro de 2011<br />
                  a<br />
                  01 de Março de 2018
                </p>
              </div>
            </div>
            <div class="membro image col-md-4 col-lg-4 col-sm-12 col-xl-4 col-xs-12">
              <img
                width="100"
                height="125"
                src="images/ex-procuradores/rosali-de-paula-lima.jpg"
                data-lazy-type="image"
                data-lazy-src="images/ex-procuradores/rosali-de-paula-lima.jpg"
                class="lazy attachment-125x125 size-125x125 lazy-loaded"
                alt=" Rosali de Paula Lima"
                loading="lazy"
                title=" Rosali de Paula Lima"
                data-lazy-srcset="images/ex-procuradores/rosali-de-paula-lima.jpg 100w, images/ex-procuradores/rosali-de-paula-lima-47x59.jpg 47w"
                data-lazy-sizes="(max-width: 100px) 100vw, 100px"
                srcset="images/ex-procuradores/rosali-de-paula-lima.jpg 100w, images/ex-procuradores/rosali-de-paula-lima-47x59.jpg 47w"
                sizes="(max-width: 100px) 100vw, 100px"
              /><noscript
                ><img
                  width="100"
                  height="125"
                  src="images/ex-procuradores/rosali-de-paula-lima.jpg"
                  class="attachment-125x125 size-125x125"
                  alt=" Rosali de Paula Lima"
                  loading="lazy"
                  title=" Rosali de Paula Lima"
                  srcset="images/ex-procuradores/rosali-de-paula-lima.jpg 100w, images/ex-procuradores/rosali-de-paula-lima-47x59.jpg 47w"
                  sizes="(max-width: 100px) 100vw, 100px"
              /></noscript>
              <div class="descMembro">
                <h4>Rosali de Paula Lima</h4>
                <p>
                  02 de Setembro de 2000<br />
                  a<br />
                  21 de Dezembro de 2001
                </p>
              </div>
            </div>
            <div class="membro image col-md-4 col-lg-4 col-sm-12 col-xl-4 col-xs-12">
              <img
                width="100"
                height="125"
                src="images/ex-procuradores/marcio-sotelo-felippe.jpg"
                data-lazy-type="image"
                data-lazy-src="images/ex-procuradores/marcio-sotelo-felippe.jpg"
                class="lazy attachment-125x125 size-125x125 lazy-loaded"
                alt=" Márcio Sotelo Felippe"
                loading="lazy"
                title=" Márcio Sotelo Felippe"
                data-lazy-srcset="images/ex-procuradores/marcio-sotelo-felippe.jpg 100w, images/ex-procuradores/marcio-sotelo-felippe-47x59.jpg 47w"
                data-lazy-sizes="(max-width: 100px) 100vw, 100px"
                srcset="images/ex-procuradores/marcio-sotelo-felippe.jpg 100w, images/ex-procuradores/marcio-sotelo-felippe-47x59.jpg 47w"
                sizes="(max-width: 100px) 100vw, 100px"
              /><noscript
                ><img
                  width="100"
                  height="125"
                  src="images/ex-procuradores/marcio-sotelo-felippe.jpg"
                  class="attachment-125x125 size-125x125"
                  alt=" Márcio Sotelo Felippe"
                  loading="lazy"
                  title=" Márcio Sotelo Felippe"
                  srcset="images/ex-procuradores/marcio-sotelo-felippe.jpg 100w, images/ex-procuradores/marcio-sotelo-felippe-47x59.jpg 47w"
                  sizes="(max-width: 100px) 100vw, 100px"
              /></noscript>
              <div class="descMembro">
                <h4>Márcio Sotelo Felippe</h4>
                <p>
                  05 de Janeiro de 1995<br />
                  a<br />
                  02 de Setembro de 2000
                </p>
              </div>
            </div>
            <div class="membro image col-md-4 col-lg-4 col-sm-12 col-xl-4 col-xs-12">
              <img
                width="100"
                height="125"
                src="images/ex-procuradores/dirceu-jose-vieira-.jpg"
                data-lazy-type="image"
                data-lazy-src="images/ex-procuradores/dirceu-jose-vieira-.jpg"
                class="lazy attachment-125x125 size-125x125 lazy-loaded"
                alt=" Dirceu José Vieira Chrysostomo"
                loading="lazy"
                title=" Dirceu José Vieira Chrysostomo"
                data-lazy-srcset="images/ex-procuradores/dirceu-jose-vieira-.jpg 100w, images/ex-procuradores/dirceu-jose-vieira-47x59.jpg 47w"
                data-lazy-sizes="(max-width: 100px) 100vw, 100px"
                srcset="images/ex-procuradores/dirceu-jose-vieira-.jpg 100w, images/ex-procuradores/dirceu-jose-vieira--47x59.jpg 47w"
                sizes="(max-width: 100px) 100vw, 100px"
              /><noscript
                ><img
                  width="100"
                  height="125"
                  src="images/ex-procuradores/dirceu-jose-vieira-.jpg"
                  class="attachment-125x125 size-125x125"
                  alt=" Dirceu José Vieira Chrysostomo"
                  loading="lazy"
                  title=" Dirceu José Vieira Chrysostomo"
                  srcset="images/ex-procuradores/dirceu-jose-vieira-.jpg 100w, images/ex-procuradores/dirceu-jose-vieira--47x59.jpg 47w"
                  sizes="(max-width: 100px) 100vw, 100px"
              /></noscript>
              <div class="descMembro">
                <h4>Dirceu José Vieira Chrysostomo</h4>
                <p>
                  14 de Outubro de 1992<br />
                  a<br />
                  04 de Janeiro de 1995
                </p>
              </div>
            </div>
            <div class="membro image col-md-4 col-lg-4 col-sm-12 col-xl-4 col-xs-12">
              <img
                width="100"
                height="125"
                src="images/ex-procuradores/sergio-joao-franca.jpg"
                data-lazy-type="image"
                data-lazy-src="images/ex-procuradores/sergio-joao-franca.jpg"
                class="lazy attachment-125x125 size-125x125 lazy-loaded"
                alt=" Sérgio João França"
                loading="lazy"
                title=" Sérgio João França"
                data-lazy-srcset="images/ex-procuradores/sergio-joao-franca.jpg 100w, images/ex-procuradores/sergio-joao-franca-47x59.jpg 47w"
                data-lazy-sizes="(max-width: 100px) 100vw, 100px"
                srcset="images/ex-procuradores/sergio-joao-franca.jpg 100w, images/ex-procuradores/sergio-joao-franca-47x59.jpg 47w"
                sizes="(max-width: 100px) 100vw, 100px"
              /><noscript
                ><img
                  width="100"
                  height="125"
                  src="images/ex-procuradores/sergio-joao-franca.jpg"
                  class="attachment-125x125 size-125x125"
                  alt=" Sérgio João França"
                  loading="lazy"
                  title=" Sérgio João França"
                  srcset="images/ex-procuradores/sergio-joao-franca.jpg 100w, images/ex-procuradores/sergio-joao-franca-47x59.jpg 47w"
                  sizes="(max-width: 100px) 100vw, 100px"
              /></noscript>
              <div class="descMembro">
                <h4>Sérgio João França</h4>
                <p>
                  17 de Março de 1987<br />
                  a<br />
                  06 de Abril de 1991
                </p>
              </div>
            </div>
            <div class="membro image col-md-4 col-lg-4 col-sm-12 col-xl-4 col-xs-12">
              <img
                width="100"
                height="125"
                src="images/ex-procuradores/feres-sabino-.jpg"
                data-lazy-type="image"
                data-lazy-src="images/ex-procuradores/feres-sabino-.jpg"
                class="lazy attachment-125x125 size-125x125 lazy-loaded"
                alt=" Feres Sabino "
                loading="lazy"
                title=" Feres Sabino "
                data-lazy-srcset="images/ex-procuradores/feres-sabino-.jpg 100w, images/ex-procuradores/feres-sabino--47x59.jpg 47w"
                data-lazy-sizes="(max-width: 100px) 100vw, 100px"
                srcset="images/ex-procuradores/feres-sabino-.jpg 100w, images/ex-procuradores/feres-sabino--47x59.jpg 47w"
                sizes="(max-width: 100px) 100vw, 100px"
              /><noscript
                ><img
                  width="100"
                  height="125"
                  src="images/ex-procuradores/feres-sabino-.jpg"
                  class="attachment-125x125 size-125x125"
                  alt=" Feres Sabino "
                  loading="lazy"
                  title=" Feres Sabino "
                  srcset="images/ex-procuradores/feres-sabino-.jpg 100w, images/ex-procuradores/feres-sabino--47x59.jpg 47w"
                  sizes="(max-width: 100px) 100vw, 100px"
              /></noscript>
              <div class="descMembro">
                <h4>Feres Sabino</h4>
                <p>
                  30 de Maio de 1985<br />
                  a<br />
                  16 de Março de 1987
                </p>
              </div>
            </div>
            <div class="membro image col-md-4 col-lg-4 col-sm-12 col-xl-4 col-xs-12">
              <img
                width="100"
                height="125"
                src="images/ex-procuradores/norma-jorge-kyriakos.jpg"
                data-lazy-type="image"
                data-lazy-src="images/ex-procuradores/norma-jorge-kyriakos.jpg"
                class="lazy attachment-125x125 size-125x125 lazy-loaded"
                alt=" Norma Jorge Kyriakos"
                loading="lazy"
                title=" Norma Jorge Kyriakos"
                data-lazy-srcset="images/ex-procuradores/norma-jorge-kyriakos.jpg 100w, images/ex-procuradores/norma-jorge-kyriakos-47x59.jpg 47w"
                data-lazy-sizes="(max-width: 100px) 100vw, 100px"
                srcset="images/ex-procuradores/norma-jorge-kyriakos.jpg 100w, images/ex-procuradores/norma-jorge-kyriakos-47x59.jpg 47w"
                sizes="(max-width: 100px) 100vw, 100px"
              /><noscript
                ><img
                  width="100"
                  height="125"
                  src="images/ex-procuradores/norma-jorge-kyriakos.jpg"
                  class="attachment-125x125 size-125x125"
                  alt=" Norma Jorge Kyriakos"
                  loading="lazy"
                  title=" Norma Jorge Kyriakos"
                  srcset="images/ex-procuradores/norma-jorge-kyriakos.jpg 100w, images/ex-procuradores/norma-jorge-kyriakos-47x59.jpg 47w"
                  sizes="(max-width: 100px) 100vw, 100px"
              /></noscript>
              <div class="descMembro">
                <h4>Norma Jorge Kyriakos</h4>
                <p>
                  31 de Janeiro de 1984<br />
                  a<br />
                  30 de Maio de 1985
                </p>
              </div>
            </div>
            <div class="membro image col-md-4 col-lg-4 col-sm-12 col-xl-4 col-xs-12">
              <img
                width="100"
                height="125"
                src="images/ex-procuradores/michel-miguel-elias.jpg"
                data-lazy-type="image"
                data-lazy-src="images/ex-procuradores/michel-miguel-elias.jpg"
                class="lazy attachment-125x125 size-125x125 lazy-loaded"
                alt=" Michel Miguel Elias Temer Lulia"
                loading="lazy"
                title=" Michel Miguel Elias Temer Lulia"
                data-lazy-srcset="images/ex-procuradores/michel-miguel-elias.jpg 100w, images/ex-procuradores/michel-miguel-elias-47x59.jpg 47w"
                data-lazy-sizes="(max-width: 100px) 100vw, 100px"
                srcset="images/ex-procuradores/michel-miguel-elias.jpg 100w, images/ex-procuradores/michel-miguel-elias-47x59.jpg 47w"
                sizes="(max-width: 100px) 100vw, 100px"
              /><noscript
                ><img
                  width="100"
                  height="125"
                  src="images/ex-procuradores/michel-miguel-elias.jpg"
                  class="attachment-125x125 size-125x125"
                  alt=" Michel Miguel Elias Temer Lulia"
                  loading="lazy"
                  title=" Michel Miguel Elias Temer Lulia"
                  srcset="images/ex-procuradores/michel-miguel-elias.jpg 100w, images/ex-procuradores/michel-miguel-elias-47x59.jpg 47w"
                  sizes="(max-width: 100px) 100vw, 100px"
              /></noscript>
              <div class="descMembro">
                <h4>Michel Miguel Elias Temer Lulia</h4>
                <p>
                  16 de Março de 1983 a<br />
                  31 de Janeiro de 1984<br />
                  <br />
                  06 de Abril de 1991 a<br />
                  08 de Outubro de 1992
                </p>
              </div>
            </div>
            <div class="membro image col-md-4 col-lg-4 col-sm-12 col-xl-4 col-xs-12">
              <img
                width="100"
                height="125"
                src="images/ex-procuradores/anna-candida-da.jpg"
                data-lazy-type="image"
                data-lazy-src="images/ex-procuradores/anna-candida-da.jpg"
                class="lazy attachment-125x125 size-125x125 lazy-loaded"
                alt="Anna Cândida da Cunha Ferraz"
                loading="lazy"
                title="Anna Cândida da Cunha Ferraz"
                data-lazy-srcset="images/ex-procuradores/anna-candida-da.jpg 100w, images/ex-procuradores/anna-candida-da-47x59.jpg 47w"
                data-lazy-sizes="(max-width: 100px) 100vw, 100px"
                srcset="images/ex-procuradores/anna-candida-da.jpg 100w, images/ex-procuradores/anna-candida-da-47x59.jpg 47w"
                sizes="(max-width: 100px) 100vw, 100px"
              /><noscript
                ><img
                  width="100"
                  height="125"
                  src="images/ex-procuradores/anna-candida-da.jpg"
                  class="attachment-125x125 size-125x125"
                  alt="Anna Cândida da Cunha Ferraz"
                  loading="lazy"
                  title="Anna Cândida da Cunha Ferraz"
                  srcset="images/ex-procuradores/anna-candida-da.jpg 100w, images/ex-procuradores/anna-candida-da-47x59.jpg 47w"
                  sizes="(max-width: 100px) 100vw, 100px"
              /></noscript>
              <div class="descMembro">
                <h4>Anna Cândida da Cunha Ferraz</h4>
                <p>
                  16 de Março de 1975<br />
                  a<br />
                  15 de Março de 1979
                </p>
              </div>
            </div>
            <div class="membro image col-md-4 col-lg-4 col-sm-12 col-xl-4 col-xs-12">
              <img
                width="100"
                height="125"
                src="images/ex-procuradores/aecio-menucci.jpg"
                data-lazy-type="image"
                data-lazy-src="images/ex-procuradores/aecio-menucci.jpg"
                class="lazy attachment-125x125 size-125x125 lazy-loaded"
                alt=" Aécio Menucci"
                loading="lazy"
                title=" Aécio Menucci"
                data-lazy-srcset="images/ex-procuradores/aecio-menucci.jpg 100w, images/ex-procuradores/aecio-menucci-47x59.jpg 47w"
                data-lazy-sizes="(max-width: 100px) 100vw, 100px"
                srcset="images/ex-procuradores/aecio-menucci.jpg 100w, images/ex-procuradores/aecio-menucci-47x59.jpg 47w"
                sizes="(max-width: 100px) 100vw, 100px"
              /><noscript
                ><img
                  width="100"
                  height="125"
                  src="images/ex-procuradores/aecio-menucci.jpg"
                  class="attachment-125x125 size-125x125"
                  alt=" Aécio Menucci"
                  loading="lazy"
                  title=" Aécio Menucci"
                  srcset="images/ex-procuradores/aecio-menucci.jpg 100w, images/ex-procuradores/aecio-menucci-47x59.jpg 47w"
                  sizes="(max-width: 100px) 100vw, 100px"
              /></noscript>
              <div class="descMembro">
                <h4>Aécio Menucci</h4>
                <p>
                  19 de Agosto de 1971<br />
                  a<br />
                  15 de Março de 1975
                </p>
              </div>
            </div>
            <div class="membro image col-md-4 col-lg-4 col-sm-12 col-xl-4 col-xs-12">
              <img
                width="100"
                height="125"
                src="images/ex-procuradores/gualter-godinho.jpg"
                data-lazy-type="image"
                data-lazy-src="images/ex-procuradores/gualter-godinho.jpg"
                class="lazy attachment-125x125 size-125x125 lazy-loaded"
                alt=" Gualter Godinho"
                loading="lazy"
                title=" Gualter Godinho"
                data-lazy-srcset="images/ex-procuradores/gualter-godinho.jpg 100w, images/ex-procuradores/gualter-godinho-47x59.jpg 47w"
                data-lazy-sizes="(max-width: 100px) 100vw, 100px"
                srcset="images/ex-procuradores/gualter-godinho.jpg 100w, images/ex-procuradores/gualter-godinho-47x59.jpg 47w"
                sizes="(max-width: 100px) 100vw, 100px"
              /><noscript
                ><img
                  width="100"
                  height="125"
                  src="images/ex-procuradores/gualter-godinho.jpg"
                  class="attachment-125x125 size-125x125"
                  alt=" Gualter Godinho"
                  loading="lazy"
                  title=" Gualter Godinho"
                  srcset="images/ex-procuradores/gualter-godinho.jpg 100w, images/ex-procuradores/gualter-godinho-47x59.jpg 47w"
                  sizes="(max-width: 100px) 100vw, 100px"
              /></noscript>
              <div class="descMembro">
                <h4>Gualter Godinho</h4>
                <p>
                  17 de Março de 1971<br />
                  a<br />
                  10 de Agosto de 1971
                </p>
              </div>
            </div>
            <div class="membro image col-md-4 col-lg-4 col-sm-12 col-xl-4 col-xs-12">
              <img
                width="100"
                height="125"
                src="images/ex-procuradores/laercio-francisco-dos-santos.jpg"
                data-lazy-type="image"
                data-lazy-src="images/ex-procuradores/laercio-francisco-dos-santos.jpg"
                class="lazy attachment-125x125 size-125x125 lazy-loaded"
                alt=" Laércio Francisco dos Santos"
                loading="lazy"
                title=" Laércio Francisco dos Santos"
                data-lazy-srcset="images/ex-procuradores/laercio-francisco-dos-santos.jpg 100w, images/ex-procuradores/laercio-francisco-dos-santos-47x59.jpg 47w"
                data-lazy-sizes="(max-width: 100px) 100vw, 100px"
                srcset="images/ex-procuradores/laercio-francisco-dos-santos.jpg 100w, images/ex-procuradores/laercio-francisco-dos-santos-47x59.jpg 47w"
                sizes="(max-width: 100px) 100vw, 100px"
              /><noscript
                ><img
                  width="100"
                  height="125"
                  src="images/ex-procuradores/laercio-francisco-dos-santos.jpg"
                  class="attachment-125x125 size-125x125"
                  alt=" Laércio Francisco dos Santos"
                  loading="lazy"
                  title=" Laércio Francisco dos Santos"
                  srcset="images/ex-procuradores/laercio-francisco-dos-santos.jpg 100w, images/ex-procuradores/laercio-francisco-dos-santos-47x59.jpg 47w"
                  sizes="(max-width: 100px) 100vw, 100px"
              /></noscript>
              <div class="descMembro">
                <h4>Laércio Francisco dos Santos</h4>
                <p>
                  16 de Dezembro de 1969 a<br />
                  16 de Março de 1971<br />
                  <br />
                  17 de Março de 1979<br />
                  a<br />
                  15 de Março de 1983
                </p>
              </div>
            </div>
            <div class="membro image col-md-4 col-lg-4 col-sm-12 col-xl-4 col-xs-12">
              <img
                width="100"
                height="125"
                src="images/ex-procuradores/otto-costa.gif"
                data-lazy-type="image"
                data-lazy-src="images/ex-procuradores/otto-costa.gif"
                class="lazy attachment-125x125 size-125x125 lazy-loaded"
                alt=" Otto Costa"
                loading="lazy"
                title=" Otto Costa"
                data-lazy-srcset="images/ex-procuradores/otto-costa.gif 100w, images/ex-procuradores/otto-costa-47x59.gif 47w"
                data-lazy-sizes="(max-width: 100px) 100vw, 100px"
                srcset="images/ex-procuradores/otto-costa.gif 100w, images/ex-procuradores/otto-costa-47x59.gif 47w"
                sizes="(max-width: 100px) 100vw, 100px"
              /><noscript
                ><img
                  width="100"
                  height="125"
                  src="images/ex-procuradores/otto-costa.gif"
                  class="attachment-125x125 size-125x125"
                  alt=" Otto Costa"
                  loading="lazy"
                  title=" Otto Costa"
                  srcset="images/ex-procuradores/otto-costa.gif 100w, images/ex-procuradores/otto-costa-47x59.gif 47w"
                  sizes="(max-width: 100px) 100vw, 100px"
              /></noscript>
              <div class="descMembro">
                <h4>Otto Costa</h4>
                <p>
                  26 de Dezembro de 1966<br />
                  a<br />
                  16 de Dezembro de 1969
                </p>
              </div>
            </div>
            <div class="membro image col-md-4 col-lg-4 col-sm-12 col-xl-4 col-xs-12">
              <img
                width="100"
                height="125"
                src="images/ex-procuradores/mozart-andreucci.jpg"
                data-lazy-type="image"
                data-lazy-src="images/ex-procuradores/mozart-andreucci.jpg"
                class="lazy attachment-125x125 size-125x125 lazy-loaded"
                alt=" Mozart Andreucci"
                loading="lazy"
                title=" Mozart Andreucci"
                data-lazy-srcset="images/ex-procuradores/mozart-andreucci.jpg 100w, images/ex-procuradores/mozart-andreucci-47x59.jpg 47w"
                data-lazy-sizes="(max-width: 100px) 100vw, 100px"
                srcset="images/ex-procuradores/mozart-andreucci.jpg 100w, images/ex-procuradores/mozart-andreucci-47x59.jpg 47w"
                sizes="(max-width: 100px) 100vw, 100px"
              /><noscript
                ><img
                  width="100"
                  height="125"
                  src="images/ex-procuradores/mozart-andreucci.jpg"
                  class="attachment-125x125 size-125x125"
                  alt=" Mozart Andreucci"
                  loading="lazy"
                  title=" Mozart Andreucci"
                  srcset="images/ex-procuradores/mozart-andreucci.jpg 100w, images/ex-procuradores/mozart-andreucci-47x59.jpg 47w"
                  sizes="(max-width: 100px) 100vw, 100px"
              /></noscript>
              <div class="descMembro">
                <h4>Mozart Andreucci</h4>
                <p>
                  27 de Dezembro de 1962<br />
                  a<br />
                  27 de Outubro de 1966
                </p>
              </div>
            </div>
            <div class="membro image col-md-4 col-lg-4 col-sm-12 col-xl-4 col-xs-12">
              <img
                width="100"
                height="125"
                src="images/ex-procuradores/jose-edgard-barreto.jpg"
                data-lazy-type="image"
                data-lazy-src="images/ex-procuradores/jose-edgard-barreto.jpg"
                class="lazy attachment-125x125 size-125x125 lazy-loaded"
                alt=" José Edgard Barreto"
                loading="lazy"
                title=" José Edgard Barreto"
                data-lazy-srcset="images/ex-procuradores/jose-edgard-barreto.jpg 100w, images/ex-procuradores/jose-edgard-barreto-47x59.jpg 47w"
                data-lazy-sizes="(max-width: 100px) 100vw, 100px"
                srcset="images/ex-procuradores/jose-edgard-barreto.jpg 100w, images/ex-procuradores/jose-edgard-barreto-47x59.jpg 47w"
                sizes="(max-width: 100px) 100vw, 100px"
              /><noscript
                ><img
                  width="100"
                  height="125"
                  src="images/ex-procuradores/jose-edgard-barreto.jpg"
                  class="attachment-125x125 size-125x125"
                  alt=" José Edgard Barreto"
                  loading="lazy"
                  title=" José Edgard Barreto"
                  srcset="images/ex-procuradores/jose-edgard-barreto.jpg 100w, images/ex-procuradores/jose-edgard-barreto-47x59.jpg 47w"
                  sizes="(max-width: 100px) 100vw, 100px"
              /></noscript>
              <div class="descMembro">
                <h4>José Edgard Barreto</h4>
                <p>
                  30 de Junho de 1947<br />
                  a<br />
                  26 de Dezembro de 1962
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
