import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InformaDialog } from './informa-dialog';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
@Component({
  selector: 'app-informa-dialog',
  templateUrl: './informa-dialog.component.html',
  styleUrl: './informa-dialog.component.sass',
  standalone: true,
  imports: [MatIconButton, MatIcon]
})
export class InformaDialogComponent {
  @Output() readonly fechar = new EventEmitter<void>();

  onClose() {
    this.fechar.emit();
    this.dialogRef.close();
  }

  constructor(
    public dialogRef: MatDialogRef<InformaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InformaDialog
  ) {}
}
