import { Component } from '@angular/core';
import { SeparadorComponent } from '../../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-ex-corregedores-gerais-pge',
  templateUrl: './ex-corregedores-gerais-pge.component.html',
  styleUrl: './ex-corregedores-gerais-pge.component.sass',
  standalone: true,
  imports: [SeparadorComponent]
})
export class ExCorregedoresGeraisPgeComponent {}
