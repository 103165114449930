<main class="main" role="main">
  <section class="page">
    <div class="container">
      <app-separador label="Procuradoria Regional de Taubaté (PR03)" />
      <div class="row">
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 the_content">
          <br />
          <h3>PR03 CHEFIA GABINETE</h3>
          <div class="pge-unidade">
            <p class="procurador"><strong>Procurador(a)</strong>: {{ findInArray("ROSELI SEBASTIANA RODRIGUES") }}</p>
            <p class="endereco"><strong>Endereço</strong>: Travessa Rochi Antônio Bonafé, 50, Jardim Sandra Maria, Taubaté-SP, 12081-020.</p>
            <p class="tel"><strong>Telefone</strong>: (12) 3682-1249 | 8952 | 7194 | (13) 3621-4861</p>
            <p class="email"><strong>E-mail</strong>: pr3atendimento&#64;sp.gov.br</p>
            <div class="descricao">
              Municípios abrangidos: Aparecida, Arapeí, Areias, Bananal, Cachoeira Paulista, Campos do Jordão, Caçapava, Canas; Caraguatatuba, Cruzeiro, Cunha Guaratinguetá, Ilha Bela, Jacareí,
              Jambeiro, Lagoinha, Lavrinhas, Lorena, Monteiro Lobato, Natividade da Serra, Paraibuna, Pindamonhangaba, Piquete, Potim, Queluz, Redenção da Serra, Roseira, Salesópolis, Santa Branca,
              Santo Antônio do Pinhal, São Bento do Sapucaí, São José do Barreiro, São José dos Campos, São Luiz do Paraitinga, São Sebastião, Silveiras, Taubaté, Tremembé, Ubatuba.
            </div>
          </div>
          <br />
          <hr />
          <br />
          <h3>SECCIONAL DE SÃO JOSÉ DOS CAMPOS</h3>
          <div class="pge-unidade">
            <p class="procurador"><strong>Procurador(a)</strong>: PAULA COSTA DE PAIVA</p>
            <p class="endereco"><strong>Endereço</strong>: Centro Comercial Aquarius Center – Prédio II – Avenida Cassiano Ricardo, 521 – salas 11 e 12.</p>
            <p class="tel"><strong>Telefone</strong>: (12) 3923-8697 | 7389 | 5503 | (12) 3921-4302</p>
          </div>
          <br />
          <hr />
          <br />
          <app-voltar-component />
          <br />
        </div>
      </div>
    </div>
  </section>
</main>
