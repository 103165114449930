<app-separador label="Relatórios" />
<p><a href="http://www.portal.pge.sp.gov.br/wp-content/uploads/2024/08/relatorio_da_ouvidoria_1_semestre_2024.pdf" target="_blank" rel="noopener noreferrer">Relatório 2024 – 1º Semestre</a></p>
<p><a href="http://www.portal.pge.sp.gov.br/wp-content/uploads/2023/09/relatorio-ouvidoria-2o-semestre-2022.pdf" target="_blank" rel="noopener noreferrer">Relatório 2023 – 2º Semestre</a></p>
<p><a href="http://www.portal.pge.sp.gov.br/wp-content/uploads/2023/09/relatorio-ouvidoria-1o-semestre-2023.pdf" target="_blank" rel="noopener noreferrer">Relatório 2023 – 1º Semestre</a></p>
<p><a href="http://www.portal.pge.sp.gov.br/wp-content/uploads/2023/09/relatorio-ouvidoria-2o-semestre-2022.pdf" target="_blank" rel="noopener noreferrer">Relatório 2022 – 2º Semestre</a></p>
<p><a href="http://www.portal.pge.sp.gov.br/wp-content/uploads/2022/08/relatorio_1semestre_2022.pdf" target="_blank" rel="noopener noreferrer">Relatório 2022 – 1º Semestre</a></p>
<p><a href="http://www.portal.pge.sp.gov.br/wp-content/uploads/2022/02/relatorio_022021.pdf" target="_blank" rel="noopener noreferrer">Relatório 2021 – 2º Semestre</a></p>
<p><a href="http://www.portal.pge.sp.gov.br/wp-content/uploads/2021/08/relatorio_012021.pdf" target="_blank" rel="noopener noreferrer">Relatório 2021 – 1º Semestre</a></p>
<p><a href="http://www.portal.pge.sp.gov.br/wp-content/uploads/2021/08/relatorio_022020.pdf" target="_blank" rel="noopener noreferrer">Relatório 2020 – 2º Semestre</a></p>
<p><a href="http://www.portal.pge.sp.gov.br/wp-content/uploads/2021/02/relatorio_012020.pdf" target="_blank" rel="noopener noreferrer">Relatório 2020 – 1º Semestre</a></p>
<p><a href="http://www.portal.pge.sp.gov.br/wp-content/uploads/2021/02/relatorio_022019.pdf" target="_blank" rel="noopener noreferrer">Relatório 2019 – 2º Semestre</a></p>
<p><a href="http://www.portal.pge.sp.gov.br/wp-content/uploads/2021/02/relatorio_012019.pdf" target="_blank" rel="noopener noreferrer">Relatório 2019 – 1º Semestre</a></p>
