import { Component, Input } from '@angular/core';
import { AuthenticationService } from 'src/app/area-restrita/shared/services/authentication.service';
import { MenuNode } from '../MenuNode';
import { MenuSguService } from '../MenuSgu.service';
import { MatExpansionPanel, MatExpansionPanelHeader } from '@angular/material/expansion';
import { MatIcon } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { MatListItem, MatListItemIcon, MatListItemLine, MatNavList } from '@angular/material/list';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrl: './menu-item.component.sass',
  standalone: true,
  imports: [MatListItem, RouterLink, MatIcon, MatListItemIcon, MatListItemLine, MatExpansionPanel, MatExpansionPanelHeader, MatNavList]
})
export class MenuItemComponent {
  @Input() item: MenuNode;
  @Input() isMenuOpen: boolean;
  @Input() isShowing: boolean;

  visibleItems = new Set<number>();
  constructor(
    private authenticationService: AuthenticationService,
    private menuSguService: MenuSguService
  ) {}
  toggleVisibility(item: MenuNode) {
    if (this.visibleItems.has(item.id)) {
      this.visibleItems.delete(item.id);
    } else {
      this.visibleItems.add(item.id);
    }
  }

  isVisible(item: MenuNode): boolean {
    return this.visibleItems.has(item.id);
  }

  changeRoute(router: string = ''): string[] {
    return router ? [router] : [];
  }

  sobreItemClicado(): void {
    this.menuSguService.emitMenuItemclicado(this.item);
  }

  logout(route: string) {
    if (route == 'logout-site-pge') this.authenticationService.logout();
  }
}
