import { Component } from '@angular/core';
import { SeparadorComponent } from '../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-programa-integridade-ouvidoria',
  templateUrl: './programa-integridade-ouvidoria.component.html',
  styleUrl: './programa-integridade-ouvidoria.component.css',
  standalone: true,
  imports: [SeparadorComponent]
})
export class ProgramaIntegridadeOuvidoriaComponent {}
