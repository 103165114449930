<main class="main" role="main">
  <section class="page">
    <div class="container">
      <app-separador label="Procuradoria Regional de Presidente Prudente (PR10)"></app-separador>
      <div class="row">
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 the_content">
          <br />
          <h3>PR10 CHEFIA GABINETE</h3>
          <div class="pge-unidade">
            <p class="procurador"><strong>Procurador(a)</strong>: {{ findInArray("JOSE MARIA ZANUTO") }}</p>
            <p class="endereco"><strong>Endereço</strong>: Avenida Coronel José Soares Marcondes,1394, , Centro, Presidente Prudente-SP, 19010-081.</p>
            <p class="tel"><strong>Telefone</strong>: (18) 3222-7233 ou (18) 3223-6770</p>
            <p class="email"><strong>E-mail</strong>: pr10atendimento&#64;sp.gov.br</p>
            <div class="descricao">
              <strong>Municípios abrangidos:</strong> Adamantina, Alfredo Marcondes, Álvares Machado, Anhumas, Caiabu, Caiua, Dracena, Emilianópolis, Estrela do Norte, Euclides da Cunha Paulista,
              Flora Rica, Flórida Paulista, Iepê, Indiana, Inubia Paulista, Irapuru, Junqueirópolis, Lucélia, Marabá Paulista, Mariápolis, Martinópolis, Mirante do Paranapanema, Monte Castelo, Nantes,
              Narandiba, Nova Guataporanga, Osvaldo Cruz, Ouro Verde, Pacaembú, Panorama, Parapuã, Paulicéia, Piquerobi, Pirapozinho, Pracinha, Presidente Bernardes, Presidente Epitácio, Presidente
              Prudente, Presidente Venceslau, Rancharia, Regente Feijó, Ribeirão dos Índios, Rosana, Sagres, Salmourão, Sandovalina, Santa Mercedes, Santo Anastácio, Santo Expedito, São João do Pau
              D'Alho, Taciba, Tarabaí, Teodoro Sampaio, Tupi Paulista.
            </div>
          </div>
          <br />
          <hr />
          <br />
          <h3>SECCIONAL DE DRACENA</h3>
          <div class="pge-unidade">
            <p class="procurador"><strong>Procurador(a)</strong>: {{ findInArray("DANIELA RODRIGUES VALENTIM ANGELOTTI") }}</p>
            <p class="endereco"><strong>Endereço</strong>: Av. Expedicionários, 1267, 2º andar, sala 203, Centro, Dracena-SP, 17900-000.</p>
            <p class="tel"><strong>Telefone</strong>: Tel. (18) 3821 3925</p>
          </div>
          <br />
          <hr />
          <br />
          <app-voltar-component></app-voltar-component>
          <br />
        </div>
      </div>
    </div>
  </section>
</main>
