<main class="main" role="main">
  <section class="page">
    <app-separador label="Procuradorias Especializadas" />
    <div class="row">
      <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 the_content">
        <h3>Procuradoria da Dívida Ativa</h3>
        <p class="procurador"><strong>Procurador(a) Chefe</strong>:<br /></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ findInArray("ELAINE VIEIRA DA MOTTA") }}</p>
        </div>
        <p class="endereco"><strong>Endereço</strong>: Rua Pamplona, 227, 6º andar, Jardim Paulista, São Paulo-SP, 01415-902.</p>
        <p class="tel"><strong>Telefone</strong>: (11) 3372-6438</p>
        <p><strong>Demais Procuradores</strong></p>
        <!-- Essa lista está mocada -->
        <p>ALVARO FEITOSA DA SILVA FILHO</p>
        <p>ANA CRISTINA VENOSA DE OLIVEIRA LIMA</p>
        <p>CAMILA ROCHA SCHWENCK</p>
        <p>FERNANDA BARDICHIA PILAT YAMAMOTO</p>
        <p>FILIPE GADELHA DIOGENES FORTES</p>
        <p>RENATO PEIXOTO PIEDADE BICUDO</p>
        <p>SIBELE FERRIGNO POLI IDE ALVES</p>
        <hr />
        <h3>Procuradoria Fiscal (PF)</h3>
        <p class="procurador"><strong>Procurador(a) Chefe</strong>:<br /></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ findInArray("REBECCA CORREA PORTO DE FREITAS") }}</p>
        </div>
        <p class="endereco"><strong>Endereço</strong>: Avenida Rangel Pestana, 300, 16º andar, Centro, São Paulo-SP, 01017-911.</p>
        <p class="tel"><strong>Telefone</strong>: (11) 3241-4790 | (11) 3243-3541 | (11) 3105-9098</p>
        <p class="email"><strong>E-mail</strong>: pfatendimento&#64;sp.gov.br</p>
        <p><strong>Assistentes</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ findInArray("FABIO AUGUSTO DAHER MONTES") }}</p>
          <p class="demais-procuradores">{{ findInArray("MICHELLE MANAIA SANJAR") }}</p>
          <p class="demais-procuradores">{{ findInArray("SUMAYA RAPHAEL MUCKDOSSE") }}</p>
        </div>
        <p>&nbsp;</p>
        <p><strong>Chefes de subprocuradorias</strong></p>
        <p><strong>1ª Subprocuradoria</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ findInArray("RICARDO RODRIGUES FERREIRA") }}</p>
        </div>
        <p><strong>1A.SECCIONAL(PF-11)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ findInArray("CINTIA WATANABE") }}</p>
        </div>
        <p><strong>2A.SECCIONAL(PF-12)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>3A.SECCIONAL(PF-13)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>4A.SECCIONAL(PF-14)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>&nbsp;</strong><strong>5A.SECCIONAL(PF-15)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ findInArray("VALERIA LUCHIARI MAGALHAES") }}</p>
        </div>
        <p><strong>6A.SECCIONAL(PF-16)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>2ª Subprocuradoria (PF-2)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>1A.SECCIONAL(PF-21)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>1A.SECCIONAL(PF-22)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ findInArray("HELOISE WITTMANN MAIA") }}</p>
        </div>
        <p><strong>3ª Subprocuradoria (PF-3)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>1A.SECCIONAL(PF-31)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>1A.SECCIONAL(PF-32)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>4ª Subprocuradoria (PF-4)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ findInArray("FERNANDA BITTENCOURT PORCHAT GODOY") }}</p>
        </div>
        <p><strong>5ª Subprocuradoria (PF-5)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>1A.SECCIONAL(PF-51)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ findInArray("VALERIA LUCHIARI MAGALHAES") }}</p>
        </div>
        <p><strong>2A.SECCIONAL(PF-52)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ findInArray("RAPHAEL FRANCO DEL DUCA") }}</p>
        </div>
        <p><strong>3A.SECCIONAL(PF-53)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ findInArray("SERGIO MAIA") }}</p>
        </div>
        <p><strong>4A.SECCIONAL(PF-54)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ findInArray("KELLY PAULINO VENANCIO") }}</p>
        </div>
        <p><strong>5A.SECCIONAL(PF-55)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ findInArray("MARIA IZABEL ALVES DE ANDRE") }}</p>
        </div>
        <p><strong>6A.SECCIONAL(PF-56)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>7A.SECCIONAL(PF-57)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ findInArray("DANIELE CRISTINA MORALES") }}</p>
        </div>
        <p><strong>8A.SECCIONAL(PF-58)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>9A.SECCIONAL(PF-59)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>6ª Subprocuradoria (PF-6)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>1A.SECCIONAL(PF-61)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>1A.SECCIONAL(PF-62)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>7ª Subprocuradoria (PF-7)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>1A.SECCIONAL(PF-71)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>2A.SECCIONAL(PF-72)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>3A.SECCIONAL(PF-73)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>4A.SECCIONAL(PF-74)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>5A.SECCIONAL(PF-75)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>6A.SECCIONAL</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>7A.SECCIONAL</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>8A.SECCIONAL</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>9A.SECCIONAL</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>13A.SECCIONAL</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p>&nbsp;</p>
        <p><strong>Procuradores lotados na unidade e subprocuradorias</strong></p>
        <div class="pge-unidade"></div>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ findInArray("ADRIANO VIDIGAL MARTINS") }}</p>
          <p class="demais-procuradores">{{ findInArray("ALEXANDRE DOTOLI NETO") }}</p>
          <p class="demais-procuradores">{{ findInArray("ALEXANDRE FERNANDES MACHADO") }}</p>
          <p class="demais-procuradores">{{ findInArray("AMARILIS INOCENTE BOCAFOLI") }}</p>
          <p class="demais-procuradores">{{ findInArray("ANNA PAULA SENA DE GOBBI") }}</p>
          <p class="demais-procuradores">{{ findInArray("AUGUSTO RODRIGUES PORCIUNCULA") }}</p>
          <p class="demais-procuradores">{{ findInArray("BERNARDO SANTOS SILVA") }}</p>
          <p class="demais-procuradores">{{ findInArray("CARLOS OGAWA COLONTONIO") }}</p>
          <p class="demais-procuradores">{{ findInArray("CONRADO LUIZ RIBEIRO SILVA BARROS") }}</p>
          <p class="demais-procuradores">{{ findInArray("DANIEL AREVALO NUNES DA CUNHA") }}</p>
          <p class="demais-procuradores">{{ findInArray("DANIELA SPIGOLON LOUREIRO") }}</p>
          <p class="demais-procuradores">{{ findInArray("DENISE FERREIRA DE OLIVEIRA CHEID") }}</p>
          <p class="demais-procuradores">{{ findInArray("FABIOLA TEIXEIRA SALZANO") }}</p>
          <p class="demais-procuradores">{{ findInArray("GUSTAVO CAMPOS ABREU") }}</p>
          <p class="demais-procuradores">{{ findInArray("HEYDE MEDEIROS COSTA LIMA ROCHA") }}</p>
          <p class="demais-procuradores">{{ findInArray("JULIANA DE OLIVEIRA COSTA GOMES SATO") }}</p>
          <p class="demais-procuradores">{{ findInArray("LAURO TERCIO BEZERRA CAMARA") }}</p>
          <p class="demais-procuradores">{{ findInArray("LUCAS CAPARELLI GUIMARAES PINTO CORREIA") }}</p>
          <p class="demais-procuradores">{{ findInArray("MARA REGINA CASTILHO REINAUER ONG") }}</p>
          <p class="demais-procuradores">{{ findInArray("MARCELA NOLASCO FERREIRA JORGE") }}</p>
          <p class="demais-procuradores">{{ findInArray("MARCELO ROBERTO BOROWSKI") }}</p>
          <p class="demais-procuradores">{{ findInArray("MARCIO FERNANDO FONTANA") }}</p>
          <p class="demais-procuradores">{{ findInArray("MARCIO YUKIO SANTANA KAZIURA") }}</p>
          <p class="demais-procuradores">{{ findInArray("MARINA MENEZES LEITE PRACA") }}</p>
          <p class="demais-procuradores">{{ findInArray("MARISA MIDORI ISHII") }}</p>
          <p class="demais-procuradores">{{ findInArray("NATALIA MUSA DOMINGUEZ NUNES") }}</p>
          <p class="demais-procuradores">{{ findInArray("RAFAEL BARROSO DE ANDRADE") }}</p>
          <p class="demais-procuradores">{{ findInArray("RENATO PEIXOTO PIEDADE BICUDO") }}</p>
          <p class="demais-procuradores">{{ findInArray("TALITA LEIXAS RANGEL") }}</p>
          <p class="demais-procuradores">{{ findInArray("TATIANA FREIRE PINTO") }}</p>
          <p class="demais-procuradores">{{ findInArray("VINICIUS JOSE ALVES AVANZA") }}</p>
          <p class="demais-procuradores">{{ findInArray("VITOR MAURICIO BRAZ DI MASI") }}</p>
          <p class="demais-procuradores">{{ findInArray("BRENO AUGUSTO MACIEL RIBEIRO DE LIMA") }}</p>
          <p class="demais-procuradores">{{ findInArray("DANIEL CASTILLO REIGADA") }}</p>
          <p class="demais-procuradores">{{ findInArray("PEDRO HENRIQUE LACERDA BARBOSA LADEIA") }}</p>
          <p class="demais-procuradores">{{ findInArray("VANESSA MOTTA TARABAY") }}</p>
          <p class="demais-procuradores">{{ findInArray("FREDERICO BENDZIUS") }}</p>
          <p class="demais-procuradores">{{ findInArray("LIETE BADARO ACCIOLI PICCAZIO") }}</p>
          <p class="demais-procuradores">{{ findInArray("PAULO GONCALVES DA COSTA JUNIOR") }}</p>
          <p class="demais-procuradores">{{ findInArray("ANA MARIA DE SANT ANA") }}</p>
          <p class="demais-procuradores">{{ findInArray("REGINA PAULA RIBEIRO DE CARVALHO CASERTA") }}</p>
          <p class="demais-procuradores">{{ findInArray("ALYNE BASILIO DE ASSIS") }}</p>
          <p class="demais-procuradores">{{ findInArray("AYLTON MARCELO BARBOSA DA SILVA") }}</p>
          <p class="demais-procuradores">{{ findInArray("CARINE SOARES FERRAZ") }}</p>
          <p class="demais-procuradores">{{ findInArray("CARLA HANDEL MISTRORIGO") }}</p>
          <p class="demais-procuradores">{{ findInArray("CARLOS ALBERTO BITTAR FILHO") }}</p>
          <p class="demais-procuradores">{{ findInArray("CARLOS EDUARDO FERNANDES DA SILVEIRA") }}</p>
          <p class="demais-procuradores">{{ findInArray("CLAUDIA CARDOSO CHAHOUD") }}</p>
          <p class="demais-procuradores">{{ findInArray("FELIPE ABRAHAO VEIGA JABUR") }}</p>
          <p class="demais-procuradores">{{ findInArray("GABRIEL TEIXEIRA DE OLIVEIRA") }}</p>
          <p class="demais-procuradores">{{ findInArray("IGOR DENISARD DANTAS MELO") }}</p>
          <p class="demais-procuradores">{{ findInArray("JORGE MIGUEL FILHO") }}</p>
          <p class="demais-procuradores">{{ findInArray("LUAN BRANCHER GUSSO MACHADO") }}</p>
          <p class="demais-procuradores">{{ findInArray("LUCIANA GIACOMINI OCCHIUTO NUNES") }}</p>
          <p class="demais-procuradores">{{ findInArray("LUCIANA PACHECO BASTOS DOS SANTOS") }}</p>
          <p class="demais-procuradores">{{ findInArray("MARCELO DE CARVALHO") }}</p>
          <p class="demais-procuradores">{{ findInArray("MARCIA REGINA BONAVINA") }}</p>
          <p class="demais-procuradores">{{ findInArray("MILTON DEL TRONO GROSCHE") }}</p>
          <p class="demais-procuradores">{{ findInArray("MONICA MARIA PETRI FARSKY") }}</p>
          <p class="demais-procuradores">{{ findInArray("MONICA TONETTO FERNANDEZ") }}</p>
          <p class="demais-procuradores">{{ findInArray("PAULO ALVES NETTO DE ARAUJO") }}</p>
          <p class="demais-procuradores">{{ findInArray("RAFAEL DE OLIVEIRA RODRIGUES") }}</p>
          <p class="demais-procuradores">{{ findInArray("ROMANOVA ABUD CHINAGLIA PAULA LIMA") }}</p>
          <p class="demais-procuradores">{{ findInArray("ROSE ANNE TANAKA") }}</p>
          <p class="demais-procuradores">{{ findInArray("ANA MARIA DE SANT ANA") }}</p>
          <p class="demais-procuradores">{{ findInArray("REGINA PAULA RIBEIRO DE CARVALHO CASERTA") }}</p>
          <p class="demais-procuradores">{{ findInArray("ADRIANO VIDIGAL MARTINS") }}</p>
          <p class="demais-procuradores">{{ findInArray("ALEXANDRE DOTOLI NETO") }}</p>
          <p class="demais-procuradores">{{ findInArray("ALEXANDRE FERNANDES MACHADO") }}</p>
          <p class="demais-procuradores">{{ findInArray("AMARILIS INOCENTE BOCAFOLI") }}</p>
          <p class="demais-procuradores">{{ findInArray("ANNA PAULA SENA DE GOBBI") }}</p>
          <p class="demais-procuradores">{{ findInArray("AUGUSTO RODRIGUES PORCIUNCULA") }}</p>
          <p class="demais-procuradores">{{ findInArray("BERNARDO SANTOS SILVA") }}</p>
          <p class="demais-procuradores">{{ findInArray("CARLOS OGAWA COLONTONIO") }}</p>
          <p class="demais-procuradores">{{ findInArray("CONRADO LUIZ RIBEIRO SILVA BARROS") }}</p>
          <p class="demais-procuradores">{{ findInArray("DANIEL AREVALO NUNES DA CUNHA") }}</p>
          <p class="demais-procuradores">{{ findInArray("DANIELA SPIGOLON LOUREIRO") }}</p>
          <p class="demais-procuradores">{{ findInArray("DENISE FERREIRA DE OLIVEIRA CHEID") }}</p>
          <p class="demais-procuradores">{{ findInArray("FABIOLA TEIXEIRA SALZANO") }}</p>
          <p class="demais-procuradores">{{ findInArray("GUSTAVO CAMPOS ABREU") }}</p>
          <p class="demais-procuradores">{{ findInArray("HEYDE MEDEIROS COSTA LIMA ROCHA") }}</p>
          <p class="demais-procuradores">{{ findInArray("JULIANA DE OLIVEIRA COSTA GOMES SATO") }}</p>
          <p class="demais-procuradores">{{ findInArray("LAURO TERCIO BEZERRA CAMARA") }}</p>
          <p class="demais-procuradores">{{ findInArray("LUCAS CAPARELLI GUIMARAES PINTO CORREIA") }}</p>
          <p class="demais-procuradores">{{ findInArray("MARA REGINA CASTILHO REINAUER ONG") }}</p>
          <p class="demais-procuradores">{{ findInArray("MARCELA NOLASCO FERREIRA JORGE") }}</p>
          <p class="demais-procuradores">{{ findInArray("MARCELO ROBERTO BOROWSKI") }}</p>
          <p class="demais-procuradores">{{ findInArray("MARCIO FERNANDO FONTANA") }}</p>
          <p class="demais-procuradores">{{ findInArray("MARCIO YUKIO SANTANA KAZIURA") }}</p>
          <p class="demais-procuradores">{{ findInArray("MARINA MENEZES LEITE PRACA") }}</p>
          <p class="demais-procuradores">{{ findInArray("MARISA MIDORI ISHII") }}</p>
          <p class="demais-procuradores">{{ findInArray("NATALIA MUSA DOMINGUEZ NUNES") }}</p>
          <p class="demais-procuradores">{{ findInArray("RAFAEL BARROSO DE ANDRADE") }}</p>
          <p class="demais-procuradores">{{ findInArray("RENATO PEIXOTO PIEDADE BICUDO") }}</p>
          <p class="demais-procuradores">{{ findInArray("TALITA LEIXAS RANGEL") }}</p>
          <p class="demais-procuradores">{{ findInArray("TATIANA FREIRE PINTO") }}</p>
          <p class="demais-procuradores">{{ findInArray("VINICIUS JOSE ALVES AVANZA") }}</p>
          <p class="demais-procuradores">{{ findInArray("VITOR MAURICIO BRAZ DI MASI") }}</p>
          <p class="demais-procuradores">{{ findInArray("BRENO AUGUSTO MACIEL RIBEIRO DE LIMA") }}</p>
          <p class="demais-procuradores">{{ findInArray("DANIEL CASTILLO REIGADA") }}</p>
          <p class="demais-procuradores">{{ findInArray("PEDRO HENRIQUE LACERDA BARBOSA LADEIA") }}</p>
          <p class="demais-procuradores">{{ findInArray("VANESSA MOTTA TARABAY") }}</p>
          <p class="demais-procuradores">{{ findInArray("FREDERICO BENDZIUS") }}</p>
          <p class="demais-procuradores">{{ findInArray("LIETE BADARO ACCIOLI PICCAZIO") }}</p>
          <p class="demais-procuradores">{{ findInArray("PAULO GONCALVES DA COSTA JUNIOR") }}</p>
          <p class="demais-procuradores">{{ findInArray("ELISA VIEIRA LOPEZ") }}</p>
          <p class="demais-procuradores">{{ findInArray("LEONARDO GONCALVES RUFFO") }}</p>
          <p class="demais-procuradores">{{ findInArray("LEYDSLAYNE ISRAEL LACERDA") }}</p>
          <p class="demais-procuradores">{{ findInArray("MAIRA GABRIELA AVELAR VIEIRA") }}</p>
          <p class="demais-procuradores">{{ findInArray("ARTUR BARBOSA DA SILVEIRA") }}</p>
          <p class="demais-procuradores">{{ findInArray("LIGIA PEREIRA BRAGA VIEIRA") }}</p>
          <p class="demais-procuradores">{{ findInArray("CARMEN SABRINA COCHRANE SANTIAGO VIANA") }}</p>
        </div>
      </div>
    </div>
  </section>
</main>
