import { Component, Input, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MatCard, MatCardHeader, MatCardContent, MatCardFooter } from '@angular/material/card';

@Component({
  selector: 'app-card-servico',
  templateUrl: './card-servico.component.html',
  styleUrl: './card-servico.component.sass',
  standalone: true,
  imports: [MatCard, MatCardHeader, MatCardContent, MatCardFooter, RouterLink]
})
export class CardServicoComponent implements OnInit {
  assetsPath = environment.assetsPath + `/images/svg/`;

  constructor(private router: Router) {}
  @Input() cardContent;
  svgPath = '';
  ngOnInit(): void {
    this.svgPath = `${environment.assetsPath}/images/svg/${this.cardContent.icone}.svg`;
  }

  redirect(route: string) {
    this.router.navigateByUrl(route);
  }
}
