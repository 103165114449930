<main class="main" role="main">
  <section class="page">
    <app-separador label="Apresentação"></app-separador>
    <div class="row">
      <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 the_content">
        <p>O Conselho é composto por <strong>14 membros</strong>, sendo 6 natos e 8 eleitos.</p>
        <p>
          Os membros natos são: o Procurador Geral do Estado (que preside o Órgão), o Procurador do Estado Corregedor-Geral e os Subprocuradores Gerais das três áreas de atuação (Consultoria,
          Contencioso Geral e Contencioso Tributário Fiscal) e o Procurador do Estado Chefe do Centro de Estudos.
        </p>
        <p>Os demais membros são escolhidos diretamente pela carreira: 1 representante de cada uma das áreas de atuação e 1 representante de cada um dos níveis da carreira.</p>
        <p>Os membros eleitos possuem mandato de 2 anos, vedada a reeleição.</p>
        <h3>Sessões</h3>
        <p>
          As reuniões ordinárias do Conselho realizam-se quinzenalmente, às sextas-feiras, a partir das 09h30. Ponto alto das sessões, o “Momento do Procurador” (presencial ou virtual) faculta a
          participação direta dos Procuradores do Estado que queiram se manifestar sobre quaisquer assuntos de interesse da Instituição.<br />
          Legislação Legislação do Conselho
        </p>
        <p>&nbsp;</p>
        <p><a href="./assets/pdf/lei_organica_pge_revista_e_atualizao_lc_1082_08_1.pdf" target="_blank">Legislação</a></p>
        <p><a href="./assets/pdf/legislacao_relativa_ao_conselho_atualizada_em_agosto_de_2021.pdf" target="_blank">Legislação do Conselho</a></p>
        <hr />
      </div>
    </div>
  </section>
</main>
