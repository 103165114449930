import { environment } from 'src/environments/environment';
import { MenuItem } from './menu.model';

export const MenuList: MenuItem[] = [
  {
    label: 'INSTITUCIONAL',
    children: [
      {
        label: 'Sobre a PGE',
        externo: false,
        redirect: 'menu/INSTITUCIONAL'
      },

      {
        label: 'Áreas de Atuação',
        externo: false,
        redirect: 'menu/AREASATUACAO'
      },

      {
        externo: true,
        redirect: 'pdf/carta-de-servicos-pge-2024.pdf',
        label: 'Carta de Serviços'
      },

      {
        label: 'Corregedoria',
        externo: false,
        redirect: 'menu/CORREGEDORIA'
      },

      {
        label: 'Conselho',
        externo: false,
        redirect: 'menu/CONSELHO'
      },

      {
        label: 'Ouvidoria',
        externo: false,
        redirect: 'menu/OUVIDORIA'
      }
    ]
  },

  {
    label: 'INFORMAÇÃO AO CIDADÃO',
    children: [
      {
        externo: false,
        redirect: 'informacao-cidadao/atendimento-protestos-icms-ipva-itcmd-certidao-negativa',
        label: 'Atendimento sobre protestos/ ICMS / IPVA / ITCMD / Certidão Negativa'
      },
      {
        externo: false,
        redirect: 'informacao-cidadao/cepen',
        label: 'Certidão Positiva com Efeito de Negativa (Cepen)'
      },
      {
        externo: false,
        redirect: 'informacao-cidadao/credenciamento-contadores',
        label: 'Credenciamento de contadores'
      },
      {
        externo: true,
        redirect: 'https://www.pge.sp.gov.br/editais/licitacoes/index.aspx',
        label: 'Compras e Contratações'
      },
      {
        externo: false,
        redirect: 'informacao-cidadao/enderecos-telefones-uteis',
        label: 'Endereços e Telefones Úteis'
      },
      {
        externo: true,
        redirect: 'pdf/entidades-representadas-pela-pge.pdf',
        label: 'Entidades representadas pela PGE'
      },
      {
        externo: false,
        redirect: 'informacao-cidadao/localizar-regionais',
        label: 'Localize a Regional'
      }
    ]
  },
  {
    label: 'CENTRO DE ESTUDOS',
    children: [
      {
        externo: true,
        redirect: 'https://www.pge.sp.gov.br/CentroEstudo/Default.htm',
        label: 'Site CE'
      },
      {
        externo: true,
        redirect: 'http://biblioteca.pge.sp.gov.br',
        label: 'Biblioteca'
      },
      {
        externo: true,
        redirect: 'https://revistas.pge.sp.gov.br',
        label: 'Publicações'
      },
      {
        externo: true,
        redirect: environment.siteEscola,
        label: 'Escola Superior da PGE'
      }
    ]
  },
  {
    externo: true,
    redirect: 'https://www.pge.sp.gov.br/Login.aspx?ReturnUrl=%2frestrito%2fdefault.aspx',
    label: 'ÁREA RESTRITA'
  }
];

export const MenuListLocalDev: MenuItem[] = [
  {
    label: 'INSTITUCIONAL',
    children: [
      {
        label: 'Sobre a PGE',
        externo: false,
        redirect: 'menu/INSTITUCIONAL'
      },

      {
        label: 'Áreas de Atuação',
        externo: false,
        redirect: 'menu/AREASATUACAO'
      },

      {
        externo: true,
        redirect: 'pdf/carta-de-servicos-pge-2024.pdf',
        label: 'Carta de Serviços'
      },

      {
        label: 'Corregedoria',
        externo: false,
        redirect: 'menu/CORREGEDORIA'
      },

      {
        label: 'Conselho',
        externo: false,
        redirect: 'menu/CONSELHO'
      },

      {
        label: 'Ouvidoria',
        externo: false,
        redirect: 'menu/OUVIDORIA'
      }
    ]
  },

  {
    label: 'INFORMAÇÃO AO CIDADÃO',
    children: [
      {
        externo: false,
        redirect: 'informacao-cidadao/atendimento-protestos-icms-ipva-itcmd-certidao-negativa',
        label: 'Atendimento sobre protestos/ ICMS / IPVA / ITCMD / Certidão Negativa'
      },
      {
        externo: false,
        redirect: 'informacao-cidadao/cepen',
        label: 'Certidão Positiva com Efeito de Negativa (Cepen)'
      },
      {
        externo: false,
        redirect: 'informacao-cidadao/credenciamento-contadores',
        label: 'Credenciamento de contadores'
      },
      {
        externo: true,
        redirect: 'https://www.pge.sp.gov.br/editais/licitacoes/index.aspx',
        label: 'Compras e Contratações'
      },
      {
        externo: false,
        redirect: 'informacao-cidadao/enderecos-telefones-uteis',
        label: 'Endereços e Telefones Úteis'
      },
      {
        externo: true,
        redirect: 'pdf/entidades-representadas-pela-pge.pdf',
        label: 'Entidades representadas pela PGE'
      },
      {
        externo: false,
        redirect: 'informacao-cidadao/localizar-regionais',
        label: 'Localize a Regional'
      }
    ]
  },
  {
    label: 'CENTRO DE ESTUDOS',
    children: [
      {
        externo: true,
        redirect: 'https://www.pge.sp.gov.br/CentroEstudo/Default.htm',
        label: 'Site CE'
      },
      {
        externo: true,
        redirect: 'http://biblioteca.pge.sp.gov.br',
        label: 'Biblioteca'
      },
      {
        externo: true,
        redirect: 'https://revistas.pge.sp.gov.br',
        label: 'Publicações'
      },
      {
        externo: true,
        redirect: environment.siteEscola,
        label: 'Escola Superior da PGE'
      }
    ]
  },
  {
    externo: false,
    redirect: 'login',
    label: 'ÁREA RESTRITA'
  }
];

export const temporariamenteRemovidos: MenuItem[] = [
  {
    label: 'Dívida Ativa',
    children: [
      {
        externo: true,
        redirect: 'https://www.dividaativa.pge.sp.gov.br/sc/pages/consultas/consultarDebito.jsf',
        label: 'Consulte seu débito'
      },
      {
        externo: true,
        redirect: 'https://www.dividaativa.pge.sp.gov.br/sc/pages/pagamento/gareLiquidacao.jsf',
        label: 'GARE para liquidação'
      },
      {
        externo: true,
        redirect: 'https://www.dividaativa.pge.sp.gov.br/sc/pages/consultas/consultarDebito.jsf',
        label: 'Parcelamento de débitos'
      },
      {
        externo: true,
        redirect: 'https://www.dividaativa.pge.sp.gov.br/sc/pages/pagamento/gareParcelamento.jsf',
        label: 'GARE para parcelamento'
      },
      {
        externo: true,
        redirect: 'https://www.dividaativa.pge.sp.gov.br/sc/pages/crda/emitirCrda.jsf',
        label: 'Certidão de regularidade'
      },
      {
        externo: true,
        redirect: 'https://www.dividaativa.pge.sp.gov.br/transacao/pages/home/home.jsf',
        label: 'Transação (Programa com Desconto)'
      },
      {
        label: 'Aplicativo Mobile PDA PGE-SP',
        children: [
          {
            externo: true,
            redirect: 'https://play.google.com/store/apps/details?id=br.gov.sp.prodesp.pptdigital',
            label: 'Google Play'
          },
          {
            externo: true,
            redirect: 'https://apps.apple.com/br/app/poupatempo-digital/id1480051058',
            label: 'App Store'
          }
        ]
      }
    ]
  },
  {
    label: 'CENTRO DE ESTUDOS',
    children: [
      {
        externo: true,
        redirect: 'https://www.pge.sp.gov.br/CentroEstudo/Default.htm',
        label: 'Site CE'
      },
      {
        externo: true,
        redirect: 'http://biblioteca.pge.sp.gov.br/cgi-bin/wxis.exe?IsisScript=phl83.xis&cipar=phl83.cip&lang=por',
        label: 'Biblioteca'
      },
      {
        externo: true,
        redirect: 'https://revistas.pge.sp.gov.br/',
        label: 'Publicações'
      },
      {
        externo: true,
        redirect: 'https://www.pge.sp.gov.br/ESPGE_2016/homeESPGE.aspx',
        label: 'Escola Superior da PGE'
      }
    ]
  },
  {
    externo: false,
    redirect: 'login',
    label: 'ÁREA RESTRITA'
  }
];
