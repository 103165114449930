<div class="container">
  <div class="card-container">
    @for (item of items; track item; let i = $index) {
      <mat-card class="card">
        <a [href]="item.redirect" target="_blank">
          <img [src]="item.imagem" [alt]="item.alt" />
        </a>
      </mat-card>
    }
  </div>
</div>
