import { Component } from '@angular/core';
import { SeparadorComponent } from '../../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-apresentacao',
  templateUrl: './apresentacao.component.html',
  standalone: true,
  imports: [SeparadorComponent]
})
export class ApresentacaoComponent {}
