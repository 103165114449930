import { Component, OnInit } from '@angular/core';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { CategoriaNoticia } from 'src/app/area-aberta/shared/enums/categoria-noticia';
import { Noticias } from 'src/app/shared/models/noticia.interface';
import { Page } from 'src/app/shared/models/paginacao/page.model';
import { NoticiaService } from 'src/app/shared/services/noticia.service';
import { ImagemTratamento } from '../../home/shared/services/imagem-tratamento';
import { SeparadorComponent } from '../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-credenciamento-contadores',
  templateUrl: './credenciamento-contadores.component.html',
  styleUrl: './credenciamento-contadores.component.sass',
  providers: [ImagemTratamento],
  standalone: true,
  imports: [SeparadorComponent, MatPaginator]
})
export class CredenciamentoContadoresComponent implements OnInit {
  objCredenciamentoContadores?: Noticias[] = [];

  pageEvent: PageEvent;
  length = 0;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25];

  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;

  constructor(
    private noticiaService: NoticiaService,
    private imagemTratamento: ImagemTratamento,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.buscarTodosCredenciamentosContadores();
  }

  buscarTodosCredenciamentosContadores(): void {
    this.noticiaService.getNoticia(this.pageIndex, this.pageSize, CategoriaNoticia.CREDENCIAMENTO_DE_CONTADORES).subscribe(
      (response: Page<Noticias>) => {
        this.objCredenciamentoContadores = response.content;
        this.length = response.totalElements;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  credenciamento(id: number): void {
    this.router.navigate(['informacao-cidadao/credenciamento-contadores/noticia', id]);
  }

  editarData(data: string): string {
    const ano = data.substring(6, 10);
    const mes = this.transformarMes(data.substring(3, 5));
    const dia = data.substring(0, 2);
    return `<strong>${ano}</strong> ${dia} - ${mes}`;
  }

  transformarMes(mes: string): string {
    const mesEditado = mes.replace('0', '');
    const month = Number(mesEditado);
    const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

    return months[month - 1];
  }

  getImagem(noticiaImagem: string): string | undefined {
    return this.imagemTratamento.getImagemBlob(noticiaImagem);
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.buscarTodosCredenciamentosContadores();
  }

  stripHTML(fonte: string) {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = fonte;
    return tmp.textContent || tmp.innerText || '';
  }
}
