import { NgClass } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatNativeDateModule, MatOption } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInput, MatDatepickerModule, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';
import { Observable, Subject, finalize, takeUntil } from 'rxjs';
import { InscricaoAuxilioSaude, Perfil, StatusAuxilio } from 'src/app/area-restrita/features/auxilio/saude/shared/models/auxilio-saude';
import { WaitLoadingService } from 'src/app/area-restrita/shared/components/wait-loading/wait-loading.service';
import { Meses } from 'src/app/area-restrita/shared/models/global-interface';
import { LocalStorageService } from 'src/app/area-restrita/shared/services/local-storage.service';
import { PaginaVisitadaService } from 'src/app/area-restrita/shared/services/pagina-visitada.service';
import { Page } from 'src/app/shared/models/paginacao/page.model';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { StateAnaliseInscricaoService } from '../../shared/services/state-analise.service';
import { InscricaoAuxilioSaudeService } from '../shared/services/inscricao-auxilio-saude.service';

@Component({
  selector: 'app-mesa-analise-inscricao',
  templateUrl: './mesa-analise-inscricao.component.html',
  styleUrl: './mesa-analise-inscricao.component.sass',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatLabel,
    MatFormField,
    MatInput,
    MatSelect,
    MatOption,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatSuffix,
    MatDatepicker,
    MatButton,
    MatPaginator,
    NgClass,
    MatDatepickerModule,
    MatNativeDateModule
  ]
})
export class MesaAnaliseInscricaoComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  pedidosInscricao: InscricaoAuxilioSaude[] = [];
  pedidosSelecionados: Set<number> = new Set<number>();
  statusAuxilio: StatusAuxilio[];
  perfil: Perfil[];
  meses: Observable<Meses[]> | undefined;
  checked = true;
  paginador = false;
  checkboxCabecalhoSelecionado: boolean = false;
  mostraTabela: boolean = false;
  ativaBotao: boolean = true;
  pageEvent: PageEvent;
  length = 0;
  pageSize = 10;
  pageNumber = 0;
  pageSizeOptions = [5, 10, 25];
  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;
  isAnaliseInscricao = false;

  constructor(
    private inscricaoAuxilioSaudeService: InscricaoAuxilioSaudeService,
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastService,
    private localStorage: LocalStorageService,
    private waitLoadingService: WaitLoadingService,
    private stateAnaliseInscricaoService: StateAnaliseInscricaoService,
    private paginaService: PaginaVisitadaService
  ) {}

  ngOnInit(): void {
    this.paginaService.salvaPagina('Analisar Inscrição no Auxílio Saúde').subscribe();
    this.isAnaliseInscricao = this.router.url.includes('analisar-inscricao');
    this.pageNumber = this.stateAnaliseInscricaoService.page;
    this.pageSize = this.stateAnaliseInscricaoService.size;
    this.stateAnaliseInscricaoService.limpar();
    this.recuperarFiltro();
    this.obterTiposStatus();
    const filtroArmazenado = this.localStorage.getJson('filtroInscricao');
    if (filtroArmazenado && this.existeValorNoFiltro(filtroArmazenado)) {
      setTimeout(() => {
        this.buscarPedidos();
      }, 1000);
    }
  }

  dadosFormulario = this.formBuilder.group({
    procuradorServidor: [this.stateAnaliseInscricaoService.procuradorServidor],
    status: [this.stateAnaliseInscricaoService.status == '' ? '1' : this.stateAnaliseInscricaoService.status],
    dataEnvio: [this.stateAnaliseInscricaoService.dataEnvio]
  });

  get listaPedidos() {
    return this.pedidosInscricao;
  }

  buscarPedidos() {
    if (this.dadosFormulario.valid) {
      this.waitLoadingService.open();

      this.localStorage.setJson('filtroInscricao', this.dadosFormulario.value);

      this.inscricaoAuxilioSaudeService
        .buscarPedidos(this.dadosFormulario, this.pageNumber, this.pageSize)
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => this.waitLoadingService.close())
        )
        .subscribe({
          next: (response: Page<InscricaoAuxilioSaude>) => {
            this.pedidosInscricao = response.content;
            this.length = response.totalElements;
            this.paginador = !response.empty;
            this.mostraTabela = true;
            this.ativaBotao = true;
          },
          error: (error) => this.toastr.error(error.message)
        });
    }
  }

  avaliarPedido(pedido) {
    this.stateAnaliseInscricaoService.procuradorServidor = this.dadosFormulario.get('procuradorServidor').value;
    this.stateAnaliseInscricaoService.status = this.dadosFormulario.get('status').value;
    this.stateAnaliseInscricaoService.dataEnvio = this.dadosFormulario.get('dataEnvio').value;
    this.stateAnaliseInscricaoService.telaAnalise = true;
    this.stateAnaliseInscricaoService.page = this.pageNumber;
    this.stateAnaliseInscricaoService.size = this.pageSize;
    if (!this.isAnaliseInscricao) {
      this.router.navigate(['area-restrita', 'analise-pedido-auxilio-saude', pedido.id]);
      return;
    }
    this.router.navigate(['area-restrita', 'inscricao-auxilio-avaliacao', pedido.usuario.id]);
  }

  deferirPedidos() {
    this.inscricaoAuxilioSaudeService
      .deferirPedidos(this.pedidosSelecionados)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response: string) => {
          this.toastr.success(response);
          this.buscarPedidos();
          this.pedidosSelecionados = new Set<number>();
          this.checkboxCabecalhoSelecionado = false;
          this.ativaBotao = true;
        },
        (error: HttpErrorResponse) => {
          this.toastr.error('Ocorreu um erro ao deferir os pedidos: ' + error.message);
        }
      );
  }

  indeferirPedidos() {
    this.inscricaoAuxilioSaudeService
      .indeferirPedidos(this.pedidosSelecionados)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response: string) => {
          this.toastr.success(response);
          this.buscarPedidos();
          this.pedidosSelecionados = new Set<number>();
          this.checkboxCabecalhoSelecionado = false;
          this.ativaBotao = true;
        },
        (error: HttpErrorResponse) => {
          this.toastr.error('Ocorreu um erro ao indeferir os pedidos: ' + error.message);
        }
      );
  }

  checkPedidoSelecionado(pedido: InscricaoAuxilioSaude) {
    if (pedido.podeDeferir) {
      const pedidoId = Number.parseInt(pedido.id);

      if (this.pedidosSelecionados.has(pedidoId)) {
        this.pedidosSelecionados.delete(pedidoId);
      } else {
        this.pedidosSelecionados.add(pedidoId);
      }

      this.ativaBotao = this.pedidosSelecionados.size == 0;
    }
    const todosSelecionados = this.pedidosInscricao
      .filter((pedido) => pedido.podeDeferir)
      .every((pedido) => this.pedidosSelecionados.has(Number.parseInt(pedido.id)));
    this.checkboxCabecalhoSelecionado = todosSelecionados;
  }

  obterTiposStatus() {
    this.inscricaoAuxilioSaudeService
      .obterListaStatusInscricaoAuxilio()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (dados) => {
          this.statusAuxilio = dados;
        },
        error: (error) => console.error('Erro ao obter dados do serviço', error)
      });
  }

  limparFormulario() {
    this.dadosFormulario.reset({
      procuradorServidor: '',
      status: '',
      dataEnvio: ''
    });
    this.localStorage.remove('filtroInscricao');
    this.length = 0;
    this.pageSize = 10;
    this.pageNumber = 0;
    this.pedidosSelecionados.clear();
    this.pedidosInscricao = [];
    this.checked = true;
    this.paginador = false;
    this.mostraTabela = false;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  selecionarTodos(event: Event) {
    const isChecked = event.target as HTMLInputElement;
    this.checkboxCabecalhoSelecionado = isChecked.checked;

    this.pedidosInscricao.forEach((pedido) => {
      if (pedido.podeDeferir) {
        if (isChecked) {
          this.pedidosSelecionados.add(Number.parseInt(pedido.id));
        } else {
          this.pedidosSelecionados.delete(Number.parseInt(pedido.id));
        }
      }
    });
    this.ativaBotao = !isChecked || this.pedidosSelecionados.size == 0;
  }

  getCurrentDate(): string {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
    const day = ('0' + currentDate.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageNumber = e.pageIndex;
    this.buscarPedidos();
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map((str) => +str);
    }
  }

  recuperarFiltro(): void {
    const filtro = this.localStorage.getJson('filtroInscricao');

    if (filtro) {
      this.dadosFormulario.patchValue(filtro);
    }
  }

  private existeValorNoFiltro(filtro): boolean {
    return Object.values(filtro).some((value) => !!value);
  }
}
