<div class="container-noticia-aberta">
  <div class="container-noticia">
    <h1 style="align-self: start">Notícias</h1>
    @for (noticia of objNoticias; track noticia) {
      <div class="div-noticia">
        <p class="title-noticia">{{ noticia?.titulo }}</p>
        <p class="data-noticia" [innerHTML]="editarData(noticia.dataPublicacao)"></p>
        @if (noticia?.imagem) {
          <img [src]="getImagem(noticia?.imagem)" alt="Imagem da Notícia" class="imagem-noticia" />
        }
        <p class="text-noticia" [innerHTML]="processarTexto(noticia.texto)"></p>
        <p class="text-noticia leia-mais" (click)="visualizarNoticia(noticia.id)" (keypress)="visualizarNoticia(noticia.id)" tabindex="0"><a> ... Leia Mais</a></p>
      </div>
    }
    @if (visualizarPaginacao) {
      <div>
        <mat-paginator
          #paginator
          (page)="handlePageEvent($event)"
          [length]="length"
          [pageSize]="pageSize"
          [disabled]="disabled"
          [showFirstLastButtons]="showFirstLastButtons"
          [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
          [hidePageSize]="hidePageSize"
          [pageIndex]="pageIndex"
          aria-label="Select page"
        ></mat-paginator>
        <br />
      </div>
    }
  </div>
  <div class="mais-noticias">
    <div>
      <div class="container-cards">
        <h2 style="align-self: center; font-size: 18px">Últimos Links</h2>
        @for (pagina of paginasAcessadas; track $index; let i = $index) {
          <div class="item">
            <span class="separador">></span>
            <a [href]="linkPagina(pagina.urlPagina)" title="{{ nomePagina(pagina.urlPagina) }}">
              {{ nomePagina(pagina.urlPagina) }}
            </a>
          </div>
        }
      </div>
    </div>
    <br />
    @if (hisNoticias.length !== 0) {
      <h2 style="align-self: center">Historico de Notícias</h2>
      <div class="container-cards">
        @for (item of hisNoticias; track item; let i = $index) {
          <div class="card-noticia" (click)="visualizarNoticia(item?.id)" (keypress)="visualizarNoticia(item?.id)" tabindex="1">
            <div class="titulo">
              {{ item?.titulo }}
            </div>
            @if (item?.imagem) {
              <img [src]="getImagem(item?.imagem)" alt="Imagem {{ i + 1 }}" />
            }
            <div class="texto" [innerHTML]="item?.texto"></div>
          </div>
        }
      </div>
    }
  </div>
</div>

<br />
<div class="div-bottom">
  {{ bottomDesc }}
</div>
