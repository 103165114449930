import { Servico } from '../../../home/servicos/servico.model';

export const LegislacaoList: Servico[] = [
  {
    titulo: 'Atos Normativos',
    icone: 'Resolução PGE-COR 2.2010 Consolidada',
    url: 'pdf/resolucao-pge-cor-2-8-2010-consolidada-e-atualizada-res-2-3-23-relatorio-mensal-de-atividades.pdf',
    linkExterno: true
  },
  {
    titulo: 'Estágio Confirmatório',
    icone: 'Resolução PGE COR 1.2022',
    url: 'pdf/resolucao_pge_cor_01_2022.pdf',
    linkExterno: true
  },
  {
    titulo: 'Residência fora da sede',
    icone: 'Resolução PGE COR 2.2022',
    url: 'pdf/resolucao_pge_cor_02_2022.pdf',
    linkExterno: true
  },
  {
    titulo: 'Frequência',
    icone: 'Resolução PGE COR 4.2013',
    url: 'pdf/resoluo-pge-cor-4-2013-frequncia.pdf',
    linkExterno: true
  },
  {
    titulo: 'Plano de Aulas',
    icone: 'Resolução PGE COR 1.2010',
    url: 'pdf/resoluo-pge-cor-1-2010-plano-de-aulas.pdf',
    linkExterno: true
  },
  {
    titulo: 'Atividade de Árbitro',
    icone: 'Resolução PGE COR 1.2018',
    url: 'pdf/resolucao_pge_cor_01_2018-texto-consolidado.pdf',
    linkExterno: true
  }
];
