import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StateAnaliseService {
  procuradorServidor = '';
  status = '';
  perfil = '';
  mesAnoSolicitacao = '';
  periodoDe = '';
  periodoAte = '';
  telaAnalise = false;
  page = 0;
  size = 10;
  mesAnoOrdem = 0;
  limpar() {
    this.procuradorServidor = '';
    this.status = '';
    this.perfil = '';
    this.mesAnoSolicitacao = '';
    this.periodoDe = '';
    this.periodoAte = '';
    this.page = 0;
    this.size = 10;
    this.mesAnoOrdem = 0;
  }
}

@Injectable({
  providedIn: 'root'
})
export class StateAnaliseInscricaoService {
  procuradorServidor = '';
  status = '';
  dataEnvio = '';
  telaAnalise = false;
  page = 0;
  size = 10;
  limpar() {
    this.procuradorServidor = '';
    this.status = '';
    this.page = 0;
    this.size = 10;
  }
}
