import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { LocalStorageService } from 'src/app/area-restrita/shared/services/local-storage.service';
import { Globais } from '../../../shared/globais/globais';
import { MenuPgeFim } from './menu-pge-fim';
import { MenuNode } from './MenuNode';
import { MenuPgeHome } from './menu-pge-home';

@Injectable({
  providedIn: 'root'
})
export class MenuSguService {
  private menuDataSubject = new BehaviorSubject<MenuNode[]>([]);
  menuData$ = this.menuDataSubject.asObservable();
  menuUser: MenuNode[] = [];
  version: number = 0;
  private menuItemclick = new Subject<MenuNode>();

  constructor(
    private http: HttpClient,
    private localS: LocalStorageService
  ) {}

  getMenu() {
    const idUsuario = this.localS.get('idUsuario');
    this.getMenus(idUsuario);
    return this.menuUser;
  }

  async getMenus(idUsuario: string) {
    this.version++;
    this.menuUser = [];

    this.menuUser.push(MenuPgeHome);
    const token = localStorage.getItem('token').toString();
    const headers = new HttpHeaders({ Authorization: token });
    const params = new HttpParams().set('idUsuario', idUsuario);
    await this.http.get<MenuNode[]>(`${Globais.urlToken}site-pge/api/menus`, { headers: headers, params: params }).subscribe(
      (resp) => {
        for (const itemMenu of resp) this.menuUser.push(itemMenu);
        this.menuUser.push(MenuPgeFim);
        this.menuDataSubject.next(this.menuUser);
      },
      (error) => {
        console.error('Error na busca de dados em criarMenus()', error);
      }
    );
  }

  emitMenuItemclicado$ = this.menuItemclick.asObservable();

  emitMenuItemclicado(item: MenuNode) {
    this.menuItemclick.next(item);
  }
}
