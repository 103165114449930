import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogActions } from '@angular/material/dialog';
import { ConfirmaDialogData } from './confirma-dialog-data';

@Component({
  selector: 'app-confirma-dialog',
  templateUrl: './confirma-dialog.component.html',
  styleUrl: './confirma-dialog.component.sass',
  standalone: true,
  imports: [MatDialogActions]
})
export class ConfirmaDialogComponent {
  escondeBotaoNao = false;
  constructor(
    public dialogRef: MatDialogRef<ConfirmaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmaDialogData
  ) {
    this.escondeBotaoNao = this.data.escondeBotaoNao == true;
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}
