import { Location, CurrencyPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { NotaFiscalAnexadaAuxilio, PedidoAuxilioSaude } from 'src/app/area-restrita/features/auxilio/saude/shared/models/auxilio-saude';
import { AvaliacaoAuxilioDto } from 'src/app/area-restrita/features/auxilio/saude/shared/models/auxilio-saude-dto';
import { Dependente } from 'src/app/area-restrita/features/auxilio/saude/shared/models/dependente';
import { AuxilioSaudeService } from 'src/app/area-restrita/features/auxilio/saude/shared/services/auxilio-saude.service';
import { ConversorValidadorService } from 'src/app/area-restrita/shared/services/conversor-validador.service';
import { PaginaVisitadaService } from 'src/app/area-restrita/shared/services/pagina-visitada.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { environment } from 'src/environments/environment';
import { StateAnaliseService } from './../../shared/services/state-analise.service';
import { ExtrairAnoDataPipe } from '../../../../shared/pipes/extrair-ano-data.pipe';
import { ObterMesDataPipe } from '../../../../shared/pipes/obter-mes-data.pipe';
import { MatButton } from '@angular/material/button';
import { NgxMaskDirective } from 'ngx-mask';
import { MatInput } from '@angular/material/input';
import { MatFormField } from '@angular/material/form-field';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-analise-pedido-auxilio-saude',
  templateUrl: './analise-pedido-auxilio-saude.component.html',
  styleUrl: './analise-pedido-auxilio-saude.component.sass',
  standalone: true,
  imports: [
    MatIcon,
    MatTooltip,
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    NgxMaskDirective,
    MatButton,
    CurrencyPipe,
    ObterMesDataPipe,
    ExtrairAnoDataPipe
  ]
})
export class AnalisePedidoAuxilioSaudeComponent implements OnInit, OnDestroy {
  assetsPath = environment.assetsPath;
  private readonly destroy$ = new Subject<void>();

  modoVisualizacao = false;
  pedidoAuxilio: PedidoAuxilioSaude;
  dependentes: Dependente[] = [];
  documentosTitular: NotaFiscalAnexadaAuxilio[] = [];
  documentosDependente: NotaFiscalAnexadaAuxilio[] = [];
  valorPerso: boolean = false;
  avaliacaoAuxilio: AvaliacaoAuxilioDto = {
    flagAvaliacao: '',
    idAprovador: '',
    observacao: '',
    valorDeferidoPge: 0.0,
    dataAprovacao: null
  };
  dataInscricao = '';
  valorDeferidoPGE: string = '0.00';
  primeiroRadioSelecionado: boolean = true;
  valorSelecionado: string = '';
  validado: boolean = true;
  dataAprovacao: string = this.getCurrentDate();
  obervacaoRetificacao: string = '';

  constructor(
    private auxilioSaudeService: AuxilioSaudeService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private base64ToPdfService: ConversorValidadorService,
    private stateAnaliseService: StateAnaliseService,
    private toastr: ToastService,
    private paginaService: PaginaVisitadaService
  ) {}

  ngOnInit(): void {
    this.paginaService.salvaPagina('Analise do Pedido').subscribe();
    const idPedido = this.route.snapshot.params['id'];
    this.carregarAuxilioSaude(idPedido);
  }

  obterInscricao(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.auxilioSaudeService
        .obterInscricao(this.pedidoAuxilio.usuario.id)
        .toPromise()
        .then((dados) => {
          if (dados != null) {
            this.dataInscricao = dados?.dataInscricao;
          }
          resolve();
        })
        .catch((error) => reject(new Error('Erro ao obter inscrição', error)));
    });
  }

  formAnalisePedidoAuxilioSaude = this.formBuilder.group({
    valorDeferidoPGE: { value: this.formatarMoeda(0.0), disabled: true },
    dataAprovacaoPGE: ['', Validators.required],
    observacao: [''],
    aprovador: ['']
  });

  carregarAuxilioSaude(idPedido: string) {
    this.auxilioSaudeService
      .obterPedidoAuxilioSaude(idPedido)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (dados) => {
          this.pedidoAuxilio = dados;
          this.obterInscricao();
          if (!this.pedidoAuxilio.podeDeferir) {
            this.formAnalisePedidoAuxilioSaude.get('dataAprovacaoPGE').disable();
            this.formAnalisePedidoAuxilioSaude.get('observacao').disable();
          }
          this.obterListaDocumentos(this.pedidoAuxilio);
        },
        error: (error) => console.error('Erro ao obter dados do serviço', error)
      });
  }

  obterListaDocumentos(pedido: PedidoAuxilioSaude) {
    this.auxilioSaudeService
      .obterListaDocumentos(pedido.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (dados) => {
          dados.forEach((element: NotaFiscalAnexadaAuxilio) => {
            if (element.titular) {
              this.documentosTitular.push(element);
            } else {
              this.documentosDependente.push(element);
            }
          });
          this.desativaDataAprovacao();
        },
        error: (error) => console.error('Erro ao obter dados do serviço', error)
      });
  }

  cancelarEnvio() {
    this.stateAnaliseService.telaAnalise = false;
    this.location.back();
  }

  enviarFormulario() {
    if (this.camposObrigatoriosPreenchidos()) {
      this.avaliacaoAuxilio.observacao = this.formAnalisePedidoAuxilioSaude.controls['observacao'].value || '';
      this.avaliacaoAuxilio.valorDeferidoPge = this.converterMoedaParaNumero(this.formAnalisePedidoAuxilioSaude.controls['valorDeferidoPGE'].value) || 0;

      const dataOriginal = new Date(this.formAnalisePedidoAuxilioSaude.get('dataAprovacaoPGE').value);
      dataOriginal.setDate(dataOriginal.getDate() + 1);
      const dataFormatada = dataOriginal.toLocaleDateString();

      this.avaliacaoAuxilio.dataAprovacao = dataFormatada;

      this.auxilioSaudeService
        .enviarAnalisePedidoAuxilioSaude(this.avaliacaoAuxilio, this.pedidoAuxilio)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          () => {
            this.toastr.success('Pedido avaliado com sucesso.');
            setTimeout(() => {
              this.router.navigate(['area-restrita', 'analise-pedidos-auxilio-saude']);
            }, 2000);
          },
          (error) => {
            this.toastr.error(error.message);
          }
        );
    } else {
      this.toastr.error('Falta preencher campos obrigatórios.');
    }
  }

  EnviarRetificacaoAvaliarAuxilioSaude() {
    this.obervacaoRetificacao = this.formAnalisePedidoAuxilioSaude.controls['observacao'].value || '';
    if (this.obervacaoRetificacao.length > 0) {
      this.auxilioSaudeService
        .enviarRetificacaoAvaliarAuxilioSaude(this.obervacaoRetificacao, this.pedidoAuxilio)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          () => {
            this.toastr.success('Retificação de Avaliação enviada com sucesso.');
            setTimeout(() => {
              this.router.navigate(['area-restrita', 'analise-pedidos-auxilio-saude']);
            }, 2000);
          },
          (error) => {
            this.toastr.error(error.message);
          }
        );
    } else {
      this.toastr.error('Falta preencher campos obrigatórios.');
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openPDF(event: MouseEvent, pedido: NotaFiscalAnexadaAuxilio): void {
    event.preventDefault();
    this.base64ToPdfService.base64ToBlob(pedido.arquivo);
  }

  atualizarValorDeferidoPGE(): void {
    this.primeiroRadioSelecionado = true;
    this.pedidoAuxilio.valorTotalDeferido = this.pedidoAuxilio.valorReembolsoTotal;
  }

  selecionarValor(event: Event): void {
    const valorSelecionado = (event.target as HTMLInputElement).value;
    this.valorSelecionado = valorSelecionado;

    if (valorSelecionado === '100%') {
      this.formAnalisePedidoAuxilioSaude.controls['valorDeferidoPGE'].setValue(this.formatarMoeda(this.pedidoAuxilio.valorReembolsoTotal));
      this.formAnalisePedidoAuxilioSaude.controls['valorDeferidoPGE']?.disable();
    } else if (valorSelecionado === '0%') {
      this.formAnalisePedidoAuxilioSaude.controls['valorDeferidoPGE'].setValue(this.formatarMoeda(0.0));
      this.formAnalisePedidoAuxilioSaude.controls['valorDeferidoPGE']?.disable();
    } else {
      this.formAnalisePedidoAuxilioSaude.controls['valorDeferidoPGE'].setValue(null);
      this.formAnalisePedidoAuxilioSaude.controls['valorDeferidoPGE']?.enable();
    }
  }

  camposObrigatoriosPreenchidos(): boolean {
    return !!this.valorSelecionado && !!this.formAnalisePedidoAuxilioSaude.get('dataAprovacaoPGE').value;
  }
  validaModo() {
    return !this.pedidoAuxilio.podeDeferir ? true : false;
  }

  getCurrentDate(): string {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
    const day = ('0' + currentDate.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  desativaDataAprovacao() {
    if (this.pedidoAuxilio.valorDeferidoPge != null) {
      this.formAnalisePedidoAuxilioSaude.controls['dataAprovacaoPGE']?.disable();
    } else {
      this.formAnalisePedidoAuxilioSaude.controls['dataAprovacaoPGE']?.enable();
    }
  }

  private converterMoedaParaNumero(valorMoeda: string): number {
    // Remove tudo exceto números, pontos e vírgulas.
    const numeroSemFormatacao = valorMoeda.replace(/[^\d,]/g, '');

    // Substitui vírgulas por pontos para o formato decimal.
    const numeroFormatado = numeroSemFormatacao.replace(',', '.');

    // Converte a string formatada para um número.
    const valorNumerico = parseFloat(numeroFormatado);

    // Retorna NaN se o resultado da conversão não for um número válido.
    return !isNaN(valorNumerico) ? valorNumerico : 0;
  }

  formatarMoeda(value: number): string {
    return value.toFixed(2);
  }
}
