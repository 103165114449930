import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { WaitLoadingComponent } from './wait-loading.component';

@Injectable({
  providedIn: 'root'
})
export class WaitLoadingService {
  private dialogRef;

  constructor(private dialog: MatDialog) {}

  public open(): void {
    this.dialogRef = this.dialog.open(WaitLoadingComponent, {
      panelClass: 'transparent',
      disableClose: true
    });
  }

  public close(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
}
