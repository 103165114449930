import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Globais } from 'src/app/area-restrita/shared/globais/globais';
import { LocalStorageService } from 'src/app/area-restrita/shared/services/local-storage.service';
import { LimiteMensal } from '../../saude/shared/models/limite-mensal.model';

@Injectable({
  providedIn: 'root'
})
export class LimiteMensalService {
  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService
  ) {}

  consultaLimiteMensal(idPerfil: string, ano: number, mes: number): Observable<LimiteMensal> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: this.localStorage.get('token')
    });

    const parametros = new HttpParams().set('ano', ano?.toString()).set('mes', mes?.toString());

    return this.http.get<LimiteMensal>(Globais.urlToken + `site-pge/api/limites/${idPerfil}`, { headers: headers, params: parametros }).pipe(
      map((response) => {
        return response;
      })
    );
  }
}
