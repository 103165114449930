import { Component } from '@angular/core';
import { SeparadorEmailComponent } from '../../../../core/layout/separador-email/separador-email.component';
import { SeparadorComponent } from '../../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-subprocuradoria-tributario-fiscal',
  templateUrl: './subprocuradoria-tributario-fiscal.component.html',
  standalone: true,
  imports: [SeparadorComponent, SeparadorEmailComponent]
})
export class SubprocuradoriaTributarioFiscalComponent {}
