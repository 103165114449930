<div class="flex-container">
  <div class="header-menu-container">
    <div>
      <app-separador label="SERVIÇOS" />
      <app-servicos />
    </div>
    <div>
      <app-separador label="DESTAQUES" />
    </div>
    <div class="posicionamento">
      <app-noticias-destaque />
    </div>
    <div>
      <app-separador label="ACESSO RÁPIDO" />
    </div>
    <div class="full-width-background">
      <app-acesso-rapido />
    </div>
    <div class="full-width-background-redes" style="margin-top: 30px">
      <app-nas-redes />
    </div>
  </div>
</div>
