import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';

export const authGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const token = localStorage.getItem('token');
  const isLoginPage = route.routeConfig?.path == 'login';
  const router = inject(Router);
  const hasToken = token !== null;
  if (!isLoginPage && !hasToken) router.navigate(['/login']);
  if (isLoginPage && hasToken) router.navigate(['/area-restrita/home']);

  return true;
};
