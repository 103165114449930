import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DynamicTableService {
  createSearchHTML(headers: string[], rows: string[][]): string {
    let html = `<div class="conteudo-dinamico"> <table><tr>`;
    headers.forEach((header) => {
      html += `<th>${header}</th>`;
    });
    html += '</tr>';
    rows.forEach((row, rowIndex) => {
      html += '<tr>';
      row.forEach((cell, columnIndex) => {
        const isEmpty = cell?.trim() === '';
        let rowSpanCount = 1;
        if (!isEmpty) {
          for (let nextRow = rowIndex + 1; nextRow < rows.length; nextRow++) {
            if (rows[nextRow][columnIndex]?.trim() === '' && columnIndex < 2) {
              rowSpanCount++;
              rows[nextRow][columnIndex] = '__ROWSPANED__';
            } else {
              break;
            }
          }
        }
        if (cell === '__ROWSPANED__') return;

        if (rowSpanCount > 1) {
          html += `<td rowspan="${rowSpanCount}">${cell}</td>`;
        } else {
          html += `<td>${cell}</td>`;
        }
      });
      html += '</tr>';
    });
    html += '</table></div>';
    return html;
  }

  generateTableHTML(reportTitle, headers, rows) {
    const MAX_ROWS_PER_TABLE = 55;
    const tables = [];
    let startIndex = 0;
    while (startIndex < rows.length) {
      let endIndex = Math.min(startIndex + MAX_ROWS_PER_TABLE, rows.length);
      let hasRowspan = false;
      for (let i = startIndex; i < endIndex; i++) {
        for (let j = 0; j < rows[i].length; j++) {
          let rowspan = 1;
          if (rows[i][j] !== '__ROWSPANED__' && j < 2) {
            for (let k = i + 1; k < rows.length; k++) {
              if (rows[k][j] === '__ROWSPANED__') {
                rowspan++;
                if (i + rowspan > endIndex) {
                  hasRowspan = true;
                  break;
                }
              } else {
                break;
              }
            }
          }
          if (hasRowspan) {
            endIndex = i;
            break;
          }
        }
        if (hasRowspan) break;
      }

      const tableRows = rows.slice(startIndex, endIndex);

      let html = `<style>
        table {
          width: 100%;
          font-family: sans-serif;
          border-collapse: collapse;
          font-size: 16px;
        }
        th, td {
          border: 1px solid darkblue;
          text-align: left;
          padding: 8px;
        }
        th {
          background-color: #f2f2f2;
        }
        .report-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;
        }
        .report-title {
          font-size: 28px;
          flex-grow: 1;
          text-align: center;
        }
        .logo-brasao {
          width: 50px;
          height: auto;
        }
      </style>
      <div class="report-header">
        <div class="report-title">${reportTitle}</div>
      </div>
      <table>
      <thead>
      <tr>${headers.map((header) => `<th>${header}</th>`).join('')}</tr>
      </thead>
      <tbody>`;

      for (let i = 0; i < tableRows.length; i++) {
        html += '<tr>';
        for (let j = 0; j < tableRows[i].length; j++) {
          if (tableRows[i][j] === '__ROWSPANED__') {
            continue;
          }
          let rowspan = 1;
          if (j < 2) {
            for (let k = i + 1; k < tableRows.length; k++) {
              if (tableRows[k][j] === '__ROWSPANED__') rowspan++;
              else break;
            }
          }
          html += `<td${rowspan > 1 && j < 2 ? ` rowspan="${rowspan}"` : ''}>${tableRows[i][j]}</td>`;
        }
        html += '</tr>';
      }

      html += `
      </tbody>
      </table>
      </div>`;

      tables.push(html);
      startIndex = endIndex;
    }

    return tables;
  }

  generateTableHTML2(reportTitle, headers, rows) {
    let html = `<style>
    table {
      width: 100%;
      border-collapse: collapse;
      font-size: 16px;
    }
    th, td {
      border: 1px solid darkblue;
      text-align: left;
      padding: 8px;
    }
    th {
      background-color: #f2f2f2;
    }
    .report-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }
    .report-title {
      font-size: 28px;
      flex-grow: 1;
      text-align: center;
    }
    .logo-brasao {
      width: 50px;
      height: auto;
    }
  </style>
  <div class="report-header">
    <div class="report-title">${reportTitle}</div>
  </div>
  <table>
  <thead>
  <tr>${headers.map((header) => `<th>${header}</th>`).join('')}</tr>
  </thead>
  <tbody>`;
    for (let i = 0; i < rows.length; i++) {
      html += '<tr>';
      for (let j = 0; j < rows[i].length; j++) {
        if (rows[i][j] === '__ROWSPANED__') {
          continue;
        }
        let rowspan = 1;
        if (j < 2) {
          for (let k = i + 1; k < rows.length; k++) {
            if (rows[k][j] === '__ROWSPANED__') rowspan++;
            else break;
          }
        }
        html += `<td${rowspan > 1 && j < 2 ? ` rowspan="${rowspan}"` : ''}>${rows[i][j]}</td>`;
      }
      html += '</tr>';
    }
    html += `
  </tbody>
  </table>
  </div>`;
    return html;
  }
}
