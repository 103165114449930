import { Component, EventEmitter, Output } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-wait-loading',
  templateUrl: './wait-loading.component.html',
  styleUrl: './wait-loading.component.css',
  standalone: true,
  imports: [MatProgressSpinner]
})
export class WaitLoadingComponent {
  @Output() readonly fechar = new EventEmitter<void>();

  onClose() {
    this.fechar.emit();
  }
}
