<main class="main" role="main">
  <section class="page">
    <div class="container">
      <app-separador label="Procuradoria Regional da Sorocaba (PR04)"></app-separador>
      <div class="row">
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 the_content">
          <br />
          <h3>PR04 CHEFIA GABINETE</h3>
          <div class="pge-unidade">
            <p class="procurador"><strong>Procurador(a)</strong>: {{ findInArray("MARCELO BULIANI BOLZAN") }}</p>
            <p class="endereco"><strong>Endereço</strong>: Rua Coronel Bendito Pires, 34, Centro, Sorocaba-SP, 18010-160.</p>
            <p class="tel"><strong>Telefone</strong>: (15) 3231-3000 ou (15) 3232-4625 3231 5717 Fax (15) 3232-6515</p>
            <p class="email"><strong>E-mail</strong>: pr4atendimento&#64;sp.gov.br</p>
            <div class="descricao">
              <strong>Municípios abrangidos:</strong> Alambari, Alumínio, Angatuba, Apiaí, Araçariguama, Araçaioaba da Serra, Barra do Chapéu, Boituva, Bom Sucesso de Itararé, Buri, Cabreúva, Campina
              do Monte Alegre, Capão Bonito, Capela do Alto, Cerquilho, Cesário Lange, Guapiara, Ibiúna, Iperó, Itaberá, Itaoca, Itapetininga, Itapeva, Itapirapuã Paulista, Itararé, Itu, Jumirim,
              Mairinque, Nova Campina, Piedade, Pilar do Sul, Porto Feliz, Quadra, Ribeira, Ribeirão Branco, Ribeirão Grande, Salto, Salto de Pirapora, São Miguel Arcanjo, São Roque, Sarapuí,
              Sorocaba, Tapiraí, Taquarivaí, Tatuí, Tietê, Votorantim.
            </div>
          </div>
          <br />
          <hr />
          <br />
          <app-voltar-component></app-voltar-component>
          <br />
        </div>
      </div>
    </div>
  </section>
</main>
