import { Component } from '@angular/core';
import { SeparadorComponent } from '../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-relatorio-ouvidoria',
  templateUrl: './relatorio-ouvidoria.component.html',
  styleUrl: './relatorio-ouvidoria.component.css',
  standalone: true,
  imports: [SeparadorComponent]
})
export class RelatorioOuvidoriaComponent {}
