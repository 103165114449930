<div class="container">
  <br />
  <h1 class="card-title titulo">Dados do Pedido</h1>
  <br />
  <div class="row g-3">
    <form [formGroup]="dadosFormulario" (ngSubmit)="buscarPedidos()">
      <div class="d-flex row">
        <div class="col-md-3">
          <label for="status" class="form-label" id="status">Status:</label>
          <mat-form-field>
            <mat-select id="status" class="mat-form-control" formControlName="status" tabindex="0">
              @for (s of statusAuxilio; track s) {
                <mat-option [value]="s.id">
                  {{ s.nome }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <label for="mesAnoSolicitacao" class="form-label" id="mesAnoSolicitacao">Mês/Ano de solicitação:</label>
          <input id="mesAnoSolicitacao" name="mesAnoSolicitacao" class="form-control mat-full-width" type="month" formControlName="mesAnoSolicitacao" />
        </div>
        <div class="col-md-3">
          <label for="programa" class="form-label" id="programa">Programa:</label>
          <mat-form-field>
            <mat-select id="programa" class="mat-form-control" formControlName="programa">
              @for (s of tiposPrograma; track s) {
                <mat-option [value]="s.id">
                  {{ s.tipo }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="d-flex row" style="margin-top: 10px">
        <div class="col-md-3">
          <label for="tipoAuxilioSaude" class="form-label" id="tipoAuxilioSaude">Tipo Auxílio Saúde:</label>
          <mat-form-field>
            <mat-select id="tipoAuxilioSaude" class="mat-form-control" formControlName="tipoAuxilioSaude">
              @for (s of tiposAuxilioSaude; track s) {
                <mat-option [value]="s.id">
                  {{ s.tipo }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <label for="totalSolicitado" class="form-label">Total Solicitado:</label>
          <mat-form-field>
            <input matInput type="text" class="form-control" id="totalSolicitado" name="totalSolicitado" formControlName="totalSolicitado" />
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <label for="totalDeferido" class="form-label">Total Deferido:</label>
          <mat-form-field>
            <input matInput type="text" class="form-control" id="totalDeferido" name="totalDeferido" formControlName="totalDeferido" />
          </mat-form-field>
        </div>
      </div>
      <br />
      <div class="d-flex row">
        <div class="col-12 conjunto-botoes">
          <button mat-button color="primary" type="submit" id="buscar" name="buscar" class="btn-dark" [disabled]="!this.dadosFormulario.valid">Buscar</button>
          <button mat-button type="button" id="limpaForm" name="limpaForm" class="btn btn-link text-secondary" (click)="limparFormulario()">Limpar filtros</button>
        </div>
      </div>
    </form>
    <br />
    <br />
    <div>
      <div class="table-responsive">
        @if (historicoSolicitacoesResponse.length > 0) {
          <table class="tabelaPadrao">
            <thead>
              <tr>
                <th class="coluna-remover"></th>
                <th class="titulo text-center">Status</th>
                <th class="titulo text-center">Mês Solicitado para Reembolso</th>
                <th class="titulo text-center">Programa</th>
                <th class="titulo text-center">Tipo de Auxílio Saúde</th>
                <th class="titulo text-center">Total Solicitado</th>
                <th class="titulo text-center">Total Deferido</th>
              </tr>
            </thead>
            <tbody>
              @for (historico of historicoSolicitacoesResponse; track historico; let i = $index) {
                <tr>
                  <td>
                    @if (historico.status.id === statusAuxilioEnum.AGUARDANDO_AVALIACAO) {
                      <button mat-icon-button type="submit" (click)="remover(historico.id)">
                        <mat-icon>delete</mat-icon>
                      </button>
                    }
                  </td>
                  <td class="text-center">
                    <a class="tipoLink" tabindex="0" (click)="pedido(historico.id)" (keypress)="pedido(historico.id)">
                      {{ historico.status.nome }}
                    </a>
                  </td>
                  <td class="text-center">
                    {{ meses[historico.mesParcela - 1] + "/" + historico.anoReembolso }}
                  </td>
                  <td class="text-center">{{ historico.tipoAuxilio.tipo }}</td>
                  <td class="text-center">{{ historico.tipoAuxilioSaude.tipo }}</td>
                  <td class="text-center">
                    {{ historico.valorReembolsoTotal | currency }}
                  </td>
                  <td class="text-center">
                    {{ historico.valorDeferidoPge || 0 | currency }}
                  </td>
                </tr>
              }
            </tbody>
          </table>
        }
        @if (vazio) {
          <div>
            <p class="text-center">Nenhum registro encontrado.</p>
          </div>
        }
      </div>
      <div class="d-flex justify-content-center">
        @if (historicoSolicitacoesResponse.length > 0) {
          <mat-paginator
            #paginator
            class="custom-paginator"
            aria-label="Select page"
            [length]="length"
            [pageSize]="pageSize"
            [disabled]="disabled"
            [showFirstLastButtons]="showFirstLastButtons"
            [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
            [hidePageSize]="hidePageSize"
            [pageIndex]="pageNumber"
            (page)="handlePageEvent($event)"
          />
        }
      </div>
    </div>
  </div>
</div>
