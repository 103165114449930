<main class="main" role="main" (window:resize)="onResize($event)">
  <section class="conselho mb50">
    <app-separador label="Composição"></app-separador>
    <div>
      <h5>MOMENTO VIRTUAL DO PROCURADOR</h5>
      <br />
      <p>
        O “Momento virtual do Procurador” faculta a participação direta dos Procuradores do Estado que queiram se manifestar sobre quaisquer assuntos de interesse da Instituição. Para mandar um e-mail
        para o “Momento virtual do Procurador”&nbsp;<a routerLink="/institucional/conselho/momento-virtual-procurador">clique aqui</a>.
      </p>
    </div>
    <br />
    <h5>COMPOSIÇÃO BIÊNIO {{ presidente.bienio }}</h5>
    <br />
    <div class="presidente">
      <h5>{{ presidente.titulo }}</h5>
      <div>
        <table>
          <tr>
            <td><img [src]="presidente.imagem" alt="Presidente" /></td>
            <td class="ajustePresidente">
              <h5>{{ presidente.nome }}</h5>
              <br />
              <p>Representação: {{ presidente.representacao }}</p>
            </td>
          </tr>
        </table>

        <div></div>
      </div>
      <br />
      <hr />
    </div>
    <h5>MEMBROS NATOS</h5>
    <mat-grid-list [cols]="gridCols" rowHeight="2:1" [style.width]="'100%'" [style.padding-bottom]="'calc(50% + 0.5px)'">
      @for (nato of listaProcuradoresNatos; track nato) {
        <mat-grid-tile class="ajuste">
          <img [src]="nato.imagem" alt="Membros natos" />
          <div class="representante">
            <h5>{{ nato.nome }}</h5>
            Representação: {{ nato.representacao }}
          </div>
        </mat-grid-tile>
      }
    </mat-grid-list>
    <hr />
    <h5>MEMBROS ELEITOS</h5>
    <div>
      <mat-grid-list [cols]="gridCols" rowHeight="2:1" [style.width]="'100%'" [style.heigth]="'auto'">
        @for (eleito of listaProcuradoresEleitos; track eleito) {
          <mat-grid-tile class="ajuste">
            <img [src]="eleito.imagem" alt="Membros eleitos" />
            <div class="representante">
              <h5>{{ eleito.nome }}</h5>
              Representação: {{ eleito.representacao }}<br />
              E-mail: {{ eleito.email }}
            </div>
          </mat-grid-tile>
        }
      </mat-grid-list>
    </div>
  </section>
</main>
