import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Globais } from '../globais/globais';
import { PaginaVisitada } from '../models/pagina-visitada';

@Injectable({
  providedIn: 'root'
})
export class PaginaVisitadaService {
  constructor(public http: HttpClient) {}

  public salvaPagina(nomePagina: string): Observable<PaginaVisitada> {
    const idUsuario = Number(localStorage.getItem('idUsuario'));
    const uri = window.location.href.toLowerCase();
    const url = nomePagina + '@' + uri;
    return this.http.post<PaginaVisitada>(Globais.urlToken + 'site-pge/api/pagina/add', { idUsuario, url });
  }

  public obtemPaginas(): Observable<PaginaVisitada[]> {
    const idUsuario = Number(localStorage.getItem('idUsuario'));
    return this.http.get<PaginaVisitada[]>(Globais.urlToken + 'site-pge/api/pagina/' + idUsuario);
  }
}
