<div class="mapa-do-site" [class.expanded]="isExpanded" (click)="toggleContent()" (keypress)="toggleContent()" tabindex="0">
  <div class="header">
    <span class="cor">MAPA DO SITE</span>
    <mat-icon>{{ isExpanded ? "expand_less" : "expand_more" }}</mat-icon>
    <span class="toggle" (click)="toggleContent()" (keypress)="toggleContent()" tabindex="0"> </span>
  </div>
  @if (isExpanded) {
    <div class="content">
      <div class="menu-container">
        <ng-container>
          <div class="menu-item">
            <h5>SERVIÇOS</h5>
            <span class="sub-menu">
              @for (servico of servicoList; track servico) {
                <li>
                  @if (!servico.linkExterno) {
                    <a [routerLink]="servico.url">{{ servico.titulo }}</a>
                  }
                  @if (servico.linkExterno) {
                    <a [href]="servico.url" target="_blank">{{ servico.titulo }}</a>
                  }
                </li>
              }
            </span>
          </div>
        </ng-container>
        @for (item of menuList; track item) {
          <div class="menu-item">
            @if (item.children) {
              <h4>{{ item.label }}</h4>
              <div class="sub-menu">
                @for (subItem of item.children; track subItem) {
                  <li>
                    @if (!subItem.externo) {
                      <a [routerLink]="subItem.redirect">{{ subItem.label }}</a>
                    }
                    @if (subItem.externo) {
                      <a [href]="subItem.redirect" target="_blank">{{ subItem.label }}</a>
                    }
                    @if (subItem.children && subItem.children.length) {
                      <ul class="sub-sub-menu">
                        @for (subSubItem of subItem.children; track subSubItem) {
                          <li>
                            @if (!subSubItem.externo) {
                              <a [routerLink]="subSubItem.redirect">{{ subSubItem.label }}</a>
                            }
                            @if (subSubItem.externo) {
                              <a [href]="subSubItem.redirect" target="_blank">{{ subSubItem.label }}</a>
                            }
                            @if (subSubItem.children && subSubItem.children.length) {
                              <ul class="sub-sub-sub-menu">
                                @for (subSubSubItem of subSubItem.children; track subSubSubItem) {
                                  <li>
                                    @if (!subSubSubItem.externo) {
                                      <a [routerLink]="subSubSubItem.redirect">{{ subSubSubItem.label }}</a>
                                    }
                                    @if (subSubSubItem.externo) {
                                      <a [href]="subSubSubItem.redirect" target="_blank">{{ subSubSubItem.label }}</a>
                                    }
                                  </li>
                                }
                              </ul>
                            }
                          </li>
                        }
                      </ul>
                    }
                  </li>
                }
              </div>
            }
            @if (!item.children) {
              <a [href]="item.redirect" class="nav-link menubtn" target="_blank"
                ><h4>{{ item.label }}</h4></a
              >
            }
          </div>
        }
      </div>
    </div>
  }
</div>
