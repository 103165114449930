<nav class="navbar navbar-expand-lg barra-brasao">
  <div class="container-brasao" (click)="goToHome()" (keypress)="goToHome()" tabindex="0">
    <img class="brasao" alt="Brasão PGE" [src]="assetsPath + '/images/brasao.svg'" (click)="goToHome()" (keypress)="goToHome()" tabindex="0" />
    <div class="iconesNav justify-content-end">
      <mat-icon (click)="fontIncrease()" matTooltip="Aumentar a fonte" class="icones">text_increase</mat-icon>
      <mat-icon (click)="fontDecrease()" matTooltip="Diminuir a fonte" class="icones">text_decrease</mat-icon>
      <mat-icon (click)="changeTheme()" matTooltip="Alterar o tema" class="icones">contrast</mat-icon>
      <a href="comunicar-erro" matTooltip="Comunicar Erros" aria-label="Comunicar Erros" class="icones">
        <mat-icon class="warning_amber">warning_amber</mat-icon>
      </a>
    </div>
  </div>
</nav>
