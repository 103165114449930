import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-separador-email',
  templateUrl: './separador-email.component.html',
  styleUrl: './separador-email.component.sass',
  standalone: true
})
export class SeparadorEmailComponent {
  @Input() label: string;
  @Input() msg1: string;
  @Input() msg2: string;
  @Input() msg3: string;
  @Input() msg4: string;
}
