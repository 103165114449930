<div class="grid-container">
  @for (noticia of cardsObj.slice(0, 5); track noticia) {
    <div class="grid-item" (click)="abrirUmaNoticia(noticia?.id)" (keypress)="abrirUmaNoticia(noticia?.id)" tabindex="0">
      <img [src]="getImagem(noticia?.imagem)" class="item-imagem" alt="Imagem da Notícia" />
      <div class="description">{{ noticia?.titulo }}</div>
    </div>
  }
  <div class="align-button">
    <button (click)="noticiasDiversas()" mat-flat-button class="botao-mais-noticias">Mais Notícias</button>
  </div>
</div>
