import { CurrencyPipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatNativeDateModule, MatOption } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInput, MatDatepickerModule, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatFormField, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxCurrencyDirective } from 'ngx-currency';
import { NgxFileDropEntry, NgxFileDropModule } from 'ngx-file-drop';
import { combineLatest, finalize, Subject, takeUntil } from 'rxjs';
import { AnoReembolso } from 'src/app/area-restrita/features/auxilio/saude/shared/models/ano-reembolso';
import { HistoricoSolicitacoes } from 'src/app/area-restrita/features/auxilio/saude/shared/models/historico-solicitacoes';
import { ConfirmaDialogData } from 'src/app/area-restrita/shared/components/confirma-dialog/confirma-dialog-data';
import { ConfirmaDialogComponent } from 'src/app/area-restrita/shared/components/confirma-dialog/confirma-dialog.component';
import { WaitLoadingService } from 'src/app/area-restrita/shared/components/wait-loading/wait-loading.service';
import { ConversorValidadorService } from 'src/app/area-restrita/shared/services/conversor-validador.service';
import { PaginaVisitadaService } from 'src/app/area-restrita/shared/services/pagina-visitada.service';
import { Page } from 'src/app/shared/models/paginacao/page.model';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { environment } from 'src/environments/environment';
import { pessoaService } from '../../../home/shared/services/pessoa.service';
import { LimiteMensalService } from '../../shared/services/limite-mensal.service';
import { getPerfilById, PerfilEnum } from '../shared/enums/perfil.enum';
import { AuxilioSaude, InscricaoAuxilioSaude, NotaFiscalAnexadaAuxilio, Pessoa } from '../shared/models/auxilio-saude';
import { Dependente } from '../shared/models/dependente';
import { LimiteMensal } from '../shared/models/limite-mensal.model';
import { TipoAuxilioSaude } from '../shared/models/tipo-auxilio-saude';
import { AuxilioSaudeService } from '../shared/services/auxilio-saude.service';
import { InscricaoAuxilioSaudeService } from '../shared/services/inscricao-auxilio-saude.service';
import { SolicitacaoAuxilioSaudeService } from '../shared/services/solicitacao-auxilio-saude.service';
import { TipoAuxilio } from './../shared/models/tipo-auxilio';

@Component({
  selector: 'app-solicitacao-auxilio-saude',
  templateUrl: './solicitacao-auxilio-saude.component.html',
  styleUrl: './solicitacao-auxilio-saude.component.sass',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatSelect,
    MatOption,
    MatInput,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatSuffix,
    MatDatepicker,
    NgxCurrencyDirective,
    NgxFileDropModule,
    MatCheckbox,
    MatButton,
    MatPaginator,
    CurrencyPipe,
    MatDatepickerModule,
    MatNativeDateModule
  ]
})
export class SolicitacaoAuxilioSaudeComponent implements OnInit, OnDestroy {
  /* Verificação do saldo - 247 - Ajustes - Tela Solicitação Auxílio Saúde */
  saldo: number = 0;
  limite: number = 0;
  totalLimite = 0;
  hasDependentes: boolean;
  deferido: number = 0;
  /* Verificação do saldo*/

  assetsPath = environment.assetsPath;
  private readonly destroy$ = new Subject<void>();
  historicoSolicitacoesResponse: HistoricoSolicitacoes[] = [];
  tipoAuxilioResponse: TipoAuxilio[] = [];
  tipoAuxilioSaudeResponse: TipoAuxilioSaude[] = [];
  anoReembolsoResponse: AnoReembolso[] = [];
  anexoArqTitular: NgxFileDropEntry[] = [];
  anexoArqDependente: NgxFileDropEntry[] = [];
  limiteReembolsoResponse: number = 0;
  dataInscricaoResponse: string = '';
  mensagemErro: string = '';
  atingiuLimite: boolean = false;
  meses: string[] = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
  requerimentoDigital: boolean;
  possuoDependente: boolean;
  dependenteImpostoRenda: boolean = false;
  dependente: Dependente[];
  idInscricaoResponse: number = 0;
  verificarExistenciaInscricaoUsuario: boolean;
  dependentesSelecionados: boolean = false;

  documentosTitular: NotaFiscalAnexadaAuxilio[] = [];
  documentosDependente: NotaFiscalAnexadaAuxilio[] = [];

  existeDependente: boolean;
  dadosFormulario = this.formBuilder.group({
    tipoAuxilio: [0, Validators.required],
    tipoAuxilioSaude: [0, Validators.required],
    anoReembolso: [0, Validators.required],
    mesParcela: [0, Validators.required],
    dataVencimentoParcela: ['', Validators.required],
    valorReembolsoDependente: [0],
    valorReembolsoTitular: [0],
    valorReembolsoTotal: { value: 0, disabled: true },
    usuario: { id: Number(localStorage.getItem('idUsuario')) },
    dependentesAuxilioSaude: []
  });
  quantidadeDependentes = 0;

  dadosFormularioPaginacao = this.formBuilder.group({
    status: [''],
    mesAnoSolicitacao: [''],
    programa: [''],
    totalSolicitado: '',
    totalDeferido: ''
  });
  pageEvent: PageEvent;
  length = 0;
  vazio = false;
  pageSize = 10;
  pageNumber = 0;
  pageSizeOptions = [5, 10, 25];
  paginador = false;
  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;
  pessoaUsuario: Pessoa;
  retificacao: boolean = false;
  observacao: string = '';
  idProduto: string;
  historico: boolean = true;
  alterarCamposHabilitadosOuDesabilitados: boolean = true;

  constructor(
    private solicitacaoAuxilioSaudeService: SolicitacaoAuxilioSaudeService,
    private inscricaoAuxilioSaudeService: InscricaoAuxilioSaudeService,
    private auxilioSaudeService: AuxilioSaudeService,
    private limiteMensalService: LimiteMensalService,
    private formBuilder: FormBuilder,
    private toastr: ToastService,
    private waitLoadingService: WaitLoadingService,
    private pessoaService: pessoaService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private base64ToPdfService: ConversorValidadorService,
    private paginaService: PaginaVisitadaService
  ) {}

  valorTitularRequired = true;

  ngOnInit(): void {
    this.paginaService.salvaPagina('Solicitação Auxílio').subscribe();
    this.idProduto = this.route.snapshot.params['id'];
    if (this.idProduto) this.carregarAuxilioSaude(this.idProduto);
    else this.obterDadosPessoa();
  }

  carregarAuxilioSaude(idPedido: string) {
    this.historico = false;
    this.auxilioSaudeService
      .obterPedidoAuxilioSaude(idPedido)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (dados) => {
          this.dadosFormulario.patchValue({
            tipoAuxilio: dados.tipoAuxilio.id,
            tipoAuxilioSaude: dados.tipoAuxilioSaude.id,
            anoReembolso: dados.anoReembolso,
            mesParcela: dados.mesParcela,
            dataVencimentoParcela: this.transformarDataStringParaISO(dados.dataVencimentoParcela),
            valorReembolsoDependente: dados.valorReembolsoDependente,
            valorReembolsoTitular: dados.valorReembolsoTitular,
            valorReembolsoTotal: dados.valorReembolsoTotal,
            usuario: { id: Number(dados.usuario.id) },
            dependentesAuxilioSaude: dados.dependentesAuxilioSaude
          });
          this.obterListaTipoAuxilio();
          this.obterListaTipoAuxilioSaude();
          this.obterLista();
          this.obterListaAnoReembolso();
          this.obterLimite();
          this.obterInscricao();
          this.pessoaUsuario = dados?.usuario?.pessoa;
          this.saldo = 0;
          this.deferido = 0;
          if (dados.status.nome != 'Enviado para Retificação') {
            this.alterarCamposHabilitadosOuDesabilitados = false;
            this.dadosFormulario.get('tipoAuxilioSaude').disable();
            this.dadosFormulario.get('anoReembolso').disable();
            this.dadosFormulario.get('anoReembolso').disable();
            this.dadosFormulario.get('mesParcela').disable();
            this.dadosFormulario.get('dataVencimentoParcela').disable();
            this.dadosFormulario.get('valorReembolsoDependente').disable();
            this.dadosFormulario.get('valorReembolsoTitular').disable();
          }
          this.dadosFormulario
            .get('valorReembolsoTitular')
            .valueChanges.subscribe((value) =>
              this.dadosFormulario.get('valorReembolsoTotal').setValue(value + this.dadosFormulario.get('valorReembolsoDependente').value)
            );
          this.dadosFormulario.get('valorReembolsoDependente').valueChanges.subscribe((value) => {
            if (value > 0) this.valorTitularRequired = false;

            this.dadosFormulario.get('valorReembolsoTotal').setValue(value + this.dadosFormulario.get('valorReembolsoTitular').value);
          });
          this.retificacao = dados.status.nome == 'Enviado para Retificação' ? true : false;
          this.observacao = dados.observacao;
          this.obterListaDocumentos(idPedido);
        },
        error: (error) => console.error('Erro ao obter dados do serviço', error)
      });
  }

  transformarDataStringParaISO(dataString: string): string {
    const partes = dataString.split('/');
    const dia = partes[0];
    const mes = partes[1];
    const ano = partes[2];
    return `${ano}-${mes}-${dia}`;
  }

  openPDF(event: MouseEvent, pedido: NotaFiscalAnexadaAuxilio): void {
    event.preventDefault();
    this.base64ToPdfService.base64ToBlob(pedido.arquivo);
  }

  obterListaDocumentos(pedido: string) {
    this.auxilioSaudeService
      .obterListaDocumentos(pedido)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (dados: NotaFiscalAnexadaAuxilio[]) => {
          dados.forEach((element: NotaFiscalAnexadaAuxilio) => {
            if (element.titular) {
              this.documentosTitular.push(element);
            } else {
              this.documentosDependente.push(element);
            }
          });
        },
        error: (error) => console.error('Erro ao obter dados do serviço', error)
      });
  }

  obterDadosPessoa() {
    this.pessoaService.obterDadosPessoa(Number(localStorage.getItem('idUsuario'))).subscribe(
      (response: Pessoa) => {
        this.pessoaUsuario = response;
        if (getPerfilById(this.pessoaUsuario.perfil.id) === PerfilEnum.Aposentado) {
          const data: ConfirmaDialogData = {
            title: '',
            message: 'Usuário com perfil APOSENTADO. Não é possível realizar inscrição.',
            escondeBotaoNao: true
          };
          this.dialog
            .open(ConfirmaDialogComponent, {
              width: '20%',
              data: data
            })
            .afterClosed()
            .subscribe(() => this.router.navigate(['area-restrita/home']));
        } else {
          this.obterListaTipoAuxilio();
          this.obterListaTipoAuxilioSaude();
          this.obterLista();
          this.obterListaAnoReembolso();
          this.obterInscricao();
          this.saldo = 0;
          this.deferido = 0;

          this.dadosFormulario
            .get('valorReembolsoTitular')
            .valueChanges.subscribe((value) =>
              this.dadosFormulario.get('valorReembolsoTotal').setValue(value + this.dadosFormulario.get('valorReembolsoDependente').value)
            );
          this.dadosFormulario.get('valorReembolsoDependente').valueChanges.subscribe((value) => {
            if (value > 0) this.valorTitularRequired = false;

            this.dadosFormulario.get('valorReembolsoTotal').setValue(value + this.dadosFormulario.get('valorReembolsoTitular').value);
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  obterListaTipoAuxilio(): void {
    this.solicitacaoAuxilioSaudeService.obterListaTipoAuxilio().subscribe((response) => {
      this.tipoAuxilioResponse = response;
      const resultado = this.tipoAuxilioResponse.find(
        (f) =>
          f.tipo
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '') === 'auxilio saude'
      );
      const idOpcaoUnica = resultado ? resultado.id : null;
      if (typeof idOpcaoUnica === 'number') {
        this.dadosFormulario.get('tipoAuxilio').setValue(idOpcaoUnica);
        this.dadosFormulario.get('tipoAuxilio').disable();
      }
    });
  }

  obterListaTipoAuxilioSaude(): void {
    this.solicitacaoAuxilioSaudeService.obterListaTipoAuxilioSaude().subscribe((response) => {
      this.tipoAuxilioSaudeResponse = response;
    });
  }

  obterLista(): void {
    this.solicitacaoAuxilioSaudeService
      .obterLista(null, this.pageNumber, this.pageSize)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (dados: Page<HistoricoSolicitacoes>) => {
          this.historicoSolicitacoesResponse = dados.content;
          this.calculaSaldo();
          this.length = dados.totalElements;
          this.vazio = dados.empty;
          this.paginador = !dados.empty;
        },
        error: (error) => this.toastr.error('Erro ao obter dados do serviço', error)
      });
  }

  obterListaAnoReembolso(): void {
    this.solicitacaoAuxilioSaudeService
      .obterListaAnoReembolso()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (dados) => {
          this.anoReembolsoResponse = dados;
        },
        error: (error) => console.error('Erro ao obter dados do serviço', error)
      });
  }

  obterInscricao() {
    this.inscricaoAuxilioSaudeService
      .obterInscricao()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (dados: InscricaoAuxilioSaude) => {
          this.dependente = dados?.dependentes;
          this.verificarExistenciaInscricaoUsuario = dados ? true : false;
          this.dataInscricaoResponse = dados?.dataInscricao;
          this.quantidadeDependentes = dados!.dependentes.length != 0 ? dados?.dependentes.length : 0;
          this.hasDependentes = dados?.dependentes.length > 0 ? true : false;

          const dependentesData = dados?.dependentes.map((dependente) => ({
            id: dependente.id
          }));

          this.dadosFormulario.patchValue({ dependentesAuxilioSaude: dependentesData });
        },
        error: (error) => console.error('Erro ao obter dados do serviço', error)
      });
  }

  obterLimite(): void {
    this.limiteMensalService
      .consultaLimiteMensal(this.pessoaUsuario.perfil.id, this.dadosFormulario.get('anoReembolso').value, this.dadosFormulario.get('mesParcela').value)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (dados: LimiteMensal) => {
          let dataNascimento = this.pessoaUsuario.dataNascimento;

          if (typeof dataNascimento === 'string' && dataNascimento.includes('/')) {
            const [dia, mes, ano] = dataNascimento.split('/');
            dataNascimento = `${ano}-${mes}-${dia}`;
          }

          const dataNascimentoDate = new Date(dataNascimento);

          if (isNaN(dataNascimentoDate.getTime())) {
            console.error('Data de Nascimento inválida:', dataNascimento);
            return;
          }

          const anoAtual = new Date().getFullYear();
          const idadeUsuario = anoAtual - dataNascimentoDate.getFullYear();
          if (idadeUsuario >= 50) {
            console.log('Usuário com 50 anos ou mais. Aumentando limite em 50%.');

            this.limiteReembolsoResponse = dados.valor * 1.5;
            console.log('dados.valor', dados.valor);
            console.log('dados.valor * 1.5', dados.valor * 1.5);
            return;
          }
          this.limiteReembolsoResponse = dados.valor;
        },
        error: (error) => console.error('Erro ao obter dados do serviço', error)
      });
  }

  verificacaoDependenteUsuario(): void {
    this.inscricaoAuxilioSaudeService
      .obterListaDependentes()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (dados) => {
          if (dados.length > 0) {
            this.hasDependentes = true;
          } else {
            this.hasDependentes = false;
          }
        }
      });
  }

  calculaSaldoChange(): void {
    this.saldo = this.limiteReembolsoResponse;
    this.deferido = 0;

    const dados = this.historicoSolicitacoesResponse;
    for (const dado of dados) {
      if (dado.mesParcela == this.dadosFormulario.get('mesParcela').value && dado.anoReembolso == this.dadosFormulario.get('anoReembolso').value) {
        this.deferido += Number(dado.valorDeferidoPge);
      }
    }
    this.saldo -= this.deferido;
  }

  calculaSaldo(): void {
    console.warn('Atenção!!! Calcula saldo de acordo com o mês corrente, lembre-se disso.');
    this.saldo = this.limiteReembolsoResponse;
    this.deferido = 0;
    const dados = this.historicoSolicitacoesResponse;
    for (const dado of dados) {
      this.deferido += Number(dado.valorDeferidoPge);
    }
    this.saldo -= this.deferido;
    // this.verificacaoDependenteUsuario();
  }

  calcularValorReembolsoMes(): boolean {
    let somatorioTotalMes = 0;
    const dados = this.historicoSolicitacoesResponse;
    dados
      .filter((dado) => dado.mesParcela == this.dadosFormulario.get('mesParcela').value && dado.anoReembolso == this.dadosFormulario.get('anoReembolso').value)
      .forEach((dado) => {
        somatorioTotalMes += Number(dado.valorReembolsoTotal);
      });

    const valorTitular = this.dadosFormulario.get('valorReembolsoTitular').value || 0;
    const valorDependente = this.dadosFormulario.get('valorReembolsoDependente').value || 0;

    if (!(valorTitular + valorDependente > 0)) {
      this.toastr.warning('Valor total deve ser maior que zero.');
      return false;
    }

    this.dadosFormulario
      .get('valorReembolsoTotal')
      .setValue(this.dadosFormulario.get('valorReembolsoTitular').value + this.dadosFormulario.get('valorReembolsoDependente').value);

    if (this.dadosFormulario.get('valorReembolsoTotal').value + somatorioTotalMes <= this.limiteReembolsoResponse) {
      return true;
    } else {
      this.toastr.error('Limite de reembolso mensal atingido.');
      this.atingiuLimite = false;
      this.dadosFormulario.get('valorReembolsoDependente').reset();
      this.dadosFormulario.get('valorReembolsoTitular').reset();
      this.dadosFormulario.get('valorReembolsoTotal').reset();
      return false;
    }
  }

  onFileDropClick(tipoEnvio: boolean) {
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.multiple = true;
    inputElement.onchange = (event: Event) => {
      const target = event.target as HTMLInputElement;
      if (target.files) {
        const files: FileList = target.files;
        const fileEntries: NgxFileDropEntry[] = [];
        Array.from(files).forEach((file) => {
          // Criando um FileSystemFileEntry simulado
          const fileEntry: FileSystemFileEntry = {
            isDirectory: false,
            isFile: true,
            file: (successCallback: (file: File) => void) => {
              successCallback(file);
            },
            name: file.name,
            fullPath: file.name,
            filesystem: undefined,
            getParent: function (): void {
              throw new Error('Function not implemented.');
            }
          };
          fileEntries.push({ relativePath: file.name, fileEntry });
        });
        this.arquivosProcessando(fileEntries, tipoEnvio);
      }
    };
    inputElement.click();
  }

  validarAtingiuLimite() {
    const soma = this.dadosFormulario.get('valorReembolsoTitular').value + this.dadosFormulario.get('valorReembolsoDependente').value;
    this.dadosFormulario.get('valorReembolsoTotal').setValue(soma);
    if (this.limiteReembolsoResponse <= soma) {
      this.atingiuLimite = true;
    } else {
      this.atingiuLimite = false;
    }
  }

  updatRequerimentoDigital() {
    this.requerimentoDigital = !this.requerimentoDigital;
  }
  updatePossuoDependente() {
    this.possuoDependente = !this.possuoDependente;
  }
  updateDependenteImpostoRenda() {
    this.dependenteImpostoRenda = !this.dependenteImpostoRenda;
  }

  public arquivosSoltos(listaArquivos: NgxFileDropEntry[], tipoEnvio: boolean) {
    const maximoArquivos: number = 5;
    const maximoNomeArquivo: number = 160;
    for (const droppedFile of listaArquivos) {
      if (droppedFile.fileEntry.isFile && this.verificarExtensao(droppedFile.fileEntry.name)) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          if (this.tamanhoMaximoArquivo(file.size)) {
            if (file.name.length <= maximoNomeArquivo) {
              if (
                (tipoEnvio && listaArquivos.length + this.documentosTitular.length <= maximoArquivos) ||
                (!tipoEnvio && listaArquivos.length + this.documentosDependente.length <= maximoArquivos * this.quantidadeDependentes)
              ) {
                const reader = new FileReader();
                reader.onload = (event) => {
                  const base64String = (event.target?.result as string).split(',')[1];
                  const anexo = {
                    id: null,
                    auxilio: { id: this.idProduto ? this.idProduto : null },
                    nomeArquivo: file.name,
                    arquivo: base64String,
                    titular: tipoEnvio
                  };
                  // Usando if-else em vez de operador ternário
                  if (tipoEnvio) {
                    this.atualizarLista(this.documentosTitular, anexo);
                  } else {
                    this.atualizarLista(this.documentosDependente, anexo);
                  }
                };
                reader.readAsDataURL(file);
              } else {
                this.toastr.error(
                  `Número máximo de ${tipoEnvio ? maximoArquivos : maximoArquivos * this.quantidadeDependentes} arquivos excedido para${tipoEnvio ? ' o titular.' : ' os dependentes.'}`
                );
              }
            } else {
              this.toastr.error(`O nome do arquivo excede o limite permitido de ${maximoNomeArquivo} caracteres.`);
            }
          } else {
            this.toastr.error('O arquivo excede o tamanho máximo permitido de 25MB.');
          }
        });
      } else {
        this.toastr.error('Apenas arquivos nos formatos PDF, JPG, JPEG e PNG são aceitos.');
      }
    }
  }

  public arquivosProcessando(listaArquivos: NgxFileDropEntry[], tipoEnvio: boolean) {
    for (const droppedFile of listaArquivos) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      if (this.verificarExtensao(fileEntry.name)) {
        fileEntry.file((file: File) => {
          this.processar(file, tipoEnvio, listaArquivos);
        });
      } else {
        this.toastr.error('Apenas arquivos nos formatos PDF, JPG, JPEG e PNG são aceitos.');
      }
    }
  }

  processar(file: File, tipoEnvio: boolean, listaArquivos: NgxFileDropEntry[]) {
    const maximoArquivos: number = 5;
    const maximoNomeArquivo: number = 160;
    if (this.tamanhoMaximoArquivo(file.size)) {
      if (file.name.length <= maximoNomeArquivo) {
        if (
          (tipoEnvio && listaArquivos.length + this.documentosTitular.length <= maximoArquivos) ||
          (!tipoEnvio && listaArquivos.length + this.documentosDependente.length <= maximoArquivos * this.quantidadeDependentes)
        ) {
          const reader = new FileReader();
          reader.onload = (event) => {
            const base64String = (event.target?.result as string).split(',')[1];
            const anexo = {
              id: null,
              auxilio: { id: this.idProduto ? this.idProduto : null },
              nomeArquivo: file.name,
              arquivo: base64String,
              titular: tipoEnvio
            };
            const atualizarLista = (lista) => {
              const existeItem = lista.find((item) => item.nomeArquivo === anexo.nomeArquivo);
              if (existeItem) {
                existeItem.arquivo = anexo.arquivo;
              } else {
                lista.push(anexo);
              }
            };
            if (tipoEnvio) {
              atualizarLista(this.documentosTitular);
            } else {
              atualizarLista(this.documentosDependente);
            }
          };
          reader.readAsDataURL(file);
        } else {
          this.toastr.error(
            `Número máximo de ${tipoEnvio ? maximoArquivos : maximoArquivos * this.quantidadeDependentes} arquivos excedido para${tipoEnvio ? ' o titular.' : ' os dependentes.'}`
          );
        }
      } else {
        this.toastr.error(`O nome do arquivo excede o limite permitido de ${maximoNomeArquivo} caracteres.`);
      }
    } else {
      this.toastr.error('O arquivo excede o tamanho máximo permitido de 25MB.');
    }
  }

  verificarExtensao(nomeArquivo: string) {
    let verificarExtensao = false;
    const extensaoPermitida = ['.pdf', '.jpg', '.jpeg', '.png', '.docx'];
    const regex = /(?:\.([^.]+))?$/;
    const extensao = regex.exec(nomeArquivo);
    if (undefined !== extensao && null !== extensao) {
      for (const ext of extensaoPermitida) {
        if (ext === extensao[0]) {
          verificarExtensao = true;
        }
      }
    }
    return verificarExtensao;
  }

  tamanhoMaximoArquivo(tamanho: number) {
    let tamanhoArquivo = false;
    if (tamanho < 25000000) {
      // 25MB em bytes
      tamanhoArquivo = true;
    }
    return tamanhoArquivo;
  }

  public removerArquivo(index: number, titular: boolean): void {
    if (titular) {
      this.documentosTitular.splice(index, 1);
    } else {
      this.documentosDependente.splice(index, 1);
    }
  }

  validadorFormulario(): boolean {
    const valorTitular = this.dadosFormulario.get('valorReembolsoTitular').value || 0;
    const valorDependente = this.dadosFormulario.get('valorReembolsoDependente').value || 0;
    if (
      (valorDependente > 0 || valorTitular > 0) &&
      !!this.dadosFormulario.get('anoReembolso') &&
      !!this.dadosFormulario.get('mesParcela') &&
      !!this.dadosFormulario.get('dataVencimentoParcela') &&
      this.documentosTitular.length >= 0 &&
      this.requerimentoDigital == true
    ) {
      if (this.possuoDependente && this.hasDependentes) return true;
      else if (this.hasDependentes == false) return true;
    }
    return false;
  }

  comparaData(dateString, mesSolicitacao, anoSolicitacao) {
    const dataInscricao = dateString.split('/').map(Number);
    const anoMesInscricao = dataInscricao[2] * 100 + dataInscricao[1];
    const anoMesSolicitacao = anoSolicitacao * 100 + mesSolicitacao;
    return anoMesSolicitacao >= anoMesInscricao;
  }

  Enviarformulario(): void {
    const mesParcela = this.dadosFormulario.get('mesParcela').value;
    const anoReembolso = this.dadosFormulario.get('anoReembolso').value;
    const DATA_LIMITE = '01/01/2024';
    const isDataLimite = this.comparaData(this.dataInscricaoResponse, mesParcela, anoReembolso);
    const isDataLimiteDependente = this.comparaData(DATA_LIMITE, mesParcela, anoReembolso);
    const isObrigatorios = this.dadosFormulario.value && this.dadosFormulario.valid && this.validadorFormulario() && this.calcularValorReembolsoMes();
    try {
      if (this.verificarExistenciaInscricaoUsuario) {
        if (isDataLimiteDependente && (isDataLimite || isDataLimiteDependente) && isObrigatorios) {
          this.waitLoadingService.open();
          const formularioCopy = this.ajustarFormulario(this.dadosFormulario);

          this.solicitacaoAuxilioSaudeService
            .enviarformulario(formularioCopy)
            .pipe(
              takeUntil(this.destroy$),
              finalize(() => this.waitLoadingService.close())
            )
            .subscribe(
              (response: AuxilioSaude) => {
                this.idInscricaoResponse = Number.parseInt(response?.id);
                this.enviarArquivosAuxilio();
              },
              (error) => {
                console.error('Erro ao enviar dados', error);
                this.toastr.error('Erro ao enviar dados: ' + error.causes);
              }
            );
        } else {
          if (!isObrigatorios) {
            this.mensagemErro = 'Existem campos obrigatórios não informados';
            throw new Error('Favor preencher todos os dados obrigatórios');
          } else if (isObrigatorios && !isDataLimite && !this.hasDependentes) {
            throw new Error('O Mês/Ano não pode ser menor que a data de inscrição.');
          } else if (isObrigatorios && !isDataLimiteDependente && this.hasDependentes) {
            throw new Error('Você possui dependentes, portanto a data não pode ser menor que 2024.');
          }
        }
      } else {
        this.toastr.error('Inscrição não realizada.');
        console.error('Ao enviar o formulário! Inscrição não realizada.');
      }
    } catch (error) {
      this.handleErrors(error);
    } finally {
      this.waitLoadingService.close();
    }
  }
  handleErrors(error) {
    this.toastr.error(error);
  }

  EnviarFormularioRetificado(): void {
    const mesParcela = this.dadosFormulario.get('mesParcela').value;
    const anoReembolso = this.dadosFormulario.get('anoReembolso').value;
    const DATA_LIMITE = '01/01/2024';
    const isDataLimite = this.comparaData(this.dataInscricaoResponse, mesParcela, anoReembolso);
    const isDataLimiteDependente = this.comparaData(DATA_LIMITE, mesParcela, anoReembolso);
    const isObrigatorios = this.dadosFormulario.value && this.dadosFormulario.valid && this.validadorFormulario() && this.calcularValorReembolsoMes();
    try {
      if (isDataLimiteDependente && (isDataLimite || isDataLimiteDependente) && isObrigatorios) {
        const formularioCopy = this.ajustarFormulario(this.dadosFormulario);
        this.solicitacaoAuxilioSaudeService
          .enviarFormularioRetificado(formularioCopy, Number(this.idProduto))
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            () => {
              if (this.documentosTitular.length > 0 || this.documentosDependente.length > 0) {
                this.enviarArquivosAuxilioRetificado();
              }

              this.toastr.success('Solicitação enviada com sucesso.');
              setTimeout(() => {
                this.router.navigate(['area-restrita', 'pedido-auxilio-saude']);
              }, 1000);
            },
            (error) => {
              console.error('Erro ao enviar dados', error);
              this.toastr.error('Erro ao enviar dados: ' + error.causes);
            }
          );
      } else {
        if (!isObrigatorios) {
          this.mensagemErro = 'Existem campos obrigatórios não informados';
          throw new Error('Favor preencher todos os dados obrigatórios');
        } else if (isObrigatorios && !isDataLimite && !this.hasDependentes) {
          throw new Error('O Mês/Ano não pode ser menor que a data de inscrição.');
        } else if (isObrigatorios && !isDataLimiteDependente && this.hasDependentes) {
          throw new Error('Você possui dependentes, portanto a data não pode ser menor que 2024.');
        }
      }
    } catch (error) {
      this.handleErrors(error);
    }
  }

  ajustarFormulario(formularioValue: FormGroup) {
    const data = new Date(formularioValue.controls['dataVencimentoParcela'].value);
    const dia = ('0' + data.getDate()).slice(-2);
    const mes = ('0' + (data.getMonth() + 1)).slice(-2);
    const ano = data.getFullYear();

    formularioValue.controls['dataVencimentoParcela'].setValue(`${dia}/${mes}/${ano}`);
    //if (this.dadosFormulario.get('valorReembolsoTitular').value) this.dadosFormulario.get('valorReembolsoTitular').setValue(0);
    //if (this.dadosFormulario.get('valorReembolsoDependente').value) this.dadosFormulario.get('valorReembolsoDependente').setValue(0);

    return formularioValue;
  }

  enviarArquivosAuxilio(): void {
    this.documentosTitular.forEach((documento) => {
      documento.auxilio.id = this.idInscricaoResponse.toString();
    });
    this.documentosDependente.forEach((documento) => {
      documento.auxilio.id = this.idInscricaoResponse.toString();
    });

    combineLatest([
      this.solicitacaoAuxilioSaudeService.enviarArquivo(this.documentosTitular),
      this.solicitacaoAuxilioSaudeService.enviarArquivo(this.documentosDependente)
    ]).subscribe(
      () => {
        this.toastr.success('Enviado com sucesso');
        this.mensagemErro = '';
        this.limparForm();
        this.requerimentoDigital = false;
        this.possuoDependente = false;
        this.dependenteImpostoRenda = false;
        this.obterLista();
        setTimeout(() => {
          location.reload();
        }, 500);
      },
      (error: HttpErrorResponse) => {
        this.toastr.error('Erro ao enviar arquivos: ' + error.message);
      }
    );
  }

  enviarArquivosAuxilioRetificado(): void {
    combineLatest([
      this.solicitacaoAuxilioSaudeService.enviarArquivoRetificado(this.documentosTitular),
      this.solicitacaoAuxilioSaudeService.enviarArquivoRetificado(this.documentosDependente)
    ]).subscribe(
      () => {
        this.toastr.success('Enviado com sucesso');
        this.mensagemErro = '';
        this.limparForm();
        this.requerimentoDigital = false;
        this.possuoDependente = false;
        this.dependenteImpostoRenda = false;
        this.obterLista();
      },
      (error: HttpErrorResponse) => {
        this.toastr.error('Erro ao enviar arquivos: ' + error.message);
      }
    );
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageNumber = e.pageIndex;
    this.obterLista();
  }

  limparForm() {
    this.dadosFormulario.get('anoReembolso').reset();
    this.dadosFormulario.get('mesParcela').reset();
    this.dadosFormulario.get('dataVencimentoParcela').reset();
    this.dadosFormulario.get('valorReembolsoDependente').reset();
    this.dadosFormulario.get('valorReembolsoTitular').reset();
    this.dadosFormulario.get('valorReembolsoTotal').reset();
    this.documentosTitular = [];
    this.documentosDependente = [];
  }

  verficacaoReembolsoAnteriorDataNascimentoDependente() {
    const formData = this.dadosFormulario.getRawValue();
    const { mesParcela, anoReembolso } = formData;
    if (mesParcela && anoReembolso && this.dependente.length > 0) {
      this.hasDependentes = this.dependente.every((dep) => {
        const anoResponse = Number(dep?.dataNascimento.substring(6, 10));
        const mesResponse = Number(dep?.dataNascimento.substring(3, 5));

        if (anoResponse < anoReembolso) {
          return true;
        } else if (anoResponse === anoReembolso) {
          return mesResponse < mesParcela;
        } else {
          return false;
        }
      });
      if (!this.hasDependentes) {
        this.toastr.warning('Ano e mês de de solicitação de reembolso menor que a data de nascimento do dependente.');
        this.anexoArqDependente = [];
        this.dadosFormulario.get('valorReembolsoDependente').reset();
      }
    }
    if (mesParcela) {
      this.obterLimite();
    }
  }

  private atualizarLista(lista, anexo) {
    const existeItem = lista.find((item) => item.nomeArquivo === anexo.nomeArquivo);
    if (existeItem) {
      existeItem.arquivo = anexo.arquivo;
    } else {
      lista.push(anexo);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
