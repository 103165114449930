<main class="main" role="main">
  <section class="page">
    <app-separador label="DIÁRIO OFICIAL" />
    <table>
      <tbody>
        <tr class="tituloTable">
          <th>Data</th>
        </tr>
        <tr>
          <td>25/04/2024</td>
        </tr>
      </tbody>
    </table>
  </section>
</main>
