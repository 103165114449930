import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable, map } from 'rxjs';
import { Globais } from 'src/app/area-restrita/shared/globais/globais';
import { Page } from 'src/app/shared/models/paginacao/page.model';
import { AuxilioFinanceiroVoltaEtapa } from '../models/auxilio-financeiro-voltaetapa';
import { AuxilioSaudeMesaAnalise } from '../models/auxilio-saude';
import { AuxilioFinanceiro, FormFinanceiro } from './../models/auxilio-financeiro';

@Injectable({
  providedIn: 'root'
})
export class FinanceiroAuxilioSaudeService {
  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient
  ) {}

  dadosCarregados = this.formBuilder.group({
    procuradorServidor: [''],
    totalDeferido: [''],
    mesAnoSolicitacao: [''],
    periodoDe: [''],
    periodoAte: ['']
  });

  salvarDados(dados) {
    this.dadosCarregados.setValue(dados);
    console.log('Dados salvos no serviço:', this.dadosCarregados.value);
  }

  esvaziarDados() {
    this.dadosCarregados.reset({
      procuradorServidor: '',
      totalDeferido: '',
      mesAnoSolicitacao: '',
      periodoDe: '',
      periodoAte: ''
    });
    console.log('Dados esvaziados no serviço:', this.dadosCarregados.value);
  }

  buscarDeferidos(
    dadosFormulario: FormGroup<FormFinanceiro>,
    pageIndex: string | number,
    pageSize: string | number
  ): Observable<Page<AuxilioSaudeMesaAnalise>> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token
    });

    const dataRecebida = dadosFormulario.controls.mesAnoSolicitacao.value;

    let parametros = new HttpParams()
      .set('nomeUsuario', dadosFormulario.controls.procuradorServidor.value)
      .set('dataEnvioInicial', dadosFormulario.controls.periodoDe.value)
      .set('dataEnvioFinal', dadosFormulario.controls.periodoAte.value)
      .set('idStatus', 2)
      .set('page', pageIndex)
      .set('size', pageSize);

    if (dataRecebida && dataRecebida.trim() !== '') {
      const partesData = dataRecebida.split('-');
      parametros = parametros.set('anoReembolso', partesData[0]);
      parametros = parametros.set('mesParcela', partesData[1]);
    }
    return this.http.get<Page<AuxilioSaudeMesaAnalise>>(Globais.urlToken + 'site-pge/api/auxilios', { headers: headers, params: parametros });
  }

  definePagamento(dados: AuxilioFinanceiro): Observable<string> {
    const token = localStorage.getItem('token');
    const idUsuario = localStorage.getItem('idUsuario');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token
    });
    const parametros = new HttpParams().set('idUsuario', idUsuario);
    return this.http
      .put<string>(Globais.urlToken + 'site-pge/api/auxilios/pagar', dados, { headers: headers, params: parametros, responseType: 'text' as 'json' })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  voltaEtapa(dados: AuxilioFinanceiroVoltaEtapa): Observable<string> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token
    });
    const parametros = new HttpParams();
    return this.http
      .put<string>(Globais.urlToken + 'site-pge/api/auxilios/saude/financeiro/voltar-etapa', dados, {
        headers: headers,
        params: parametros,
        responseType: 'text' as 'json'
      })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
}
