import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatNativeDateModule, MatOption } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInput, MatDatepickerModule, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatFormField, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { SafeHtml } from '@angular/platform-browser';
import { NgxMaskDirective } from 'ngx-mask';
import { Subject, finalize, takeUntil } from 'rxjs';
import { DependenteParetesco } from 'src/app/area-restrita/features/auxilio/saude/shared/models/dependente-parentesco';
import { AuxilioSaudeService } from 'src/app/area-restrita/features/auxilio/saude/shared/services/auxilio-saude.service';
import { WaitLoadingService } from 'src/app/area-restrita/shared/components/wait-loading/wait-loading.service';
import { PaginaVisitadaService } from 'src/app/area-restrita/shared/services/pagina-visitada.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { StatusAuxilio } from '../shared/models/auxilio-saude';
import { FabricaRelatorioPedidoAuxilio } from '../shared/models/fabrica-relatorio-pedido-auxilio';
import { TipoAuxilioSaude } from '../shared/models/tipo-auxilio-saude';
import { MesaAuxilioSaudeService } from '../shared/services/mesa-auxilio-saude.service';
import { RelatorioAuxilioSaudeService } from '../shared/services/relatorio-auxilio-saude.service';
import { ReportAuxilioSaudeService } from '../shared/services/report-auxilio-saude.service';
import { SolicitacaoAuxilioSaudeService } from '../shared/services/solicitacao-auxilio-saude.service';
import { FabricaRelatorioDependente } from './../shared/models/fabrica-relatorio-dependente';

@Component({
  selector: 'app-relatorio-auxilio-saude',
  templateUrl: './relatorio-auxilio-saude.component.html',
  styleUrl: './relatorio-auxilio-saude.component.sass',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatSelect,
    MatOption,
    MatInput,
    NgxMaskDirective,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatSuffix,
    MatDatepicker,
    MatButton,
    MatPaginator,
    MatDatepickerModule,
    MatNativeDateModule
  ]
})
export class RelatorioAuxilioSaudeComponent implements OnInit {
  resultSearch: SafeHtml;
  parentesco: DependenteParetesco[] = [];
  formReport: FormGroup;
  gerando: boolean = false;

  length = 0;
  pageSize = 10;
  pageNumber = 0;
  pageSizeOptions = [5, 10, 25];
  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;
  tipoRelatorio: number = 1;
  statusAuxilio: StatusAuxilio[];
  tiposAuxilioSaude: TipoAuxilioSaude[];

  relatorioOpcoes = [
    { id: 1, nome: 'Pedidos' },
    { id: 2, nome: 'Dependentes' }
  ];

  private readonly destroy$ = new Subject<void>();

  constructor(
    private solicitacaoAuxilioSaudeService: SolicitacaoAuxilioSaudeService,
    private reportAuxilioSaude: ReportAuxilioSaudeService,
    private reportAuxilioSaudeService: AuxilioSaudeService,
    private relatorioPedidoAuxilio: FabricaRelatorioPedidoAuxilio,
    private relatorioPedidoAuxilioService: RelatorioAuxilioSaudeService,
    private mesaAuxilioSaudeService: MesaAuxilioSaudeService,
    private relatorioDependente: FabricaRelatorioDependente,
    private waitLoadingService: WaitLoadingService,
    private toastr: ToastService,
    private paginaService: PaginaVisitadaService
  ) {}

  ngOnInit(): void {
    this.paginaService.salvaPagina('Relatório de Auxílio Saúde').subscribe();
    this.parentesco = this.reportAuxilioSaude.getParentesco();
    this.obterTiposStatus();
    this.obterListaTipoAuxilioSaude();
    this.formReport = new FormGroup({
      status: new FormControl(''),
      tipoRelatorio: new FormControl(this.relatorioOpcoes[0].id),
      nomeTitular: new FormControl(''),
      cpfTitular: new FormControl(''),
      nomeDependente: new FormControl(''),
      cpfDependente: new FormControl(''),
      parentesco: new FormControl(99),
      exportaPdfCsv: new FormControl(''),
      mesAnoSolicitacao: new FormControl(''),
      vencimentoParcela: new FormControl(''),
      valorReembolso: new FormControl(''),
      dataAvaliacao: new FormControl(''),
      tipoAuxilioSaude: new FormControl('')
    });
  }

  obterTiposStatus() {
    this.mesaAuxilioSaudeService
      .obterListaStatusAuxilio()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (dados) => {
          dados.sort((a, b) => a.id - b.id);
          this.statusAuxilio = dados;
        },
        error: (error) => console.error('Erro ao obter dados do serviço', error)
      });
  }

  clear(): void {
    this.formReport = new FormGroup({
      status: new FormControl(this.statusAuxilio[0].id),
      tipoRelatorio: new FormControl(this.relatorioOpcoes[0].id),
      nomeTitular: new FormControl(''),
      cpfTitular: new FormControl(''),
      nomeDependente: new FormControl(''),
      cpfDependente: new FormControl(''),
      parentesco: new FormControl(99),
      exportaPdfCsv: new FormControl(''),
      mesAnoSolicitacao: new FormControl(''),
      vencimentoParcela: new FormControl(''),
      valorReembolso: new FormControl(''),
      dataAvaliacao: new FormControl(''),
      tipoAuxilioSaude: new FormControl('')
    });
    this.length = 0;
    this.pageSize = 10;
    this.pageNumber = 1;
    this.tipoRelatorio = 1;
    this.gerando = false;
  }

  async getSearch(): Promise<void> {
    this.gerando = false;

    const index: number = this.formReport.get('parentesco')!.value;
    const parentesco = index == 99 ? '' : this.parentesco[index].nome;

    if (this.tipoRelatorio == 2) {
      await this.relatorioDependente.criarRelatorio(
        this.formReport.get('nomeTitular')!.value,
        this.formReport.get('cpfTitular')!.value,
        this.formReport.get('nomeDependente')!.value,
        this.formReport.get('cpfDependente')!.value,
        parentesco,
        this.pageNumber,
        this.pageSize
      );

      this.resultSearch = this.relatorioDependente.getTabela();
      this.length = this.relatorioDependente.getTotalElementos();
      this.pageNumber = this.relatorioDependente.getPageNumber();
      this.pageSize = this.relatorioDependente.getPageSize();
      this.gerando = true;
    } else {
      await this.relatorioPedidoAuxilio.criarRelatorio(this.formReport, this.pageNumber, this.pageSize);

      this.resultSearch = this.relatorioPedidoAuxilio.getTabela();
      this.length = this.relatorioPedidoAuxilio.getTotalElementos();
      this.pageNumber = this.relatorioPedidoAuxilio.getPageNumber();
      this.pageSize = this.relatorioPedidoAuxilio.getPageSize();
      this.gerando = true;
    }
  }

  getPdfCsv(tipo: string) {
    this.gerando = false;
    const index: number = this.formReport.get('parentesco')!.value;
    const parentesco = index == 99 ? '' : this.parentesco[index].nome;

    if (this.tipoRelatorio == 2) {
      this.reportAuxilioSaudeService
        .relatorioPesquisaDependentesTipo(
          this.formReport.get('nomeTitular').value,
          this.formReport.get('cpfTitular').value,
          this.formReport.get('nomeDependente').value,
          this.formReport.get('cpfDependente').value,
          parentesco,
          tipo.toUpperCase()
        )
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => this.waitLoadingService.close())
        )
        .subscribe({
          next: (response: Blob) => {
            const url = window.URL.createObjectURL(response);
            window.open(url);
          },
          error: (error) => this.toastr.error(error.message)
        });
    } else if (this.tipoRelatorio == 1) {
      this.relatorioPedidoAuxilioService
        .relatorioPesquisaPedidosAuxilioTipo(
          this.formReport.get('status').value,
          this.formReport.get('nomeTitular').value,
          this.formReport.get('cpfTitular').value,
          this.formReport.get('nomeDependente').value,
          this.formReport.get('cpfDependente').value,
          parentesco,
          tipo.toUpperCase()
        )
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => this.waitLoadingService.close())
        )
        .subscribe({
          next: (response: Blob) => {
            const url = window.URL.createObjectURL(response);
            window.open(url);
          },
          error: (error) => this.toastr.error(error.error)
        });
    }
  }

  handlePageEvent(e: PageEvent) {
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageNumber = e.pageIndex;
    this.getSearch();
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map((str) => +str);
    }
  }

  obterListaTipoAuxilioSaude(): void {
    this.solicitacaoAuxilioSaudeService.obterListaTipoAuxilioSaude().subscribe((response) => {
      this.tiposAuxilioSaude = response;
    });
  }
}
