import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, throwError } from 'rxjs';
import { LocalStorageService } from 'src/app/area-restrita/shared/services/local-storage.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LegadoUsuarioService {
  headers = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
    Authorization: this.localStorage.get('token')
  });

  env = environment;
  private user: BehaviorSubject<string> = new BehaviorSubject<string>('');
  user$: Observable<string> = this.user.asObservable();
  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService,
    private toastService: ToastService
  ) {}

  enviarNovaSenhaParaLegado(cpf: string, senha: string): Observable<void> {
    return this.http.post<void>(this.env.apiUrl + 'legado/senha/' + cpf + '&' + senha, { headers: this.headers }).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Erro durante o envio de nova senha ao sistema legado:', error);
        return throwError(() => error);
      })
    );
  }

  checkUsuarioLegado(id: string): Observable<void> {
    const url = `${this.env.apiUrl}legado/check/${id}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post<void>(url, null, { headers: headers });
  }
}
