import { Component, OnInit } from '@angular/core';
import { WebServiceInfosService } from 'src/app/shared/services/infos-webservice.service';
import { SeparadorComponent } from '../../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-autarquias',
  templateUrl: './autarquias.component.html',
  standalone: true,
  imports: [SeparadorComponent]
})
export class AutarquiasComponent implements OnInit {
  informacoes: string[] = [];
  codigoUnidades: string[] = [
    '4022AX00000092',
    '4022AX00000012',
    '4022AA00000092',
    '4022AA00000012',
    '4022AP00000090',
    '4022AP00000010',
    '40222500000092',
    '40222500000012',
    '4022AK00000092',
    '4022AK00000012',
    '4022AZ00000092',
    '4022AZ00000012',
    '4022AQ00000092',
    '4022AQ00000012',
    'AQ22AQ00000092',
    'AQ22AQ00000012',
    '4022AR00000092',
    '4022AR00000012',
    '4022AY00000092',
    '4022AY00000012',
    '4022AH00000092',
    '4022AH00000012',
    '4022AJ00000092',
    '4022AJ00000012',
    '4022AS00000092',
    '4022AS00000012'
  ];

  constructor(private service: WebServiceInfosService) {}

  ngOnInit(): void {
    this.carregarConteudo();
  }

  carregarConteudo() {
    const array: string[] = [];
    this.codigoUnidades.forEach((element) => {
      this.service.getInfosPorCodigo(element).subscribe(
        (response) => {
          const parser = new DOMParser();
          const xml = parser.parseFromString(response, 'text/xml');
          xml.querySelectorAll('NOME_PROCURADOR, NOME_SUBSTITUTO, NOME_SUBSTITUTO2').forEach((element) => {
            if (element.textContent !== '') {
              array.push(element.textContent.trim());
            }
          });
        },
        (error) => {
          console.log(error);
        }
      );
    });

    this.informacoes = array;
  }

  findInArray(nome: string): string {
    const result = this.informacoes.find((element) => element === nome);
    return result ? result : 'Nome não encontrado';
  }
}
