{
  "name": "site-pge-front-end",
  "version": "0.5.56",
  "environmentRun": "PROD",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build --configuration=dev",
    "dev": "ng build --configuration=dev",
    "hml": "ng build --configuration=hml",
    "prd": "ng build --configuration=prd",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "lint": "ng lint"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.0.3",
    "@angular/cdk": "^18.0.3",
    "@angular/common": "^18.0.3",
    "@angular/compiler": "^18.0.3",
    "@angular/core": "^18.0.3",
    "@angular/forms": "^18.0.3",
    "@angular/material": "^18.0.3",
    "@angular/platform-browser": "^18.0.3",
    "@angular/platform-browser-dynamic": "^18.0.3",
    "@angular/router": "^18.0.3",
    "html2canvas": "^1.4.1",
    "jquery": "^3.7.1",
    "ngx-currency": "^18.0.0",
    "ngx-file-drop": "^16.0.0",
    "ngx-mask": "^16.4.2",
    "path-browserify": "^1.0.1",
    "primeng": "^17.18.0",
    "quill": "^1.3.7",
    "rxjs": "~7.8.0",
    "xlsx": "^0.18.5",
    "zone.js": "~0.14.7"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.0.4",
    "@angular/cli": "^18.0.4",
    "@angular/compiler-cli": "^18.0.3",
    "@eslint/js": "9.9.0",
    "angular-eslint": "18.3.0",
    "eslint": "9.9.0",
    "globals": "^15.8.0",
    "prettier": "3.3.2",
    "typescript": "~5.4.5",
    "typescript-eslint": "8.1.0"
  }
}
