<main class="main" role="main">
  <section class="page">
    <div class="container">
      <app-separador label="Procuradoria Regional da Campinas (PR05)" />
      <div class="row">
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 the_content">
          <br />
          <h3>PR05 CHEFIA GABINETE</h3>
          <div class="pge-unidade">
            <p class="procurador"><strong>Procurador(a)</strong>: {{ findInArray("GUILHERME MALAGUTI SPINA") }}</p>
            <p class="endereco"><strong>Endereço</strong>: Av. Brasil, 2340, Jardim Chapadão, Campinas-SP, 13070-178.</p>
            <p class="tel"><strong>Telefone</strong>: (19) 3775-8040 /8042 /8086</p>
            <p class="email"><strong>E-mail</strong>: protocolo-pr5&#64;sp.gov.br</p>
            <div class="descricao">
              <strong>Municípios abrangidos:</strong>Aguaí, Águas da Prata, Águas de Lindóia, Águas de São Pedro, Americana, Amparo, Araras, Arthur Nogueira, Atibaia, Bom Jesus dos Perdões, Bragança
              Paulista, Caconde, Cajamar, Campinas, Campo Limpo Paulista, Capivari, Charqueada, Conchal, Cordeirópolis, Corumbataí, Casa Branca, Cosmópolis, Divinolândia, Elias Fausto, Engenheiro
              Coelho, Espírito Santo do Pinhal, Estiva Gerbi, Holambra, Hortolândia, Indaiatuba, Ipeúna, Iracemápolis, Itapira, Itatiba, Itobi, Itupeva, Jaguariúna, Jarinu, Joanópolis, Jundiaí, Leme,
              Limeira, Lindóia, Louveira, Mococa, Mogi Guaçu, Mogi Mirim, Mombuca, Monte Alegre do Sul, Monte Mor, Morungaba, Nazaré Paulista, Nova Odessa, Paulínia, Pedra Bela, Pedreira, Pinhalzinho,
              Piracaia, Piracicaba, Rafard, Rio Claro, Rio das Pedras, Saltinho, Santa Bárbara D Oeste, Santa Cruz das Palmeiras, Santa Gertrudes, Santa Maria da Serra, Santo Antonio da Posse, Santo
              Antonio do Jardim, São João da Boa Vista, São José do Rio Pardo, São Pedro,São Sebastão da Grama, Serra Negra, Socorro, Sumaré, Tambaú, Tapiratiba, Valinhos, Vargem, Vargem Grande do
              Sul, Várzea Paulista, Vinhedo.
            </div>
          </div>
          <hr />
          <br />
          <h3>SECCIONAL DE BRAGANCA PAULISTA</h3>
          <div class="pge-unidade">
            <p class="procurador"><strong>Procurador(a)</strong>: {{ findInArray("HENRIQUE MARTINI MONTEIRO") }}</p>
            <p class="endereco"><strong>Endereço</strong>: Travessa Itália, 57, Centro, Bragança Paulista-SP, 12900-061.</p>
            <p class="tel"><strong>Telefone</strong>: (11) 4892-6501</p>
          </div>
          <hr />
          <br />
          <h3>SECCIONAL DE PIRACICABA</h3>
          <div class="pge-unidade">
            <p class="procurador"><strong>Procurador(a)</strong>: {{ findInArray("FERNANDA PAULINO") }}</p>
            <p class="endereco"><strong>Endereço</strong>: Rua Dr. João Sampaio, 666, Bairro São Dimas, Piracicaba-SP, 13416-383.</p>
            <p class="tel"><strong>Telefone</strong>: (19) 3447-7802</p>
          </div>
          <hr />
          <br />
          <h3>SECCIONAL DE RIO CLARO</h3>
          <br />
          <div class="pge-unidade">
            <p class="procurador"><strong>Procurador(a)</strong>: {{ findInArray("PRISCILA APARECIDA RAVAGNANI") }}</p>
            <p class="endereco"><strong>Endereço</strong>: Av. da Saudade, 37, 1º andar, Bairro da Consolação, Rio Claro-SP, 13500-300.</p>
            <p class="tel"><strong>Telefone</strong>: (19) 3524-7979/ 3524-4150</p>
          </div>
          <hr />
          <br />
          <h3>SECCIONAL DE SAO JOAO DA BOA VISTA</h3>
          <br />
          <div class="pge-unidade">
            <p class="procurador"><strong>Procurador(a)</strong>: {{ findInArray("MARIA FERNANDA SILOS ARAUJO LANCELLOTTI") }}</p>
            <p class="endereco"><strong>Endereço</strong>: Rua Joel Lisboa Biotto, 305, Terras de São José, São João da Boa Vista-SP, 13874-822.</p>
            <p class="tel"><strong>Telefone</strong>: (19) 3681-9601 | 9602 | 9612</p>
          </div>
          <hr />
          <br />
          <h3>SECCIONAL DE JUNDIAI</h3>
          <br />
          <div class="pge-unidade">
            <p class="procurador"><strong>Procurador(a)</strong>: {{ findInArray("PAULO GUILHERME GORSKI DE QUEIROZ") }}</p>
            <p class="endereco"><strong>Endereço</strong>: Avenida Prefeito Luis Latorre, 4200, Vila das Hortênsias, Jundiaí-SP, 13209-430.</p>
            <p class="tel"><strong>Telefone</strong>: (11) 4523-2002 /2004 /2005 /2008</p>
          </div>
          <hr />
          <br />
          <h3>SECCIONAL DE LIMEIRA</h3>
          <br />
          <div class="pge-unidade">
            <p class="procurador"><strong>Procurador(a)</strong>: {{ findInArray("JOSE RENATO ROCCO ROLAND GOMES") }}</p>
            <p class="endereco"><strong>Endereço</strong>: Rua Senador Vergueiro, 250, Centro, Limeira-SP, 13480-005.</p>
            <p class="tel"><strong>Telefone</strong>: (19) 3446-5451 | (19) 3446-5455</p>
          </div>
          <br />
          <hr />
          <br />
          <app-voltar-component />
          <br />
        </div>
      </div>
    </div>
  </section>
</main>
