<div class="container">
  <div class="column cor">
    <h3 class="cor">QUER MAIS INFORMAÇÕES?</h3>
    <h3 class="cor">SIGA NOSSAS REDES SOCIAIS</h3>
  </div>
  <div class="column" style="align-items: flex-end">
    <h1 class="cor">/PGEOFICIAL</h1>
    <div class="social-icons">
      <a href="https://www.whatsapp.com/channel/0029VaO5LfK9mrGgrM7MFi3X" target="_blank"><img [src]="assetsPath + '/images/whatsapp.svg'" alt="Whats App" /></a>
      <a href="https://www.facebook.com/pgespoficial/?locale=pt_BR" target="_blank"><img [src]="assetsPath + '/images/facebook.svg'" alt="Facebook" /></a>
      <a href="https://www.instagram.com/pgespoficial/" target="_blank"> <img [src]="assetsPath + '/images/instagram.svg'" alt="Instagram" /></a>
      <a href="https://br.linkedin.com/company/procuradoria-geral-do-estado-de-s%C3%A3o-paulo-pge-sp" target="_blank"><img [src]="assetsPath + '/images/linkedin.svg'" alt="Linkedin" /></a>
    </div>
  </div>
</div>
