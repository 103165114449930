import { Component, OnInit } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

import { ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { Router } from '@angular/router';
import { ImagemTratamento } from 'src/app/area-aberta/features/home/shared/services/imagem-tratamento';
import { transformarData } from 'src/app/area-restrita/features/home/shared/utils/util';
import { Globais } from 'src/app/area-restrita/shared/globais/globais';
import { PaginaVisitada } from 'src/app/area-restrita/shared/models/pagina-visitada';
import { PaginaVisitadaService } from 'src/app/area-restrita/shared/services/pagina-visitada.service';
import { Noticias } from 'src/app/shared/models/noticia.interface';
import { Page } from 'src/app/shared/models/paginacao/page.model';
import { NoticiaService } from 'src/app/shared/services/noticia.service';
import { Pessoa } from '../../auxilio/saude/shared/models/auxilio-saude';
import { PessoaService } from '../shared/services/pessoa.service';

@Component({
  selector: 'app-home-info-area-restrita',
  templateUrl: './home-info-area-restrita.component.html',
  styleUrl: './home-info-area-restrita.component.sass',
  providers: [ImagemTratamento],
  standalone: true,
  imports: [MatPaginator],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeInfoAreaRestritaComponent implements OnInit {
  bottomDesc = Globais.versionFront;
  paginasAcessadas: PaginaVisitada[] = [];
  objNoticias?: Noticias[] = [];
  hisNoticias?: Noticias[] = [];
  currentImageIndex: number = 0;
  visualizarPaginacao: boolean = false;

  pageEvent: PageEvent;
  length = 0;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25];

  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;
  idCategoria = 2; //Notícia Área Restrita

  isTextLimited: boolean = true;

  constructor(
    private noticiaService: NoticiaService,
    private pessoaService: PessoaService,
    private imagemTratamento: ImagemTratamento,
    private router: Router,
    private paginaService: PaginaVisitadaService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.paginaService.obtemPaginas().subscribe(
      (resp) => {
        this.paginasAcessadas = resp;
        this.cdr.markForCheck();
      },
      (error) => console.log('Error ao obter Historico do usuario', error)
    );
    this.obterNoticiasHistorico();
    if (localStorage.getItem('idUsuario')) {
      console.info('Notícias? Ok');
      this.obterDadosPessoa();
    } else {
      console.warn('Notícias? IdPerfil não encontrado!');
    }
  }

  nomePagina(url): string {
    const parts = url.split('@');
    return parts.length > 1 ? parts[0] : 'Pagina sem nome';
  }
  linkPagina(url): string {
    const parts = url.split('@');
    return parts[1] || '';
  }

  async obterNoticiasHistorico() {
    const historicNoticias = localStorage.getItem('historicoNoticia');
    if (historicNoticias) {
      const idsNoticia = historicNoticias.split(',');
      for (const id of idsNoticia) {
        if (id.trim() !== '') {
          try {
            const resp = await this.noticiaService.getOneNoticia(Number(id)).toPromise();
            this.hisNoticias.push(resp);
          } catch (error) {
            console.error('Erro ao obter notícia:', error);
          }
        }
      }
    }
  }
  obterNoticiasPorIdPerfil(id: number) {
    this.noticiaService.obterNoticiasPorIdPerfil(this.pageIndex, this.pageSize, id).subscribe(
      (response: Page<Noticias>) => {
        console.log(response);
        this.objNoticias = response.content;
        this.visualizarPaginacao = true;
        this.length = response.totalElements;
        this.cdr.markForCheck();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  obterDadosPessoa() {
    this.pessoaService.obterDadosPessoa(Number(localStorage.getItem('idUsuario'))).subscribe(
      (response: Pessoa) => {
        const id = response.perfil.id;
        this.obterNoticiasPorIdPerfil(Number(id));
        this.cdr.markForCheck();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  editarData(data: string): string {
    return transformarData(data);
  }

  getImagem(noticiaImagem: string): string | undefined {
    if (!noticiaImagem) console.log(noticiaImagem);
    return this.imagemTratamento.getImagemBlob(noticiaImagem);
  }

  processarTexto(texto: string): string {
    const linhas = texto.split('<p');

    this.isTextLimited = linhas.length > 10;

    const textoProcessado = this.isTextLimited ? linhas.slice(0, 10).join('<p') : texto;
    const textoSemQuebra = textoProcessado.replace(/<br>/g, '');
    const textoAlterado = textoSemQuebra.replace(/<[^>]*>/g, '');

    return textoAlterado;
  }

  visualizarNoticia(id: number) {
    const historicoNoticia = localStorage.getItem('historicoNoticia');

    if (historicoNoticia) {
      const ids = historicoNoticia.split(',');
      const index = ids.indexOf(id.toString());
      if (index !== -1) ids.splice(index, 1);
      ids.unshift(id.toString());
      if (ids.length > 10) ids.pop();
      localStorage.setItem('historicoNoticia', ids.join(','));
    } else localStorage.setItem('historicoNoticia', id.toString());
    this.router.navigate(['area-restrita', 'noticia-home', id]);
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.obterDadosPessoa();
  }
}
