import { CurrencyPipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatNativeDateModule, MatOption } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInput, MatDatepickerModule, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatTooltip } from '@angular/material/tooltip';
import { Router, RouterLink } from '@angular/router';
import { Observable, Subject, finalize, takeUntil } from 'rxjs';
import { AuxilioSaudeMesaAnalise, Perfil, StatusAuxilio } from 'src/app/area-restrita/features/auxilio/saude/shared/models/auxilio-saude';
import { MesaAuxilioSaudeService } from 'src/app/area-restrita/features/auxilio/saude/shared/services/mesa-auxilio-saude.service';
import { WaitLoadingService } from 'src/app/area-restrita/shared/components/wait-loading/wait-loading.service';
import { Meses } from 'src/app/area-restrita/shared/models/global-interface';
import { LocalStorageService } from 'src/app/area-restrita/shared/services/local-storage.service';
import { PaginaVisitadaService } from 'src/app/area-restrita/shared/services/pagina-visitada.service';
import { Page } from 'src/app/shared/models/paginacao/page.model';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { FormatBRLPipe } from '../../../../shared/pipes/format-brl.pipe';
import { MesPorExtensoPipe } from '../../../../shared/pipes/mes-por-extenso.pipe';
import { StateAnaliseService } from '../../shared/services/state-analise.service';
import { TipoAuxilioSaude } from '../shared/models/tipo-auxilio-saude';
import { SolicitacaoAuxilioSaudeService } from '../shared/services/solicitacao-auxilio-saude.service';

@Component({
  selector: 'app-mesa-analise-pedido-auxilio-saude',
  templateUrl: './mesa-analise-pedido-auxilio-saude.component.html',
  styleUrl: './mesa-analise-pedido-auxilio-saude.component.sass',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    MatSelect,
    MatOption,
    MatLabel,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatSuffix,
    MatDatepicker,
    MatButton,
    RouterLink,
    MatIcon,
    MatTooltip,
    MatPaginator,
    CurrencyPipe,
    FormatBRLPipe,
    MesPorExtensoPipe,
    MatDatepickerModule,
    MatNativeDateModule
  ]
})
export class MesaAnalisePedidoAuxilioSaudeComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  pedidosAuxilio: AuxilioSaudeMesaAnalise[] = [];
  tiposAuxilioSaude: TipoAuxilioSaude[];
  pedidosSelecionados: Set<number> = new Set<number>();
  statusAuxilio: StatusAuxilio[];
  perfil: Perfil[];
  meses: Observable<Meses[]> | undefined;
  checked = true;
  paginador = false;
  checkboxCabecalhoSelecionado: boolean = false;
  mostraTabela: boolean = false;
  ativaBotao: boolean = true;
  pageEvent: PageEvent;
  length = 0;
  pageSize = 10;
  pageNumber = 0;
  pageSizeOptions = [5, 10, 25];
  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;
  isAnaliseInscricao = false;
  mesAnoOrdem = 0;

  constructor(
    private solicitacaoAuxilioSaudeService: SolicitacaoAuxilioSaudeService,
    private mesaAuxilioSaudeService: MesaAuxilioSaudeService,
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastService,
    private localStorage: LocalStorageService,
    private waitLoadingService: WaitLoadingService,
    private stateAnaliseService: StateAnaliseService,
    private paginaService: PaginaVisitadaService
  ) {}

  ngOnInit(): void {
    this.paginaService.salvaPagina('Analisar Pedido de Auxílio Saúde').subscribe();
    this.isAnaliseInscricao = this.router.url.includes('analisar-inscricao');
    this.pageNumber = this.stateAnaliseService.page;
    this.pageSize = this.stateAnaliseService.size;
    this.mesAnoOrdem = this.stateAnaliseService.mesAnoOrdem;
    this.stateAnaliseService.limpar();
    this.recuperarFiltro();
    this.obterTiposStatus();
    this.obterListaTipoAuxilioSaude();
    this.obterPerfis();
    const filtroArmazenado = this.localStorage.getJson('filtro');
    if (filtroArmazenado && this.existeValorNoFiltro(filtroArmazenado)) {
      setTimeout(() => {
        this.buscarPedidos();
      }, 1000);
    }
  }

  dadosFormulario = this.formBuilder.group({
    procuradorServidor: [this.stateAnaliseService.procuradorServidor],
    status: [this.stateAnaliseService.status ?? '1'],
    perfil: [this.stateAnaliseService.perfil],
    tipoAuxilioSaude: [this.stateAnaliseService.tipoAuxilioSaude],
    dataAprovacaoPGE: [this.getCurrentDate()],
    mesAnoSolicitacao: [this.stateAnaliseService.mesAnoSolicitacao],
    periodoDe: [this.stateAnaliseService.periodoDe],
    periodoAte: [this.stateAnaliseService.periodoAte]
  });

  get listaPedidos() {
    return this.pedidosAuxilio.sort((a, b) => {
      const anoA = parseInt(a.anoReembolso, 10);
      const anoB = parseInt(b.anoReembolso, 10);
      const mesA = parseInt(a.mesParcela, 10);
      const mesB = parseInt(b.mesParcela, 10);

      if (this.mesAnoOrdem === 0) {
        if (anoA !== anoB) {
          return anoA - anoB;
        }
        return mesA - mesB;
      } else {
        if (anoA !== anoB) {
          return anoB - anoA;
        }
        return mesB - mesA;
      }
    });
  }

  buscarPedidos() {
    if (this.dadosFormulario.valid) {
      this.waitLoadingService.open();

      this.localStorage.setJson('filtro', this.dadosFormulario.value);

      this.mesaAuxilioSaudeService
        .buscarPedidos(this.dadosFormulario, this.pageNumber, this.pageSize)
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => this.waitLoadingService.close())
        )
        .subscribe({
          next: (response: Page<AuxilioSaudeMesaAnalise>) => {
            this.pedidosAuxilio = response.content;
            this.pedidosAuxilio.sort((a, b) => {
              if (a.anoReembolso > b.anoReembolso) return -1;
              if (a.anoReembolso < b.anoReembolso) return 1;
              if (a.mesParcela > b.mesParcela) return -1;
              if (a.mesParcela < b.mesParcela) return 1;
              return 0;
            });
            this.length = response.totalElements;
            this.paginador = !response.empty;
            this.mostraTabela = true;
          },
          error: (error) => this.toastr.error(error.message)
        });
    }
  }

  avaliarPedido(pedido) {
    this.stateAnaliseService.procuradorServidor = this.dadosFormulario.get('procuradorServidor').value;
    this.stateAnaliseService.status = this.dadosFormulario.get('status').value;
    this.stateAnaliseService.perfil = this.dadosFormulario.get('perfil').value;
    this.stateAnaliseService.mesAnoSolicitacao = this.dadosFormulario.get('mesAnoSolicitacao').value;
    this.stateAnaliseService.periodoDe = this.dadosFormulario.get('periodoDe').value;
    this.stateAnaliseService.periodoAte = this.dadosFormulario.get('periodoAte').value;
    this.stateAnaliseService.telaAnalise = true;
    this.stateAnaliseService.page = this.pageNumber;
    this.stateAnaliseService.size = this.pageSize;
    this.stateAnaliseService.mesAnoOrdem = this.mesAnoOrdem;
    if (!this.isAnaliseInscricao) {
      this.router.navigate(['area-restrita', 'analise-pedido-auxilio-saude', pedido.id]);
      return;
    }
    console.log('passou para cá');
    this.router.navigate(['area-restrita', 'inscricao-auxilio-avaliacao', pedido.usuario.id]);
  }

  deferirPedidos() {
    this.mesaAuxilioSaudeService
      .deferirPedidos(this.pedidosSelecionados)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response) => {
          this.toastr.success(response);
          this.buscarPedidos();
          this.pedidosSelecionados = new Set<number>();
          this.checkboxCabecalhoSelecionado = false;
        },
        (error: HttpErrorResponse) => {
          this.toastr.error('Ocorreu um erro ao deferir os pedidos: ' + error.message);
        }
      );
  }

  indeferirPedidos() {
    this.mesaAuxilioSaudeService
      .indeferirPedidos(this.pedidosSelecionados)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response) => {
          this.toastr.success(response);
          this.buscarPedidos();
          this.pedidosSelecionados = new Set<number>();
          this.checkboxCabecalhoSelecionado = false;
        },
        (error: HttpErrorResponse) => {
          this.toastr.error('Ocorreu um erro ao indeferir os pedidos:. ' + error.message);
        }
      );
  }

  checkPedidoSelecionado(pedido: AuxilioSaudeMesaAnalise) {
    if (pedido.podeDeferir) {
      const pedidoId = Number.parseInt(pedido.id);

      if (this.pedidosSelecionados.has(pedidoId)) {
        this.pedidosSelecionados.delete(pedidoId);
      } else {
        this.pedidosSelecionados.add(pedidoId);
      }

      this.ativaBotao = this.pedidosSelecionados.size == 0;
    }
    const todosSelecionados = this.pedidosAuxilio
      .filter((pedido) => pedido.podeDeferir)
      .every((pedido) => this.pedidosSelecionados.has(Number.parseInt(pedido.id)));
    this.checkboxCabecalhoSelecionado = todosSelecionados;
  }

  obterTiposStatus() {
    this.mesaAuxilioSaudeService
      .obterListaStatusAuxilio()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (dados: StatusAuxilio[]) => {
          this.statusAuxilio = dados;
        },
        error: (error) => console.error('Erro ao obter dados do serviço', error)
      });
  }

  obterPerfis() {
    this.waitLoadingService.open();
    this.mesaAuxilioSaudeService
      .obterListaPerfis()
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.waitLoadingService.close())
      )
      .subscribe({
        next: (dados) => {
          this.perfil = dados;
        },
        error: (error) => console.error('Erro ao obter dados do serviço', error)
      });
  }

  limparFormulario() {
    this.dadosFormulario.reset({
      procuradorServidor: '',
      status: '1',
      perfil: '',
      mesAnoSolicitacao: '',
      periodoDe: '',
      periodoAte: ''
    });
    this.localStorage.remove('filtro');
    this.length = 0;
    this.pageSize = 10;
    this.pageNumber = 0;
    this.pedidosSelecionados.clear();
    this.pedidosAuxilio = [];
    this.checked = true;
    this.paginador = false;
    this.mostraTabela = false;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  selecionarTodos(event: Event) {
    console.log(event);
    const isChecked = (event.target as HTMLInputElement).checked;
    this.checkboxCabecalhoSelecionado = isChecked;

    this.pedidosAuxilio.forEach((pedido) => {
      if (pedido.podeDeferir) {
        if (isChecked) {
          this.pedidosSelecionados.add(Number.parseInt(pedido.id));
        } else {
          this.pedidosSelecionados.delete(Number.parseInt(pedido.id));
        }
      }
    });
    this.ativaBotao = !isChecked || this.pedidosSelecionados.size == 0;
  }

  getCurrentDate(): string {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
    const day = ('0' + currentDate.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageNumber = e.pageIndex;
    this.buscarPedidos();
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map((str) => +str);
    }
  }

  recuperarFiltro(): void {
    const filtro = this.localStorage.getJson('filtro');

    if (filtro) {
      this.dadosFormulario.patchValue(filtro);
    }
  }

  obterListaTipoAuxilioSaude(): void {
    this.solicitacaoAuxilioSaudeService.obterListaTipoAuxilioSaude().subscribe((response) => {
      this.tiposAuxilioSaude = response;
    });
  }

  private existeValorNoFiltro(filtro): boolean {
    return Object.values(filtro).some((value) => !!value);
  }
}
