<div id="confirma" name="confirma">
  <p class="title">{{ data.title }}</p>
  <br />
  <p class="mensagem">{{ data.message }}</p>
  <mat-dialog-actions class="dialog-actions">
    <button class="dialog-button" (click)="onConfirm()">Sim</button>
    @if (!escondeBotaoNao) {
      <button class="dialog-button" (click)="onDismiss()">Não</button>
    }
  </mat-dialog-actions>
</div>
