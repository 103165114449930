<div class="container">
  <br />
  <h1 class="card-title titulo">Relatório de Auxílio Saúde</h1>
  <br />
  <br />
  <form [formGroup]="formReport">
    <div class="d-flex row div-split">
      <div class="col-md-4">
        <label for="tipoRelatorio" class="form-label">Tipo de Perfil:</label>
        <mat-form-field class="mat-full-width">
          <mat-select class="mat-form-control" formControlName="tipoRelatorio" [(ngModel)]="tipoRelatorio">
            @for (s of relatorioOpcoes; track s) {
              <mat-option [value]="s.id">
                {{ s.nome }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>

      @if (tipoRelatorio === 1) {
        <div class="col-md-4">
          <label for="status" class="form-label" id="status">Status:</label>
          <mat-form-field class="mat-full-width">
            <mat-select class="mat-form-control" formControlName="status">
              <mat-option value="">Todos</mat-option>
              @for (s of this.statusAuxilio; track s) {
                <mat-option [value]="s.id">
                  {{ s.nome }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      }
      <div class="col-md-3">
        <label for="tipoAuxilioSaude" class="form-label" id="tipoAuxilioSaude">Tipo Auxílio Saúde:</label>
        <mat-form-field>
          <mat-select id="tipoAuxilioSaude" class="mat-form-control" formControlName="tipoAuxilioSaude">
            @for (s of tiposAuxilioSaude; track s) {
              <mat-option [value]="s.id">
                {{ s.tipo }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="d-flex row div-split">
      <div class="col-md-4">
        <label for="nomeTitular" class="form-label">Nome do titular:</label>
        <mat-form-field class="mat-full-width">
          <input matInput type="text" id="nomeTitular" name="nomeTitular" formControlName="nomeTitular" />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <label for="cpfTitular" class="form-label">CPF do titular:</label>
        <mat-form-field class="mat-full-width">
          <input matInput type="text" mask="000.000.000-00" id="cpfTitular" name="cpfTitular" formControlName="cpfTitular" />
        </mat-form-field>
      </div>
    </div>
    @if (tipoRelatorio === 2) {
      <div class="d-flex row div-split">
        <div class="col-md-4">
          <label for="nomeDependente" class="form-label">Nome do Dependente:</label>
          <mat-form-field class="mat-full-width">
            <input matInput type="text" id="nomeDependente" name="nomeDependente" formControlName="nomeDependente" />
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <label for="cpfDependente" class="form-label">CPF do Dependente:</label>
          <mat-form-field class="mat-full-width">
            <input matInput type="text" mask="000.000.000-00" id="cpfDependente" name="cpfDependente" formControlName="cpfDependente" />
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <label for="parentesco" class="form-label">Parentesco:</label>
          <mat-form-field class="mat-full-width">
            <mat-select class="mat-form-control" formControlName="parentesco">
              @for (s of parentesco; track s; let i = $index) {
                <mat-option [value]="i">
                  {{ s.nome }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    }
    @if (tipoRelatorio === 1) {
      <div class="d-flex row div-split">
        <div class="col-md-2">
          <label for="mesAnoSolicitacao" class="form-label" id="mesAnoSolicitacao">Mês/Ano de solicitação:</label>
          <input id="mesAnoSolicitacao" name="mesAnoSolicitacao" class="mat-full-width" type="month" formControlName="mesAnoSolicitacao" />
        </div>
        <div class="col-md-2">
          <label for="vencimentoParcela" class="form-label">Vencimento da parcela:</label>
          <mat-form-field class="mat-full-width">
            <input matInput formControlName="vencimentoParcela" [matDatepicker]="vencimentoParcela" />
            <mat-datepicker-toggle matIconSuffix [for]="vencimentoParcela" />
            <mat-datepicker #vencimentoParcela />
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <label for="valorReembolso" class="form-label">Valor Total do Reembolso:</label>
          <mat-form-field class="mat-full-width">
            <input matInput type="text" id="valorReembolso" name="valorReembolso" formControlName="valorReembolso" />
          </mat-form-field>
        </div>
        <div class="col-md-2">
          <label for="dataAvaliacao" class="form-label">Data da Avaliação:</label>
          <mat-form-field class="mat-full-width">
            <input matInput formControlName="dataAvaliacao" [matDatepicker]="dataAvaliacao" />
            <mat-datepicker-toggle matIconSuffix [for]="dataAvaliacao" />
            <mat-datepicker #dataAvaliacao />
          </mat-form-field>
        </div>
      </div>
    }
    <br />
    <div class="div-split">
      <div class="d-flex row div-split">
        <div class="botao-enviar-formulario">
          <div>
            <button mat-button class="btn-dark report-button ajusteEspaco" style="margin: 3px" type="submit" (click)="getPdfCsv('pdf')">
              &nbsp; Exportar para PDF
              <span class="material-icons" style="color: white !important">picture_as_pdf</span>
            </button>
            <button mat-button class="btn-dark report-button ajusteEspaco" style="margin: 3px" type="submit" (click)="getPdfCsv('xlsx')">
              &nbsp; Exportar para Excel <span class="material-icons" style="color: white !important">grid_on</span>
            </button>
          </div>
          <div>
            <button mat-button type="submit" id="buscar" name="buscar" style="margin: 3px" class="btn-dark tamanhoBotao" (click)="getSearch()">Buscar</button>
            <button mat-button type="button" id="limpaForm" style="margin: 3px" name="limpaForm" (click)="clear()">Limpar filtros</button>
          </div>
        </div>
      </div>
    </div>
    <br />
  </form>
  <br />
  @if (gerando) {
    <div class="div-split">
      <div class="row grid-for resultsSearch">
        <div id="table-atual" [innerHtml]="resultSearch"></div>
      </div>
      <div class="d-flex justify-content-center">
        <mat-paginator
          #paginator
          class="custom-paginator"
          aria-label="Select page"
          [length]="length"
          [pageSize]="pageSize"
          [disabled]="disabled"
          [showFirstLastButtons]="showFirstLastButtons"
          [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
          [hidePageSize]="hidePageSize"
          [pageIndex]="pageNumber"
          (page)="handlePageEvent($event)"
        />
      </div>
    </div>
  }
</div>
