<main class="main" role="main">
  <section class="page">
    <app-separador label="Autarquias"></app-separador>
    <div class="row">
      <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 the_content">
        <h3><strong>CJ AGÊNCIA METROPOLITANA DE CAMPINAS (AGEMCAMP)</strong></h3>
        <div class="pge-unidade">
          <p class="procurador"><strong>Procurador(a)</strong>: IVAN DE CASTRO DUARTE MARTINS</p>
          <p class="endereco"><strong>Endereço</strong>: Engenheiro Cândido Gomide, 778, , Jd. Guanabara, Campinas-SP, 13073-200.</p>
        </div>
        <br />
        <strong>Demais Procuradores<br /></strong>
        <div class="pge-unidade">
          <p class="demais-procuradores">JOSE CARLOS PIRES DE CAMPOS FILHO</p>
        </div>
        <hr />
        <h3><strong>CJ AGÊNCIA REGUL. DE SERVIÇOS PÚBLICOS DO ESTADO DE SÃO PAULO – ARSESP</strong></h3>
        <div class="pge-unidade">
          <p class="procurador"><strong>Procurador(a)</strong>: {{ findInArray("MATHEUS ALVES NASCIMENTO") }}</p>
          <p class="endereco"><strong>Endereço</strong>: Av. Paulista, nº 2313, 4º andar, São Paulo, São Paulo-SP, 01311-300.</p>
          <p class="tel"><strong>Telefone</strong>: 3293-5102/ 5103 / 5105</p>
        </div>
        <p><strong>Demais Procuradores</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ findInArray("ROBERTO DE ALMEIDA GALLEGO") }}</p>
        </div>
        <hr />
        <h3><strong>CJ AGÊNCIA REGUL. DE SERVIÇOS PÚBLICOS DELEGADOS DE TRANSPORTES (ARTESP)</strong></h3>
        <div class="pge-unidade">
          <p class="procurador"><strong>Procurador(a)</strong>: {{ findInArray("ANNA LUISA BARROS CAMPOS PAIVA COSTA") }}</p>
          <p class="endereco"><strong>Endereço</strong>: Iguatemi, 105, , Itaim Bibi, Sao Paulo-SP, .</p>
        </div>
        <p><strong>Demais Procuradores</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ findInArray("ANNA LUIZA QUINTELLA FERNANDES") }}</p>
          <p class="demais-procuradores">{{ findInArray("CLAUDIA REGINA VILARES") }}</p>
          <p class="demais-procuradores">{{ findInArray("DECIO GRISI FILHO") }}</p>
          <p class="demais-procuradores">{{ findInArray("FRANCISCO ACIOLI GARCIA") }}</p>
          <p class="demais-procuradores">{{ findInArray("GLENDERSON BLASER PETARLI") }}</p>
          <p class="demais-procuradores">{{ findInArray("LORENA DE MORAES E SILVA") }}</p>
        </div>
        <hr />
        <h3><strong>CJ CENTRO PAULA SOUZA (CEETEPS)</strong></h3>
        <div class="pge-unidade">
          <p class="procurador"><strong>Procurador(a)</strong>: {{ findInArray("JOSE PROCOPIO DA SILVA DE SOUZA DIAS") }}</p>
          <p class="endereco"><strong>Endereço</strong>: Dos Andradas, 140, 4 andar, , Sao Paulo-SP, 01124-060.</p>
        </div>
        <p><strong>Assistentes</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ findInArray("BRUNO BARREIRA OLIVEIRA GONDIM") }}</p>
          <p class="demais-procuradores">{{ findInArray("MARILY DINIZ DO AMARAL CHAVES") }}</p>
        </div>
        <hr />
        <h3><strong>CJ DEPARTAMENTO DE ÁGUAS E ENERGIA ELÉTRICA (DAEE)</strong></h3>
        <div class="pge-unidade">
          <p class="procurador"><strong>Procurador(a)</strong>: {{ findInArray("ROGERIO AUGUSTO DA SILVA") }}</p>
          <p class="endereco"><strong>Endereço</strong>: Rua Boa Vista,170, 15º andar Edificio A Bloco 3, Centro, São Paulo-SP, 01014-000.</p>
          <p class="tel"><strong>Telefone</strong>: (11) 3638-5126 / 5129 / 5128</p>
        </div>
        <p><strong>Demais Procuradores</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ findInArray("ADRIANA GUIMARAES GOMES PEREIRA") }}</p>
          <p class="demais-procuradores">{{ findInArray("REINALDO PASSOS DE ALMEIDA") }}</p>
        </div>
        <hr />
        <h3><strong>CJ DEPARTAMENTO DE ESTRADAS E RODAGENS (DER)</strong></h3>
        <div class="pge-unidade">
          <p class="procurador"><strong>Procurador(a)</strong>: {{ findInArray("HELIO OZAKI BARBOSA") }}</p>
          <p class="endereco"><strong>Endereço</strong>: Av. do Estado, 777, 3º andar, São Paulo, São Paulo-SP, 01107-000.</p>
          <p class="tel"><strong>Telefone</strong>: 3311-1401 /1402 /1403 /1404</p>
        </div>
        <p><strong>Demais Procuradores</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ findInArray("CRISTINA TAVARES DE FREITAS") }}</p>
          <p class="demais-procuradores">{{ findInArray("CYRO SAADEH") }}</p>
          <p class="demais-procuradores">{{ findInArray("DERLY BARRETO E SILVA FILHO") }}</p>
          <p class="demais-procuradores">{{ findInArray("ERALDO AMERUSO OTTONI") }}</p>
          <p class="demais-procuradores">{{ findInArray("IGOR BUENO PERUCHI") }}</p>
        </div>
        <hr />
        <h3><strong>CJ DEPARTAMENTO ESTADUAL DE TRÂNSITO (DETRAN)</strong></h3>
        <div class="pge-unidade">
          <p class="procurador"><strong>Procurador(a)</strong>: {{ findInArray("LUIZ FERNANDO ROBERTO") }}</p>
          <p class="endereco"><strong>Endereço</strong>: João Brícola, 32, 15ºandar, , São Paulo-SP, 01014-010.</p>
        </div>
        <p><strong>Demais Procuradores</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ findInArray("ISO CHAITZ SCHERKERKEWITZ") }}</p>
          <p class="demais-procuradores">{{ findInArray("PAULO DE TARSO NERI") }}</p>
        </div>
        <hr />
        <h3><strong>CJ FACULDADE DE MEDICINA DE SÃO JOSÉ DO RIO PRETO (FAMERP)</strong></h3>
        <div class="pge-unidade">
          <p class="endereco"><strong>Endereço</strong>: Brigadeiro Faria Lima, 5416, , Vila São Pedro, São José do Rio Preto-SP, 15090-000.</p>
        </div>
        <p><strong>Demais Procuradores</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <hr />
        <h3><strong>CJ FACULDADE DE MEDICINA DE MARÍLIA (FAMEMA)</strong></h3>
        <div class="pge-unidade">
          <p class="procurador"><strong>Procurador(a)</strong>: {{ findInArray("CAROLINA ADRIANA MENDES MARTINS BRAGA PONTE") }}</p>
          <p class="endereco"><strong>Endereço</strong>: Monte Carmelo, 800, MARILIA-SP, 17501-080.</p>
        </div>
        <p><strong>Demais Procuradores</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <hr />
        <h3><strong>CJ HOSPITAL DAS CLÍNICAS DA FACULDADE DE MEDICINA DE BOTUCATU (HCFMB)</strong></h3>
        <div class="pge-unidade">
          <p class="procurador"><strong>Procurador(a)</strong>: {{ findInArray("EDUARDO LUIZ DE OLIVEIRA FILHO") }}</p>
          <p class="endereco"><strong>Endereço</strong>: de Rubião Junior, s/nº, Faculdade De Medicina de Botucatu, , Botucatu-SP, 18618-970.</p>
        </div>
        <p><strong>Demais Procuradores</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <hr />
        <h3><strong>CJ HOSPITAL DAS CLÍNICAS DA FACULDADE DE MEDICINA DE RIBEIRÃO PRETO DA UNIVERSIDADE DE SÃO PAULO – HCFMRP – USP</strong></h3>
        <div class="pge-unidade">
          <p class="procurador"><strong>Procurador(a)</strong>: {{ findInArray("LUCAS DE FARIA RODRIGUES") }}</p>
          <p class="endereco"><strong>Endereço</strong>: Bandeirantes, 3900, Campus Universitário, Vila Monte Alegre, Ribeirão Preto-SP, 14048-900.</p>
        </div>
        <p><strong>Demais Procuradores</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ findInArray("ALOISIO PIRES DE CASTRO") }}</p>
          <p class="demais-procuradores">{{ findInArray("PAULO ROBERTO VAZ FERREIRA") }}</p>
        </div>
        <hr />
        <h3><strong>CJ INSTITUTO DE ASSISTÊNCIA MÉDICA AO SERVIDOR PÚBLICO ESTADUAL – IAMSPE</strong></h3>
        <div class="pge-unidade">
          <p class="procurador"><strong>Procurador(a)</strong>: {{ findInArray("LUCIA DE FARIA FREITAS") }}</p>
          <p class="endereco"><strong>Endereço</strong>: Av. Ibirapuera, 981, , Vila Clementino, São Paulo-SP, 04029-000.</p>
          <p class="tel"><strong>Telefone</strong>: 5575-6147 - 5088-8701</p>
        </div>
        <p><strong>Demais Procuradores</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ findInArray("PAULA LUTFALLA MACHADO LELLIS") }}</p>
        </div>
        <hr />
        <h3><strong>CJ SÃO PAULO PREVIDÊNCIA – SPPREV</strong></h3>
        <div class="pge-unidade">
          <p class="procurador"><strong>Procurador(a)</strong>: {{ findInArray("LUCIANA MONTEIRO CLAUDIANO") }}</p>
          <p class="endereco"><strong>Endereço</strong>: Rua Bela Cintra, 657, , São Paulo, São Paulo-SP, 01415-908.</p>
          <p class="tel"><strong>Telefone</strong>: 3214-9001/9002/9013</p>
        </div>
        <p><strong>Demais Procuradores</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ findInArray("DEMERVAL FERRAZ DE ARRUDA JUNIOR") }}</p>
          <p class="demais-procuradores">{{ findInArray("ELIANA DE FATIMA UNZER") }}</p>
          <p class="demais-procuradores">{{ findInArray("MARIA CRISTINA MIKAMI DE OLIVEIRA") }}</p>
          <p class="demais-procuradores">{{ findInArray("MIRIAN KIYOKO MURAKAWA") }}</p>
          <p class="demais-procuradores">{{ findInArray("PAULO SERGIO MONTEZ") }}</p>
        </div>
        <hr />
      </div>
    </div>
  </section>
</main>
