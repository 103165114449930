<main class="main" role="main">
  <section class="page">
    <div class="container-noticia-aberta">
      <div class="container-noticia">
        <h1 class="title-noticia">{{ noticia?.titulo }}</h1>
        <p class="data">
          {{ noticia?.dataPublicacao | date: "dd/MM/yyyy" : "pt-BR" }}
        </p>
        @if (noticia?.imagem) {
          <img [src]="getImagem(noticia?.imagem)" alt="Imagem da Notícia" class="imagem-noticia" />
        }
        <div class="corpo-noticia" [innerHTML]="processarTexto(noticia?.texto)"></div>
        <div class="arquivo">
          <table>
            <tbody>
              @for (item of arquivos; track item; let i = $index) {
                <tr>
                  <td>
                    <a href="#" (click)="openPDF($event, item)"><mat-icon fontIcon="cloud_download"></mat-icon>{{ item.nome }}</a>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
        <br />
        <p-carousel [value]="urlsImagens" [numVisible]="1" [numScroll]="1" [circular]="true" autoplayInterval="3000">
          <ng-template let-imagem pTemplate="item">
            <div class="container-cards">
              <div class="card-noticia centralizar-img-carrossel">
                <img [src]="imagem" [alt]="" />
              </div>
            </div>
          </ng-template>
        </p-carousel>
        <div class="align-button">
          <a href="/">
            <mat-icon class="home-icon">home</mat-icon>
          </a>
        </div>
        <br />
      </div>
      <div class="mais-noticias">
        <app-separador label="Outras Notícias"></app-separador>
        <div class="container-cards">
          @for (item of objNoticias; track item; let i = $index) {
            <div class="card-noticia" (click)="abrirUmaNoticia(item?.id)" (keypress)="abrirUmaNoticia(item?.id)" tabindex="0">
              <img [src]="getImagem(item?.imagem)" alt="Imagem {{ i + 1 }}" />
              <div class="titulo">
                {{ item?.titulo }}
              </div>
            </div>
          }
        </div>
        <br />
        <div class="align-button-r">
          <button (click)="retornaListaNoticias()" mat-flat-button class="botao-voltar"><mat-icon class="add-icon">add</mat-icon> NOTÍCIAS</button>
        </div>
      </div>
    </div>
  </section>
</main>
