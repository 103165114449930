<main class="main" role="main">
  <section class="page">
    <div class="container">
      <app-separador label="Procuradorias Regionais" />
      <div class="row">
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 the_content">
          <p>&nbsp;</p>
          <h3><a routerLink="/institucional/regionais/PR01">Procuradoria Regional da Grande São Paulo (PR01)</a></h3>
          <hr />
          <h3><a routerLink="/institucional/regionais/PR02">Procuradoria Regional de Santos (PR02)</a></h3>
          <hr />
          <h3><a routerLink="/institucional/regionais/PR03">Procuradoria Regional de Taubaté (PR03)</a></h3>
          <hr />
          <h3><a routerLink="/institucional/regionais/PR04">Procuradoria Regional de Sorocaba (PR04)</a></h3>
          <hr />
          <h3><a routerLink="/institucional/regionais/PR05">Procuradoria Regional de Campinas (PR05)</a></h3>
          <hr />
          <h3><a routerLink="/institucional/regionais/PR06">Procuradoria Regional de Ribeirão Preto (PR06)</a></h3>
          <hr />
          <h3><a routerLink="/institucional/regionais/PR07">Procuradoria Regional de Bauru (PR07)</a></h3>
          <hr />
          <h3><a routerLink="/institucional/regionais/PR08">Procuradoria Regional de São José do Rio Preto (PR08)</a></h3>
          <hr />
          <h3><a routerLink="/institucional/regionais/PR09">Procuradoria Regional de Araçatuba (PR09)</a></h3>
          <hr />
          <h3><a routerLink="/institucional/regionais/PR10">Procuradoria Regional de Presidente Prudente (PR10)</a></h3>
          <hr />
          <h3><a routerLink="/institucional/regionais/PR11">Procuradoria Regional de Marília (PR11)</a></h3>
          <hr />
          <h3><a routerLink="/institucional/regionais/PR12">Procuradoria Regional de São Carlos (PR12)</a></h3>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <hr />
          <hr />
        </div>
      </div>
    </div>
  </section>
</main>
