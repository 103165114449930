import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import verificaCpf from 'src/app/area-aberta/features/home/noticias/shared/custom-validators/cpf.validator';
import dataNascimentoValidator from 'src/app/area-aberta/features/home/noticias/shared/custom-validators/dataNascimento.validor';
import { Dependente } from 'src/app/area-restrita/features/auxilio/saude/shared/models/dependente';
import { FichaInscricaoCadastro } from 'src/app/area-restrita/features/auxilio/saude/shared/models/ficha-inscricao-cadastro';
import { PaginaVisitadaService } from 'src/app/area-restrita/shared/services/pagina-visitada.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { environment } from 'src/environments/environment';
import { AuxilioSaude } from '../shared/models/auxilio-saude';
import { AuxilioSaudeService } from '../shared/services/auxilio-saude.service';
import { InscricaoAuxilioSaudeService } from '../shared/services/inscricao-auxilio-saude.service';
import FichaAuxilioHelper from './ficha-auxilio-saude.heper';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';

@Component({
  selector: 'app-ficha-auxilio-saude',
  templateUrl: './ficha-auxilio-saude.component.html',
  styleUrl: './ficha-auxilio-saude.component.sass',
  standalone: true,
  imports: [
    FormsModule,
    MatFormField,
    MatInput,
    NgxMaskDirective,
    MatCheckbox,
    MatButton,
    MatIcon,
    MatSelect,
    MatOption,
    ReactiveFormsModule,
    MatRadioGroup,
    MatRadioButton,
    MatProgressSpinner,
    MatLabel,
    NgxMaskPipe
  ]
})
export class FichaAuxilioSaudeComponent implements OnInit, OnDestroy {
  assetsPath = environment.assetsPath;
  private readonly destroy$ = new Subject<void>();
  fichaHelper = new FichaAuxilioHelper();
  mensagensErro = this.fichaHelper.mensagensErro;
  opcoesRelacionamento = this.fichaHelper.opcoesRelacionamento;
  mostrarFormulario: boolean = false;
  possuiInscricao: boolean = false;
  mostrarFrase: boolean = true;
  dependentes: Dependente[] = [];
  dependentesAuxiliar: Dependente[] = [];
  dependentesFichaInscricao: Dependente[] = [];
  selecionados: Set<Dependente> = new Set<Dependente>();
  mensagemErro: string = '';
  dataAlteracao: string = '';
  mostrarCheckbox: boolean = false;
  fichaInscricaoCadastro: FichaInscricaoCadastro | null;
  ativaBotao: boolean = true;
  inscricaoIndeferida: boolean = false;

  dadosFormulario: AuxilioSaude = {
    id: '',
    rg: '',
    cpf: '',
    lotacao: '',
    endereco: '',
    cep: '',
    cidade: '',
    uf: '',
    telefone: '',
    celular: '',
    email: '',
    dependentes: [] as { id: number }[],
    usuario: { id: Number(localStorage.getItem('idUsuario')), nome: '' },
    dependentesImpostoRenda: false,
    contratantePlanoSaude: false,
    autorizacaoIAMSPE: false,
    possuoDependentes: false,
    dataAlteracao: new Date().toLocaleDateString()
  };

  dadosAvaliacao = {
    idUsuario: null,
    idFicha: '',
    justificativa: '',
    idStatus: 0,
    loading: false
  };

  auxilioStatusOptions = [
    {
      id: 2,
      name: 'Deferido'
    },
    {
      id: 3,
      name: 'Indeferido'
    }
  ];

  idUsuario: number;

  isValidationMode = false;
  constructor(
    private auxilioSaudeService: AuxilioSaudeService,
    private inscricaoAuxilioSaudeService: InscricaoAuxilioSaudeService,
    private toastr: ToastService,
    private router: Router,
    private route: ActivatedRoute,
    private paginaService: PaginaVisitadaService
  ) {
    this.idUsuario = this.route.snapshot.params['id'] || localStorage.getItem('idUsuario');
  }

  carregarDadosFichaAuxilio(): void {
    this.isValidationMode = this.router.url.includes('inscricao-auxilio-avaliacao');
    this.auxilioSaudeService
      .carregarFichaAuxilioSaude(this.idUsuario)
      .pipe(takeUntil(this.destroy$))
      .subscribe(([fichaInscricaoResponse, inscricaoResponse, dependentesResponse]) => {
        this.dependentesAuxiliar = dependentesResponse;

        if (inscricaoResponse) {
          this.dadosFormulario = {
            id: inscricaoResponse.id || '',
            rg: inscricaoResponse.rg || '',
            cpf: inscricaoResponse.cpf || '',
            lotacao: inscricaoResponse.lotacao || '',
            endereco: inscricaoResponse.endereco || '',
            cep: inscricaoResponse.cep || '',
            cidade: inscricaoResponse.cidade || '',
            uf: inscricaoResponse.uf || '',
            telefone: inscricaoResponse.telefone || '',
            celular: inscricaoResponse.celular || '',
            email: inscricaoResponse.email || '',
            dependentes: inscricaoResponse.dependentes || [],
            usuario: inscricaoResponse.usuario || { id: Number(localStorage.getItem('idUsuario')), nome: '' },
            dependentesImpostoRenda: inscricaoResponse.dependentesImpostoRenda || false,
            contratantePlanoSaude: inscricaoResponse.contratantePlanoSaude || false,
            autorizacaoIAMSPE: false,
            possuoDependentes: false,
            dataAlteracao: inscricaoResponse.dataAlteracao || new Date().toLocaleDateString()
          };

          this.fichaInscricaoCadastro = fichaInscricaoResponse;
          this.fichaInscricaoCadastro.nome = inscricaoResponse.usuario.nome;
          this.dadosFormulario.dependentesImpostoRenda = true;
          this.possuiInscricao = true;

          this.dadosAvaliacao.idUsuario = this.idUsuario.toString();
          this.dadosAvaliacao.justificativa = inscricaoResponse.justificativa;
          this.dadosAvaliacao.idFicha = inscricaoResponse.id;
          this.dadosAvaliacao.idStatus = Number(inscricaoResponse.status.id);

          this.dataAlteracao = inscricaoResponse.dataAlteracao;

          this.inscricaoIndeferida = inscricaoResponse.status.id == 3 || inscricaoResponse.status.id == 5 ? true : false;

          //this.carregarDadosAuxilio();
          this.carregarDependentePreenchido();
        } else if (fichaInscricaoResponse) {
          this.fichaInscricaoCadastro = fichaInscricaoResponse;
          this.dadosFormulario.rg = fichaInscricaoResponse.rg;
          this.dadosFormulario.cpf = fichaInscricaoResponse.cpf;
          this.dadosFormulario.endereco = fichaInscricaoResponse.endereco;
          this.dadosFormulario.lotacao = fichaInscricaoResponse.lotacao;
          this.dadosFormulario.cep = fichaInscricaoResponse.cep;
          this.dadosFormulario.cidade = fichaInscricaoResponse.cidade;
          this.dadosFormulario.uf = fichaInscricaoResponse.uf;
          this.dadosFormulario.telefone = fichaInscricaoResponse.dddTelefone + fichaInscricaoResponse.telefone;
          this.dadosFormulario.celular = fichaInscricaoResponse.dddCelular + fichaInscricaoResponse.celular;
          this.dadosFormulario.email = fichaInscricaoResponse.email;
          this.dadosFormulario.dependentes = dependentesResponse.map((dep) => ({ ...dep }));
        }
        this.carregarDependentePreenchido();
      });
  }

  ngOnInit(): void {
    this.carregarDadosFichaAuxilio();
    this.paginaService.salvaPagina(this.isValidationMode ? 'Avaliação da Inscrição' : 'Ficha de Inscrição').subscribe();
  }

  // Enviar dados dos dependentes que o usuário desejar inserir no banco de dados
  formularioDependentes: FormGroup = new FormGroup({
    passo: new FormArray([
      new FormGroup({
        checked: new FormControl(false),
        nome: new FormControl('', [Validators.required]),
        cpfDependente: new FormControl('', [Validators.required, verificaCpf.bind(this)]),
        dataNascimento: new FormControl(null, [Validators.required, dataNascimentoValidator.bind(this)]),
        descricaoDependente: new FormControl('', [Validators.required]),
        usuario: new FormGroup({
          id: new FormControl(Number(localStorage.getItem('idUsuario')))
        })
      })
    ])
  });

  get passo(): FormArray {
    return this.formularioDependentes.get('passo') as FormArray;
  }

  adicionarLinhaGrid() {
    const ultimoGrupo = this.passo.at(this.passo.length - 1) as FormGroup;
    console.log(ultimoGrupo);
    if (this.verificarCamposPrenhenchidosDependentes(ultimoGrupo)) {
      this.passo.push(
        new FormGroup({
          checked: new FormControl(false),
          nome: new FormControl(''),
          cpfDependente: new FormControl(''),
          dataNascimento: new FormControl(),
          descricaoDependente: new FormControl(''),
          usuario: new FormGroup({
            id: new FormControl(Number(localStorage.getItem('idUsuario')))
          })
        })
      );
    }
  }

  verificarCamposPrenhenchidosDependentes(formGroup: FormGroup): boolean {
    for (const controle in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty.call(controle)) {
        const valor = formGroup.get(controle)?.value;
        if (valor === null || valor === undefined || valor === '') {
          return false;
        }
      }
    }
    return true;
  }

  // Obter dados  de dependentes do usuario
  obterListaDependentes(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.inscricaoAuxilioSaudeService
        .obterListaDependentes()
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (dados) => {
            this.dependentesAuxiliar = dados;
            if (dados.length > 0) {
              this.mostrarCheckbox = true;
            }
            resolve();
          },
          error: (error) => reject(new Error('Erro ao obter lista de dependentes: ', error))
        });
    });
  }

  carregarDependentePreenchido(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.dependentesAuxiliar.forEach((item) => {
        if (item.checked) {
          this.selecionados.add(item);
        }
      });
      this.dependentes = this.dependentesAuxiliar;
      resolve();
    });
  }

  selecaoDependente(dependente: Dependente) {
    if (this.selecionados.has(dependente)) {
      this.selecionados.delete(dependente);
    } else {
      this.selecionados.add(dependente);
    }
  }

  FormataOutputCPF(cpf: string): string {
    if (!cpf) return '';
    cpf = cpf.replace(/\D/g, '');
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }

  excluirDependente(): void {
    const idSelecionadoDependente = [...this.selecionados].map((dependente) => ({ ...dependente }).id)[0];
    this.auxilioSaudeService.excluirDependente(idSelecionadoDependente).subscribe(
      () => {
        this.toastr.success('Dependente(s) Excluído(s) com Sucesso');
        this.mostrarFormulario = false;
        this.mostrarFrase = true;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/area-restrita/inscricao-auxilio-saude']);
        });
      },
      (error) => {
        console.error('Erro ao Apagar dado(s)', error);
      }
    );
  }

  enviarDadosDependentes() {
    console.log(this.passo.controls as FormGroup[]);
    if (this.verificarObjectCamposPreenchidosDependentes()) {
      const dadosParaEnviar = (this.passo.controls as FormGroup[])
        .map((grupo) => grupo.getRawValue())
        .map((e) => {
          const dataNascimento = new Date(e.dataNascimento);
          dataNascimento.setDate(dataNascimento.getDate() + 1); // Adiciona um dia à data de nascimento
          return {
            ...e,
            dataNascimento: dataNascimento.toLocaleDateString()
          };
        });

      this.auxilioSaudeService.enviarDadosDependentes(dadosParaEnviar as Dependente[]).subscribe(
        () => {
          this.mostrarFormulario = false;
          this.mostrarFrase = true;
          this.toastr.success('Enviado com sucesso');
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/area-restrita/inscricao-auxilio-saude']);
          });
          while (this.passo.length !== 0) {
            this.passo.removeAt(0);
          }
          this.passo.push(
            new FormGroup({
              nome: new FormControl(''),
              cpfDependente: new FormControl(''),
              dataNascimento: new FormControl(),
              descricaoDependente: new FormControl(''),
              usuario: new FormGroup({
                id: new FormControl(Number(localStorage.getItem('idUsuario')))
              })
            })
          );
        },
        (error) => {
          console.log(error);
          this.toastr.error(error);
        }
      );
    } else this.toastr.error('Favor preencher todos os campos');
  }

  verificarObjectCamposPreenchidosDependentes(): boolean {
    for (let i = 0; i < this.passo.length; i++) {
      const formGroup = this.passo.at(i) as FormGroup;
      if (!this.verificarCamposPrenhenchidosDependentes(formGroup)) {
        return false;
      }
    }
    return true;
  }

  enviarDadosFormulario() {
    if (this.camposObrigatoriosPreenchidos()) {
      this.dadosFormulario.dependentes = [...this.selecionados].map((dependente) => ({ id: dependente.id }));
      this.auxilioSaudeService.enviarDadosFormulario(this.dadosFormulario).subscribe(
        () => {
          this.toastr.success('Dados enviados com Sucesso');
          this.limpaChecksBox();
          this.carregarDadosFichaAuxilio();
          this.enviarAtualizacaoStatus();
          this.mensagemErro = '';
        },
        (error) => {
          const errorMessage = error.erros ? error.erros['Erro de negócio'] : 'Verifique a ficha, erro ao efetuar inscrição.';
          this.toastr.warning(error, errorMessage);
        }
      );
    } else {
      this.mensagemErro = 'Falta preencher campos obrigatórios.';
    }
  }

  atualizarDadosFichaInscricao() {
    if (this.camposObrigatoriosPreenchidosEditar()) {
      this.dadosFormulario.dependentes = [...this.selecionados].map((dependente) => ({ id: dependente.id, checked: dependente.checked }));
      this.auxilioSaudeService.atualizarDadosFormulario(this.dadosFormulario).subscribe(
        () => {
          this.toastr.success('Dados atualizados com Sucesso');
          this.limpaChecksBox();
          this.carregarDadosFichaAuxilio();
          this.enviarAtualizacaoStatus();
          this.mensagemErro = '';
        },
        (error: HttpErrorResponse) => {
          const errorMessage = error.error.erros['Erro de negócio'] ?? 'Verifique a ficha, erro ao efetuar inscrição.';
          this.toastr.warning(errorMessage, 'Erro de negócio');
        }
      );
    } else {
      this.mensagemErro = 'Falta preencher campos obrigatórios.';
    }
  }

  camposObrigatoriosPreenchidos(): boolean {
    if (this.mostrarCheckbox && [...this.selecionados].length > 0) {
      return (
        this.dadosFormulario.celular !== '' &&
        this.dadosFormulario.email !== '' &&
        this.dadosFormulario.dependentesImpostoRenda &&
        this.dadosFormulario.contratantePlanoSaude &&
        this.dadosFormulario.autorizacaoIAMSPE &&
        this.dadosFormulario.possuoDependentes
      );
    } else {
      return this.dadosFormulario.celular !== '' && this.dadosFormulario.email !== '' && this.dadosFormulario.dependentesImpostoRenda;
    }
  }

  camposObrigatoriosPreenchidosEditar(): boolean {
    if ([...this.selecionados].length > 0) {
      return (
        this.dadosFormulario.celular !== '' &&
        this.dadosFormulario.email !== '' &&
        this.dadosFormulario.dependentesImpostoRenda &&
        this.dadosFormulario.possuoDependentes
      );
    } else {
      return this.dadosFormulario.celular !== '' && this.dadosFormulario.email !== '' && this.dadosFormulario.dependentesImpostoRenda;
    }
  }

  carregarDadosAuxilio() {
    this.auxilioSaudeService.obterPedidoAuxilioSaude(this.dadosFormulario.id).subscribe((response) => {
      this.dadosAvaliacao.idUsuario = response.usuario.id;
      this.dadosAvaliacao.justificativa = response.observacao;
      this.dadosAvaliacao.idFicha = response.id;
      this.dadosAvaliacao.idStatus = Number(response.status.id);
    });
  }

  enviarAtualizacaoStatus() {
    if (!this.isValidationMode) return;
    const obsDeveSerPreenchido = this.dadosAvaliacao.idStatus == 3 && !this.dadosAvaliacao.justificativa;
    if (obsDeveSerPreenchido) {
      this.toastr.warning('Favor preencher a justificativa.');
      return;
    }
    this.auxilioSaudeService.enviarAtualizacaoStatus(Number(this.dadosFormulario.id), this.dadosAvaliacao);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  limpaChecksBox() {
    this.dadosFormulario.dependentesImpostoRenda = false;
    this.dadosFormulario.contratantePlanoSaude = false;
    this.dadosFormulario.autorizacaoIAMSPE = false;
    this.dadosFormulario.possuoDependentes = false;
  }

  removerLinhaDependente(i: number): void {
    const dependenteExcluir = this.dependentes[i];
    dependenteExcluir.checked = true;
    this.selecionados.clear();
    this.selecionados.add(dependenteExcluir);
    this.excluirDependente();
  }

  avaliarPedido(statusId: number) {
    switch (statusId) {
      case 2: {
        this.deferirIndeferirPedido();
        break;
      }
      case 3: {
        if (!this.dadosAvaliacao.justificativa || this.dadosAvaliacao.justificativa == '') {
          this.toastr.warning('Informe a justificativa para indeferir.');
          return;
        }
        this.deferirIndeferirPedido();
        break;
      }
      default: {
        this.toastr.warning('Opção status de avaliação inválido: ' + statusId);
      }
    }
  }

  deferirIndeferirPedido() {
    this.dadosAvaliacao.loading = true;

    this.inscricaoAuxilioSaudeService
      .deferirIndeferirPedido(this.dadosAvaliacao)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response: string) => {
          this.dadosAvaliacao.loading = false;
          this.toastr.success(response);
          setTimeout(() => {
            this.router.navigate(['area-restrita', 'analise-pedidos-auxilio-saude']);
          }, 2000);
        },
        (error: HttpErrorResponse) => {
          this.dadosAvaliacao.loading = false;
          this.toastr.error('Ocorreu um erro ao deferir os pedidos: ' + error.message);
        },
        () => {
          this.dadosAvaliacao.loading = false;
        }
      );
  }
}
