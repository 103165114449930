<div id="informa" name="informa">
  <table>
    <tr>
      <td colspan="2" style="text-align: center"><b>Planilha Excel</b></td>
    </tr>
    <tr>
      <td class="left-cell" width="50%">Nome</td>
      <td class="info">{{ data.title }}</td>
    </tr>
    <tr>
      <td class="left-cell">Número de linhas</td>
      <td class="info">{{ data.linhas }}</td>
    </tr>
    <tr>
      <td class="left-cell">Registros lidos</td>
      <td class="info">{{ data.registros }}</td>
    </tr>
    <tr>
      <td class="left-cell">CPF inválido, sem valor, ...</td>
      <td class="info">{{ data.falhas }}</td>
    </tr>
    <tr>
      <td class="left-cell">Registros a processar</td>
      <td class="info">{{ data.paraProcessar }}</td>
    </tr>
  </table>
  <div class="container">
    <button mat-icon-button style="line-height: 0" (click)="onClose()">
      <mat-icon style="font-size: 24px">check_circle_icon</mat-icon>
    </button>
  </div>
</div>
