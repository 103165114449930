import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { FileSystemDirectoryEntry, FileSystemFileEntry, NgxFileDropEntry, NgxFileDropModule } from 'ngx-file-drop';
import { LocalStorageService } from 'src/app/area-restrita/shared/services/local-storage.service';
import { PaginaVisitadaService } from 'src/app/area-restrita/shared/services/pagina-visitada.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import * as XLSX from 'xlsx';
import { Siafem } from '../shared/models/siafem';
import { AuxilioSaudeService } from '../shared/services/auxilio-saude.service';
import { AuxilioSiafem } from './../shared/models/auxilio-saude';
import { CurrencyPipe } from '@angular/common';
import { MatButton } from '@angular/material/button';
import { MatGridList, MatGridTile } from '@angular/material/grid-list';

@Component({
  selector: 'app-siafem-importe',
  templateUrl: './siafem-importe.component.html',
  styleUrl: './siafem-importe.component.sass',
  standalone: true,
  imports: [MatGridList, MatGridTile, NgxFileDropModule, MatButton, MatPaginator, CurrencyPipe]
})
export class SiafemImporteComponent implements AfterViewInit, OnInit {
  listaSiafem: Siafem[] = [];
  mesAnoSolicitacao = this.localS.get('mesAnoSolicitacao');
  envioSiafem: boolean = false;
  public progress: number = 0;
  paginaAtual: number = 1;
  itensPorPagina: number = 10;

  indiceInicioPagina: number = 0;
  indiceFimPagina: number = 10;

  public dropZoneLabel: string = 'Adicione um arquivo .xlsx';
  ListaPedidosSiafem: AuxilioSiafem[] = [];
  headers: string[] = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    public localS: LocalStorageService,
    public auxilioS: AuxilioSaudeService,
    private matPaginatorIntl: MatPaginatorIntl,
    public router: Router,
    private toastr: ToastService,
    private paginaService: PaginaVisitadaService
  ) {
    this.customizarTextosPaginator();
  }

  ngOnInit(): void {
    this.paginaService.salvaPagina('SIAFEM - Financeiro').subscribe();
  }

  ngAfterViewInit() {
    this.paginator.page.subscribe((event: PageEvent) => this.mudarPagina(event.pageIndex, event.pageSize));
  }

  customizarTextosPaginator() {
    this.matPaginatorIntl.itemsPerPageLabel = 'Itens por página:';
    this.matPaginatorIntl.nextPageLabel = 'Próxima página';
    this.matPaginatorIntl.previousPageLabel = 'Página anterior';
    this.matPaginatorIntl.firstPageLabel = 'Primeira página';
    this.matPaginatorIntl.lastPageLabel = 'Última página';
    this.matPaginatorIntl.getRangeLabel = (page, pageSize, length) => {
      if (length === 0 || pageSize === 0) {
        return `0 de ${length}`;
      }
      length = Math.max(length, 0);
      this.indiceInicioPagina = page * pageSize;
      this.indiceFimPagina = this.indiceInicioPagina < length ? Math.min(this.indiceInicioPagina + pageSize, length) : this.indiceInicioPagina + pageSize;
      return `${this.indiceInicioPagina + 1} – ${this.indiceFimPagina} de ${length}`;
    };
    this.matPaginatorIntl.changes.next();
  }

  mudarPagina(pagina: number, tamanho: number): void {
    this.paginaAtual = pagina + 1;
    this.itensPorPagina = tamanho;
  }

  get itensPaginados(): Siafem[] {
    return this.listaSiafem.slice(this.indiceInicioPagina, this.indiceFimPagina);
  }

  get totalPaginas(): number {
    return Math.ceil(this.listaSiafem.length / this.itensPorPagina);
  }

  public onFileDrop(files: NgxFileDropEntry[]): void {
    this.envioSiafem = false;
    const droppedFile = files[0];
    if (files.length > 1) {
      this.toastr.warning('Por favor coloque apenas um arquivo');
      return;
    }
    if (droppedFile.fileEntry.isFile) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        if (file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) {
          this.dropZoneLabel = file.name;
          this.handleFile(file);
        } else {
          this.toastr.warning('Por favor selecione um arquivo ".xlsx" ou ".xls".');
        }
      });
    } else {
      const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      console.log(droppedFile.relativePath, fileEntry);
    }
  }

  private async handleFile(file: File): Promise<void> {
    const reader: FileReader = new FileReader();
    reader.onload = async (e: ProgressEvent<FileReader>) => {
      const bstr: string = e.target?.result as string;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      const data: Record<string, string | number>[] = XLSX.utils.sheet_to_json(ws);

      const monthsKey: string[] = [];
      const ddMmYyyy: Date[] = [];
      let nomeKey: string = '';
      let cpfKey: string = '';
      let dataKey: string = '';
      let valorKey: string = '';
      let sucesso = 0;

      const month = ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez', 'feb', 'apr', 'may', 'aug', 'sep', 'oct', 'dec'];
      const regexMonth = new RegExp('\\b(' + month.join('|') + ')\\b', 'i');
      const regexCPF = /^\d{2,3}\.\d{3}\.\d{3}-\d{2}$/;
      const regexCPF2 = /^\d{9,11}$/;
      for (const key in data[0]) {
        if (Object.prototype.hasOwnProperty.call(data[0], key)) {
          this.headers.push(key);
          if (regexMonth.test(key.toLowerCase())) {
            monthsKey.push(key);
            ddMmYyyy.push(this.convertDateAbbreviated(key)!);
          }
          if (cpfKey.length <= 0 && key.toLowerCase().indexOf('cpf') >= 0) {
            cpfKey = key;
          }
          if (dataKey.length <= 0 && key.toLowerCase().indexOf('data') >= 0) {
            dataKey = key;
          }
          if (valorKey.length <= 0 && (key.toLowerCase().indexOf('valor') >= 0 || key.toLowerCase().indexOf('total') >= 0)) {
            valorKey = key;
          }
          if (nomeKey.length <= 0 && key.toLowerCase().indexOf('nome') >= 0) {
            nomeKey = key;
          }
        }
      }
      if (data.length < 1 || cpfKey == '' || dataKey == '' || valorKey == '') {
        this.toastr.error('Erro na leitura', 'Formato não válido');
        console.error(
          'ATENÇÃO! Formato inválido. ' +
            this.headers.join('!') +
            '], [cpfKey = ' +
            cpfKey +
            '], [dataKey = ' +
            dataKey +
            '], [valorKey = ' +
            valorKey +
            '], [nomeKey = ' +
            nomeKey +
            ']',
          'Excel inválido'
        );
      }
      this.listaSiafem = [];

      for (let i = 0; i < data.length && data.length > 0 && cpfKey.length > 0 && dataKey.length > 0 && valorKey.length > 0; i++) {
        if ((regexCPF.test(data[i][cpfKey] as string) || regexCPF2.test(data[i][cpfKey] as string)) && this.isNumber(data[i][valorKey] as number)) {
          sucesso++;
          const mY = this.excelDateToMy(data[i][dataKey] as number);
          const mesDescription: string = month[mY[0] - 1] + '/' + mY[1];
          this.listaSiafem.push({
            nome: data[i][nomeKey] as string,
            cpf: data[i][cpfKey] as number,
            mes_ano: mesDescription,
            total: data[i][valorKey] as number
          });
          this.listaSiafem.sort((a, b) => {
            return a.cpf - b.cpf;
          });

          this.ListaPedidosSiafem.push({
            nomeTitular: data[i]['NOME'] as string,
            cpfTitular: data[i][cpfKey] as string,
            mes: mY[0],
            ano: mY[1],
            valorDeferidoPge: data[i][valorKey] as number,
            idUsuario: this.localS.get('idUsuario'),
            valorReembolsoTotal: data[i][valorKey] as number,
            ix: 0
          });
        } else {
          if (!this.isNumber(data[i][valorKey] as number)) console.error('[Valor inválido - ', data[i][valorKey], ']');
          if (!regexCPF.test(data[i][cpfKey] as string) || !regexCPF2.test(data[i][cpfKey] as string)) console.error('[CPF inválido - ', data[i][cpfKey], ']');
        }
        this.progress = i - Math.round((i / (data.length - 1)) * 100);
        await this.delay(100);
      }
      if (sucesso <= 0) this.toastr.warning('Nenhum registro foi processado em sua planilha');
      this.progress = 0;
      this.envioSiafem = true;
    };
    reader.readAsBinaryString(file);
  }

  private delay(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  private convertDateAbbreviated(abbreviatedDate: string): Date | null {
    let parts: string[] = [];

    if (abbreviatedDate.indexOf('/') >= 0) {
      parts = abbreviatedDate.split('/');
    } else if (abbreviatedDate.indexOf('-') >= 0) {
      parts = abbreviatedDate.split('-');
    } else {
      return null;
    }

    let yy = parseInt(parts[1]);
    const abbreviatedMm: string = parts[0].toLowerCase();

    if (yy < 100) {
      yy += 2000;
    }
    const mm: string = '|jan|fev|mar|abr|mai|jun|jul|ago|set|out|nov|dez';
    const mmEnglish: string = '|jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec';

    if (mm.indexOf(abbreviatedMm) >= 0) {
      const mesNumber: number = mm.indexOf(abbreviatedMm) / 4;
      const dateCompleted = new Date(yy, mesNumber);
      return dateCompleted;
    } else if (mmEnglish.indexOf(abbreviatedMm) >= 0) {
      const mesNumber: number = mmEnglish.indexOf(abbreviatedMm) / 4;
      const dateCompleted = new Date(yy, mesNumber);
      return dateCompleted;
    }
    {
      return null;
    }
  }

  private excelDateToMy(serial: number): number[] {
    const excelEpoch = new Date(Date.UTC(1899, 11, 31));
    const excelLeapYearBugOffset = 1;
    const trueDate = new Date(excelEpoch.getTime() + (serial - excelLeapYearBugOffset) * 86400000);
    const day = trueDate.getUTCDate().toString().padStart(2, '0');
    const month = (trueDate.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = trueDate.getUTCFullYear();
    return this.dateToMmYyyy(`${year}-${month}-${day}`);
  }

  private dateToMmYyyy(dataStr: string): number[] {
    const dt = new Date(dataStr + 'T00:01:00');
    const m = dt.getMonth() + 1;
    const y = dt.getFullYear();
    return [m, y];
  }

  async envioBotaoSiafem() {
    let sucesso = 0;
    for (const dado of this.ListaPedidosSiafem) {
      sucesso++;
      await this.auxilioS.inserirAuxilioSiafem(dado);
    }
    await this.delay(500);
    this.progress = 0;
    this.envioSiafem = false;
    this.listaSiafem = [];
    if (sucesso > 0) this.toastr.success('Processo de baixa realizado com sucesso');
    else this.toastr.warning('Sem baixas!');
    this.router.navigate(['/area-restrita/administracao-auxilio-saude/financeiro']);
  }

  private isNumber(value: number): boolean {
    try {
      const numberTemp: number = value;
      if (numberTemp > 0) return true;
      else return false;
    } catch {
      return false;
    }
  }
}
