<main class="main" role="main">
  <section class="page">
    <div class="flex-container-separador">
      <app-separador label="Subprocuradoria" />
      <div class="flex-spacer-separador"></div>
      <app-separador-email
        label="ATENDIMENTO"
        msg1="Horário: de segunda a sexta-feira, das 8h às 20h"
        msg2="E-mail: pge-subctf@sp.gov.br"
        msg3="Telefones: (11) 3372-6450 | 3372-6677 | 3372-6661"
        msg4=""
      />
    </div>
    <div class="row">
      <div>
        <h2>Subprocuradoria Geral do Contencioso Tributário-Fiscal</h2>
        <div class="pge-unidade">
          <p class="procurador"><strong>Subprocurador(a) Geral:</strong> Danilo Barth Pires</p>
          <p class="procurador"><strong>Subprocurador(a) Geral Adjunto(a):</strong> Thiago Oliveira de Matos</p>
        </div>
        <p>
          A Subprocuradoria Geral do Contencioso Tributário-Fiscal (SubGCTF) é responsável pela gestão e cobrança da dívida ativa estadual, bem como pela defesa do Estado e de suas autarquias nas
          ações relativas à matéria tributária (artigo 99, I e VI da Constituição Estadual e artigos 35 e 36 da Lei Complementar nº 1.270, de 25 de agosto de 2015). Foi criada em 2008, após ser
          desmembrada da área do Contencioso Geral.
        </p>
        <p><strong>Assessores/Assistente</strong></p>
        <p>ALEXANDRE ABOUD – Assessor</p>
        <p>DANIELLE EUGENNE MIGOTO FERRARI FRATINI – Assessora</p>
        <p>JANINE GOMES BERGER DE OLIVEIRA MACATRÃO – Assistente</p>
        <br />
        <hr />
        <br />
        <h3>NÚCLEO DE TRANSAÇÃO TRIBUTÁRIA</h3>
        <div class="pge-unidade">
          <p class="procurador"><strong>Coordenador(a)</strong>: Wolker Volanin Bicalho</p>
          <p>Carmen Sabrina Cochrane Santiago Viana</p>
          <p>Cristina Mendes Miranda de Azevedo</p>
        </div>
        <br />
        <hr />
        <br />
      </div>
    </div>
  </section>
</main>
