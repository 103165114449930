import { Component, OnInit } from '@angular/core';
import { WebServiceInfosService } from 'src/app/shared/services/infos-webservice.service';
import { SeparadorComponent } from '../../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-consultorias',
  templateUrl: './consultorias.component.html',
  standalone: true,
  imports: [SeparadorComponent]
})
export class ConsultoriasComponent implements OnInit {
  informacoes: string[] = [];
  // 3 codigos estão como string pois misturam letras com numeros, isso já está assim no wordpress
  // verificar se não ocorrerá problema com o endpoint
  codigoUnidades: string[] = [
    '40223800000092',
    '40223800000012',
    '40221300000092',
    '40221300000012',
    '40221200000092',
    '40221200000012',
    '40222000000092',
    '40222000000012',
    '40223000000092',
    '40223000000012',
    '40222900000092',
    '40222900000012',
    '40223500000092',
    '40223500000012',
    '4022AT00000092',
    '0422AT00000090',
    '4022AT00000012', // codigo se repete
    '40225000000092',
    '40225000000012',
    '40220800000092',
    '40220800000012',
    '40224100000092',
    '40224100000012',
    '40222500000092',
    '40222500000012',
    '40222600000092',
    '40222600000012',
    '40221700000092',
    '40221700000012',
    '40221600000092',
    '40221600000012',
    '40228000000092',
    '40228000000012',
    '40220900000092',
    '40220900000012',
    '40221800000092',
    '40221800000012',
    '40223700000092',
    '40223700000012',
    '40223100000092',
    '40223100000012'
  ];

  constructor(private service: WebServiceInfosService) {}

  ngOnInit(): void {
    this.carregarConteudo();
  }

  carregarConteudo() {
    const array: string[] = [];
    this.codigoUnidades.forEach((element) => {
      this.service.getInfosPorCodigo(element).subscribe(
        (response) => {
          const parser = new DOMParser();
          const xml = parser.parseFromString(response, 'text/xml');
          xml.querySelectorAll('NOME_PROCURADOR, NOME_SUBSTITUTO, NOME_SUBSTITUTO2').forEach((element) => {
            if (element.textContent !== '') {
              array.push(element.textContent.trim());
            }
          });
        },
        (error) => {
          console.log(error);
        }
      );
    });

    this.informacoes = array;
  }

  findInArray(nome: string): string {
    const result = this.informacoes.find((element) => element === nome);
    return result ? result : 'Nome não encontrado';
  }
}
