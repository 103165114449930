import { Component } from '@angular/core';
import { Dir } from '@angular/cdk/bidi';
import { SeparadorComponent } from '../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-atendimento',
  templateUrl: './atendimento.component.html',
  standalone: true,
  imports: [SeparadorComponent, Dir]
})
export class AtendimentoComponent {}
