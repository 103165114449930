<main class="main" role="main">
  <section class="page">
    <app-separador label="Histórico"></app-separador>
    <div class="row">
      <div>
        <p>
          A Procuradoria Geral do Estado de São Paulo (PGE/SP) é uma instituição de natureza permanente, essencial à Justiça e à Administração Pública Estadual. Ela é responsável pela Advocacia do
          Estado, tendo como funções as representações judicial e extrajudicial, além de consultoria jurídica, tanto para o Estado quanto para suas autarquias, incluindo as de regime especial, com
          exceção das universidades públicas.
        </p>
        <p>
          A história formal da Instituição remonta ao Decreto-Lei Estadual 17.330, de 27.06.1947, assinado pelo então governador Adhemar de Barros. À época, ela recebeu o nome de Departamento Jurídico
          do Estado (DJE) e era subordinada à Secretaria de Justiça e Negócios do Interior.
        </p>
        <p>
          Ela aglutinou o trabalho das então autônomas Procuradoria Judicial (PJ), Procuradoria Fiscal (PF), Procuradoria do Patrimônio Imobiliário (PPI) e da Procuradoria Assistência Judiciária
          (PAJ), que era denominada anteriormente por Procuradoria do Serviço Social (PSS). Hoje em dia, a assistência judiciária é exercida pela Defensoria Geral do Estado de São Paulo (prevista pela
          Constituição Federal de 1988 e criada pela Lei Complementar Estadual 988 de 09.01.2006).
        </p>
        <p>
          O primeiro procurador geral do Estado foi José Edgard Pereira Barreto. Lembrando que, desde 1954, após reestruturação no DJE, passou a ser exigido concurso público para acesso aos cargos da
          classe inicial da carreira de Advogado do Estado. Naquele mesmo ano, ocorreu o primeiro concurso para ingresso na Carreira. Desde então, para exercer o cargo de procurador geral do Estado, é
          obrigatório que o indicado pelo governador para a função faça parte dessa Carreira.
        </p>
        <p>
          Ainda em fevereiro de 1948, o DJE é regulamentado por meio do Decreto 18.018-A que criou os Escritórios Jurídicos Estaduais (EJE) que, anos mais tarde, se transformariam nas atuais doze
          Procuradorias Regionais, mais a representação da PGE/SP perante os tribunais superiores em Brasília.
        </p>
        <p>
          Em 1967, a Constituição Estadual dedicou uma seção para tratar exclusivamente da PGE/SP, que foi reorganizada pela Lei estadual 9.847, de 25.09.1967. A primeira Lei Orgânica da Procuradoria
          Geral do Estado (LOPGE) foi a Lei Complementar Estadual 93, de 28.05.1974, que dentre outros órgãos auxiliares, criou o Centro de Estudos (CEPGE), órgão responsável pelo aperfeiçoamento
          profissional de procuradores e servidores e pela difusão de informações de interesse, prioritariamente, da advocacia pública, mas também de outras carreiras, jurídicas ou não.
          Aproximadamente 30 anos depois, é criada a Escola Superior da Procuradoria Geral do Estado (ESPGE).
        </p>
        <p>
          A Constituição Federal de 1988 protege o exercício das advocacias, pública e privada e, em 1989, com a promulgação da nova Constituição do Estado de São Paulo, a PGE/SP passa a ser uma
          instituição de natureza permanente, vinculada diretamente ao governador do Estado.
        </p>
        <p>
          Na virada do milênio, a PGE/SP assume a advocacia das autarquias e o controle da dívida ativa. Com a transformação da antiga PAJ na Defensoria Pública, órgão independente, a área que tratava
          de todo o contencioso do Estado é desmembrada em duas outras subprocuradorias, originando a terceira área de atuação do órgão: Subprocuradoria Geral da Consultoria, Subprocuradoria Geral do
          Contencioso Geral e Subprocuradoria Geral do Contencioso Tributário-Fiscal.
        </p>
        <p>Ao longo da história, a instituição teve três Leis Orgânicas, sendo a mais recente a Lei Complementar nº 1.270, de 25 de agosto de 2015.</p>
      </div>
    </div>
  </section>
</main>
