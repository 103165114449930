<main class="main" role="main">
  <app-separador label="Comunicados"></app-separador>
  <section class="noticias-lista">
    <div id="is-content" class="row">
      @for (obj of objComunicados; track obj) {
        <div class="is-post col-xl-6 col-lg-6 col-md-6 col-md-6 col-sm-12 col-xs-12">
          <div class="noticia">
            <div class="the_content">
              <div class="data" [innerHTML]="editarData(obj.dataPublicacao)"></div>
              <h5>
                <a class="tipoLink" (click)="comunicado(obj.id)" (keypress)="comunicado(obj.id)" tabindex="0">{{ obj.titulo }}</a>
              </h5>
            </div>
          </div>
        </div>
      }
    </div>
  </section>
  <div>
    <mat-paginator
      #paginator
      class="custom-paginator"
      (page)="handlePageEvent($event)"
      [length]="length"
      [pageSize]="pageSize"
      [disabled]="disabled"
      [showFirstLastButtons]="showFirstLastButtons"
      [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
      [hidePageSize]="hidePageSize"
      [pageIndex]="pageIndex"
      aria-label="Select page"
    ></mat-paginator>
  </div>
</main>
