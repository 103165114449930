<main class="main" role="main">
  <section class="page">
    <div class="flex-container-separador">
      <app-separador label="Quem Somos"></app-separador>
      <div class="flex-spacer-separador"></div>
      <app-separador-email
        label="ATENDIMENTO"
        msg1="Horário: de segunda a sexta-feira, das 8h às 20h"
        msg2="E-mail: pgegpg@sp.gov.br"
        msg3="Telefones: (11) 3372-6656 | 3372-6656 | 3372-6672"
        msg4="Endereço: Rua Pamplona,227, 17º andar, Jardim Paulista, São Paulo-SP, 01405-902"
      ></app-separador-email>
    </div>
    <div class="row">
      <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 the_content">
        <p>
          A Ouvidoria é um canal direto de comunicação entre a Procuradoria Geral do Estado e os usuários de seus serviços. Suas principais atribuições são receber e encaminhar queixas, reclamações e
          sugestões. A Ouvidoria da Procuradoria Geral do Estado foi instituída pela Resolução PGE n. 409 de 23.8.99, em cumprimento ao disposto na Lei n. 10.294, de 20.4.1999, regulamentada pelo
          Decreto n. 44.074, de 1º. 7.1999
        </p>
        <p>
          A Lei n. 10.294/99 trouxe importantes inovações: estabeleceu os direitos básicos dos usuários, determinou a instituição de Ouvidorias e Comissões de Ética em todos os órgãos e entidades
          prestadoras de serviços públicos no Estado de São Paulo , criou o Sistema Estadual de Defesa do Usuário de Serviços Públicos. A Ouvidoria está subordinada diretamente ao Procurador Geral do
          Estado e o Ouvidor, no exercício de suas funções, tem livre acesso a todos os setores do órgão em que atua.
        </p>
        <p>
          <b><u>Orientações:</u></b
          ><br />
          <b>a)</b> não serão aceitas manifestações anônimas (art. 5º, inciso IV, da Constituição Federal);<br />
          <b>b)</b> os pedidos de pareceres jurídicos emitidos pela PGE deverão ser feitos diretamente à Ouvidoria da Secretaria de Estado envolvida no assunto;<br />
          <b>c)</b> se sua pergunta estiver relacionada à Dívida Ativa, consulte antes o portal
          <a href="http://www.dividaativa.pge.sp.gov.br/" target="_blank" rel="noopener noreferrer"><b>www.dividaativa.pge.sp.gov.br</b></a
          >, pois a resposta que você busca pode estar lá.
        </p>
        <p>
          <b
            >Antes de remeter sua consulta, verifique se você pode encontrar as respostas que procura no Portal da Transparência do Estado de São Paulo. É simples e fácil:
            <a href="http://www.transparencia.sp.gov.br/" target="_blank" rel="noopener noreferrer">www.transparencia.sp.gov.br</a></b
          >
        </p>
        <p><b>Ouvidora da PGE:</b> Regina Maria Sartori.</p>
        <p>
          <b>Ex-Ouvidoras:<br /></b> · Lucia de Faria Freitas (12/2015 a 07/2019)<br />
          · Maria Rita Vaz de Arruda Corsini (01/2011 a 12/2015)<br />
          · Flavia Cherto Carvalhaes (05/2008 a 01/2011)<br />
          · Claudia Cardoso Chahoud (01/2007 a 04/2008)<br />
          · Ana Sofia Schmidt de Oliveira (07/2006 a 12/2006)<br />
          · Cíntia Byczkowski (10/2005 a 06/2006)<br />
          · Magally Motta de Oliveira (2004 e 2005)<br />
          · Raquel Freitas de Souza (2004)<br />
          · Ana Sofia Schmidt de Oliveira (2002 a 2004)<br />
          · Yara Chucralla Moherdaui Blasi (2000 a 2002)<br />
          · Nancy Regina Costa Flosi (1999 e 2000)
        </p>
        <hr />
      </div>
    </div>
  </section>
</main>
