<mat-drawer-container hasBackdrop="unset" style="background: inherit">
  <div [ngClass]="{ 'flex-center': notLoginForm() }">
    <mat-drawer #drawer mode="over">
      <app-drawer-content (toggleDrawer)="drawer.toggle()"></app-drawer-content>
    </mat-drawer>
    <div class="content-wrapper" [ngClass]="{ 'all-content': notLoginForm() }">
      <main>
        <div>
          <div class="header-menu-container">
            @if (notLoginForm()) {
              <app-header></app-header>
            }
            @if (notLoginForm()) {
              <button class="button-menu" mat-icon-button (click)="openMenu()">
                <mat-icon>menu</mat-icon>
              </button>
              <app-menu class="menu-container" (toggleDrawer)="drawer.toggle()"></app-menu>
            }
          </div>
          @if (!search) {
            <div class="container-router">
              <router-outlet></router-outlet>
            </div>
          }
          @if (search) {
            <app-search-results></app-search-results>
          }
        </div>
      </main>
    </div>
  </div>
  @if (notLoginForm()) {
    <app-footer></app-footer>
  }
</mat-drawer-container>
