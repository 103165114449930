import { Component } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { SeparadorComponent } from '../../core/layout/separador/separador.component';

@Component({
  selector: 'app-programa-estagio',
  templateUrl: './programa-estagio.component.html',
  styleUrl: './programa-estagio.component.sass',
  standalone: true,
  imports: [SeparadorComponent, MatButton, MatIcon]
})
export class ProgramaEstagioComponent {
  url: string = 'informacao-cidadao/comunicados';

  programa = [
    {
      titulo: 'Estágio na Procuradoria Geral de São Paulo',
      abrir: false
    },
    {
      titulo: 'Carga horária/Duração',
      abrir: false
    },
    {
      titulo: 'Comunicados',
      abrir: false
    },
    {
      titulo: 'Edital',
      abrir: false
    }
  ];

  toggle(index: number): void {
    this.programa[index].abrir = !this.programa[index].abrir;
    this.programa.forEach((item, i) => {
      if (i !== index) item.abrir = false;
    });
  }
}
