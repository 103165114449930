import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { Subject } from 'rxjs/internal/Subject';
import { finalize, takeUntil } from 'rxjs/operators';
import { LegislacaoService } from 'src/app/area-aberta/shared/legislacao.service';
import { Legislacao, LegislacaoArquivo } from 'src/app/area-restrita/features/auxilio/saude/shared/models/legislacao';
import { TipoLegislacao } from 'src/app/area-restrita/features/auxilio/saude/shared/models/tipo-publicacao';
import { StateAnaliseInscricaoService } from 'src/app/area-restrita/features/auxilio/shared/services/state-analise.service';
import { WaitLoadingService } from 'src/app/area-restrita/shared/components/wait-loading/wait-loading.service';
import { ConversorValidadorService } from 'src/app/area-restrita/shared/services/conversor-validador.service';
import { Page } from 'src/app/shared/models/paginacao/page.model';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { TruncateHtmlPipe } from '../../../../shared/pipes/truncate-html.pipe';
import { SeparadorComponent } from '../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-legislacao',
  templateUrl: './legislacao.component.html',
  styleUrl: './legislacao.component.sass',
  standalone: true,
  imports: [
    SeparadorComponent,
    FormsModule,
    ReactiveFormsModule,
    MatLabel,
    MatFormField,
    MatSelect,
    MatOption,
    MatInput,
    MatButton,
    MatPaginator,
    TruncateHtmlPipe
  ]
})
export class LegislacaoComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  publicacoes: Legislacao[] = [];
  tipoLegislacao: TipoLegislacao[];
  paginador = false;
  mostraTabela: boolean = false;
  ativaBotao: boolean = true;
  pageEvent: PageEvent;
  length = 0;
  pageSize = 10;
  pageNumber = 0;
  pageSizeOptions = [5, 10, 25];
  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;

  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastService,
    private legislacaoService: LegislacaoService,
    private waitLoadingService: WaitLoadingService,
    private stateAnaliseInscricaoService: StateAnaliseInscricaoService,
    private base64ToPdfService: ConversorValidadorService
  ) {}

  ngOnInit(): void {
    this.pageNumber = this.stateAnaliseInscricaoService.page;
    this.pageSize = this.stateAnaliseInscricaoService.size;
    this.stateAnaliseInscricaoService.limpar();
    this.obterTipoPublicacao();
  }

  dadosFormulario = this.formBuilder.group({
    tipoLegislacao: [null],
    publicacao: [null],
    conteudo: [null]
  });

  get listaPublicacoes() {
    return this.publicacoes;
  }

  buscar(): void {
    this.publicacoes = [];
    this.mostraTabela = false;
    if (this.dadosFormulario.valid) {
      this.waitLoadingService.open();
      this.legislacaoService
        .buscar(this.dadosFormulario, this.pageNumber, this.pageSize)
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => this.waitLoadingService.close())
        )
        .subscribe({
          next: (response: Page<Legislacao>) => {
            response.content.forEach((element) => {
              element.conteudo = element.conteudo.replace('h1', 'p').replace('h2', 'p').replace('h3', 'p');
            });

            this.publicacoes = response.content;
            this.length = response.totalElements;
            this.pageSize = response.size;
            this.pageNumber = response.number;
            this.paginador = this.length > 0;
            this.mostraTabela = true;
            this.ativaBotao = true;
          },
          error: (error) => this.toastr.error(error.message)
        });
    }
  }

  obterTipoPublicacao() {
    this.legislacaoService
      .obterListaTipoPublicacao()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (dados) => {
          this.tipoLegislacao = dados;
        },
        error: (error) => console.error('Erro ao obter dados do serviço', error)
      });
  }

  getTruncatedContent(content: string): string {
    if (content.length > 255) {
      return content.substring(0, 255) + '...';
    }
    return content;
  }

  limparFormulario() {
    this.dadosFormulario.reset();
    this.length = 0;
    this.pageSize = 10;
    this.pageNumber = 0;
    this.publicacoes = [];
    this.paginador = false;
    this.mostraTabela = false;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageNumber = e.pageIndex;
    this.buscar();
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map((str) => +str);
    }
  }

  openPDF(event: Event, id: number) {
    event.preventDefault();
    this.waitLoadingService.open();
    this.legislacaoService
      .buscarPdf(id)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.waitLoadingService.close())
      )
      .subscribe({
        next: (response: LegislacaoArquivo) => {
          if (response) {
            this.base64ToPdfService.base64ToBlob(response.arquivo);
          } else {
            this.toastr.info('Nenhum arquivo disponível.');
          }
        },
        error: (error) => this.toastr.error(error.message)
      });
  }
}
