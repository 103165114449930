<app-header />
<mat-nav-list>
  <mat-list-item (click)="toggleExpand('ITEM1')">
    <span mat-line>INSTITUCIONAL</span>
    <button mat-icon-button type="submit">
      <mat-icon>{{ isExpanded("ITEM1") ? "expand_less" : "expand_more" }}</mat-icon>
    </button>
  </mat-list-item>
  @if (isExpanded("ITEM1")) {
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>Subitem 1</mat-panel-title>
      </mat-expansion-panel-header>
      <p>Conteúdo do subitem 1</p>
    </mat-expansion-panel>
  }
  <mat-list-item (click)="toggleExpand('ITEM2')">
    <span mat-line>OUVIDORIA</span>
    <button mat-icon-button type="submit">
      <mat-icon>{{ isExpanded("ITEM2") ? "expand_less" : "expand_more" }}</mat-icon>
    </button>
  </mat-list-item>
  @if (isExpanded("ITEM2")) {
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>Subitem 2</mat-panel-title>
      </mat-expansion-panel-header>
      <p>Conteúdo do subitem 2</p>
    </mat-expansion-panel>
  }
  <mat-list-item (click)="toggleExpand('ITEM3')">
    <span mat-line>INFORMAÇÃO AO CIDADÃO</span>
    <button mat-icon-button type="submit">
      <mat-icon>{{ isExpanded("ITEM3") ? "expand_less" : "expand_more" }}</mat-icon>
    </button>
  </mat-list-item>
  @if (isExpanded("ITEM3")) {
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>Subitem 3</mat-panel-title>
      </mat-expansion-panel-header>
    </mat-expansion-panel>
  }
  <mat-list-item (click)="toggleExpand('ITEM5')">
    <span mat-line>CENTRO DE ESTUDOS</span>
    <button mat-icon-button type="submit">
      <mat-icon>{{ isExpanded("ITEM5") ? "expand_less" : "expand_more" }}</mat-icon>
    </button>
  </mat-list-item>
  @if (isExpanded("ITEM5")) {
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>Subitem 5</mat-panel-title>
      </mat-expansion-panel-header>
    </mat-expansion-panel>
  }
  <mat-list-item (click)="toggleExpand('ITEM4')">
    <span mat-line>ÁREA RESTRITA</span>
    <button mat-icon-button type="submit">
      <mat-icon>{{ isExpanded("ITEM4") ? "expand_less" : "expand_more" }}</mat-icon>
    </button>
  </mat-list-item>
  @if (isExpanded("ITEM4")) {
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>ÁREA RESTRITA</mat-panel-title>
      </mat-expansion-panel-header>
      <p>Conteúdo do subitem 4</p>
    </mat-expansion-panel>
  }
  <!-- Adicione mais itens e subitens conforme necessário -->
</mat-nav-list>
