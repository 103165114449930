import { FormControl } from '@angular/forms';

export default function dataNascimentoValidator(control: FormControl): Record<string, boolean> {
  if (control != null && control.value != null) {
    const anoNascimento = control.value.substring(0, 4);
    const anoAtual = new Date().getFullYear();

    if (control.value.length < 10 || anoNascimento < 1900 || anoNascimento > anoAtual) {
      return { dataNascimentoInvalida: true };
    }
  }
  return null;
}
