import { CurrencyPipe, Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatFormField } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatTooltip } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxMaskDirective } from 'ngx-mask';
import { Subject, takeUntil } from 'rxjs';
import { NotaFiscalAnexadaAuxilio, PedidoAuxilioSaude } from 'src/app/area-restrita/features/auxilio/saude/shared/models/auxilio-saude';
import { AvaliacaoAuxilioDto } from 'src/app/area-restrita/features/auxilio/saude/shared/models/auxilio-saude-dto';
import { Dependente } from 'src/app/area-restrita/features/auxilio/saude/shared/models/dependente';
import { AuxilioSaudeService } from 'src/app/area-restrita/features/auxilio/saude/shared/services/auxilio-saude.service';
import { ConversorValidadorService } from 'src/app/area-restrita/shared/services/conversor-validador.service';
import { PaginaVisitadaService } from 'src/app/area-restrita/shared/services/pagina-visitada.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { ExtrairAnoDataPipe } from '../../../../shared/pipes/extrair-ano-data.pipe';
import { ObterMesDataPipe } from '../../../../shared/pipes/obter-mes-data.pipe';
import { StateAnaliseService } from './../../shared/services/state-analise.service';

@Component({
  selector: 'app-analise-pedido-auxilio-saude',
  templateUrl: './analise-pedido-auxilio-saude.component.html',
  styleUrl: './analise-pedido-auxilio-saude.component.sass',
  standalone: true,
  imports: [
    MatIcon,
    MatTooltip,
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    NgxMaskDirective,
    MatButton,
    CurrencyPipe,
    ObterMesDataPipe,
    ExtrairAnoDataPipe
  ]
})
export class AnalisePedidoAuxilioSaudeComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  modoVisualizacao = false;
  pedidoAuxilio: PedidoAuxilioSaude;
  dependentes: Dependente[] = [];
  documentosTitular: NotaFiscalAnexadaAuxilio[] = [];
  documentosDependente: NotaFiscalAnexadaAuxilio[] = [];
  valorPerso: boolean = false;
  avaliacaoAuxilio: AvaliacaoAuxilioDto = {
    flagAvaliacao: '',
    idAprovador: '',
    observacao: '',
    valorDeferidoPge: 0.0,
    dataAprovacao: null
  };
  dataInscricao = '';
  valorDeferidoPGE: string = '0.00';
  primeiroRadioSelecionado: boolean = true;
  valorSelecionado: string = '';
  validado: boolean = true;
  dataAprovacao: string = this.getCurrentDate();
  obervacaoRetificacao: string = '';
  valorEspecifico: boolean;
  zeroPorcento: boolean;
  cemPorcento: boolean;

  constructor(
    private auxilioSaudeService: AuxilioSaudeService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private base64ToPdfService: ConversorValidadorService,
    private stateAnaliseService: StateAnaliseService,
    private toastr: ToastService,
    private paginaService: PaginaVisitadaService
  ) {}

  ngOnInit(): void {
    this.paginaService.salvaPagina('Analise do Pedido').subscribe();
    const idPedido = this.route.snapshot.params['id'];
    this.carregarAuxilioSaude(idPedido);
  }

  obterInscricao(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.auxilioSaudeService
        .obterInscricao(this.pedidoAuxilio.usuario.id)
        .toPromise()
        .then((dados) => {
          if (dados != null) {
            this.dataInscricao = dados?.dataInscricao;
          }
          resolve();
        })
        .catch((error) => reject(new Error('Erro ao obter inscrição', error)));
    });
  }

  formAnalisePedidoAuxilioSaude = this.formBuilder.group({
    valorDeferidoPGE: { value: this.formatarMoeda(0.0), disabled: true },
    dataAprovacaoPGE: ['', Validators.required],
    observacao: [''],
    aprovador: ['']
  });

  carregarAuxilioSaude(idPedido: string) {
    this.auxilioSaudeService
      .obterPedidoAuxilioSaude(idPedido)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (dados) => {
          this.pedidoAuxilio = dados;

          // Desativar campos de forma centralizada
          if (!this.pedidoAuxilio.podeDeferir) {
            const formControls = this.formAnalisePedidoAuxilioSaude.controls;
            formControls['dataAprovacaoPGE'].disable();
            formControls['observacao'].disable();
          }

          // Resetar valores
          const avaliacaoFlags = {
            A: { cemPorcento: true, zeroPorcento: false, valorEspecifico: false },
            S: { cemPorcento: false, zeroPorcento: true, valorEspecifico: false },
            I: { cemPorcento: false, zeroPorcento: false, valorEspecifico: true }
          };

          // Verificar o valor de flagAvaliacao, tratando o caso de null
          const avaliacao =
            this.pedidoAuxilio.flagAvaliacao !== null ?
              avaliacaoFlags[this.pedidoAuxilio.flagAvaliacao]
            : { cemPorcento: false, zeroPorcento: false, valorEspecifico: false };

          // Aplicar valores com base no flagAvaliacao
          this.cemPorcento = avaliacao.cemPorcento;
          this.zeroPorcento = avaliacao.zeroPorcento;
          this.valorEspecifico = avaliacao.valorEspecifico;

          this.formAnalisePedidoAuxilioSaude.controls['valorDeferidoPGE'].setValue(this.pedidoAuxilio.valorDeferidoPge.toString());
          this.obterInscricao();
          this.obterListaDocumentos(this.pedidoAuxilio);
        },
        error: (error) => console.error('Erro ao obter dados do serviço', error)
      });
  }

  obterListaDocumentos(pedido: PedidoAuxilioSaude) {
    this.auxilioSaudeService
      .obterListaDocumentos(pedido.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (dados) => {
          dados.forEach((element: NotaFiscalAnexadaAuxilio) => {
            if (element.titular) {
              this.documentosTitular.push(element);
            } else {
              this.documentosDependente.push(element);
            }
          });
          this.desativaDataAprovacao();
        },
        error: (error) => console.error('Erro ao obter dados do serviço', error)
      });
  }

  cancelarEnvio() {
    this.stateAnaliseService.telaAnalise = false;
    this.location.back();
  }

  enviarFormulario() {
    if (this.camposObrigatoriosPreenchidos()) {
      this.avaliacaoAuxilio.observacao = this.formAnalisePedidoAuxilioSaude.controls['observacao'].value || '';
      this.avaliacaoAuxilio.valorDeferidoPge = this.converterMoedaParaNumero(this.formAnalisePedidoAuxilioSaude.controls['valorDeferidoPGE'].value) || 0;

      const dataOriginal = new Date(this.formAnalisePedidoAuxilioSaude.get('dataAprovacaoPGE').value);
      dataOriginal.setDate(dataOriginal.getDate() + 1);
      const dataFormatada = dataOriginal.toLocaleDateString();

      this.avaliacaoAuxilio.dataAprovacao = dataFormatada;

      this.auxilioSaudeService
        .enviarAnalisePedidoAuxilioSaude(this.avaliacaoAuxilio, this.pedidoAuxilio)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          () => {
            this.toastr.success('Pedido avaliado com sucesso.');
            setTimeout(() => {
              this.router.navigate(['area-restrita', 'administracao-auxilio-saude', 'analisar-pedido']);
            }, 2000);
          },
          (error) => {
            this.toastr.error(error.message);
          }
        );
    } else {
      this.toastr.error('Falta preencher campos obrigatórios.');
    }
  }

  EnviarRetificacaoAvaliarAuxilioSaude() {
    this.obervacaoRetificacao = this.formAnalisePedidoAuxilioSaude.controls['observacao'].value || '';
    if (this.obervacaoRetificacao.length > 0) {
      this.auxilioSaudeService
        .enviarRetificacaoAvaliarAuxilioSaude(this.obervacaoRetificacao, this.pedidoAuxilio)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          () => {
            this.toastr.success('Retificação de Avaliação enviada com sucesso.');
            setTimeout(() => {
              this.router.navigate(['area-restrita', 'administracao-auxilio-saude', 'analisar-pedido']);
            }, 2000);
          },
          (error) => {
            this.toastr.error(error.message);
          }
        );
    } else {
      this.toastr.error('Falta preencher campos obrigatórios.');
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openPDF(event: MouseEvent, pedido: NotaFiscalAnexadaAuxilio): void {
    event.preventDefault();
    this.base64ToPdfService.base64ToBlob(pedido.arquivo);
  }

  atualizarValorDeferidoPGE(): void {
    this.primeiroRadioSelecionado = true;
    this.pedidoAuxilio.valorTotalDeferido = this.pedidoAuxilio.valorReembolsoTotal;
  }

  selecionarValor(event: Event): void {
    const valorSelecionado = (event.target as HTMLInputElement).value;
    this.valorSelecionado = valorSelecionado;

    if (valorSelecionado === '100%') {
      this.formAnalisePedidoAuxilioSaude.controls['valorDeferidoPGE'].setValue(this.formatarMoeda(this.pedidoAuxilio.valorReembolsoTotal));
      this.formAnalisePedidoAuxilioSaude.controls['valorDeferidoPGE']?.disable();
    } else if (valorSelecionado === '0%') {
      this.formAnalisePedidoAuxilioSaude.controls['valorDeferidoPGE'].setValue(this.formatarMoeda(0.0));
      this.formAnalisePedidoAuxilioSaude.controls['valorDeferidoPGE']?.disable();
    } else {
      this.formAnalisePedidoAuxilioSaude.controls['valorDeferidoPGE'].setValue(null);
      this.formAnalisePedidoAuxilioSaude.controls['valorDeferidoPGE']?.enable();
    }
  }

  camposObrigatoriosPreenchidos(): boolean {
    return !!this.valorSelecionado && !!this.formAnalisePedidoAuxilioSaude.get('dataAprovacaoPGE').value;
  }
  validaModo() {
    return !this.pedidoAuxilio.podeDeferir ? true : false;
  }

  getCurrentDate(): string {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
    const day = ('0' + currentDate.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  desativaDataAprovacao() {
    if (this.pedidoAuxilio.valorDeferidoPge != null) {
      this.formAnalisePedidoAuxilioSaude.controls['dataAprovacaoPGE']?.disable();
    } else {
      this.formAnalisePedidoAuxilioSaude.controls['dataAprovacaoPGE']?.enable();
    }
  }

  private converterMoedaParaNumero(valorMoeda: string): number {
    // Remove qualquer caractere que não seja número, vírgula ou ponto.
    const numeroSemFormatacao = valorMoeda.replace(/[^\d.,]/g, '');

    // Verifica se o número tem vírgula e ponto. Se tiver, assume que vírgula é para decimais.
    if (numeroSemFormatacao.indexOf(',') > -1 && numeroSemFormatacao.indexOf('.') > -1) {
      // Remove os pontos que são separadores de milhar
      const numeroLimpo = numeroSemFormatacao.replace(/\./g, '');

      // Substitui a vírgula pelo ponto para conversão decimal
      const numeroFormatado = numeroLimpo.replace(',', '.');

      // Converte para número de ponto flutuante
      return parseFloat(numeroFormatado);
    } else {
      // Caso tenha apenas vírgula ou ponto, assume o último como separador decimal
      const numeroFormatado = numeroSemFormatacao.replace(',', '.');
      return parseFloat(numeroFormatado);
    }
  }

  formatarMoeda(value: number): string {
    return value.toFixed(2);
  }
}
