<div class="top-bar">
  <div class="div-menu-button">
    @if (isMenuOpen) {
      @if (!botaoFechar) {
        <button class="button-menu" mat-icon-button (click)="toggleMenu(false, true)">
          <mat-icon aria-hidden="false">menu_open</mat-icon>
        </button>
      }
      @if (botaoFechar) {
        <button class="button-menu" mat-icon-button (click)="toggleMenu(true, true)">
          <mat-icon aria-hidden="false">close</mat-icon>
        </button>
      }
    }
    @if (!isMenuOpen) {
      <button class="button-menu" mat-icon-button (click)="toggleMenu(false, true)">
        <mat-icon aria-hidden="false">menu</mat-icon>
      </button>
    }
  </div>
  <div class="usuario-area">
    <mat-icon class="user-area-icon" aria-hidden="false" fontIcon="notifications"></mat-icon>
    <mat-icon aria-hidden="false" fontIcon="account_circle"></mat-icon>
    <div class="usuario-info" (click)="toggleMenuUsuario()" (keypress)="toggleMenuUsuario()" tabindex="0">
      <span>Usuario: {{ getPrimeiroESegundoNome() }}</span>
      <span>Matricula: {{ this.idUsuario }}</span>
    </div>
    <mat-icon aria-hidden="false" fontIcon="expand_more" #menuIcon (click)="toggleMenuUsuario()"></mat-icon>
  </div>
</div>
<mat-drawer-container class="background-container router-area-restrita" autosize>
  <mat-drawer [opened]="true" #drawer class="menu-area-restrita" mode="side" [ngClass]="isMenuOpen || isShowing ? 'mat-drawer-open' : 'mat-drawer-close'">
    <div class="side-drawer" [class.side-drawer-reduzido]="!isMenuOpen && !isShowing">
      <img width="150px" [class.brasao-reduzido]="!isMenuOpen && !isShowing" src="assets/images/brasao.png" alt="Brasão PGE" />
      @if (isMenuOpen || isShowing) {
        <p class="title-sp">
          Procuradoria Geral do <br />
          Estado de São Paulo
        </p>
      }
      <div class="contaier-overflow div-menu">
        <mat-nav-list [class.mat-nav-list-reduzido]="!isMenuOpen && !isShowing">
          @for (item of dataSource; track item; let i = $index) {
            <div>
              <app-menu-item [item]="item" [isMenuOpen]="isMenuOpen" [isShowing]="isShowing"></app-menu-item>
            </div>
          }
        </mat-nav-list>
      </div>
    </div>
  </mat-drawer>
  <mat-drawer-content [class.collapsed-content]="!isMenuOpen && !isShowing">
    <div class="contaier-overflow">
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
@if (menuAtivo) {
  <div class="opcao-usuario" (mouseleave)="fecharMenu()" (mouseenter)="manterMenu()">
    <div>Meu Cadastro</div>
    <div (click)="alterarSenha()" (keypress)="alterarSenha()" tabindex="1">Alterar senha</div>
    <div (click)="logoutUsuario()" (keypress)="logoutUsuario()" tabindex="2">Sair</div>
  </div>
}
