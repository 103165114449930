@if (cardContent?.linkExterno) {
  <a [href]="cardContent?.url" target="_blank" class="nav-link">
    <mat-card class="carousel-card">
      <mat-card-header class="carousel-card-header">
        <img [src]="svgPath" alt="{{ cardContent?.titulo }}" />
      </mat-card-header>
      <mat-card-content class="carousel-card-body">
        <span>{{ cardContent?.titulo }}</span>
      </mat-card-content>
      <mat-card-footer> </mat-card-footer>
    </mat-card>
  </a>
}

@if (!cardContent?.linkExterno) {
  <a [routerLink]="cardContent?.url" class="nav-link" target="_blank">
    <mat-card class="carousel-card">
      <mat-card-header class="carousel-card-header">
        <img [src]="svgPath" alt="{{ cardContent?.titulo }}" />
      </mat-card-header>
      <mat-card-content class="carousel-card-body">
        <span>{{ cardContent?.titulo }}</span>
      </mat-card-content>
      <mat-card-footer> </mat-card-footer>
    </mat-card>
  </a>
}
