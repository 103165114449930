import { ItemAcessoRapido } from './link-acesso-rapido.model';

export const AcessoRapido: ItemAcessoRapido[] = [
  {
    redirect: 'programa-estagio',
    imagem: 'assets/images/acesso-rapido/programa-de-estagio-pge-sp.jpg',
    alt: 'Estágio e Residência Jurídica'
  },
  {
    redirect: 'planejamento-estrategico',
    imagem: 'assets/images/acesso-rapido/planejamento-estrategico-pge.jpg',
    alt: 'Planejamento Estratégico PGE'
  },
  {
    redirect: 'https://www.dividaativa.pge.sp.gov.br/transacao/pages/home/home.jsf',
    imagem: 'assets/images/acesso-rapido/programa-acordo-paulista.jpg',
    alt: 'Programa Acordo Paulista'
  },
  {
    redirect: 'procurando-saber',
    imagem: 'assets/images/acesso-rapido/pge-nas-escolas.jpg',
    alt: 'Procurando Saber'
  },
  {
    redirect: 'informacao-cidadao/cepen',
    imagem: 'assets/images/acesso-rapido/certidao-positiva-com-efeito-de-negativa.jpg',
    alt: 'Certidao Positiva com Efeito de Negativa'
  },
  {
    redirect: 'minutas-padronizadas',
    imagem: 'assets/images/acesso-rapido/minutas-padronizadas.jpg',
    alt: 'Canal da PGE'
  },
  {
    redirect: 'menu/OUVIDORIA',
    imagem: 'assets/images/acesso-rapido/ouvidoria.jpg',
    alt: 'Ouvidoria, Integridade e Transparência'
  },
  {
    redirect: 'informacao-cidadao/enderecos-telefones-uteis',
    imagem: 'assets/images/acesso-rapido/enderecos-e-telefones.jpg',
    alt: 'Enderecos e Telefones'
  }
];
