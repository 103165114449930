import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { Subject, finalize, takeUntil } from 'rxjs';
import { WaitLoadingService } from 'src/app/area-restrita/shared/components/wait-loading/wait-loading.service';
import { PaginaVisitadaService } from 'src/app/area-restrita/shared/services/pagina-visitada.service';
import { Page } from 'src/app/shared/models/paginacao/page.model';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { environment } from 'src/environments/environment';
import { AuxilioSaudeMesaAnalise } from '../shared/models/auxilio-saude';
import { FinanceiroAuxilioSaudeService as FinanceiroAuxilioService } from '../shared/services/financeiro-auxilio-saude.service';
import { MesPorExtensoPipe } from '../../../../shared/pipes/mes-por-extenso.pipe';
import { FormatBRLPipe } from '../../../../shared/pipes/format-brl.pipe';
import { RouterLink } from '@angular/router';
import { MatButton } from '@angular/material/button';
import { NgxCurrencyDirective } from 'ngx-currency';
import { MatInput } from '@angular/material/input';
import { MatFormField } from '@angular/material/form-field';
@Component({
  selector: 'app-auxilio-financeiro',
  templateUrl: './auxilio-financeiro.component.html',
  styleUrl: './auxilio-financeiro.component.sass',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    NgxCurrencyDirective,
    MatButton,
    MatPaginator,
    RouterLink,
    FormatBRLPipe,
    MesPorExtensoPipe
  ]
})
export class AuxilioFinanceiroComponent implements OnDestroy, OnInit {
  assetsPath = environment.assetsPath;
  private readonly destroy$ = new Subject<void>();
  pedidosAuxilioFinanceiro: AuxilioSaudeMesaAnalise[] = [];
  pedidosSelecionados: Set<number> = new Set<number>();
  checked = true;
  checkboxCabecalhoSelecionado: boolean = false;
  ativaBotao: boolean = true;
  dataAprovacao: string = '';

  public progress: number = 0;
  paginaAtual: number = 1;
  itensPorPagina: number = 10;
  indiceInicioPagina: number = 0;
  indiceFimPagina: number = 10;

  paginador = false;
  mostraTabela: boolean = false;

  pageEvent: PageEvent;
  length = 0;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25];

  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private financeiroAuxilioS: FinanceiroAuxilioService,
    private matPaginatorIntl: MatPaginatorIntl,
    private toastrS: ToastService,
    private formBuilder: FormBuilder,
    private waitLoadingService: WaitLoadingService,
    private paginaService: PaginaVisitadaService
  ) {
    this.customizarTextosPaginator();
  }
  ngOnInit(): void {
    this.paginaService.salvaPagina('Financeiro do Auxílio Saúde').subscribe();
  }

  get itensPaginados(): AuxilioSaudeMesaAnalise[] {
    return this.pedidosAuxilioFinanceiro;
  }

  mudarPagina(pagina: number, tamanho: number): void {
    this.paginaAtual = pagina + 1;
    this.itensPorPagina = tamanho;
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.buscarDeferidos();
  }

  customizarTextosPaginator() {
    this.matPaginatorIntl.itemsPerPageLabel = 'Itens por página:';
    this.matPaginatorIntl.nextPageLabel = 'Próxima página';
    this.matPaginatorIntl.previousPageLabel = 'Página anterior';
    this.matPaginatorIntl.firstPageLabel = 'Primeira página';
    this.matPaginatorIntl.lastPageLabel = 'Última página';
    this.matPaginatorIntl.getRangeLabel = (page, pageSize, length) => {
      if (length === 0 || pageSize === 0) {
        return `0 de ${length}`;
      }
      length = Math.max(length, 0);
      this.indiceInicioPagina = page * pageSize;
      this.indiceFimPagina = this.indiceInicioPagina < length ? Math.min(this.indiceInicioPagina + pageSize, length) : this.indiceInicioPagina + pageSize;
      return `${this.indiceInicioPagina + 1} – ${this.indiceFimPagina} de ${length}`;
    };
    this.matPaginatorIntl.changes.next();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  dadosFormulario = this.formBuilder.group({
    procuradorServidor: [''],
    mesAnoSolicitacao: [''],
    periodoDe: [''],
    periodoAte: ['']
  });

  buscarDeferidos() {
    this.waitLoadingService.open();
    this.financeiroAuxilioS
      .buscarDeferidos(this.dadosFormulario, this.pageIndex, this.pageSize)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.waitLoadingService.close())
      )
      .subscribe({
        next: (response: Page<AuxilioSaudeMesaAnalise>) => {
          this.pedidosAuxilioFinanceiro = response.content;
          this.length = response.totalElements;
          this.paginador = !response.empty;
          this.mostraTabela = true;

          this.pedidosSelecionados = new Set<number>();
          if (this.pedidosAuxilioFinanceiro.length == 0) this.toastrS.warning('Nenhum auxílio deferido foi encontrado.');
        },
        error: (error) => this.toastrS.error(error.message)
      });
  }

  voltarEtapa() {
    const auxilioFinanceiroVoltaEtapa = {
      idsAuxilios: [...this.pedidosSelecionados]
    };
    this.financeiroAuxilioS
      .voltaEtapa(auxilioFinanceiroVoltaEtapa)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: string) => {
          this.pedidosSelecionados = new Set<number>();
          this.buscarDeferidos();
          this.toastrS.success(response);
        },
        error: (error) => this.toastrS.error(error.message)
      });
  }

  checkPedidoSelecionado(pedido: AuxilioSaudeMesaAnalise) {
    const pedidoId = Number.parseInt(pedido.id);
    if (this.pedidosSelecionados.has(pedidoId)) {
      this.pedidosSelecionados.delete(pedidoId);
    } else {
      this.pedidosSelecionados.add(pedidoId);
    }
    this.checkboxCabecalhoSelecionado = this.pedidosAuxilioFinanceiro.every((pedido) => this.pedidosSelecionados.has(Number.parseInt(pedido.id)));
  }

  selecionarTodos(event: Event) {
    const checkbox = event.target as HTMLInputElement;
    this.checkboxCabecalhoSelecionado = checkbox.checked;

    this.pedidosAuxilioFinanceiro.forEach((pedido) => {
      const pedidoId = Number.parseInt(pedido.id);
      if (this.checkboxCabecalhoSelecionado) {
        this.pedidosSelecionados.add(pedidoId);
      } else {
        this.pedidosSelecionados.delete(pedidoId);
      }
    });
  }

  limparFormulario() {
    this.dadosFormulario.reset({
      procuradorServidor: '',
      mesAnoSolicitacao: '',
      periodoDe: '',
      periodoAte: ''
    });
    this.pedidosSelecionados.clear();
    this.pedidosAuxilioFinanceiro = [];
    this.checked = true;
  }

  realizarPagamento() {
    const auxilioFin = {
      idsAuxilios: [...this.pedidosSelecionados],
      dataPagamento: this.getConvertDate(this.dataAprovacao)
    };
    this.financeiroAuxilioS
      .definePagamento(auxilioFin)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: string) => {
          this.waitLoadingService.close();
          this.toastrS.success(response);
          this.buscarDeferidos();
          this.pedidosSelecionados = new Set<number>();
        },
        error: (error) => this.toastrS.error(error.message)
      });
  }

  getConvertDate(date: string): string {
    const currentDate = new Date(date);
    const year = currentDate.getFullYear();
    const month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
    const day = ('0' + currentDate.getDate()).slice(-2);

    return `${day}/${month}/${year}`;
  }

  isValidDate(data: string): boolean {
    if (data == '') return false;
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (!data.match(regex)) {
      return false;
    }

    const date = new Date(data);
    const timestamp = date.getTime();

    if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
      return false;
    }

    return date.toISOString().startsWith(data);
  }
  formatCpf(cpf: string): string {
    const cleaned = cpf.replace(/\D/g, '');
    const pattern = /(\d{3})(\d{3})(\d{3})(\d{2})/;
    const formatted = cleaned.replace(pattern, '$1.$2.$3-$4');
    return formatted;
  }
}
