import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MenuList, MenuListLocalDev } from './menu-file';
import { MenuItem } from './menu.model';
import { ActivatedRoute } from '@angular/router';
import { SearchResultsComponent } from './search-results/search-results.component';
import { SearchComponent } from './search/search.component';
import { NgTemplateOutlet } from '@angular/common';
import { MatMenuItem } from '@angular/material/menu';
import { MatIcon } from '@angular/material/icon';
import { MatDialogClose } from '@angular/material/dialog';
import { MatIconButton } from '@angular/material/button';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.sass',
  standalone: true,
  imports: [MatIconButton, MatDialogClose, MatIcon, MatMenuItem, NgTemplateOutlet, SearchComponent, SearchResultsComponent]
})
export class MenuComponent implements OnInit {
  /* *** Testes do LOGIN ÚNICO *** TEMPORÁRIO ATÉ O FINAL DOS TESTES ***
  TODO - security, parameter
  */
  routerDefault: string = 'http://www.portal-legado.hom.pge.sp.gov.br/SitePge/Login.aspx?ReturnUrl=%2fSitePge%2frestrito%2fdefault.aspx';
  ambiente = environment.env;
  subMenuitem: MenuItem;
  menuList: MenuItem[];
  busca: string;

  constructor(private activeRoute: ActivatedRoute) {
    if (this.ambiente == 'loc' || this.ambiente == 'dev' || this.ambiente == 'hml') {
      this.menuList = MenuListLocalDev;
    } else {
      this.menuList = MenuList;
    }
  }
  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe((params) => {
      this.busca = params['search'];
    });
  }

  alternarMenu(menuItem: MenuItem) {
    if (this.subMenuitem === menuItem) {
      this.subMenuitem = null;
    } else {
      this.subMenuitem = menuItem;
    }
  }
}
