<main class="main" role="main">
  <section class="page">
    <app-separador label="Dívida Ativa"></app-separador>
    <div class="row">
      <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 the_content">
        <p>Acesse o Portal da Dívida Ativa <a href="https://www.dividaativa.pge.sp.gov.br/sc/pages/home/home_novo.jsf" target="_blank" rel="noopener noreferrer">CLIQUE AQUI</a></p>
        <p>Acesse o Portal da Transação <a href="https://www.dividaativa.pge.sp.gov.br/transacao/pages/home/home.jsf" target="_blank" rel="noopener noreferrer">CLIQUE AQUI</a></p>
        <p>&nbsp;</p>
        <h3>Relatório dos Maiores Devedores:</h3>
        <p>&nbsp;</p>
        <p>
          A lista abaixo relaciona os 500 maiores devedores inscritos na Dívida Ativa do Estado de São Paulo, por ordem decrescente de valor (excluídos eventuais honorários advocatícios devidos pelo
          ajuizamento de execução fiscal). Não foram consideradas as condições cadastrais dos devedores e nem a situação das dívidas, que podem estar com a exigibilidade suspensa, nos termos do artigo
          151 do Código Tributário Nacional.
        </p>
        <p><a href="https://www.pge.sp.gov.br/acompanhe/maiores_devedores.pdf" target="_blank" rel="noopener noreferrer">Confira a lista</a></p>
      </div>
    </div>
  </section>
</main>
