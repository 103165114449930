import { Component } from '@angular/core';
import { NasRedesComponent } from './nas-redes/nas-redes.component';
import { AcessoRapidoComponent } from './acesso-rapido/acesso-rapido.component';
import { NoticiasDestaqueComponent } from './noticias/noticias-destaque/noticias-destaque.component';
import { ServicosComponent } from './servicos/servicos.component';
import { SeparadorComponent } from '../../core/layout/separador/separador.component';

@Component({
  selector: 'app-home-area-aberta',
  templateUrl: './home-area-aberta.component.html',
  styleUrl: './home-area-aberta.component.sass',
  standalone: true,
  imports: [SeparadorComponent, ServicosComponent, NoticiasDestaqueComponent, AcessoRapidoComponent, NasRedesComponent]
})
export class HomeAreaAbertaComponent {}
