import { Component } from '@angular/core';
import { SeparadorComponent } from '../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-diario-oficial',
  templateUrl: './diario-oficial.component.html',
  styleUrl: './diario-oficial.component.sass',
  standalone: true,
  imports: [SeparadorComponent]
})
export class DiarioOficialComponent {}
