<app-separador label="Programa de Integridade/PGE" />
<p><b>O que é integridade?</b></p>
<p>
  A Integridade está relacionado à honestidade, aquele que possui fortes princípios morais e éticos. Integridade é a qualidade fundamental dos relacionamentos sociais e institucionais, que contribui
  para a confiança e confiabilidade.
</p>
<p><b>Programa de Integridade da PGE</b></p>
<p>O Programa de Integridade da PGE reflete o nosso compromisso inabalável com os mais altos padrões de conduta ética e moral.</p>
<p>
  A integridade é a base sobre a qual a PGE-SP construiu sua reputação, obteve a confiança do Poder Executivo e da sociedade paulista, e também de nossos servidores e colaboradores, na prestação de
  nossas atividades institucionais, e na implementação de políticas públicas com segurança jurídica.
</p>
<p>O nosso Programa de Integridade foi desenvolvido para assegurar que todos os nossos procedimentos, decisões e ações estejam alinhados com nossos valores fundamentais.</p>
<p>
  Os objetivos do Programa de Integridade da PGE-SP são: promover a cultura de ética e transparência, garantir a conformidade legal e regulatória, prevenir e detectar irregularidades e educar e
  treinar colaboradores.
</p>

<p style="padding-top: 20px"><a href="https://www.pge.sp.gov.br/PIPGE_2024.pdf" target="_blank" rel="noopener noreferrer">Programa de Integridade da Procuradoria Geral do Estado de São Paulo</a></p>
