import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Globais } from 'src/app/area-restrita/shared/globais/globais';
import { UsuarioLogin } from 'src/app/shared/models/usuario-login.interface';
import { environment } from 'src/environments/environment';
import { UsuarioAutenticacao } from '../models/usuarioAutenticacao.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  isLoggedIn = false;
  statusCode = 0;
  private token: BehaviorSubject<string> = new BehaviorSubject<string>(localStorage.getItem('token'));
  private url: string = environment.apiUrl + 'usuarios/';
  constructor(
    private http: HttpClient,
    private router: Router
  ) {}

  getUsuarioAutenticacao(hashLogin: string, hashSenha: string): Observable<UsuarioAutenticacao> {
    return this.http.get<UsuarioAutenticacao>(this.url + `${hashLogin}/${hashSenha}`);
  }

  setToken(token: string) {
    this.token.next(token);
    this.isLoggedIn = true;
    Globais.tokenBearer = token;
    localStorage.setItem('token', token);
  }

  setIdUsuario(idUsuario: number) {
    Globais.idUsuario = idUsuario;
    localStorage.setItem('idUsuario', idUsuario.toString());
  }

  setCpf(cpf: number) {
    localStorage.setItem('cpf', cpf.toString());
  }

  login(usuario: string, senha: string): Observable<HttpResponse<UsuarioLogin>> {
    localStorage.setItem('usuario', usuario.trim());
    localStorage.setItem('senha', senha.trim());
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const body = new HttpParams().set('usuario', usuario).set('senha', senha);

    return this.http.post<UsuarioLogin>(Globais.urlToken + 'site-pge/api/login', body.toString(), { headers: headers, observe: 'response' });
  }

  logout(route: string = '/') {
    this.token.next('');
    this.isLoggedIn = false;
    Globais.idUsuario = 0;
    Globais.tokenBearer = '';
    Globais.isLogin = false;
    localStorage.clear();
    this.router.navigate([route]);
  }

  getToken(): Observable<string> {
    return this.token.asObservable();
  }

  getTokenValue(): string {
    return this.token.value;
  }
}
