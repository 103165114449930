<main class="main" role="main">
  <section class="page">
    <app-separador label="Atribuições"></app-separador>
    <div class="row">
      <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 the_content">
        <p>
          Órgão superior da Instituição – assim como o Gabinete do Procurador Geral e a Corregedoria, o Conselho da Procuradoria Geral do Estado tem suas atribuições fixadas na Lei Complementar nº
          1.270, de 25-8-2015, dentre as quais se destacam:<br />
          – elaborar lista tríplice a ser encaminhada ao Governador para escolha do Procurador do Estado Corregedor Geral;<br />
          – referendar a escolha do Procurador do Estado Chefe do Centro de Estudos e dos membros do Conselho Curador a que se refere o § 1º do artigo 49 desta lei complementar;<br />
          – decidir, pelo voto de 2/3 (dois terços) de seus membros, pela convocação de Procurador ou servidor da Procuradoria Geral do Estado para prestar esclarecimentos sobre sua atuação
          funcional;<br />
          – deliberar, mediante proposta do Procurador Geral, sobre a abertura de concurso de remoção;<br />
          – organizar e dirigir os concursos de ingresso e de promoção na carreira de Procurador do Estado e realizar o concurso de remoção, processando e julgando reclamações e recursos a eles
          pertinentes;<br />
          – fixar os critérios de merecimento para fins de promoção;<br />
          – elaborar lista de classificação do concurso de ingresso para homologação pelo Procurador Geral e publicação;<br />
          – convocar os Procuradores do Estado empossados para a escolha de vagas, de acordo com a ordem de classificação no concurso de ingresso;<br />
          – decidir sobre a confirmação na carreira de Procurador do Estado, nos termos do artigo 91 desta lei complementar;<br />
          – deliberar sobre a remoção de oficio e a remoção compulsória;<br />
          – manifestar-se previamente e em caráter vinculante sobre pedidos de afastamento de integrantes da carreira e suas renovações anuais, ressalvados os casos previstos nesta lei
          complementar;<br />
          – determinar, sem prejuízo da competência do Procurador Geral e do Corregedor Geral, a instauração de sindicâncias e de processos administrativos disciplinares contra integrantes da carreira
          de Procurador do Estado;<br />
          – opinar sobre aplicação de penalidade disciplinar a Procurador do Estado, bem como nos recursos correspondentes;<br />
          – referendar proposta do Procurador Geral para criação de novas unidades, subunidades ou órgãos da Procuradoria Geral do Estado, bem como para alteração da sede ou dos limites territoriais
          das Procuradorias Regionais;<br />
          – referendar proposta do Procurador Geral para fixação ou alteração do número de Procuradores do Estado destinados a cada um dos órgãos de execução das Áreas do Contencioso e da Consultoria
          Geral;<br />
          – fixar, mediante proposta do Procurador Geral, os requisitos para a classificação em órgãos de execução da Procuradoria Geral do Estado, bem como para o desempenho de atribuições e funções
          de confiança previstas nesta lei complementar;<br />
          – deliberar sobre a criação e a fixação de vagas de estagiários;<br />
          – manifestar-se obrigatoriamente nas propostas de alteração de estrutura, organização e atribuições da Procuradoria Geral do Estado e regime jurídico dos Procuradores do Estado;<br />
          – opinar sobre a proposta de orçamento anual da Procuradoria Geral do Estado, na forma e nos prazos estabelecidos em seu regimento;<br />
          – fixar o número de Corregedores Auxiliares, observado o disposto nesta lei complementar;<br />
          – opinar sobre medidas propostas pela Corregedoria da Procuradoria Geral do Estado;<br />
          – pronunciar-se sobre qualquer matéria que lhe seja encaminhada pelo Procurador Geral;<br />
          – representar ao Procurador Geral sobre providências reclamadas pelo interesse público, concernentes à Procuradoria Geral do Estado;<br />
          – propor ao Procurador Geral a adoção de medidas concernentes ao aperfeiçoamento, estrutura e funcionamento da Instituição;<br />
          – tutelar as prerrogativas funcionais, desagravando Procurador do Estado ofendido no exercício de seu cargo e oficiando as autoridades competentes;<br />
          – examinar relatórios de correição e de levantamentos estatísticos elaborados pela Corregedoria Geral;<br />
          – decidir, pelo voto de 2/3 (dois terços) de seus membros, pelo encaminhamento ao Governador de proposta do Procurador Geral visando à destituição do Corregedor Geral em caso de abuso de
          poder, conduta incompatível ou grave omissão dos deveres do cargo, assegurada ampla defesa;<br />
          – manifestar-se sobre proposta de contratação de jurista, formulada pelo Procurador Geral, nos termos do artigo 3º, § 1º, desta lei complementar;<br />
          – elaborar seu regimento interno.
        </p>
        <p>Além disso, o Conselho é o centro das discussões da Carreira, funcionando como fórum de debate dos anseios dos Procuradores do Estado.</p>
        <hr />
      </div>
    </div>
  </section>
</main>
