<app-separador label="Atendimento"></app-separador>
<p>**Antes de remeter sua consulta, verifique se você pode encontrar as respostas que procura no Portal da Transparência do Estado de São Paulo. É simples e fácil: **www.transparencia.sp.gov.br</p>
<b>ATENDIMENTO ELETRÔNICO</b>
<p>Utilize o Fala SP para enviar uma mensagem à Ouvidoria da PGE.</p>
<p><a href="https://fala.sp.gov.br" target="_blank">Registre sua manifestação</a></p>
<b>ATENDIMENTO TELEFÔNICO</b>
<p>De segunda a sexta-feira, das 9h às 12h e das 13h até às 17h.</p>
<p>Telefone: (11) 3372-6405/6406.</p>
<b>ATENDIMENTO PESSOAL</b>
<p>
  Rua Pamplona, 227, 1º. Andar, Bela Vista, São Paulo – SP, CEP: 01405-902. O atendimento pessoal é realizado de segunda à sexta-feira, das 10 às 12h e das 14 às 16h, mediante agendamento pelo
  telefone (11) 3372-6405.
</p>
<b>CARTAS PARA A OUVIDORIA</b>
<p>Rua Pamplona, 227, 1º. Andar, Bela Vista, São Paulo – SP, CEP: 01405-902.</p>
