import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { UsuarioLogin } from 'src/app/shared/models/usuario-login.interface';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { SenhaService } from '../shared/services/senha.service';
import { MatDivider } from '@angular/material/divider';
import { MatButton } from '@angular/material/button';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { FormsModule } from '@angular/forms';
import { FundoAreaRestritaPgeComponent } from '../shared/components/fundo-area-restrita-pge/fundo-area-restrita-pge.component';

@Component({
  selector: 'app-esqueci-senha-card',
  templateUrl: './esqueci-senha-card.component.html',
  styleUrl: './esqueci-senha-card.component.sass',
  standalone: true,
  imports: [FundoAreaRestritaPgeComponent, FormsModule, MatProgressSpinner, MatButton, MatDivider]
})
export class EsqueciSenhaCardComponent {
  private router = inject(Router);
  isError = false;
  email = '';
  enviado?: boolean = false;
  constructor(
    private readonly senhaService: SenhaService,
    private toastr: ToastService
  ) {
    this.senhaService.user$.subscribe({
      next: (user: UsuarioLogin) => {
        console.log(user);
        this.setEmail(user.email);
        this.email = user.email;
        localStorage.setItem('idUsuario', user.idUsuario.toString());
      }
    });
  }

  confirmaEsqueciSenha() {
    this.enviado = true;
    this.senhaService.confirmarEsqueciSenha().subscribe({
      next: () => {
        this.voltarParaLogin();
        this.enviado = false;
        this.toastr.success('Sucesso ao enviar email para troca de senha!');
      },
      error: (error) => {
        console.log(error);
        this.toastr.error('Falha no envio de email, tente novamente mais tarde!');
        this.voltarParaLogin();
        this.enviado = false;
      }
    });
  }

  setEmail(email: string) {
    if (!email) {
      this.voltarParaLogin();
      return;
    }
    this.email = email;
  }

  voltarParaLogin() {
    this.router.navigate(['/login']);
  }
}
