import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subject, takeUntil } from 'rxjs';
import { RelatorioAuxilioSaude } from 'src/app/area-restrita/shared/models/report-auxilio-saude';
import { DynamicTableService } from 'src/app/area-restrita/shared/services/dynamic-table.service';
import { ReportService } from 'src/app/area-restrita/shared/services/report.service';
import { RelatorioAuxilioSaudeService } from '../services/relatorio-auxilio-saude.service';
import { PedidoAuxilioSaude } from './auxilio-saude';

@Injectable({
  providedIn: 'root'
})
export class FabricaRelatorioPedidoAuxilio {
  private readonly destroy$ = new Subject<void>();
  private titulo: string;
  private rows: string[][];
  private tipo: string;
  private tituloColunas: string[];
  private totalElements: number;
  private pageSize: number;
  private pageNumber: number;

  constructor(
    private relatorioAuxilioSaudeService: RelatorioAuxilioSaudeService,
    private reportService: ReportService,
    private sanitizer: DomSanitizer,
    private dynamicTableService: DynamicTableService
  ) {}

  async criarRelatorio(dadosFormulario: FormGroup, pageNumber: number, pageSize: number) {
    const dadosRelatorio = await this.obterDadosRelatorio(dadosFormulario, pageNumber, pageSize);

    this.titulo = 'Relatório Pedidos Realizados';
    this.totalElements = dadosRelatorio.totalElements;
    this.pageSize = dadosRelatorio.number;
    this.pageNumber = dadosRelatorio.size;
    this.rows = this.carregarLinhas(dadosRelatorio.content);
    this.tipo = 'pdf/csv';
    this.tituloColunas = ['Nome', 'CPF', 'Ano de Referência', 'Mês/Ano de vencimento da parcela', 'Valor Total do Reembolso', 'Status', 'Data da Avaliação'];
  }

  private async obterDadosRelatorio(dadosFormulario: FormGroup, pageNumber: number, pageSize: number) {
    return new Promise<RelatorioAuxilioSaude>((resolve, reject) => {
      this.relatorioAuxilioSaudeService
        .RelatorioPesquisaPedidosAuxilio(dadosFormulario, pageNumber, pageSize)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (dados) => {
            resolve(dados);
          },
          error: (error) => reject(new Error('Erro ao obter dados do relatorio: ', error))
        });
    });
  }

  private carregarLinhas(pedidos: PedidoAuxilioSaude[]) {
    const rows: string[][] = [];
    for (const pedido of pedidos) {
      const row = [];
      row.push(pedido.usuario.nome);
      row.push(this.reportService.formatCPF(pedido.usuario.cpf));
      row.push(pedido.anoReembolso.toString());
      row.push(this.formatarData(pedido.dataVencimentoParcela));
      row.push(this.formatarValorMonetario(pedido.valorReembolsoTotal));
      row.push(pedido.status.nome);
      row.push(pedido.dataAprovacao ?? '');
      rows.push(row);
    }

    return rows;
  }

  private formatarData(data: string): string {
    const dateParts = data.split('/');
    const year = parseInt(dateParts[2]);
    const month = parseInt(dateParts[1]);
    const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    return `${months[month - 1]}/${year}`;
  }

  private formatarValorMonetario(value: number): string {
    if (value === null || value === undefined) {
      return '';
    }

    // Converte o valor para número, se for uma string válida
    const valorNumerico = typeof value === 'string' ? parseFloat(value) : value;

    if (isNaN(valorNumerico)) {
      return '';
    }

    // Formata o número para o formato de moeda R$ 0,00
    return (
      'R$ ' +
      valorNumerico
        .toFixed(2)
        .replace('.', ',')
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    );
  }

  public getTabela(): SafeHtml {
    const resultH = this.dynamicTableService.createSearchHTML(this.tituloColunas, this.rows);
    return this.sanitizer.bypassSecurityTrustHtml(resultH);
  }

  public getRows(): string[][] {
    return this.rows;
  }

  public getTipo(): string {
    return this.tipo;
  }

  public getTitulo(): string {
    return this.titulo;
  }

  public getColunas(): string[] {
    return this.tituloColunas;
  }

  public getTotalElementos(): number {
    return this.totalElements;
  }

  public getPageSize(): number {
    return this.pageNumber;
  }

  public getPageNumber(): number {
    return this.pageSize;
  }
}
