<main class="main" role="main">
  <section class="page">
    <div class="container">
      <app-separador label="Procuradoria Regional de São José do Rio Preto (PR08)"></app-separador>
      <div class="row">
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 the_content">
          <br />
          <h3>PR08 CHEFIA GABINETE</h3>
          <div class="pge-unidade">
            <p class="procurador"><strong>Procurador(a)</strong>: {{ findInArray("OSVALDIR FRANCISCO CAETANO CASTRO") }}</p>
            <p class="endereco"><strong>Endereço</strong>: Rua Siqueira Campos,3105, 1a sl, Centro, São José do Rio Preto-SP, 15010-040.</p>
            <p class="tel"><strong>Telefone</strong>: (17) 2138-8221</p>
            <p class="email"><strong>E-mail</strong>: pr8atendimento&#64;sp.gov.br</p>
            <div class="descricao">
              <strong>Municípios abrangidos:</strong> Adolfo, Altair, Álvares Florence, Américo de Campos, Aparecida D'Oeste, Ariranha, Aspásia, Bady Bassit, Bálsamo, Cajobi, Cardoso, Catanduva,
              Catiguá, Cedral, Cosmorama, Dirce Reis, Dolcinópolis, Elisiário , Embaúba, Estrela D'Oeste, Fernandópolis, Guapiaçu , Guaraci, Guarani D'Oeste, Ibirá, Icém , Indiaporã , Ipiguá, Irapuã,
              Itajobi, Jaci, Jales, José Bonifácio, Macaubal, Macedônia, Marapoama, Marinópolis , Mendonça, Meridiano, Mesópolis, Mira Estrela, Mirassol, Mirassolândia, Monções, Monte Aprazível, Neves
              Paulista, Nhandeara, Nipoã, Nova Aliança, Nova Canaã Paulista, Nova Granada, Novais, Novo Horizonte, Olímpia, Onda Verde, Orindiúva , Ouroeste, Palestina, Palmares Paulista, Palmeira
              D'Oeste , Paranapuã, Paraíso, Parisi, Paulo de Faria, Pedranópolis, Pindorama, Planalto, Poloni, Pontalinda , Pontes Gestal, Populina, Potirendaba, Riolândia, Rubinéia, Sales, Santa
              Adélia, Santa Albertina, Santa Clara D'Oeste, Santa Fé do Sul, Santa Rita D'Oeste, Santa Salete, Santana da Ponte Pensa, São Francisco, São João das Duas Pontes, São José do Rio Preto,
              Sebastianópolis do Sul, Severínia, Tabapuã , Tanabi, Três Fronteiras, Turmalina, Ubarana , Uchoa, União Paulista, Urânia, Urupês, Valentim Gentil, Vitória Brasil, Votuporanga e Zacarias.
            </div>
          </div>
          <br />
          <hr />
          <br />
          <app-voltar-component></app-voltar-component>
          <br />
        </div>
      </div>
    </div>
  </section>
</main>
