import { Component } from '@angular/core';
import { SeparadorComponent } from '../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-divida-ativa',
  templateUrl: './divida-ativa.component.html',
  standalone: true,
  imports: [SeparadorComponent]
})
export class DividaAtivaComponent {}
