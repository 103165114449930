<ng-container>
  @if (item.url === "S" && !item.router.includes("http")) {
    <a mat-list-item [routerLink]="changeRoute(item.router)" (click)="logout(item.router); sobreItemClicado()">
      <mat-icon matListItemIcon>{{ item.materialIcon }}</mat-icon>
      @if (isMenuOpen || isShowing) {
        <span matListItemLine>{{ item.name }}</span>
      }
    </a>
  }

  @if (item.url === "S" && item.router.includes("http")) {
    <a mat-list-item [href]="" (click)="abrirNovaAba(changeRoute(item.router)[0]); logout(item.router); sobreItemClicado()">
      <mat-icon matListItemIcon>{{ item.materialIcon }}</mat-icon>
      @if (isMenuOpen || isShowing) {
        <span matListItemLine>{{ item.name }}</span>
      }
    </a>
  }
  @if (item.url === "N") {
    <mat-expansion-panel class="mat-elevation-z0" hideToggle [expanded]="isVisible(item)">
      <mat-expansion-panel-header (click)="toggleVisibility(item)">
        <a mat-list-item>
          <mat-icon matListItemIcon>{{ isVisible(item) ? "keyboard_arrow_down" : "keyboard_arrow_right" }}</mat-icon>
          @if (isMenuOpen || isShowing) {
            <span matListItemLine>{{ item.name }}</span>
          }
        </a>
      </mat-expansion-panel-header>
      <mat-nav-list style="margin-left: 10px">
        @for (subItem of item.children; track subItem) {
          <app-menu-item [item]="subItem" [isMenuOpen]="isMenuOpen" [isShowing]="isShowing" />
        }
      </mat-nav-list>
    </mat-expansion-panel>
  }
</ng-container>
