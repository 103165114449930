<div class="container">
  <br />
  <h1 class="card-title titulo">Limite Mensal</h1>
  <br />
  <div class="row g-3">
    <form [formGroup]="dadosFormulario" (ngSubmit)="changeLimite()">
      <div class="d-flex row">
        <!-- Seleção do Tipo de Perfil -->
        <div class="col-md-3">
          <label for="perfil" class="form-label" id="perfil">Tipo de Perfil:</label>
          <mat-form-field>
            <mat-select class="mat-form-control" formControlName="perfil" (selectionChange)="obterLimiteHistorico()" (keyup.enter)="changeLimite()">
              @for (s of perfil; track s) {
                <mat-option [value]="s.id">
                  {{ s.nome }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="d-flex row" style="margin-top: 15px">
        <!-- Novo Valor -->
        <div class="col-md-3">
          <label for="valorNovo" class="form-label">Novo Valor:</label>
          <mat-form-field>
            <input
              matInput
              currencyMask
              type="text"
              id="valorNovo"
              name="valorNovo"
              formControlName="valorNovo"
              placeholder="R$ 0,00"
              [min]="0"
              [options]="{
                align: 'left',
                allowNegative: false,
                allowZero: false,
                decimal: ',',
                precision: 2,
                prefix: 'R$ ',
                thousands: '.',
                nullable: true
              }"
            />
          </mat-form-field>
        </div>

        <!-- Data de Início do Novo Valor -->
        <div class="col-md-3">
          <label for="dataInicio" class="form-label">Data de Início:</label>
          <mat-form-field>
            <input matInput formControlName="dataInicio" />
          </mat-form-field>
        </div>
      </div>
      <div class="d-flex row" style="margin-top: 15px">
        <!-- Valor Atual (Bloqueado)  -->
        <div class="col-md-3">
          <label for="valorAtual" class="form-label">Valor Atual:</label>
          <mat-form-field>
            <input
              matInput
              currencyMask
              type="text"
              id="valorAtual"
              name="valorAtual"
              formControlName="valorAtual"
              placeholder="R$ 0,00"
              [min]="0"
              [options]="{
                align: 'left',
                allowNegative: false,
                allowZero: false,
                decimal: ',',
                precision: 2,
                prefix: 'R$ ',
                thousands: '.',
                nullable: true
              }"
            />
          </mat-form-field>
        </div>

        <!-- Data de Expiração (Bloqueada) -->
        <div class="col-md-3">
          <label for="dataExpiracao" class="form-label">Data de Expiração:</label>
          <mat-form-field>
            <input matInput formControlName="dataExpiracao" />
          </mat-form-field>
        </div>
      </div>

      <!-- Botão de Envio -->
      <div class="d-flex row">
        <div class="col-12 conjunto-botoes">
          <button mat-flat-button color="primary" type="submit" id="buscar" name="buscar" class="btn-dark" [disabled]="!dadosFormulario.valid">Salvar</button>
        </div>
      </div>
    </form>

    <div class="table-responsive">
      <table class="tabelaPadrao">
        <thead>
          <tr>
            <th class="fw-bold text-center">Responsável</th>
            <th class="fw-bold text-center">Perfil</th>
            <th class="fw-bold text-center">Valor</th>
            <th class="fw-bold text-center">Data de Expiração</th>
          </tr>
        </thead>
        <tbody>
          @for (limite of trocasRecentes; track limite; let i = $index) {
            <tr>
              <td class="text-center">{{ limite.usuario.nome }}</td>
              <td class="text-center">{{ limite.perfil.nome }}</td>
              <td class="text-center">{{ limite.valor | currency }}</td>
              <td class="text-center">{{ limite.dataExpiracao || "Valor padrão" }}</td>
            </tr>
          }
        </tbody>
      </table>
      <!-- Aviso quando a tabela estiver vazia -->
      @if (!trocasRecentes || trocasRecentes.length === 0) {
        <div class="center-text">
          <p>Nenhum registro encontrado.</p>
        </div>
      }

      @if (trocasRecentes.length === 5 && trocasRecentes[4].dataExpiracao !== null) {
        <div class="alert-message">
          <span class="alert-icon">*</span>
          <p>Serão exibidas somente as últimas 5 alterações de saldo.</p>
        </div>
      }
    </div>
  </div>
</div>
