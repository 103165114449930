import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-separador',
  templateUrl: './separador.component.html',
  styleUrl: './separador.component.sass',
  standalone: true
})
export class SeparadorComponent {
  @Input() label: string;
}
