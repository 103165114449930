@if (menuAtual === "INSTITUCIONAL") {
  <div>
    <app-separador label="INSTITUCIONAL"></app-separador>
    <!-- Item 1 - Sobre a PGE -->
    <div class="accordion-menu">
      <div class="accordion-title" (click)="toggle(0)" (keypress)="toggle(0)" tabindex="0">
        <mat-icon class="red-icon">{{ Institucional[0].isOpen ? "expand_less" : "expand_more" }}</mat-icon>
        {{ Institucional[0].title }}
      </div>
      @if (Institucional[0].isOpen) {
        <div class="accordion-content">
          <app-quem-somos-pge></app-quem-somos-pge>
        </div>
      }
    </div>
    <!-- Item 2 - Gabinete -->
    <div class="accordion-menu">
      <div class="accordion-title" (click)="toggle(1)" (keypress)="toggle(1)" tabindex="1">
        <mat-icon class="red-icon">{{ Institucional[1].isOpen ? "expand_less" : "expand_more" }}</mat-icon>
        {{ Institucional[1].title }}
      </div>
      @if (Institucional[1].isOpen) {
        <div class="accordion-content">
          <div class="accordion-menu">
            <div class="accordion-title" (click)="toggle(1, 0)" (keypress)="toggle(1, 0)" tabindex="2">
              <mat-icon class="red-icon">{{ Institucional[1].children[0].isOpen ? "expand_less" : "expand_more" }}</mat-icon>
              {{ Institucional[1].children[0].title }}
            </div>
            @if (Institucional[1].children[0].isOpen) {
              <div class="accordion-content">
                <app-composicao-gab></app-composicao-gab>
              </div>
            }
          </div>
          <div class="accordion-menu">
            <div class="accordion-title" (click)="toggle(1, 1)" (keypress)="toggle(1, 1)" tabindex="3">
              <mat-icon class="red-icon">{{ Institucional[1].children[1].isOpen ? "expand_less" : "expand_more" }}</mat-icon>
              {{ Institucional[1].children[1].title }}
            </div>
            @if (Institucional[1].children[1].isOpen) {
              <div class="accordion-content">
                <app-assessorias-gab></app-assessorias-gab>
              </div>
            }
          </div>
        </div>
      }
    </div>
    <!-- Item 3 - Histórico -->
    <div class="accordion-menu">
      <div class="accordion-title" (click)="toggle(2)" (keypress)="toggle(2)" tabindex="4">
        <mat-icon class="red-icon">{{ Institucional[2].isOpen ? "expand_less" : "expand_more" }}</mat-icon>
        {{ Institucional[2].title }}
      </div>
      @if (Institucional[2].isOpen) {
        <div class="accordion-content">
          <app-historico></app-historico>
        </div>
      }
    </div>
    <!-- Item 4 - Ex-Procuradores Gerais -->
    <div class="accordion-menu">
      <div class="accordion-title" (click)="toggle(3)" (keypress)="toggle(3)" tabindex="5">
        <mat-icon class="red-icon">{{ Institucional[3].isOpen ? "expand_less" : "expand_more" }}</mat-icon>
        {{ Institucional[3].title }}
      </div>
      @if (Institucional[3].isOpen) {
        <div class="accordion-content">
          <app-ex-procuradores-gerais></app-ex-procuradores-gerais>
        </div>
      }
    </div>
    <!-- Item 5 - Organograma -->
    <div class="accordion-menu">
      <p class="accordion-title" (click)="toggle(4)" (keypress)="toggle(4)" tabindex="6">
        <mat-icon class="red-icon">{{ Institucional[4].isOpen ? "expand_less" : "expand_more" }}</mat-icon>
        {{ Institucional[4].title }}
      </p>
      @if (Institucional[4].isOpen) {
        <div class="accordion-content">
          <app-separador label="ORGANOGRAMA"></app-separador>
          <br />
          <a href="./assets/pdf/organograma_pge_2020.pdf" target="_blank">Organograma</a>
        </div>
      }
    </div>
    <!-- Item 6 - Lei Orgânica -->
    <div class="accordion-menu">
      <div class="accordion-title" (click)="toggle(5)" (keypress)="toggle(5)" tabindex="7">
        <mat-icon class="red-icon">{{ Institucional[5].isOpen ? "expand_less" : "expand_more" }}</mat-icon>
        {{ Institucional[5].title }}
      </div>
      @if (Institucional[5].isOpen) {
        <div class="accordion-content">
          <app-separador label="LEI ORGÂNICA"></app-separador>
          <br />
          <a href="./assets/pdf/lei_organica_pge_revista_e_atualizao_lc_1082_08_1.pdf" target="_blank">Lei Orgânica</a>
        </div>
      }
    </div>
    <!-- Item 7 - Legislação -->
    <div class="accordion-menu">
      <div class="accordion-title" (click)="toggle(6)" (keypress)="toggle(6)" tabindex="8">
        <mat-icon class="red-icon">{{ Institucional[6].isOpen ? "expand_less" : "expand_more" }}</mat-icon>
        {{ Institucional[6].title }}
      </div>
      @if (Institucional[6].isOpen) {
        <div class="accordion-content">
          <app-separador label="LEGISLAÇÃO"></app-separador>
          <br />
          <a href="https://www.pge.sp.gov.br/editais/atosnormativos/index.aspx" target="_blank">Legislação</a>
          <!-- <br />
          <a routerLink="/institucional/legislacao-pesquisa">Legislação Novo</a> -->
        </div>
      }
    </div>
  </div>
}
@if (menuAtual === "AREASATUACAO") {
  <div>
    <app-separador label="ÁREAS DE ATUAÇÃO"></app-separador>
    <!-- Item 1 - Consultoria Geral -->
    <div class="accordion-menu">
      <div class="accordion-title" (click)="toggle(0)" (keypress)="toggle(0)" tabindex="0">
        <mat-icon class="red-icon">{{ AreaAtuacao[0].isOpen ? "expand_less" : "expand_more" }}</mat-icon>
        {{ AreaAtuacao[0].title }}
      </div>
      @if (AreaAtuacao[0].isOpen) {
        <div class="accordion-content">
          <!-- SubItem 1 - Subprocuradoria -->
          <div class="accordion-menu">
            <div class="accordion-title" (click)="toggle(0, 0)" (keypress)="toggle(0, 0)" tabindex="1">
              <mat-icon class="red-icon">{{ AreaAtuacao[0].children[0].isOpen ? "expand_less" : "expand_more" }}</mat-icon>
              {{ AreaAtuacao[0].children[0].title }}
            </div>
            @if (AreaAtuacao[0].children[0].isOpen) {
              <div class="accordion-content">
                <app-subprocuradoria-consultoria-geral></app-subprocuradoria-consultoria-geral>
              </div>
            }
          </div>
          <!-- SubItem 2 - Especializadas -->
          <div class="accordion-menu">
            <div class="accordion-title" (click)="toggle(0, 1)" (keypress)="toggle(0, 1)" tabindex="2">
              <mat-icon class="red-icon">{{ AreaAtuacao[0].children[1].isOpen ? "expand_less" : "expand_more" }}</mat-icon>
              {{ AreaAtuacao[0].children[1].title }}
            </div>
            @if (AreaAtuacao[0].children[1].isOpen) {
              <div class="accordion-content">
                <app-especializadas-consultoria-geral></app-especializadas-consultoria-geral>
              </div>
            }
          </div>
          <!-- SubItem 3 - Scretarias -->
          <div class="accordion-menu">
            <div class="accordion-title" (click)="toggle(0, 2)" (keypress)="toggle(0, 2)" tabindex="3">
              <mat-icon class="red-icon">{{ AreaAtuacao[0].children[2].isOpen ? "expand_less" : "expand_more" }}</mat-icon>
              {{ AreaAtuacao[0].children[2].title }}
            </div>
            @if (AreaAtuacao[0].children[2].isOpen) {
              <div class="accordion-content">
                <app-consultorias></app-consultorias>
              </div>
            }
          </div>
          <!-- SubItem 4 - Autarquias -->
          <div class="accordion-menu">
            <div class="accordion-title" (click)="toggle(0, 3)" (keypress)="toggle(0, 3)" tabindex="4">
              <mat-icon class="red-icon">{{ AreaAtuacao[0].children[3].isOpen ? "expand_less" : "expand_more" }}</mat-icon>
              {{ AreaAtuacao[0].children[3].title }}
            </div>
            @if (AreaAtuacao[0].children[3].isOpen) {
              <div class="accordion-content">
                <app-autarquias></app-autarquias>
              </div>
            }
          </div>
        </div>
      }
    </div>
    <!-- Item 2 - Contencioso Geral -->
    <div class="accordion-menu">
      <div class="accordion-title" (click)="toggle(1)" (keypress)="toggle(1)" tabindex="5">
        <mat-icon class="red-icon">{{ AreaAtuacao[1].isOpen ? "expand_less" : "expand_more" }}</mat-icon>
        {{ AreaAtuacao[1].title }}
      </div>
      @if (AreaAtuacao[1].isOpen) {
        <div class="accordion-content">
          <!-- SubItem 1 - Subprocuradoria -->
          <div class="accordion-menu">
            <div class="accordion-title" (click)="toggle(1, 0)" (keypress)="toggle(1, 0)" tabindex="6">
              <mat-icon class="red-icon">{{ AreaAtuacao[1].children[0].isOpen ? "expand_less" : "expand_more" }}</mat-icon>
              {{ AreaAtuacao[1].children[0].title }}
            </div>
            @if (AreaAtuacao[1].children[0].isOpen) {
              <div class="accordion-content">
                <app-subprocuradoria-contencioso-geral></app-subprocuradoria-contencioso-geral>
              </div>
            }
          </div>
          <!-- SubItem 2 - Unidades Especializadas -->
          <div class="accordion-menu">
            <div class="accordion-title" (click)="toggle(1, 1)" (keypress)="toggle(1, 1)" tabindex="7">
              <mat-icon class="red-icon">{{ AreaAtuacao[1].children[1].isOpen ? "expand_less" : "expand_more" }}</mat-icon>
              {{ AreaAtuacao[1].children[1].title }}
            </div>
            @if (AreaAtuacao[1].children[1].isOpen) {
              <div class="accordion-content">
                <app-especializadas-contencioso-geral></app-especializadas-contencioso-geral>
              </div>
            }
          </div>
          <!-- SubItem 3 - Regionais -->
          <div class="accordion-menu">
            <div class="accordion-title" (click)="toggle(1, 2)" (keypress)="toggle(1, 2)" tabindex="8">
              <mat-icon class="red-icon">{{ AreaAtuacao[1].children[2].isOpen ? "expand_less" : "expand_more" }}</mat-icon>
              {{ AreaAtuacao[1].children[2].title }}
            </div>
            @if (AreaAtuacao[1].children[2].isOpen) {
              <div class="accordion-content">
                <app-regionais-contencioso-geral></app-regionais-contencioso-geral>
              </div>
            }
          </div>
        </div>
      }
    </div>
    <!-- Item 3 - Tributário Fiscal -->
    <div class="accordion-menu">
      <div class="accordion-title" (click)="toggle(2)" (keypress)="toggle(2)" tabindex="9">
        <mat-icon class="red-icon">{{ AreaAtuacao[2].isOpen ? "expand_less" : "expand_more" }}</mat-icon>
        {{ AreaAtuacao[2].title }}
      </div>
      @if (AreaAtuacao[2].isOpen) {
        <div class="accordion-content">
          <!-- SubItem 1 - Subprocuradoria -->
          <div class="accordion-menu">
            <div class="accordion-title" (click)="toggle(2, 0)" (keypress)="toggle(2, 0)" tabindex="10">
              <mat-icon class="red-icon">{{ AreaAtuacao[2].children[0].isOpen ? "expand_less" : "expand_more" }}</mat-icon>
              {{ AreaAtuacao[2].children[0].title }}
            </div>
            @if (AreaAtuacao[2].children[0].isOpen) {
              <div class="accordion-content">
                <app-subprocuradoria-tributario-fiscal></app-subprocuradoria-tributario-fiscal>
              </div>
            }
          </div>
          <!-- SubItem 2 - Especializadas -->
          <div class="accordion-menu">
            <div class="accordion-title" (click)="toggle(2, 1)" (keypress)="toggle(2, 1)" tabindex="11">
              <mat-icon class="red-icon">{{ AreaAtuacao[2].children[1].isOpen ? "expand_less" : "expand_more" }}</mat-icon>
              {{ AreaAtuacao[2].children[1].title }}
            </div>
            @if (AreaAtuacao[2].children[1].isOpen) {
              <div class="accordion-content">
                <app-especializadas-tributario-fiscal></app-especializadas-tributario-fiscal>
              </div>
            }
          </div>
          <!-- SubItem 3 - Regionais -->
          <div class="accordion-menu">
            <div class="accordion-title" (click)="toggle(2, 2)" (keypress)="toggle(2, 2)" tabindex="12">
              <mat-icon class="red-icon">{{ AreaAtuacao[2].children[2].isOpen ? "expand_less" : "expand_more" }}</mat-icon>
              {{ AreaAtuacao[2].children[2].title }}
            </div>
            @if (AreaAtuacao[2].children[2].isOpen) {
              <div class="accordion-content">
                <app-regionais-tributario-fiscal></app-regionais-tributario-fiscal>
              </div>
            }
          </div>
        </div>
      }
    </div>
  </div>
}

@if (menuAtual === "CORREGEDORIA") {
  <div>
    <app-separador label="CORREGEDORIA"></app-separador>
    <!-- Item 1 - Apresentação de Composição-->
    <div class="accordion-menu">
      <div class="accordion-title" (click)="toggle(0)" (keypress)="toggle(0)" tabindex="0">
        <mat-icon class="red-icon">{{ Corregedoria[0].isOpen ? "expand_less" : "expand_more" }}</mat-icon>
        {{ Corregedoria[0].title }}
      </div>
      @if (Corregedoria[0].isOpen) {
        <div class="accordion-content">
          <app-apresentacao-composicao></app-apresentacao-composicao>
        </div>
      }
    </div>
    <!-- Item 2 - Ex-corregedores Gerais -->
    <div class="accordion-menu">
      <div class="accordion-title" (click)="toggle(1)" (keypress)="toggle(1)" tabindex="1">
        <mat-icon class="red-icon">{{ Corregedoria[1].isOpen ? "expand_less" : "expand_more" }}</mat-icon>
        {{ Corregedoria[1].title }}
      </div>
      @if (Corregedoria[1].isOpen) {
        <div class="accordion-content">
          <app-ex-corregedores-gerais-pge></app-ex-corregedores-gerais-pge>
        </div>
      }
    </div>
    <!-- Item 3 - Legislação -->
    <div class="accordion-menu">
      <div class="accordion-title" (click)="toggle(2)" (keypress)="toggle(2)" tabindex="2">
        <mat-icon class="red-icon">{{ Corregedoria[2].isOpen ? "expand_less" : "expand_more" }}</mat-icon>
        {{ Corregedoria[2].title }}
      </div>
      @if (Corregedoria[2].isOpen) {
        <div class="accordion-content">
          <app-legislacao-corregedoria></app-legislacao-corregedoria>
        </div>
      }
    </div>
  </div>
}

@if (menuAtual === "CONSELHO") {
  <div>
    <app-separador label="CONSELHO"></app-separador>
    <!-- Item 1 - Apresentação -->
    <div class="accordion-menu">
      <div class="accordion-title" (click)="toggle(0)" (keypress)="toggle(0)" tabindex="0">
        <mat-icon class="red-icon">{{ Conselho[0].isOpen ? "expand_less" : "expand_more" }}</mat-icon>
        {{ Conselho[0].title }}
      </div>
      @if (Conselho[0].isOpen) {
        <div class="accordion-content">
          <app-apresentacao></app-apresentacao>
        </div>
      }
    </div>
    <!-- Item 2 - Composição -->
    <div class="accordion-menu">
      <div class="accordion-title" (click)="toggle(1)" (keypress)="toggle(1)" tabindex="1">
        <mat-icon class="red-icon">{{ Conselho[1].isOpen ? "expand_less" : "expand_more" }}</mat-icon>
        {{ Conselho[1].title }}
      </div>
      @if (Conselho[1].isOpen) {
        <div class="accordion-content">
          <app-composicao-conselho></app-composicao-conselho>
        </div>
      }
    </div>
    <!-- Item 3 - Atribuições -->
    <div class="accordion-menu">
      <div class="accordion-title" (click)="toggle(2)" (keypress)="toggle(2)" tabindex="2">
        <mat-icon class="red-icon">{{ Conselho[2].isOpen ? "expand_less" : "expand_more" }}</mat-icon>
        {{ Conselho[2].title }}
      </div>
      @if (Conselho[2].isOpen) {
        <div class="accordion-content">
          <app-atribuicoes></app-atribuicoes>
        </div>
      }
    </div>
    <!-- Item 4 - Legislação -->
    <div class="accordion-menu">
      <div class="accordion-title" (click)="toggle(3)" (keypress)="toggle(3)" tabindex="3">
        <mat-icon class="red-icon">{{ Conselho[3].isOpen ? "expand_less" : "expand_more" }}</mat-icon>
        {{ Conselho[3].title }}
      </div>
      @if (Conselho[3].isOpen) {
        <div class="accordion-content">
          <app-legislacao-conselho></app-legislacao-conselho>
        </div>
      }
    </div>
  </div>
}

@if (menuAtual === "OUVIDORIA") {
  <div>
    <app-separador label="OUVIDORIA"></app-separador>
    <!-- Item 1 - Sobre a Ouvidoria -->
    <div class="accordion-menu">
      <div class="accordion-title" (click)="toggle(0)" (keypress)="toggle(0)" tabindex="0">
        <mat-icon class="red-icon">{{ Ouvidoria[0].isOpen ? "expand_less" : "expand_more" }}</mat-icon>
        {{ Ouvidoria[0].title }}
      </div>
      @if (Ouvidoria[0].isOpen) {
        <div class="accordion-content">
          <app-sobre-ouvidoria></app-sobre-ouvidoria>
        </div>
      }
    </div>
    <!-- Item 2 - Atendimento -->
    <div class="accordion-menu">
      <div class="accordion-title" (click)="toggle(1)" (keypress)="toggle(1)" tabindex="1">
        <mat-icon class="red-icon">{{ Ouvidoria[1].isOpen ? "expand_less" : "expand_more" }}</mat-icon>
        {{ Ouvidoria[1].title }}
      </div>
      @if (Ouvidoria[1].isOpen) {
        <div class="accordion-content">
          <app-atendimento-ouvidoria></app-atendimento-ouvidoria>
        </div>
      }
    </div>

    <!-- Item 3 - Ex-Ouvidorias -->
    <div class="accordion-menu">
      <div class="accordion-title" (click)="toggle(2)" (keypress)="toggle(2)" tabindex="2">
        <mat-icon class="red-icon">{{ Ouvidoria[2].isOpen ? "expand_less" : "expand_more" }}</mat-icon>
        {{ Ouvidoria[2].title }}
      </div>
      @if (Ouvidoria[2].isOpen) {
        <div class="accordion-content">
          <app-ex-ouvidoria></app-ex-ouvidoria>
        </div>
      }
    </div>

    <!-- Item 4 - Relatórios -->
    <div class="accordion-menu">
      <div class="accordion-title" (click)="toggle(3)" (keypress)="toggle(3)" tabindex="3">
        <mat-icon class="red-icon">{{ Ouvidoria[3].isOpen ? "expand_less" : "expand_more" }}</mat-icon>
        {{ Ouvidoria[3].title }}
      </div>
      @if (Ouvidoria[3].isOpen) {
        <div class="accordion-content">
          <app-relatorio-ouvidoria></app-relatorio-ouvidoria>
        </div>
      }
    </div>

    <!-- Item 5 - Informações classificadas e desclassificadas -->
    <div class="accordion-menu">
      <div class="accordion-title" (click)="toggle(4)" (keypress)="toggle(4)" tabindex="4">
        <mat-icon class="red-icon">{{ Ouvidoria[4].isOpen ? "expand_less" : "expand_more" }}</mat-icon>
        {{ Ouvidoria[4].title }}
      </div>
      @if (Ouvidoria[4].isOpen) {
        <div class="accordion-content">
          <app-inform-ouvidoria></app-inform-ouvidoria>
        </div>
      }
    </div>

    <!-- Item 6 - Programa de Integridade/PGE -->
    <div class="accordion-menu">
      <div class="accordion-title" (click)="toggle(5)" (keypress)="toggle(5)" tabindex="5">
        <mat-icon class="red-icon">{{ Ouvidoria[5].isOpen ? "expand_less" : "expand_more" }}</mat-icon>
        {{ Ouvidoria[5].title }}
      </div>
      @if (Ouvidoria[5].isOpen) {
        <div class="accordion-content">
          <app-programa-integridade-ouvidoria></app-programa-integridade-ouvidoria>
        </div>
      }
    </div>

    <!-- Item 7 - SIC -->
    <div class="accordion-menu">
      <div class="accordion-title" (click)="toggle(6)" (keypress)="toggle(6)" tabindex="6">
        <mat-icon class="red-icon">{{ Ouvidoria[6].isOpen ? "expand_less" : "expand_more" }}</mat-icon>
        {{ Ouvidoria[6].title }}
      </div>
      @if (Ouvidoria[6].isOpen) {
        <div class="accordion-content">
          <app-sic-ouvidoria></app-sic-ouvidoria>
        </div>
      }
    </div>
  </div>
}
