import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { catchError, map, Observable, throwError } from 'rxjs';
import { Legislacao, LegislacaoArquivo } from 'src/app/area-restrita/features/auxilio/saude/shared/models/legislacao';
import { TipoLegislacao } from 'src/app/area-restrita/features/auxilio/saude/shared/models/tipo-publicacao';
import { Globais } from 'src/app/area-restrita/shared/globais/globais';
import { Page } from 'src/app/shared/models/paginacao/page.model';

@Injectable({
  providedIn: 'root'
})
export class LegislacaoService {
  constructor(private http: HttpClient) {}

  buscar(dadosFormulario: FormGroup, pageIndex: string | number, pageSize: string | number): Observable<Page<Legislacao>> {
    const parametros = new HttpParams()
      .set('publicacao', dadosFormulario.controls['publicacao']?.value ?? '')
      .set('conteudo', dadosFormulario.controls['conteudo']?.value ?? '')
      .set('tipoLegislacao', dadosFormulario.controls['tipoLegislacao']?.value ?? '')
      .set('page', pageIndex.toString())
      .set('size', pageSize.toString());

    return this.http.get<Page<Legislacao>>(Globais.urlToken + 'site-pge/api/legislacao', { params: parametros }).pipe(
      map((response) => {
        console.log(response);
        return response;
      })
    );
  }

  obterListaTipoPublicacao(): Observable<TipoLegislacao[]> {
    return this.http.get<TipoLegislacao[]>(`${Globais.urlToken}site-pge/api/tipo-legislacao`).pipe(
      catchError((error) => {
        console.error('Erro durante a obtenção da lista de status:', error);
        return throwError('Falha na obtenção da lista de status.');
      })
    );
  }

  buscarPdf(id: number): Observable<LegislacaoArquivo> {
    const parametros = new HttpParams().set('id', id);
    return this.http.get<LegislacaoArquivo>(Globais.urlToken + 'site-pge/api/legislacao/pdf', { params: parametros }).pipe(
      map((response) => {
        console.log(response);
        return response;
      })
    );
  }
}
