<main class="main" role="main">
  <section class="page">
    <div class="container">
      <app-separador label="Procuradoria Regional de Marília (PR11)" />
      <div class="row">
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 the_content">
          <br />
          <h3>PR11 CHEFIA GABINETE</h3>
          <div class="pge-unidade">
            <p class="procurador"><strong>Procurador(a)</strong>: {{ findInArray("RICARDO PINHA ALONSO") }}</p>
            <p class="endereco"><strong>Endereço</strong>: Rua Bahia,201, C. Postal 288, Centro, Marília-SP, 17501-080.</p>
            <p class="tel"><strong>Telefone</strong>: (14) 3433-9699 | 9478 | (14) 3422-2491 | 8967</p>
            <p class="email"><strong>E-mail</strong>: pr11atendimento&#64;sp.gov.br</p>
            <div class="descricao">
              <strong>Municípios abrangidos:</strong> Álvaro de Carvalho, Alvinlândia, Arco-Íris, Assis, Bastos, Bernardino de Campos, Borá, Campos Novos Paulista, Cândido Mota, Canitar, Chavantes,
              Cruzália, Echaporã, Espírito Santo do Turvo, Fartura, Fernão, Florinéa, Galia, Garça, Herculândia, Iacri, Ibirarema, Ipauçu, João Ramalho, Lupércio, Lutécia, Manduri, Maracaí, Marília,
              Ocauçu, Óleo, Oriente, Oscar Bressane, Ourinhos, Palmital, Paraguaçu Paulista, Pedrinhas Paulista, Piraju, Platina, Pompéia, Quatá, Queiroz, Quintana, Ribeirão do Sul, Rinópolis, Salto
              Grande, Santa Cruz do Rio Pardo, São Pedro do Turvo, Sarutaiá, Taguaí, Taruma, Tejupá, Timburi, Tupã, Vera Cruz.
            </div>
          </div>
          <br />
          <hr />
          <br />
          <h3>SECCIONAL DE ASSIS</h3>
          <div class="pge-unidade">
            <p class="procurador"><strong>Procurador(a)</strong>: VLAMIR MENEGUINI</p>
            <p class="endereco"><strong>Endereço</strong>: R. Rui Barbosa, 2325, Assis, Assis-SP, 19816-000.</p>
            <p class="tel"><strong>Telefone</strong>: (18) 3322-7111</p>
          </div>
          <br />
          <hr />
          <br />
          <h3>SECCIONAL DE OURINHOS</h3>
          <div class="pge-unidade">
            <p class="procurador"><strong>Procurador(a)</strong>: LUIS GUSTAVO ANDRADE DOS SANTOS</p>
            <p class="endereco"><strong>Endereço</strong>: R. José Justino de Carvalho, 1171, , Jardim Matilde, Ourinhos-SP, 19901-560.</p>
            <p class="tel"><strong>Telefone</strong>: (14) 3322-1915</p>
          </div>
          <br />
          <hr />
          <br />
          <app-voltar-component />
          <br />
        </div>
      </div>
    </div>
  </section>
</main>
