<main class="main" role="main">
  <section class="page">
    <div class="container">
      <app-separador label="Procuradoria Regional de Bauru (PR07)"></app-separador>
      <div class="row">
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 the_content">
          <br />
          <h3>PR07 CHEFIA GABINETE</h3>
          <div class="pge-unidade">
            <p class="procurador"><strong>Procurador(a)</strong>: {{ findInArray("SILVIO CARLOS TELLI") }}</p>
            <p class="endereco"><strong>Endereço</strong>: Avenida Cruzeiro do Sul, 13-15, Jd. Carvalho, Bauru-SP, 17030-743.</p>
            <p class="tel"><strong>Telefone</strong>: (14)3878-4021/4022</p>
            <p class="email"><strong>E-mail</strong>: pr7atendimento&#64;sp.gov.br</p>
            <div class="descricao">
              <strong>Municípios abrangidos:</strong> Águas de Santa Bárbara, Agudos, Anhembi, Arealva, Areiópolis, Arandu, Avaí, Avaré, Balbinos, Barão de Antonina, Bariri, Barra Bonita, Bauru,
              Bocaina, Bofete, Boracéia, Borebi, Botucatu, Cabrália Paulista, Cafelândia, Cerqueira Cesar, Conchas, Coronel Macedo, Dois Córregos, Duartina, Getulina, Guaicará, Guaimbé, Guarantã,
              Iaras, Igaraçu do Tietê, Itaí, Itaju, Itaporanga, Itapuí, Itatinga, Jaú, Julio Mesquita, Laranjal Paulista, Lençóis Paulista, Lins, Lucianópolis, Macatuba, Mineiros do Tietê,
              Paranapanema, Pardinho, Pederneiras, Pereiras, Pirajuí, Piratininga, Porangaba, Pratânia, Presidente Alves, Promissão, Reginópolis, Riversul, Sabino, São Manuel, Taquarituba, Torre de
              Pedra, Ubirajara e Uru.
            </div>
          </div>
          <br />
          <hr />
          <br />
          <h3>SECCIONAL DE BOTUCATU</h3>
          <div class="pge-unidade">
            <p class="procurador"><strong>Procurador(a)</strong>: {{ findInArray("WASHINGTON LUIZ JANIS JUNIOR") }}</p>
            <p class="endereco"><strong>Endereço</strong>: Avenida Santana, 353, Centro, Botucatu-SP, 18603-700.</p>
            <p class="tel"><strong>Telefone</strong>: (14) 3882-5305, ramal 22 | (14) 3882-1519</p>
          </div>
          <br />
          <hr />
          <br />
          <h3>SECCIONAL DE AVARE</h3>
          <div class="pge-unidade">
            <p class="procurador"><strong>Procurador(a)</strong>:{{ findInArray("PAULO SERGIO GARCEZ GUIMARAES NOVAES") }}</p>
            <p class="endereco"><strong>Endereço</strong>: Rua Rio Grande do Norte, 674, Pinheiro Machado, Avaré-SP, 18700-630.</p>
            <p class="tel"><strong>Telefone</strong>: (14) 3733-7050</p>
          </div>
          <br />
          <hr />
          <br />
          <app-voltar-component></app-voltar-component>
          <br />
        </div>
      </div>
    </div>
  </section>
</main>
