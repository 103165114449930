import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/area-restrita/shared/services/authentication.service';
import { LocalStorageService } from 'src/app/area-restrita/shared/services/local-storage.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { LegadoUsuarioService } from '../../auxilio/shared/services/legado-usuario.service';
import { SenhaService, TrocaSenha } from '../shared/services/senha.service';
import { MatInput } from '@angular/material/input';
import { MatFormField } from '@angular/material/form-field';
import { MatDivider } from '@angular/material/divider';
import { MatButton } from '@angular/material/button';
import { FundoAreaRestritaPgeComponent } from '../shared/components/fundo-area-restrita-pge/fundo-area-restrita-pge.component';

@Component({
  selector: 'app-alterar-senha-card',
  templateUrl: './alterar-senha-card.component.html',
  styleUrl: './alterar-senha-card.component.sass',
  standalone: true,
  imports: [FundoAreaRestritaPgeComponent, FormsModule, ReactiveFormsModule, MatButton, MatDivider, MatFormField, MatInput]
})
export class AlterarSenhaCardComponent implements OnInit {
  areaRestrita: boolean;
  senhaExpirada = true;
  temError = false;
  mensagemError = '';

  changePasswordForm: FormGroup;
  changePasswordRestritaForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private senhaService: SenhaService,
    private toastr: ToastService,
    private localStorage: LocalStorageService,
    private rota: Router,
    private legadoUsuarioService: LegadoUsuarioService,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.changePasswordForm = this.fb.group({
      senhaAntiga: ['', Validators.required],
      senhaNova: ['', [Validators.required]],
      confirmaSenha: ['', Validators.required]
    });

    this.changePasswordRestritaForm = this.fb.group({
      senhaAntiga: ['', Validators.required],
      senhaNova: ['', [Validators.required]],
      confirmaSenha: ['', Validators.required]
    });

    this.areaRestrita = this.route.snapshot.params['isRestrito'] === 'true';
    this.route.queryParams.subscribe((params) => {
      this.areaRestrita = params['isRestrito'] === 'true'; // Converter para booleano
    });
  }

  changePassword(restrito: boolean): void {
    this.temError = false;
    const form = restrito ? this.changePasswordRestritaForm : this.changePasswordForm;

    if (form.invalid) {
      this.mensagemError = 'Todos os campos devem ser preenchidos';
      this.temError = true;
      return;
    }

    const { senhaAntiga, senhaNova, confirmaSenha } = form.value;

    if (senhaNova !== confirmaSenha) {
      this.mensagemError = 'Senha digitada não é igual a nova senha!';
      this.temError = true;
      return;
    }

    if (senhaAntiga === senhaNova) {
      this.mensagemError = 'Senha nova não pode ser igual a antiga';
      this.temError = true;
      return;
    }

    if (senhaNova.includes('&')) {
      this.mensagemError = 'A senha nova não pode conter o caracter "&"!';
      this.temError = true;
      return;
    }
    const config = new MatSnackBarConfig();
    config.duration = 5000;
    const trocaSenha: TrocaSenha = { senhaAntiga, senhaNova };
    this.senhaService.alterarSenha(trocaSenha).subscribe(
      () => {
        this.toastr.info('Senha Alterada com sucesso no sistema atual!', 'Aviso do Sistema', config);

        this.legadoUsuarioService.checkUsuarioLegado(this.localStorage.get('cpf')).subscribe(
          () => {
            this.toastr.info('Usuario encontrado no legado!', 'Aviso do Sistema', config);
            this.verificaLegado(trocaSenha.senhaNova);
            this.voltar();
            this.authenticationService.logout('/login');
          },
          (error: HttpErrorResponse) => {
            console.debug(error.message);
            this.toastr.info('Usuario não encontrado no legado, será levado para o novo sistema somente!', 'Aviso do Sistema', config);
            this.authenticationService.logout('/login');
          }
        );
      },
      () => {
        this.toastr.error('Falha ao trocar senha no sistema atual.', 'Aviso do Sistema', config);
      }
    );
  }

  verificaLegado(senhaNova: string): void {
    const cpfUsuario = this.localStorage.get('cpf');
    if (cpfUsuario) {
      this.legadoUsuarioService.enviarNovaSenhaParaLegado(cpfUsuario, senhaNova).subscribe(
        () => {
          this.toastr.info('Senha alterada com sucesso no Sistema Legado!', 'Ok');
        },
        (error: HttpErrorResponse) => {
          this.toastr.warning('A troca de senha não foi adiante! ' + error.error.detail, 'Atenção! Mensagem do Sistema Legado');
        }
      );
    } else {
      console.error('A troca de senha do legado não foi adiante!!!!');
    }
  }

  voltar(): void {
    this.authenticationService.logout();
  }
}
