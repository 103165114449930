<nav class="navbar navbar-expand-lg barra-brasao">
  <div class="container-brasao" tabindex="0" (click)="goToHome()" (keypress)="goToHome()">
    <img class="brasao" alt="Brasão PGE" src="images/brasao.svg" tabindex="0" (click)="goToHome()" (keypress)="goToHome()" />
    <div class="iconesNav justify-content-end">
      <mat-icon matTooltip="Aumentar a fonte" class="icones" (click)="fontIncrease()">text_increase</mat-icon>
      <mat-icon matTooltip="Diminuir a fonte" class="icones" (click)="fontDecrease()">text_decrease</mat-icon>
      <mat-icon matTooltip="Alterar o tema" class="icones" (click)="changeTheme()">contrast</mat-icon>
      <a href="comunicar-erro" matTooltip="Comunicar Erros" aria-label="Comunicar Erros" class="icones">
        <mat-icon class="warning_amber">warning_amber</mat-icon>
      </a>
    </div>
  </div>
</nav>
