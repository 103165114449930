<main class="main" role="main">
  <section class="page">
    <app-separador label="Especializadas" />
    <div class="row">
      <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 the_content">
        <br />
        <h3><strong>Procuradoria Administrativa</strong></h3>
        <p class="procurador"><strong>Procurador(a) Chefe</strong>:</p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ findInArray("JULIANA DE OLIVEIRA DUARTE FERREIRA") }}</p>
        </div>
        <p class="endereco"><strong>Endereço</strong>: Rua Pamplona,227, 4º andar, Bela Vista, São Paulo-SP, 01405-000.</p>
        <p class="tel"><strong>Telefone</strong>: 3286-4518 e Fax: 3286-4504</p>
        <p><strong>Demais Procuradores</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ findInArray("ADALBERTO ROBERT ALVES") }}</p>
          <p class="demais-procuradores">{{ findInArray("ADRIANA MASIERO REZENDE") }}</p>
          <p class="demais-procuradores">{{ findInArray("SUZANA SOO SUN LEE") }}</p>
        </div>
        <hr />
        <h3><strong>Procuradoria de Assuntos Tributários</strong></h3>
        <p class="procurador"><strong>Procurador(a) Chefe</strong>:</p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ findInArray("FERNANDA LUZIA FREIRE SERUR") }}</p>
        </div>
        <p class="endereco"><strong>Endereço</strong>: RUA PAMPLONA, 227, 17º ANDAR, JD. PAULISTA, SÃO PAULO-SP, 01405-902.</p>
        <p class="tel"><strong>Telefone</strong>: (11) 3372-6407/6408/6409</p>
        <p><strong>Demais Procuradores</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ findInArray("LUCIANA RITA LAURENZA SALDANHA GASPARINI") }}</p>
        </div>
        <br />
        <hr />
        <h3><strong>Procuradoria da Fazenda junto ao Tribunal de Contas</strong></h3>
        <p class="procurador"><strong>Procurador(a) Chefe</strong>:<br /></p>
        <p class="demais-procuradores">{{ findInArray("DENIS DELA VEDOVA GOMES") }}</p>
        <div class="pge-unidade"></div>
        <p class="endereco"><strong>Endereço</strong>: Avenida Rangel Pestana,315, 1º andar, Centro, São Paulo-SP, 01017-906.</p>
        <p class="tel"><strong>Telefone</strong>: (11) 3292-3242</p>
        <p><strong>Demais Procuradores</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ findInArray("CARIM JOSE FERES") }}</p>
          <p class="demais-procuradores">{{ findInArray("DEBORA SAMMARCO MILENA") }}</p>
          <p class="demais-procuradores">{{ findInArray("JOAO CARLOS PIETROPAOLO") }}</p>
          <p class="demais-procuradores">{{ findInArray("LUIZ MENEZES NETO") }}</p>
          <p class="demais-procuradores">{{ findInArray("PATRICIA ULSON PIZARRO WERNER") }}</p>
        </div>
        <hr />
        <h3><strong>Procuradoria da Junta Comercial</strong></h3>
        <p class="procurador"><strong>Procurador(a) Chefe</strong>:<br /></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ findInArray("CELSO JESUS MOGIONI") }}</p>
        </div>
        <p class="endereco"><strong>Endereço</strong>: Rua Barra Funda,930, 3º andar, Barra Funda, São Paulo-SP, 01152-000.</p>
        <p class="tel"><strong>Telefone</strong>: (11)3826-7661</p>
        <p><strong>Demais Procuradores</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ findInArray("GEORGE IBRAHIM FARATH") }}</p>
          <p class="demais-procuradores">{{ findInArray("JEAN JACQUES ERENBERG") }}</p>
          <p class="demais-procuradores">{{ findInArray("JORGE GOMES DA CRUZ") }}</p>
          <p class="demais-procuradores">{{ findInArray("MARILDA WATANABE") }}</p>
        </div>
        <hr />
        <h3><strong>Procuradoria de Procedimentos Disciplinares</strong></h3>
        <p class="procurador"><strong>Procurador respondendo pela Chefia</strong><br /></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ findInArray("INACIO DE LOIOLA MANTOVANI FRATINI") }}</p>
        </div>
        <p class="endereco"><strong>Endereço</strong>: Rua Maria Paula, 172, , Centro, São Paulo-SP, 01319-000.</p>
        <p class="tel"><strong>Telefone</strong>: (11) 3291-7100 ou (11) 3291-7103</p>
        <p>
          <strong>Assistentes<br /></strong>
        </p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ findInArray("KRISTINA YASSUKO IHA KIAN WANDALSEN") }}</p>
        </div>
        <p><strong>Demais Procuradores</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ findInArray("ALBERTO CUENCA SABIN CASAL") }}</p>
          <p class="demais-procuradores">{{ findInArray("GERALDO HORIKAWA") }}</p>
          <p class="demais-procuradores">{{ findInArray("JOSE ALEXANDRE CUNHA CAMPOS") }}</p>
          <p class="demais-procuradores">{{ findInArray("JOSE CARLOS CABRAL GRANADO") }}</p>
          <p class="demais-procuradores">{{ findInArray("JOSE ROBERTO GRASSI") }}</p>
          <p class="demais-procuradores">{{ findInArray("KATIA GOMES SALES") }}</p>
          <p class="demais-procuradores">{{ findInArray("MARCELA GONCALVES GODOI") }}</p>
          <p class="demais-procuradores">{{ findInArray("MARGARETE GONCALVES PEDROSO") }}</p>
          <p class="demais-procuradores">{{ findInArray("MARIANA ROSADA PANTANO") }}</p>
          <p class="demais-procuradores">{{ findInArray("MONICA MAYUMI EGUCHI") }}</p>
          <p class="demais-procuradores">{{ findInArray("RENE ZAMLUTTI JUNIOR") }}</p>
          <p class="demais-procuradores">{{ findInArray("RICARDO KENDY YOSHINAGA") }}</p>
          <p class="demais-procuradores">{{ findInArray("RITA DE CASSIA GIMENES ARCAS") }}</p>
          <p class="demais-procuradores">{{ findInArray("VERA LUCIA DE SOUZA") }}</p>
        </div>
        <p>&nbsp;</p>
      </div>
    </div>
  </section>
</main>
