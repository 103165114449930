<app-fundo-area-restrita-pge>
  <form class="login-form card-style">
    @if (enviado) {
      <div class="loader">
        <mat-spinner />
      </div>
    }
    <h1 class="title">Esqueci a senha</h1>
    <div class="input-label">
      <label class="label-align" for="cpf">A nova senha será enviada para o email abaixo:</label>
      <p>{{ email }}</p>
    </div>
    <div class="">
      <button mat-raised-button color="primary" class="login-button" type="submit" [disabled]="enviado" (click)="confirmaEsqueciSenha()">Enviar</button>
      <button mat-raised-button color="primary" class="login-button" type="submit" (click)="voltarParaLogin()">Voltar</button>
    </div>
  </form>
  <mat-divider />
  <span class="help-message">Caso tenha algum problema em efetuar a alteração da senha, entre em contato com o administrador do sistema.</span>
</app-fundo-area-restrita-pge>
