import { NativeDateAdapter } from '@angular/material/core';

export class CustomDateAdapter extends NativeDateAdapter {
  override format(date: Date, displayFormat: object): string {
    console.log('display format?', displayFormat);
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }
}

export function dateFormat(data: Date | string) {
  console.log('data', data);
  return new Date(data).toLocaleDateString();
}
