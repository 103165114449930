import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CategoriaNoticia } from 'src/app/area-aberta/shared/enums/categoria-noticia';
import { Noticias } from 'src/app/shared/models/noticia.interface';
import { Page } from 'src/app/shared/models/paginacao/page.model';
import { NoticiaService } from 'src/app/shared/services/noticia.service';
import { ImagemTratamento } from './../../shared/services/imagem-tratamento';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-noticias-destaque',
  templateUrl: './noticias-destaque.component.html',
  styleUrl: './noticias-destaque.component.sass',
  providers: [ImagemTratamento],
  standalone: true,
  imports: [MatButton],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoticiasDestaqueComponent implements OnInit, OnDestroy {
  noticiasDestaque: Noticias[] = [];

  constructor(
    private noticiaService: NoticiaService,
    private router: Router,
    private imagemTratamento: ImagemTratamento,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.carregarNoticia();
  }

  carregarNoticia(): void {
    this.noticiaService.getNoticia(0, 6, CategoriaNoticia.NOTICIA_AREA_ABERTA).subscribe(
      (response: Page<Noticias>) => {
        const noticia = response.content;
        noticia.forEach((element) => {
          this.noticiasDestaque.push(element);
        });
        this.cdr.markForCheck();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getImagem(noticiaImagem: string): string | undefined {
    return this.imagemTratamento.getImagemBlob(noticiaImagem);
  }

  abrirUmaNoticia(noticiaId: number) {
    this.router.navigate(['/uma-noticia', noticiaId]);
  }

  noticiasDiversas(): void {
    this.router.navigate(['/noticias-diversas']);
  }

  ngOnDestroy(): void {
    this.imagemTratamento.revogarUrlBlob();
  }
}
