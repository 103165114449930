import { Component } from '@angular/core';
import { MapaSiteComponent } from './mapa-site/mapa-site.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.sass',
  standalone: true,
  imports: [MapaSiteComponent]
})
export class FooterComponent {}
