<main class="main" role="main">
  <section class="page">
    <div class="container">
      <app-separador label="Ex-Corregedores Gerais da PGE"></app-separador>
      <br />
      <h3>A partir da LC 478/86</h3>
      <br />
      <div class="row">
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 the_content">
          <p class="name"><strong>ANSELMO PRIETO ALVAREZ</strong></p>
          <p class="dates">Nomeação em 08/10/2021<br />Termino do Mandato em 09/10/2023</p>
          <p class="name"><strong>ADALBERTO ROBERT ALVES</strong></p>
          <p class="dates">Nomeação em 27/09/2017<br />Termino do Mandato em 07/10/2021</p>
          <p class="name"><strong>SERGIO SEIJI ITIKAWA</strong></p>
          <p class="dates">Nomeação em 26/09/2015<br />Termino do Mandato em 27/09/2017</p>
          <p class="name"><strong>JOSÉ LUIZ BORGES DE QUEIROZ</strong></p>
          <p class="dates">Nomeação em 27/02/2010<br />Exoneração em 28/08/2015</p>
          <p class="name"><strong>NILSON BERENCHTEIN JUNIOR</strong></p>
          <p class="dates">Nomeação em 07/12/2007<br />Exoneração em 12/12/2009</p>
          <p class="name"><strong>MAURICIO KAORU AMAGASA</strong></p>
          <p class="dates">Nomeação em 15/02/2007<br />Exoneração em 04/08/2007</p>
          <p class="name"><strong> SÉRGIO D’AMICO</strong></p>
          <p class="dates">Nomeação em 23/06/2005<br />Exoneração em 26/01/2007</p>
          <p class="name"><strong>DIONÍSIO STUCCHI JUNIOR</strong></p>
          <p class="dates">Nomeação em 03/05/2001<br />Exoneração em 24/06/2005</p>
          <p class="name"><strong>MARCOS FÁBIO DE OLIVEIRA NUSDEO</strong></p>
          <p class="dates">Nomeação em 07/04/1999<br />Exoneração em 03/05/2001</p>
          <p class="name"><strong>PAULO FRANCISCO BASTOS VON BRUCK LACERDA</strong></p>
          <p class="dates">Nomeação em 03/02/1995<br />Exoneração em 24/10/1998</p>
          <p class="name"><strong>CÉLIA MARISA PRENDES</strong></p>
          <p class="dates">Nomeação em 30/12/1992<br />Exoneração em 02/02/1995</p>
          <p class="name"><strong>SILVIO FRANCISCO ANTUNES</strong></p>
          <p class="dates">Nomeação em 10/04/1987<br />Falecimento em 28/06/1992</p>
          <p class="name"><strong>FRANCISCO GASTÃO LUPPI DE CASTRO</strong></p>
          <p class="dates">Nomeação em 27/09/1986<br />Exoneração em 16/02/1987</p>
          <hr />
          <pre></pre>
        </div>
      </div>
    </div>
  </section>
</main>
