<main class="main" role="main">
  <section class="page">
    <div class="container">
      <app-separador label="Procuradoria Regional de Araçatuba (PR09)"></app-separador>
      <div class="row">
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 the_content">
          <h3>PR09 CHEFIA GABINETE</h3>
          <div class="pge-unidade">
            <p class="procurador"><strong>Procurador(a)</strong>: {{ findInArray("CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA") }}</p>
            <p class="endereco"><strong>Endereço</strong>: Rua Marechal Deodoro,600, , Centro, Araçatuba-SP, 16010-301.</p>
            <p class="tel"><strong>Telefone</strong>: (18) 3623-6920</p>
            <p class="email"><strong>E-mail</strong>: pr9atendimento&#64;sp.gov.br</p>
            <div class="descricao">
              <strong>Municípios abrangidos:</strong> Alto Alegre, Andradina, Araçatuba, Auriflama, Avanhandava, Barbosa, Bento de Abreu, Bilac, Birigui, Braúna, Brejo Alegre, Buritama, Castilho,
              Clementina, Coroados, Floreal, Gabriel Monteiro, Gastão Vidigal, General Salgado, Glicério, Guaraçaí, Guararapes, Guzolândia, Ilha Solteira, Itapura, Lavínia, Lourdes, Luiziânia, Magda,
              Mirandópolis, Muritinga do Sul, Nova Castilho, Nova Independência, Penápolis, Pereira Barreto, Piacatu, Turiúba, Rubiácea, Santo Antonio do Aracangua, Santópolis do Aguapeí, São João de
              Iracema, Sud Menucci, Suzanópolis, Valparaíso.
            </div>
          </div>
          <br />
          <hr />
          <br />
          <app-voltar-component></app-voltar-component>
          <br />
        </div>
      </div>
    </div>
  </section>
</main>
