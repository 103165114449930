import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { ImagemTratamento } from 'src/app/area-aberta/features/home/shared/services/imagem-tratamento';
import { CategoriaNoticia } from 'src/app/area-aberta/shared/enums/categoria-noticia';
import { Noticias } from 'src/app/shared/models/noticia.interface';
import { Page } from 'src/app/shared/models/paginacao/page.model';
import { NoticiaService } from 'src/app/shared/services/noticia.service';
import { SeparadorComponent } from '../../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-noticias-diversas',
  templateUrl: './noticias-diversas.component.html',
  styleUrl: './noticias-diversas.component.sass',
  providers: [ImagemTratamento],
  standalone: true,
  imports: [SeparadorComponent, MatPaginator]
})
export class NoticiasDiversasComponent implements OnInit, OnDestroy {
  objNoticias?: Noticias[] = [];
  currentImageIndex: number = 0;
  imagemMaxWidth: number = 350; // Defina o valor máximo desejado para a largura da imagem

  pageEvent: PageEvent;
  length = 0;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25];

  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;

  constructor(
    private noticiaService: NoticiaService,
    private router: Router,
    private imagemTratamento: ImagemTratamento
  ) {}

  ngOnInit(): void {
    this.carregarNoticia();
  }

  carregarNoticia(): void {
    this.noticiaService.getNoticia(this.pageIndex, this.pageSize, CategoriaNoticia.NOTICIA_AREA_ABERTA).subscribe(
      (response: Page<Noticias>) => {
        this.objNoticias = response.content;
        this.length = response.totalElements;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.carregarNoticia();
  }

  getImagem(noticiaImagem: string): string | undefined {
    return this.imagemTratamento.getImagemBlob(noticiaImagem);
  }

  abrirUmaNoticia(noticiaId: number) {
    this.router.navigate(['/uma-noticia', noticiaId]);
  }

  ngOnDestroy(): void {
    this.imagemTratamento.revogarUrlBlob();
  }

  stripHTML(fonte: string) {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = fonte;
    return tmp.textContent || tmp.innerText || '';
  }
}
