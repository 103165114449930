import { Component, Input, OnInit } from '@angular/core';
import { MatCard, MatCardContent, MatCardFooter, MatCardHeader } from '@angular/material/card';
import { Router, RouterLink } from '@angular/router';

@Component({
  selector: 'app-card-servico',
  templateUrl: './card-servico.component.html',
  styleUrl: './card-servico.component.sass',
  standalone: true,
  imports: [MatCard, MatCardHeader, MatCardContent, MatCardFooter, RouterLink]
})
export class CardServicoComponent implements OnInit {
  @Input() cardContent;
  svgPath = '';

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.svgPath = `images/svg/${this.cardContent.icone}.svg`;
  }

  redirect(route: string) {
    this.router.navigateByUrl(route);
  }
}
