import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, throwError } from 'rxjs';
import { Globais } from 'src/app/area-restrita/shared/globais/globais';
import { LocalStorageService } from 'src/app/area-restrita/shared/services/local-storage.service';
import { UsuarioLogin } from 'src/app/shared/models/usuario-login.interface';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { environment } from 'src/environments/environment';

export interface TrocaSenha {
  senhaAntiga: string;
  senhaNova: string;
}

@Injectable({
  providedIn: 'root'
})
export class SenhaService {
  headers = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
    Authorization: this.localStorage.get('token')
  });
  private user: BehaviorSubject<UsuarioLogin> = new BehaviorSubject<UsuarioLogin>(null);
  user$: Observable<UsuarioLogin> = this.user.asObservable();
  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService,
    private toastService: ToastService
  ) {}

  env = environment;

  alterarSenha(newPassword: TrocaSenha): Observable<TrocaSenha> {
    const body = new HttpParams().set('senhaAntiga', newPassword.senhaAntiga).set('senhaNova', newPassword.senhaNova);
    return this.http.post<TrocaSenha>(this.env.apiUrl + 'trocar-senha', body.toString(), { headers: this.headers }).pipe(
      catchError((error) => {
        console.error('Erro durante o envio de dados:', error);
        return throwError(() => error);
      })
    );
  }

  esqueciSenha(user: string): Observable<UsuarioLogin> {
    const body = new HttpParams().set('usuario', user);
    return this.http.post<UsuarioLogin>(Globais.urlToken + 'site-pge/api/esqueci-senha', body.toString(), { headers: this.headers });
  }

  confirmarEsqueciSenha(): Observable<string> {
    return this.http.post<string>(Globais.urlToken + 'site-pge/api/confirmar-esqueci-senha/' + this.localStorage.get('idUsuario'), { headers: this.headers });
  }

  setUserInfo(userInfo: UsuarioLogin) {
    this.user.next(userInfo);
  }
}
