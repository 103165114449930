import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import verificaCpf from 'src/app/area-aberta/features/home/noticias/shared/custom-validators/cpf.validator';
import dataNascimentoValidator from 'src/app/area-aberta/features/home/noticias/shared/custom-validators/dataNascimento.validor';
import { Dependente } from 'src/app/area-restrita/features/auxilio/saude/shared/models/dependente';
import { FichaInscricaoCadastro } from 'src/app/area-restrita/features/auxilio/saude/shared/models/ficha-inscricao-cadastro';
import { PaginaVisitadaService } from 'src/app/area-restrita/shared/services/pagina-visitada.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { FichaAuxilioSaudeComponent } from '../ficha-auxilio-saude/ficha-auxilio-saude.component';
import FichaAuxilioHelper from '../ficha-auxilio-saude/ficha-auxilio-saude.heper';
import { AuxilioSaude } from '../shared/models/auxilio-saude';
import { AuxilioSaudeService } from '../shared/services/auxilio-saude.service';
import { InscricaoAuxilioSaudeService } from '../shared/services/inscricao-auxilio-saude.service';

@Component({
  selector: 'app-ficha-analise-inscricao',
  templateUrl: './ficha-analise-inscricao.component.html',
  styleUrl: './ficha-analise-inscricao.component.sass',
  standalone: true,
  imports: [FichaAuxilioSaudeComponent]
})
export class FichaAnaliseInscricaoComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  fichaHelper = new FichaAuxilioHelper();
  mensagensErro = this.fichaHelper.mensagensErro;

  opcoesRelacionamento = this.fichaHelper.opcoesRelacionamento;

  mostrarFormulario: boolean = false;
  possuiInscricao: boolean = false;
  mostrarFrase: boolean = true;
  dependentes: Dependente[] = [];
  dependentesAuxiliar: Dependente[] = [];
  dependentesFichaInscricao: Dependente[] = [];
  selecionados: Set<Dependente> = new Set<Dependente>();
  mensagemErro: string = '';
  dataAlteracao: string = '';
  mostrarCheckbox: boolean = false;
  fichaInscricaoCadastro: FichaInscricaoCadastro | null;

  dadosFormulario: AuxilioSaude = {
    id: '',
    rg: '',
    cpf: '',
    lotacao: '',
    endereco: '',
    cep: '',
    cidade: '',
    uf: '',
    telefone: '',
    celular: '',
    email: '',
    tipoPcd: { id: 9 },
    dependentes: [] as { id: number }[],
    usuario: { id: Number(localStorage.getItem('idUsuario')), nome: '' },
    dependentesImpostoRenda: false,
    contratantePlanoSaude: false,
    autorizacaoIAMSPE: false,
    possuoDependentes: false,
    dataAlteracao: new Date().toLocaleDateString()
  };

  dadosAvaliacao = {
    observacao: '',
    idStatus: 0
  };

  auxilioStatusOptions = [
    {
      id: 2,
      name: 'Deferido'
    },
    {
      id: 3,
      name: 'Indeferido'
    }
  ];

  isValidationMode = false;
  constructor(
    private auxilioSaudeService: AuxilioSaudeService,
    private inscricaoAuxilioSaudeService: InscricaoAuxilioSaudeService,
    private toastr: ToastService,
    private router: Router,
    private route: ActivatedRoute,
    private paginaService: PaginaVisitadaService
  ) {}

  carregarDadosFichaAuxilio(): void {
    const id = this.route.snapshot.params['id'] || localStorage.getItem('idUsuario');
    this.isValidationMode = this.router.url.includes('inscricao-auxilio-avaliacao');
    this.auxilioSaudeService
      .carregarFichaAuxilioSaude(id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(([fichaInscricaoResponse, inscricaoResponse, dependentesResponse]) => {
        this.dadosFormulario = {
          id: inscricaoResponse.id || '',
          rg: inscricaoResponse.rg || '',
          cpf: inscricaoResponse.cpf || '',
          lotacao: inscricaoResponse.lotacao || '',
          endereco: inscricaoResponse.endereco || '',
          cep: inscricaoResponse.cep || '',
          cidade: inscricaoResponse.cidade || '',
          uf: inscricaoResponse.uf || '',
          telefone: inscricaoResponse.telefone || '',
          celular: inscricaoResponse.celular || '',
          email: inscricaoResponse.email || '',
          tipoPcd: inscricaoResponse.tipoPcd,
          dependentes: inscricaoResponse.dependentes || [],
          usuario: inscricaoResponse.usuario || { id: Number(localStorage.getItem('idUsuario')), nome: '' },
          dependentesImpostoRenda: inscricaoResponse.dependentesImpostoRenda || false,
          contratantePlanoSaude: inscricaoResponse.contratantePlanoSaude || false,
          autorizacaoIAMSPE: false,
          possuoDependentes: false,
          dataAlteracao: inscricaoResponse.dataAlteracao || new Date().toLocaleDateString()
        };
        this.fichaInscricaoCadastro = fichaInscricaoResponse;
        this.fichaInscricaoCadastro.nome = inscricaoResponse.usuario?.nome;
        this.dadosFormulario.dependentesImpostoRenda = true;
        this.possuiInscricao = true;

        this.dependentesAuxiliar = dependentesResponse;
        this.carregarDadosAuxilio();
        this.carregarDependentePreenchido();
      });
  }

  preenchendoFicha() {
    this.dadosFormulario.rg = this.fichaInscricaoCadastro.rg;
    this.dadosFormulario.cpf = this.fichaInscricaoCadastro.cpf;
    this.dadosFormulario.endereco = this.fichaInscricaoCadastro.endereco;
    // this.dadosFormulario.id = this.fichaInscricaoCadastro.idUsuario.toString();
    this.dadosFormulario.lotacao = this.fichaInscricaoCadastro.lotacao;
    this.dadosFormulario.cep = this.fichaInscricaoCadastro.cep;
    this.dadosFormulario.cidade = this.fichaInscricaoCadastro.cidade;
    this.dadosFormulario.uf = this.fichaInscricaoCadastro.uf;

    if (this.dadosFormulario.id == null || this.dadosFormulario.id == '0') {
      this.dadosFormulario.telefone = this.fichaInscricaoCadastro.dddTelefone + this.fichaInscricaoCadastro.telefone;
    }
    this.dadosFormulario.email = this.fichaInscricaoCadastro.email;
  }

  ngOnInit(): void {
    this.paginaService.salvaPagina(this.router.url.includes('inscricao-auxilio-avaliacao') ? 'Avaliação da Inscrição' : 'Ficha de Inscrição');
    this.carregarDadosFichaAuxilio();
    this.obterInscricao();
  }

  // Enviar dados dos dependentes que o usuário desejar inserir no banco de dados
  formularioDependentes: FormGroup = new FormGroup({
    passo: new FormArray([
      new FormGroup({
        checked: new FormControl(false),
        nome: new FormControl('', [Validators.required]),
        cpfDependente: new FormControl('', [Validators.required, verificaCpf.bind(this)]),
        dataNascimento: new FormControl(null, [Validators.required, dataNascimentoValidator.bind(this)]),
        descricaoDependente: new FormControl('', [Validators.required]),
        usuario: new FormGroup({
          id: new FormControl(Number(localStorage.getItem('idUsuario')))
        })
      })
    ])
  });

  get passo(): FormArray {
    return this.formularioDependentes.get('passo') as FormArray;
  }

  adicionarLinhaGrid() {
    const ultimoGrupo = this.passo.at(this.passo.length - 1) as FormGroup;
    if (this.verificarCamposPrenhenchidosDependentes(ultimoGrupo)) {
      this.passo.push(
        new FormGroup({
          checked: new FormControl(false),
          nome: new FormControl(''),
          cpfDependente: new FormControl(''),
          dataNascimento: new FormControl(),
          descricaoDependente: new FormControl(''),
          usuario: new FormGroup({
            id: new FormControl(Number(localStorage.getItem('idUsuario')))
          })
        })
      );
    }
  }

  verificarCamposPrenhenchidosDependentes(formGroup: FormGroup): boolean {
    for (const controle in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty.call(controle)) {
        const valor = formGroup.get(controle)?.value;
        if (valor === null || valor === undefined || valor === '') {
          return false;
        }
      }
    }
    return true;
  }

  // Obter dados  de dependentes do usuario
  obterListaDependentes(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.inscricaoAuxilioSaudeService
        .obterListaDependentes()
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (dados) => {
            this.dependentesAuxiliar = dados;
            if (dados.length > 0) {
              this.mostrarCheckbox = true;
            }
            resolve();
          },
          error: (error) => reject(new Error('Erro ao obter lista de dependentes: ', error))
        });
    });
  }

  obterInscricao(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.inscricaoAuxilioSaudeService
        .obterInscricao()
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: async (dados) => {
            if (dados != null) {
              this.dadosFormulario.id = dados.id;
              // this.fichaInscricaoCadastro.nome = dados.usuario.nome;
              this.dadosFormulario.rg = dados.rg.replace(/\D/g, '');
              this.dadosFormulario.cpf = dados.cpf;
              this.dadosFormulario.endereco = dados.endereco;
              this.dadosFormulario.lotacao = dados.lotacao;
              this.dadosFormulario.cep = dados.cep.replace(/\D/g, '');
              this.dadosFormulario.cidade = dados.cidade;
              this.dadosFormulario.uf = dados.uf;
              this.dadosFormulario.telefone = dados.telefone?.replace(/\D/g, '');
              this.dadosFormulario.celular = dados.celular?.replace(/\D/g, '');
              this.dadosFormulario.email = dados.email;
              this.dependentesFichaInscricao = dados.dependentes;
              this.dadosFormulario.dependentesImpostoRenda = true;
              this.possuiInscricao = true;
              this.dataAlteracao = dados.dataAlteracao;
            }
            resolve();
          },
          error: (error) => reject(new Error('Erro ao obter inscrição', error))
        });
    });
  }

  carregarDependentePreenchido(): Promise<void> {
    return new Promise<void>((resolve) => {
      if (
        Array.isArray(this.dependentesAuxiliar) &&
        this.dependentesAuxiliar.length > 0 &&
        Array.isArray(this.dependentesFichaInscricao) &&
        this.dependentesFichaInscricao.length > 0
      ) {
        this.dependentesFichaInscricao.forEach((itemToCompare) => {
          this.dependentesAuxiliar.forEach((item) => {
            if (item.id === itemToCompare.id && item.cpfDependente === itemToCompare.cpfDependente) {
              item.checked = true;
              this.selecionados.add(item);
            }
          });
        });
      }
      this.dependentes = this.dependentesAuxiliar;
      resolve();
    });
  }

  selecaoDependente(dependente: Dependente) {
    if (this.selecionados.has(dependente)) {
      this.selecionados.delete(dependente);
    } else {
      this.selecionados.add(dependente);
    }
  }

  FormataOutputCPF(cpf: string): string {
    if (!cpf) return '';
    cpf = cpf.replace(/\D/g, '');
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }

  excluirDependente(): void {
    const idSelecionadoDependente = [...this.selecionados].map((dependente) => ({ ...dependente }).id)[0];
    this.auxilioSaudeService.excluirDependente(idSelecionadoDependente).subscribe(
      () => {
        this.toastr.success('Dependente(s) Excluído(s) com Sucesso');
        this.mostrarFormulario = false;
        this.mostrarFrase = true;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/area-restrita/inscricao-auxilio-saude']);
        });
      },
      (error) => {
        console.error('Erro ao Apagar dado(s)', error);
      }
    );
  }

  enviarDadosDependentes() {
    if (this.verificarObjectCamposPreenchidosDependentes()) {
      const dadosParaEnviar = (this.passo.controls as FormGroup[])
        .map((grupo) => grupo.getRawValue())
        .map((e) => {
          const dataNascimento = new Date(e.dataNascimento);
          dataNascimento.setDate(dataNascimento.getDate() + 1); // Adiciona um dia à data de nascimento
          return {
            ...e,
            dataNascimento: dataNascimento.toLocaleDateString()
          };
        });

      this.auxilioSaudeService.enviarDadosDependentes(dadosParaEnviar as Dependente[]).subscribe(
        () => {
          this.mostrarFormulario = false;
          this.mostrarFrase = true;
          this.toastr.success('Enviado com sucesso');
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/area-restrita/inscricao-auxilio-saude']);
          });
          while (this.passo.length !== 0) {
            this.passo.removeAt(0);
          }
          this.passo.push(
            new FormGroup({
              nome: new FormControl(''),
              cpfDependente: new FormControl(''),
              dataNascimento: new FormControl(),
              descricaoDependente: new FormControl(''),
              usuario: new FormGroup({
                id: new FormControl(Number(localStorage.getItem('idUsuario')))
              })
            })
          );
        },
        (error) => {
          console.log(error);
          this.toastr.error(error);
        }
      );
    } else this.toastr.error('Favor preencher todos os campos');
  }

  verificarObjectCamposPreenchidosDependentes(): boolean {
    for (let i = 0; i < this.passo.length; i++) {
      const formGroup = this.passo.at(i) as FormGroup;
      if (!this.verificarCamposPrenhenchidosDependentes(formGroup)) {
        return false;
      }
    }
    return true;
  }

  enviarDadosFormulario() {
    if (this.camposObrigatoriosPreenchidos()) {
      this.dadosFormulario.dependentes = [...this.selecionados].map((dependente) => ({ id: dependente.id }));
      this.auxilioSaudeService.enviarDadosFormulario(this.dadosFormulario).subscribe(
        () => {
          this.toastr.success('Dados enviados com Sucesso');
          this.limpaChecksBox();
          this.carregarDadosFichaAuxilio();
          this.enviarAtualizacaoStatus();
          this.mensagemErro = '';
        },
        (error) => {
          console.error('Erro ao enviar dados', error);
        }
      );
    } else {
      this.mensagemErro = 'Falta preencher campos obrigatórios.';
    }
  }

  atualizarDadosFichaInscricao() {
    if (this.camposObrigatoriosPreenchidos()) {
      this.dadosFormulario.dependentes = [...this.selecionados].map((dependente) => ({ id: dependente.id }));
      this.auxilioSaudeService.atualizarDadosFormulario(this.dadosFormulario).subscribe(
        () => {
          this.toastr.success('Dados atualizados com Sucesso');
          this.limpaChecksBox();
          this.carregarDadosFichaAuxilio();
          this.enviarAtualizacaoStatus();
          this.mensagemErro = '';
        },
        (error) => {
          this.toastr.error(error.message);
        }
      );
    } else {
      this.mensagemErro = 'Falta preencher campos obrigatórios.';
    }
  }

  camposObrigatoriosPreenchidos(): boolean {
    if (this.mostrarCheckbox && [...this.selecionados].length > 0) {
      return (
        this.dadosFormulario.celular !== '' &&
        this.dadosFormulario.email !== '' &&
        this.dadosFormulario.dependentesImpostoRenda &&
        this.dadosFormulario.contratantePlanoSaude &&
        this.dadosFormulario.autorizacaoIAMSPE &&
        this.dadosFormulario.possuoDependentes
      );
    } else {
      return this.dadosFormulario.celular !== '' && this.dadosFormulario.email !== '' && this.dadosFormulario.dependentesImpostoRenda;
    }
  }

  carregarDadosAuxilio() {
    this.auxilioSaudeService.obterPedidoAuxilioSaude(this.dadosFormulario.id).subscribe((response) => {
      this.dadosAvaliacao.observacao = response.observacao;
      this.dadosAvaliacao.idStatus = Number(response.status.id);
    });
  }

  enviarAtualizacaoStatus() {
    if (!this.isValidationMode) return;
    const obsDeveSerPreenchido = this.dadosAvaliacao.idStatus == 3 && !this.dadosAvaliacao.observacao;
    if (obsDeveSerPreenchido) {
      this.toastr.warning('Favor preencher a justificativa.');
      return;
    }
    this.auxilioSaudeService.enviarAtualizacaoStatus(Number(this.dadosFormulario.id), this.dadosAvaliacao);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  limpaChecksBox() {
    this.dadosFormulario.dependentesImpostoRenda = false;
    this.dadosFormulario.contratantePlanoSaude = false;
    this.dadosFormulario.autorizacaoIAMSPE = false;
    this.dadosFormulario.possuoDependentes = false;
  }

  removerLinhaDependente(i: number): void {
    const dependenteExcluir = this.dependentes[i];
    dependenteExcluir.checked = true;
    this.selecionados.clear();
    this.selecionados.add(dependenteExcluir);
    this.excluirDependente();
  }

  private delay(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
