<main class="main" role="main">
  <section class="page">
    <app-separador label="Procedimento Administrativo de Reparação de Danos"></app-separador>
    <div class="row">
      <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 the_content">
        <p class="x_elementToProof">
          <span class="x_ContentPasted0">O</span><span class="x_ContentPasted0">&nbsp;</span><span class="x_ContentPasted0">pedido administrativo de&nbsp;</span
          ><span class="x_ContentPasted0"><b class="x_ContentPasted0">reparação de danos</b></span
          ><span class="x_ContentPasted0">&nbsp;</span
          ><span class="x_ContentPasted0">deve ser dirigido ao Procurador Geral do Estado e protocolado pela via eletrônica na Procuradoria Geral do Estado (</span
          ><u
            ><span class="x_ContentPasted0"><a href="mailto:protocolopge@sp.gov.br">protocolopge&#64;sp.gov.br</a>)</span></u
          ><span class="x_ContentPasted0">, até 5 (cinco) anos contados do ato ou fato que houver dado causa ao dano.</span>
        </p>
        <p class="x_elementToProof">
          <span class="x_ContentPasted0">O requerimento será analisado pela Subprocuradoria Geral da Consultoria Geral</span><span class="x_ContentPasted0">&nbsp;</span
          ><span class="x_ContentPasted0">e deverá observar as disposições dos artigos 54 e 65/66 da Lei estadual n. 10.177/98 e Decreto estadual n. 44.422/1999, contendo:</span>
        </p>
        <p>
          •&nbsp;&nbsp;&nbsp; o nome, a qualificação e o endereço do requerente;<br />
          •&nbsp;&nbsp;&nbsp; os fundamentos de fato e de direito do pedido;<br />
          •&nbsp;&nbsp;&nbsp; a providência pretendida;<br />
          •&nbsp;&nbsp;&nbsp; as provas documentais de que já disponha, bem como aquelas em poder da Administração que pretenda ver juntadas aos autos;<br />
          •&nbsp;&nbsp;&nbsp; indicação precisa do montante atualizado da indenização pretendida;<br />
          •&nbsp;&nbsp;&nbsp; e declarações:
        </p>
        <p>
          1 – de que concorda com as condições do art. 65 da Lei estadual n. 10.177/98;<br />
          2 – de que concorda que n<span class="x_ContentPasted0">as indenizações pagas não incidirão juros, honorários advocatícios ou</span> qualquer outro acréscimo;<br />
          3 – de<span class="x_ContentPasted0">&nbsp;</span><span class="x_ContentPasted0">inexistência de ação judicial ou a desistência de ação em curso, fundada no mesmo fato e no</span> mesmo
          direito.
        </p>
        <p>
          <span class="x_ContentPasted0">Se o pedido envolver</span><span class="x_ContentPasted0">&nbsp;</span
          ><span class="x_ContentPasted0"><u class="x_ContentPasted0">dano causado a veículo</u></span
          ><span class="x_ContentPasted0"
            >, deverão também ser juntados: i) comprovante de sua propriedade; ii) declaração de que não possuía seguro particular e, caso possua, juntar cópia da apólice e iii) três orçamentos que
            indiquem a extensão dos danos.</span
          >
        </p>
        <p>
          <span class="x_ContentPasted0"
            >O protocolo do requerimento suspende, nos termos da legislação pertinente, a prescrição da ação de responsabilidade contra o Estado, pelo período que durar sua tramitação.&nbsp;</span
          ><span class="x_ContentPasted0">&nbsp;</span>
        </p>
        <hr />
      </div>
    </div>
  </section>
</main>
