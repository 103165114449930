import { Component, OnInit } from '@angular/core';
import { WebServiceInfosService } from 'src/app/shared/services/infos-webservice.service';
import { SeparadorComponent } from '../../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-composicao-gab',
  templateUrl: './composicao.component.html',
  standalone: true,
  imports: [SeparadorComponent]
})
export class ComposicaoComponent implements OnInit {
  informacoes: string[] = [];
  codigoUnidades: number[] = [40110200000090, 40110300000090];

  constructor(private service: WebServiceInfosService) {}

  ngOnInit(): void {
    this.carregarConteudo();
  }

  carregarConteudo() {
    const array: string[] = [];
    this.codigoUnidades.forEach((element) => {
      this.service.getInfosPorCodigo(element).subscribe(
        (response) => {
          const parser = new DOMParser();
          const xml = parser.parseFromString(response, 'text/xml');
          xml.querySelectorAll('NOME_PROCURADOR, NOME_SUBSTITUTO, NOME_SUBSTITUTO2').forEach((element) => {
            if (element.textContent !== '') {
              array.push(element.textContent.trim());
            }
          });
        },
        (error) => {
          console.log(error);
        }
      );
    });

    this.informacoes = array;
  }

  findInArray(nome: string): string {
    const result = this.informacoes.find((element) => element === nome);
    return result ? result : 'Nome não encontrado';
  }
}
