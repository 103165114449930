import { MenuNode } from './MenuNode';

export const MenuPgeFim: MenuNode = {
  id: 0,
  order: 0,
  description: 'Sair da página - Logout',
  name: 'Sair',
  router: 'logout-site-pge',
  url: 'S',
  children: [],
  materialIcon: 'logout'
};
