<div class="container-programa-estagio">
  <app-separador label="Programa de Estágio e Residência Jurídica" />

  <div class="accordion-menu">
    <div class="accordion-title" tabindex="0" (click)="toggle(0)" (keypress)="toggle(0)">
      <mat-icon class="red-icon">{{ programa[0].abrir ? "expand_less" : "expand_more" }}</mat-icon>
      {{ programa[0].titulo }}
    </div>
    @if (programa[0].abrir) {
      <div class="accordion-content">
        <div class="divisor">
          <p>
            O estágio tem por objetivo proporcionar experiência teórica e, principalmente, prática do que o estudante aprende em sala de aula, auxiliando no seu desenvolvimento profissional e
            interpessoal e contribuindo efetivamente para a sua inserção no mercado de trabalho.
          </p>
          <p>
            Ao estagiar na Procuradoria Geral de São Paulo, o estudante estará em contato com uma instituição essencial à administração da justiça e à Administração Pública Estadual, vinculada
            diretamente ao Governador e responsável pela advocacia do Estado.
          </p>
        </div>
        <div class="divisor">
          <h3>Modalidades</h3>
          <h4>Ensino Médio (CIEE)</h4>
          <ul>
            <li>
              <p><strong>Valor da bolsa: </strong>R$ 475,05, além de auxílio-transporte;</p>
            </li>
            <li>
              <p><strong>Requisitos: </strong>idade mínima de 16 anos e matrícula em curso de ensino médio em instituições de ensino oficialmente reconhecidas.</p>
            </li>
          </ul>
          <h4>Ensino Superior (Graduação em Direito)</h4>
          <ul>
            <li>
              <p><strong>Valor da bolsa: </strong>R$ 1.000,00 (um mil reais), além de auxílio-transporte;</p>
            </li>
            <li>
              <p><strong>Requisitos: </strong>matrícula em curso de graduação em Direito em instituições de ensino oficialmente reconhecidas.</p>
            </li>
          </ul>
          <h4>Pós-graduação em Direito (Residência Jurídica)</h4>
          <ul>
            <li>
              <p><strong>Valor da bolsa: </strong>R$ 1.903,98 (um mil, novecentos e três reais e noventa e oito centavos), além de auxílio-transporte;</p>
            </li>
            <li>
              <p><strong>Requisitos: </strong>matrícula em curso de pós-graduação lato ou stricto sensu em Direito, em instituições de ensino oficialmente reconhecidas.</p>
            </li>
          </ul>
        </div>
        <div class="divisor">
          <h3>Ingresso</h3>
          <p>Os estudantes ingressarão após aprovação no processo seletivo, que será composto por questões objetivas de múltipla escolha e/ou discursivas.</p>
        </div>
      </div>
    }
  </div>

  <div class="accordion-menu">
    <div class="accordion-title" tabindex="0" (click)="toggle(1)" (keypress)="toggle(1)">
      <mat-icon class="red-icon">{{ programa[1].abrir ? "expand_less" : "expand_more" }}</mat-icon>
      {{ programa[1].titulo }}
    </div>
    @if (programa[1].abrir) {
      <div class="accordion-content">
        <div class="divisor">
          <p>
            O estágio terá carga horária de 4 (quatro) horas diárias, totalizando 20 (vinte) horas semanais, havendo possibilidade de realização de teletrabalho, em regime híbrido, a critério da
            Chefia do órgão a que o estagiário for designado.
          </p>
        </div>
        <div class="divisor">
          <p>
            A duração do estágio será de até 2 (dois) anos por modalidade ou até a data da conclusão do curso. O estágio também poderá ser encerrado a pedido do estagiário ou a critério da
            Administração.
          </p>
        </div>
      </div>
    }
  </div>
  <div class="accordion-menu">
    <div class="accordion-title" tabindex="0" (click)="toggle(2)" (keypress)="toggle(2)">
      <mat-icon class="red-icon">{{ programa[2].abrir ? "expand_less" : "expand_more" }}</mat-icon>
      {{ programa[2].titulo }}
    </div>
    @if (programa[2].abrir) {
      <div class="accordion-content">
        <a target="_self" [href]="url">Acervo</a>
      </div>
    }
  </div>
  <div class="accordion-menu">
    <div class="accordion-title" tabindex="0" (click)="toggle(3)" (keypress)="toggle(3)">
      <mat-icon class="red-icon">{{ programa[3].abrir ? "expand_less" : "expand_more" }}</mat-icon>
      {{ programa[3].titulo }}
    </div>
    @if (programa[3].abrir) {
      <div class="accordion-content">
        <a href="https://www.pge.sp.gov.br/ConcursoEstagiarios/Inscricao/Lst_ConcursosV2.asp" target="_blank">Faça sua inscrição</a>
      </div>
    }
  </div>
  <br />
</div>
