import { Component } from '@angular/core';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-voltar-component',
  templateUrl: './voltar-component.component.html',
  styleUrl: './voltar-component.component.sass',
  standalone: true,
  imports: [MatButton]
})
export class VoltarComponentComponent {
  voltar() {
    window.history.back();
  }
}
