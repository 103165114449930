<main class="main" role="main">
  <section class="page">
    <div class="container">
      <app-separador label="Legislação" />
      <div class="row">
        @for (item of legislacaoContent; track item) {
          <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 the_content">
            <h3>{{ item?.titulo }}</h3>
            <p>
              <a target="_blank" rel="noopener noreferrer" [href]="item?.url">{{ item?.icone }}</a>
            </p>
            <hr />
            <pre></pre>
          </div>
        }
      </div>
    </div>
  </section>
</main>
