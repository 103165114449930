import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, catchError, map, throwError } from 'rxjs';
import { Globais } from 'src/app/area-restrita/shared/globais/globais';
import { Page } from 'src/app/shared/models/paginacao/page.model';
import { AuxilioSaudeMesaAnalise, FormAuxilioSaudeMesaAnalise, Perfil, StatusAuxilio } from '../models/auxilio-saude';
import { meses } from './meses';

@Injectable({
  providedIn: 'root'
})
export class MesaAuxilioSaudeService {
  constructor(private http: HttpClient) {}

  obterMeses() {
    return meses;
  }

  obterListaStatusAuxilio(): Observable<StatusAuxilio[]> {
    const token = new String(localStorage.getItem('token')).toString();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token
    });

    return this.http.get<StatusAuxilio[]>(Globais.urlToken + 'site-pge/api/status-auxilios', { headers: headers }).pipe(
      catchError(() => {
        return throwError(() => 'Falha no envio de dados.');
      })
    );
  }

  obterListaPerfis(): Observable<Perfil[]> {
    const token = new String(localStorage.getItem('token')).toString();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token
    });

    return this.http.get<Perfil[]>(Globais.urlToken + 'site-pge/api/perfis', { headers: headers }).pipe(
      catchError(() => {
        return throwError(() => 'Falha no envio de dados.');
      })
    );
  }

  buscarPedidos(
    dadosFormulario: FormGroup<FormAuxilioSaudeMesaAnalise>,
    pageIndex: string | number,
    pageSize: string | number
  ): Observable<Page<AuxilioSaudeMesaAnalise>> {
    const token = new String(localStorage.getItem('token')).toString();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token
    });

    const dataRecebida = dadosFormulario.controls?.mesAnoSolicitacao?.value || '';

    let parametros = new HttpParams()
      .set('nomeUsuario', dadosFormulario.controls.procuradorServidor.value ?? '')
      .set('idStatus', dadosFormulario.controls.status.value ?? '')
      .set('idPerfil', dadosFormulario.controls.perfil.value ?? '')
      .set('idTipoAuxilioSaude', dadosFormulario.controls.tipoAuxilioSaude.value ?? '')
      .set('dataEnvioInicial', dadosFormulario.controls.periodoDe.value ?? '')
      .set('dataEnvioFinal', dadosFormulario.controls.periodoAte.value ?? '')
      .set('idTipoAuxilio', '2') // TODO Modificar para receber variavel conforme o caso
      .set('page', pageIndex)
      .set('size', pageSize);
    if (dataRecebida && dataRecebida.trim() !== '') {
      const partesData = dataRecebida.split('-');
      parametros = parametros.set('anoReembolso', partesData[0] ?? '');
      parametros = parametros.set('mesParcela', partesData[1] ?? '');
    }

    return this.http.get<Page<AuxilioSaudeMesaAnalise>>(Globais.urlToken + 'site-pge/api/auxilios', { headers: headers, params: parametros }).pipe(
      map((response) => {
        return response;
      })
    );
  }

  deferirPedidos(idsAuxilios: Set<number>): Observable<string> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token ? token : ''
    });

    const idUsuario = Number(localStorage.getItem('idUsuario'));
    const parametros = new HttpParams().set('idUsuario', idUsuario.toString());

    return this.http
      .put(Globais.urlToken + 'site-pge/api/auxilios/deferir', Array.from(idsAuxilios), { headers: headers, params: parametros, responseType: 'text' })
      .pipe(
        catchError(() => {
          return throwError('Ocorreu um erro ao deferir os pedidos. Por favor, tente novamente mais tarde.');
        })
      );
  }

  indeferirPedidos(idsAuxilios: Set<number>): Observable<string> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token ? token : ''
    });

    const idUsuario = Number(localStorage.getItem('idUsuario'));
    const parametros = new HttpParams().set('idUsuario', idUsuario.toString());

    return this.http.put<string>(Globais.urlToken + 'site-pge/api/auxilios/indeferir', Array.from(idsAuxilios), { headers: headers, params: parametros }).pipe(
      catchError(() => {
        return throwError('Ocorreu um erro ao deferir os pedidos. Por favor, tente novamente mais tarde.');
      })
    );
  }

  dateFormat(data: Date | string) {
    console.log('data', data);
    return new Date(data).toLocaleDateString();
  }
}
