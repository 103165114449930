<main class="main" role="main">
  <app-separador label="Credenciamento de Contadores" />
  <section class="secao">
    <div id="is-content" class="row">
      @for (obj of objCredenciamentoContadores; track obj) {
        <div class="news-card" tabindex="0" (click)="credenciamento(obj?.id)" (keypress)="credenciamento(obj?.id)">
          @if (obj?.imagem) {
            <div class="news-card-image">
              <img alt="Imagem da Notícia" tabindex="0" [src]="getImagem(obj?.imagem)" (click)="credenciamento(obj?.id)" (keypress)="credenciamento(obj?.id)" />
            </div>
          }
          <div class="news-detail">
            <div class="titulo">{{ obj?.titulo }}</div>
            <div class="date">{{ obj?.dataPublicacao }}</div>
            <div class="description">{{ stripHTML(obj?.texto) }}</div>
          </div>
        </div>
      }
    </div>
  </section>
  <div>
    <mat-paginator
      #paginator
      class="custom-paginator"
      aria-label="Select page"
      [length]="length"
      [pageSize]="pageSize"
      [disabled]="disabled"
      [showFirstLastButtons]="showFirstLastButtons"
      [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
      [hidePageSize]="hidePageSize"
      [pageIndex]="pageIndex"
      (page)="handlePageEvent($event)"
    />
  </div>
</main>
