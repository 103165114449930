import { Component } from '@angular/core';
import { MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { MatLine } from '@angular/material/core';
import { MatNavList, MatListItem } from '@angular/material/list';
import { HeaderComponent } from '../header/header.component';

@Component({
  selector: 'app-drawer-content',
  templateUrl: './drawer-content.component.html',
  styleUrl: './drawer-content.component.sass',
  standalone: true,
  imports: [HeaderComponent, MatNavList, MatListItem, MatLine, MatIconButton, MatIcon, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle]
})
export class DrawerContentComponent {
  expandedItem: string | null = null;

  toggleExpand(item: string): void {
    if (this.expandedItem === item) {
      this.expandedItem = null; // Recolhe o item se ele já estiver expandido
    } else {
      this.expandedItem = item; // Expande o item se estiver recolhido
    }
  }

  isExpanded(item: string): boolean {
    return this.expandedItem === item;
  }
}
