import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { SeparadorComponent } from '../../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-regionais-contencioso-geral',
  templateUrl: './regionais-contencioso-geral.component.html',
  standalone: true,
  imports: [SeparadorComponent, RouterLink]
})
export class RegionaisContenciosoGeralComponent {}
