<div id="informa" name="informa">
  <table>
    <tr>
      <td colspan="2" style="text-align: center">
        <b>{{ data.title }}</b>
      </td>
    </tr>
    <tr>
      <td class="left-cell" width="50%">Registros processados com sucesso</td>
      <td class="info" width="50%">{{ data.sucessos }}</td>
    </tr>
    <tr>
      <td class="left-cell" width="50%">Registros já deferidos ou inexistentes</td>
      <td class="info" width="50%">{{ data.falhas }}</td>
    </tr>
  </table>
  <div class="container">
    <button mat-icon-button style="line-height: 0" (click)="onClose()">
      <mat-icon style="font-size: 24px">check_circle_icon</mat-icon>
    </button>
  </div>
</div>
