<app-fundo-area-restrita-pge>
  <form class="login-form" (ngSubmit)="login()">
    <h1 class="title">Login</h1>
    <div class="input-label">
      <label class="label-align" for="usuario">USUÁRIO</label>
      <input type="text" id="usuario" name="usuario" required [(ngModel)]="usuario" />
    </div>
    <div class="input-label">
      <label class="label-align" for="senha">SENHA</label>
      <input type="password" id="senha" name="senha" required [(ngModel)]="senha" />
      <a tabindex="0" class="forgot-password" (click)="redirecionarParaRota($event)" (keydown.enter)="redirecionarParaRota($event)"> Esqueceu a senha? </a>
    </div>
    <button mat-raised-button color="primary" class="login-button" style="color: white !important" type="submit">Login</button>
    @if (isError) {
      <div class="label-error">
        {{ errorMessage }}
      </div>
    }
  </form>
</app-fundo-area-restrita-pge>
