import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Globais } from 'src/app/area-restrita/shared/globais/globais';
import { AuthenticationService } from 'src/app/area-restrita/shared/services/authentication.service';
import { LocalStorageService } from 'src/app/area-restrita/shared/services/local-storage.service';
import { UsuarioLogin } from 'src/app/shared/models/usuario-login.interface';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { environment } from 'src/environments/environment';
import { LegadoUsuarioService } from '../../auxilio/shared/services/legado-usuario.service';
import { ShaService } from '../../auxilio/shared/services/sha.service';
import { SenhaService } from '../shared/services/senha.service';
import { MatButton } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { FundoAreaRestritaPgeComponent } from '../shared/components/fundo-area-restrita-pge/fundo-area-restrita-pge.component';
@Component({
  selector: 'app-login-card',
  templateUrl: './login-card.component.html',
  styleUrl: './login-card.component.sass',
  standalone: true,
  imports: [FundoAreaRestritaPgeComponent, FormsModule, MatButton]
})
export class LoginCardComponent implements OnInit {
  routerDefault: string = '@@ENV@@/SitePge/Login.aspx?ReturnUrl=%2fSitePge%2frestrito%2fdefault.aspx';
  routerLegate: string = '@@ENV@@';
  newRedirect: string = '';
  env = environment;
  urlLoginLegado: string;

  usuario = '';
  senha = '';
  isError = false;
  errorMessage = '';

  ngOnInit() {
    console.log('V? ', Globais.versionFront);
    console.log('ENV? ', environment.env);
    this.routerDefault = this.routerDefault.replace('@@ENV@@', this.env.apiLegate);
    this.routerDefault = this.routerDefault.substring(0, this.routerDefault.indexOf('@@USER@@') - 9);
    this.routerLegate = this.routerLegate.replace('@@ENV@@', this.env.apiLegate);
  }

  constructor(
    private authenticationService: AuthenticationService,
    private rota: Router,
    private localStorage: LocalStorageService,
    private readonly senhaService: SenhaService,
    private legadoService: LegadoUsuarioService,
    private toastr: ToastService,
    private cryptService: ShaService
  ) {}

  managerLegate() {
    if (this.usuario != null && this.usuario.toString().trim() != '') {
      const us = this.cryptService.executaHashing(this.usuario) + '&senha=' + this.cryptService.executaHashing(this.senha);
      this.newRedirect = this.routerLegate.replace('@@USER@@', us);
    } else {
      this.newRedirect = this.routerDefault;
    }
  }

  direcionaLegado() {
    window.location.href = this.newRedirect;
  }

  login() {
    this.localStorage.clear();
    if (!this.usuario || !this.senha) {
      this.isError = true;
      this.errorMessage = 'Usuário/Senha incorretos!';
      console.error('Usuário/Senha incorretos!');
    } else {
      this.authenticationService.login(this.usuario, this.senha).subscribe({
        next: (resp: HttpResponse<UsuarioLogin>) => {
          if (resp.status == 200) {
            this.authenticationService.setToken(resp.headers.get('Authorization')!);
            localStorage.setItem('senhaExpirada', resp.body.senhaExpirada.toString());
            localStorage.setItem('cpf', resp.body.cpf);
            this.authenticationService.setIdUsuario(resp.body.idUsuario);
            if (this.redirecionarTrocaSenha(resp.body.senhaExpirada)) {
              console.error('Senha expirou');
              return;
            }
            console.log('IdUsuario? ' + this.localStorage.get('idUsuario'));
            console.log('Token? ' + (this.localStorage.get('token') ? 'Ok' : '-'));
            console.log('CPF? ' + (this.localStorage.get('cpf') ? 'Ok' : '-'));
            this.isError = false;
            this.managerLegate();

            const config = new MatSnackBarConfig();
            config.duration = 3000;
            console.log('CPF ------------->', this.localStorage.get('cpf'));

            //Verifica se o usuario esta no legado - True = Vai para o legado e o novo, False = Somente vai para o novo.
            this.legadoService.checkUsuarioLegado(this.localStorage.get('cpf')).subscribe({
              next: () => {
                this.toastr.info('Usuario encontrado no legado!', 'Aviso do Sistema', config);
                setTimeout(() => {
                  if (environment.env.toLocaleLowerCase().trim().indexOf('hml') >= 0 || environment.env.toLocaleLowerCase().trim().indexOf('prd') >= 0)
                    this.direcionaLegado();
                  else {
                    window.open(this.newRedirect, '_blank');
                    this.rota.navigate(['/area-restrita/home']);
                  }
                }, 1500);
              },
              error: (error: HttpErrorResponse) => {
                if (error.status == 422) {
                  this.toastr.info('Usuario não encontrado no legado, será levado somente para o novo sistema!', 'Aviso do Sistema', config);
                  this.rota.navigate(['/area-restrita/home']);
                } else {
                  this.toastr.error('Erro ao verificar usuario no legado.', 'Erro do Sistema', config);
                  this.rota.navigate(['/area-restrita/home']);
                }
              }
            });
          }
        },
        error: () => {
          Globais.isLogin = false;
          Globais.idUsuario = 0;
          Globais.tokenBearer = '';
          this.isError = true;
          this.errorMessage = 'Usuário/Senha incorretos!';
          this.newRedirect = this.routerDefault;
        }
      });
    }
  }
  redirecionarParaRota(event: Event): void {
    event.preventDefault(); // Prevenir o comportamento padrão do link
    if (!this.usuario) {
      this.isError = true;
      this.errorMessage = 'Favor preencher o usuário';
      return;
    }
    this.senhaService.esqueciSenha(this.usuario).subscribe(
      (resp: UsuarioLogin) => {
        this.senhaService.setUserInfo(resp);
        this.rota.navigate(['/esqueci-senha']);
      },
      (error) => {
        this.isError = true;
        this.errorMessage = 'Usuário não encontrado';
        const message = error.error.erros?.['Erro de negócio'] || 'Erro ao validar usuário!';
        this.toastr.error(message);
      }
    );
  }

  redirecionarTrocaSenha(senhaExpirada: boolean) {
    if (senhaExpirada) {
      this.rota.navigate(['/alterar-senha']);
      return true;
    }
    return false;
  }
}
