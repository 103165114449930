import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.sass',
  standalone: true,
  imports: [MatIcon, MatTooltip]
})
export class HeaderComponent {
  assetsPath = environment.assetsPath;

  isMenuOpen = false;

  constructor(private router: Router) {
    this.theme = localStorage.getItem('tema');
    if (this.theme == 'escuro') document.body.classList.add('dark-theme');
    this.fontSize = Number(localStorage.getItem('fonte')) || 16;
    this.changeAndSaveFont();
  }

  fontSize = 16;
  theme = 'claro';

  goToHome() {
    this.router.navigate(['/']);
  }

  fontIncrease() {
    if (this.fontSize >= 30) return;
    this.fontSize += 1;
    this.changeAndSaveFont();
  }

  fontDecrease() {
    if (this.fontSize <= 10) return;
    this.fontSize -= 1;
    this.changeAndSaveFont();
  }

  changeAndSaveFont() {
    document.getElementsByTagName('html')[0].style.fontSize = this.fontSize + 'px';
    localStorage.setItem('fonte', this.fontSize.toString());
  }
  goToFacebook() {
    window.open('https://www.facebook.com/pgespoficial/?locale=pt_BR', '_blank');
  }
  goToInstagram() {
    window.open('https://www.instagram.com/pgespoficial/', '_blank');
  }

  changeTheme() {
    this.theme = this.theme == 'claro' ? 'escuro' : 'claro';
    document.body.classList.toggle('dark-theme');
    console.log('alterando tema', this.theme);
    localStorage.setItem('tema', this.theme);
  }
}
