<div class="header-dialog">
  <button mat-icon-button mat-dialog-close type="submit">
    <mat-icon>close</mat-icon>
  </button>
  <h2>PGE</h2>
</div>
<div class="menu-container-flex">
  <div class="div-web">
    @for (menuItem of menuList; track menuItem) {
      <div class="menu">
        <ng-container>
          @if (menuItem.children) {
            <span class="menubtn">{{ menuItem.label }}</span>
          }
          @if (!menuItem.children) {
            <a class="nav-link menubtn" target="_blank" [href]="menuItem.redirect">{{ menuItem.label }} </a>
          }
        </ng-container>
        <div class="menu-content">
          @for (childL1 of menuItem.children; track childL1) {
            @if (!childL1.children) {
              <li mat-menu-item>
                <ng-container *ngTemplateOutlet="menuItemContainer; context: { $implicit: childL1 }" />
              </li>
            }
            @if (childL1.children && childL1.children.length > 0) {
              {{ childL1.children }}
            }
          }
        </div>
      </div>
    }
  </div>
  <div class="div-mobile">
    @for (menuItem of menuList; track menuItem) {
      <div class="menu">
        <ng-container>
          @if (menuItem.children) {
            <span class="menubtn centralizar-div" tabindex="0" (click)="alternarMenu(menuItem)" (keyup)="alternarMenu(menuItem)">{{ menuItem.label }}</span>
            @if (subMenuitem === menuItem) {
              @for (childL1 of subMenuitem?.children; track childL1) {
                @if (!childL1.children) {
                  <li mat-menu-item class="centralizar-div">
                    <ng-container *ngTemplateOutlet="menuItemContainer; context: { $implicit: childL1 }" />
                  </li>
                }
                @if (childL1.children && childL1.children.length > 0) {
                  {{ childL1.children }}
                }
              }
            }
          }
          @if (!menuItem.children) {
            <a class="nav-link menubtn" target="_blank" [href]="menuItem.redirect">{{ menuItem.label }} </a>
          }
        </ng-container>
      </div>
    }
  </div>
  <app-search />
  <div class="div-mobile">
    @if (busca) {
      <app-search-results />
    }
  </div>
</div>

<ng-template #menuItemContainer let-menuItem>
  @if (menuItem.externo) {
    <a class="menu-item nav-link" target="_blank" [href]="menuItem.redirect">{{ menuItem.label }} </a>
  }
  @if (!menuItem.externo) {
    <a class="menu-item nav-link" target="_self" [href]="menuItem.redirect">{{ menuItem.label }} </a>
  }
</ng-template>
