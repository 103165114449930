<app-separador label="Sobre a Ouvidoria"></app-separador>
<p><b>Ouvidor da PGE:</b> Eduardo José Fagundes.</p>
<p>
  A Ouvidoria é um canal direto de comunicação entre a Procuradoria Geral do Estado e os usuários de seus serviços. Suas principais atribuições são receber e encaminhar queixas, reclamações e
  sugestões. A Ouvidoria da Procuradoria Geral do Estado foi instituída pela Resolução PGE n. 409 de 23.8.99, em cumprimento ao disposto na Lei n. 10.294, de 20.4.1999, regulamentada pelo Decreto n.
  44.074, de 1º. 7.1999
</p>
<p>
  A Lei n. 10.294/99 trouxe importantes inovações: estabeleceu os direitos básicos dos usuários, determinou a instituição de Ouvidorias e Comissões de Ética em todos os órgãos e entidades prestadoras
  de serviços públicos no Estado de São Paulo, criou o Sistema Estadual de Defesa do Usuário de Serviços Públicos. A Ouvidoria está subordinada diretamente ao Procurador Geral do Estado e o Ouvidor,
  no exercício de suas funções, tem livre acesso a todos os setores do órgão em que atua.
</p>
<p><b>Orientações:</b></p>
<p><b>A - </b>Não serão aceitas manifestações anônimas (art. 5º, inciso IV, da Constituição Federal);</p>
<p><b>B - </b>Os pedidos de pareceres jurídicos emitidos pela PGE deverão ser feitos diretamente à Ouvidoria da Secretaria de Estado envolvida no assunto;</p>
<p>
  <b>C - </b>Se sua pergunta estiver relacionada à Dívida Ativa, consulte antes o portal <a href="http://www.dividaativa.pge.sp.gov.br/" target="_blank">www.dividaativa.pge.sp.gov.br</a>. Atendimento
  e dúvidas, encaminhar mensagem exclusivamente para <a href="https://www.dividaativa.pge.sp.gov.br/sc/loginInternet.jsf" target="_blank">www.dividaativa.pge.sp.gov.br/sc/loginInternet.jsf</a>, ou
  <a href="https://www.dividaativa.pge.sp.gov.br/sc/loginInternet.jsf" target="_blank">clicando aqui</a>.
</p>
<p>
  <b>D - </b>Se sua pergunta se relacionar à obrigação de pequeno valor e informe de rendimentos recebidos a esse título, consultar a página,
  <a href="http://www.portal.pge.sp.gov.br/precatorios/" target="_blank">http://www.portal.pge.sp.gov.br/precatorios/</a> ou
  <a href="http://www.portal.pge.sp.gov.br/precatorios/" target="_blank">clicando aqui</a>. Se precisar de outros esclarecimentos, envie uma mensagem para:
  <a href="mailto:{{ emailPrecatorio }}?subject=Assunto,%20pergunta%20relacionada%20à%20obrigação%20de%20pequeno%20valor%20e%20informe%20de%20rendimentos%20recebidos%20a%20esse%20título.">{{
    emailPrecatorio
  }}</a>
</p>
