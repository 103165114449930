<div class="flex-container">
  <div class="header-menu-container">
    <div>
      <app-separador label="SERVIÇOS"></app-separador>
      <app-servicos></app-servicos>
    </div>
    <div>
      <app-separador label="DESTAQUES"></app-separador>
    </div>
    <div>
      <app-noticias-destaque></app-noticias-destaque>
    </div>
    <br style="margin: 8px" />
    <div>
      <app-separador label="ACESSO RÁPIDO"></app-separador>
    </div>
    <div class="full-width-background">
      <app-acesso-rapido></app-acesso-rapido>
    </div>
    <div class="full-width-background-redes" style="margin-top: 30px">
      <app-nas-redes></app-nas-redes>
    </div>
  </div>
</div>
