<main class="main" role="main">
  <app-separador label="Atendimento"></app-separador>
  <section class="page">
    <div class="container">
      <div class="row">
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 the_content">
          <p><b>Antes de remeter sua consulta,&nbsp;&nbsp;orientamos a que&nbsp;verifique a possibilidade de encontrar as respostas que procura&nbsp;diretamente&nbsp;nos seguintes locais:</b></p>
          <p>
            1) Para Transação de dívidas&nbsp;inscritas (<strong>desconto de multa e juros</strong>), acesse o&nbsp;<em>site</em>–
            <b
              ><a id="LPlnk" href="https://www.dividaativa.pge.sp.gov.br/transacao/pages/home/home.jsf" target="_blank" rel="noopener noreferrer" data-auth="NotApplicable"
                >https://www.dividaativa.pge.sp.gov.br/transacao/pages/home/home.jsf</a
              ></b
            >
          </p>
          <p>
            2) Para Dívida Ativa, consulte o&nbsp;<i>site&nbsp;</i>do contribuinte&nbsp;–<b
              ><a href="http://www.dividaativa.pge.sp.gov.br/" target="_blank" rel="noopener noreferrer" data-auth="NotApplicable">www.dividaativa.pge.sp.gov.br</a><br
            /></b>
          </p>
          <p>
            3) Se o assunto for Precatórios ou Obrigação de Pequeno Valor – OPV , consulte o Portal dos Precatórios –
            <b
              ><a id="LPlnk" href="http://www.portal.pge.sp.gov.br/precatorios/" target="_blank" rel="noopener noreferrer" data-auth="NotApplicable"
                >Precatórios – PGE – Procuradoria Geral do Estado de SP</a
              ></b
            >
          </p>
          <p>
            4) Outras informações&nbsp; sobre a Administração podem ser encontradas no Portal da Transparência do Estado de São Paulo –<b
              ><a href="http://www.transparencia.sp.gov.br/" target="_blank" rel="noopener noreferrer" data-auth="NotApplicable">www.transparencia.sp.gov.br</a></b
            >
          </p>
          <p>&nbsp;</p>
          <p><span dir="ltr">Forma de Contato do Cidadão com a Ouvidoria:</span></p>
          <p>
            <span dir="ltr">Comparecimento</span><span dir="ltr">: Rua Pamplona, 227 – 1º andar – Bela Vista – CEP: 01405-902 – Capital/SP<br /></span>
            <span dir="ltr">(atendimento por agendamento).<br /></span> <span dir="ltr">Telefones:</span><span dir="ltr">&nbsp;</span
            ><span dir="ltr">(11) 3372-6405/6406 (atendimento das 08:00 às 18:00)</span><br aria-hidden="true" />
            <span dir="ltr">email:</span><span dir="ltr">&nbsp;</span
            ><span dir="ltr"><a href="mailto:pgeouvidoria@sp.gov.br" target="_blank" rel="noopener noreferrer">pgeouvidoria&#64;sp.gov.br</a><br /></span> <span dir="ltr">Sistema das Ouvidorias</span
            ><span dir="ltr">:</span>
            <b class="x_elementToProof"
              ><span dir="ltr"
                ><i
                  ><b><a href="http://www.ouvidoria.sp.gov.br/" target="_blank" rel="noopener noreferrer" data-auth="NotApplicable" data-linkindex="1">www.ouvidoria.sp.gov.br</a></b></i
                ></span
              ></b
            >
          </p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <hr />
        </div>
      </div>
    </div>
  </section>
</main>
