<main class="main" role="main">
  <app-separador label="Precatórios – PERGUNTAS FREQUENTES"></app-separador>
  <section class="page">
    <div class="container">
      <div class="row">
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 the_content">
          <p style="text-align: center">
            Acesse o Portal dos Precatórios <a href="https://www.precatorios.pge.sp.gov.br:8443/ppr" target="_blank" rel="noopener noreferrer"><strong>CLIQUE AQUI</strong></a>
          </p>
          <p><b>PERGUNTAS FREQUENTES:</b></p>
          <p>
            <b>1. Quero fazer acordo no meu precatório, como faço o pedido?</b><br />
            O pedido é feito pelo portal da PGE, por intermédio do seu advogado na ação de origem do precatório.
          </p>
          <p>
            <b>2. Posso pedir o acordo diretamente?</b><br />
            O acordo é judicial e, portanto, é necessária a representação por advogado. Só pode pedir diretamente o credor que não tiver sido representado por advogado na ação de origem do precatório,
            coisa que só pode ocorrer perante juizado especial, constituindo exceção à regra.
          </p>
          <p>
            <b>3. Sou advogado e quero fazer acordo dos honorários que tenho a receber, é possível?</b><br />
            Sim, podendo ser feito acordo tanto dos honorários de sucumbência quanto dos contratuais, bastando para isso acessar o site da PGE e escolher a opção correspondente.
          </p>
          <p>
            <b>4. Qual o prazo para pedir acordo?</b><br />
            Os pedidos de acordo estão sendo recebidos a partir de 4 de dezembro de 2017, e serão recebidos enquanto valer a autorização dada pela Emenda Constitucional 94/2016, que vai até 31 de
            dezembro de 2029.
          </p>
          <p>
            <b>5. O desconto do acordo depende do ano do precatório?</b><br />
            Não. O desconto é de 40% sobre o crédito atualizado, e o percentual é o mesmo independente da sua posição na fila, valendo lembrar que contribuições ao INSS, SPPREV, IPESP, IAMSPE e Cruz
            Azul, quando houver, bem como Imposto de Renda, quando incidente sobre o valor a receber, serão calculados somente depois de aplicado o desconto (ou seja, sobre os 60% do crédito após a
            dedução do desconto de 40%), observando a regra aplicável a cada caso (e, em especial, a ocorrência de recebimento acumulado de parcelas mensais em atraso, que comporta isenção) e as suas
            condições pessoais (idade e eventual moléstia grave ou deficiência, que seja causa de isenção).
          </p>
          <p>
            <b>6. Como sei o valor que vou receber?</b><br />
            Seu advogado (ou você mesmo, diretamente, quando não houver um na ação de origem do precatório – vide questão 2, acima) pode consultar o valor no site da PGE, e com base nisso lhe dar uma
            estimativa do valor a receber. Até a formalização do acordo, os valores da PGE correspondem ao bruto a receber (principal do credor, sem a dedução de contribuições nem do importo de
            renda), pois só saberemos o líquido ao aplicarmos o desconto, após seu advogado informar os dados necessários à correta dedução das contribuições e cálculo do imposto de renda
            (contribuições ao INSS, SPPREV, IPESP, IAMSPE e Cruz Azul, quando houver, bem como Imposto de Renda, quando incidente sobre o valor a receber, serão calculados observando a regra aplicável
            a cada caso, tendo em conta a natureza da verba, o recebimento de parcelas em atraso, e as condições pessoais do credor, em caso de idade, moléstia grave s/ou deficiência, que sejam causa
            de isenção), valendo lembrar que impostos e contribuições incidem somente sobre os valores efetivamente recebidos (sobre os 60% a receber, após a aplicação do desconto), e não sobre o
            total do crédito. Na dúvida, consulte sempre a PGE.
          </p>
          <p>
            <b>7. Que documentos preciso apresentar?</b><br />
            É necessária a apresentação dos seguintes documentos: (i) procuração concedida ao advogado, no processo de origem do precatório, onde constem poderes específicos para fazer o acordo; (ii)
            comprovante de que é o titular do crédito em que pretende fazer acordo, o que no caso do credor originário deve ser feito apresentando a cópia de sua conta no processo e, para quem o tenha
            sucedido no processo (caso de herdeiro e/ou cessionário), adicionalmente o documento que comprove a sucessão (no caso de cessão de crédito, o termo ou contrato da cessão; no caso de
            herança, a escritura ou formal de partilha judicial), sendo necessário que nos casos de cessão e herança haja a prévia comunicação nos autos do processo de origem; (iii) certidão de
            trânsito em julgado do processo de origem do precatório, ou outro equivalente, que comprove ser definitivo o crédito, e não mais haver discussão judicial em relação a ele; (iv) contrato de
            honorários com o advogado, para reserva do percentual que a este tiver sido reservado, de modo que o acordo seja feito somente sobre o crédito disponível.
          </p>
          <p>
            <b>8. Em quanto tempo devo receber?</b><br />
            A PGE cuidará do rápido exame e encaminhamento ao tribunal que expediu o precatório, para que este providencie sua validação e final pagamento, o que estimamos possa ocorrer em não mais
            que 90 dias da data do acordo, lembrando porém que o rápido exame depende em muito de seu cuidado na instrução do pedido, e que o pagamento e a final liberação do valor sempre dependerão
            dos tribunais.
          </p>
          <p>
            <b>9. Sou advogado e não tenho acesso ao site da PGE, como procedo?</b><br />
            Por questões de segurança, o primeiro acesso depende de solicitação formal, devendo ser apresentada carta de solicitação e, quando se tratar de sociedade de advogados, o contrato social e
            relação dos advogados vinculados ao escritório, para que possamos vincular corretamente todos os processos. O pedido deve constar em documento próprio, em anexo à mensagem eletrônica, em
            conjunto com os documentos pertinentes, e encaminhado para protocolopge&#64;sp.gov.br.
          </p>
          <p>
            <b>10. Sou advogado e, ao consultar o site da PGE, não consigo visualizar os processos de meus clientes, o que pode estar acontecendo e como resolver isso?</b><br />
            Se algum processo seu não está listado ou, dentro de um processo, não é possível visualizar algum de seus clientes, o mais provável é que o processo e/ou o cliente não estejam vinculados
            (vide questão 9, acima), sendo preciso que informe seus processos à PGE, para que possamos proceder à correta vinculação. É também possível que tenha havido a cessão do crédito do
            processo, pelo seu cliente, ou mesmo a constituição de um novo advogado, pelo que recomendamos sempre que previamente seja revisto o andamento do processo, para averiguação.
          </p>
          <p>&nbsp;</p>
          <hr />
        </div>
      </div>
    </div>
  </section>
</main>
