import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { CategoriaNoticia } from 'src/app/area-aberta/shared/enums/categoria-noticia';
import { ConversorValidadorService } from 'src/app/area-restrita/shared/services/conversor-validador.service';
import { Noticias, pdfAnexo } from 'src/app/shared/models/noticia.interface';
import { Page } from 'src/app/shared/models/paginacao/page.model';
import { NoticiaService } from 'src/app/shared/services/noticia.service';
import { ImagemTratamento } from '../../home/shared/services/imagem-tratamento';
import { MatCard, MatCardContent } from '@angular/material/card';
import { SeparadorComponent } from '../../../core/layout/separador/separador.component';
import { MatIcon } from '@angular/material/icon';
import { MatAnchor } from '@angular/material/button';

@Component({
  selector: 'app-visualizar-comunicado',
  templateUrl: './visualizar-comunicado.component.html',
  styleUrl: './visualizar-comunicado.component.sass',
  providers: [ImagemTratamento],
  standalone: true,
  imports: [MatAnchor, MatIcon, SeparadorComponent, MatCard, MatCardContent]
})
export class VisualizarComunicadoComponent implements OnInit {
  noticia: Noticias | undefined;
  objNoticias?: Noticias[] = [];
  arquivos: pdfAnexo[] = [];
  noticiaId: number;

  constructor(
    private noticiaService: NoticiaService,
    private route: ActivatedRoute,
    private router: Router,
    private imagemTratamento: ImagemTratamento,
    private base64ToPdfService: ConversorValidadorService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.noticiaId = Number.parseInt(params.get('id'));

      if (this.noticiaId !== null) {
        this.noticiaService.getOneNoticia(this.noticiaId).subscribe((noticia) => {
          this.noticia = noticia;
          this.obterNoticiasDestaque();
        });
        //TODO: Redirect para o login caso não o ID seja invalido
      }
    });
    this.obterArquivos(this.noticiaId);
  }

  obterArquivos(idNoticia: number): void {
    this.noticiaService.obterArquivosNoticias(idNoticia).subscribe(
      (response) => {
        this.arquivos = response;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  retornaListaComunicados(): void {
    this.router.navigate(['informacao-cidadao/comunicados']);
  }

  obterNoticiasDestaque(): void {
    this.noticiaService.getNoticia(0, 4, CategoriaNoticia.NOTICIA_AREA_ABERTA).subscribe((response: Page<Noticias>) => {
      this.objNoticias = response.content.filter((noticia) => noticia.ativo === true).slice(0, 4);
    });
  }

  abrirUmaNoticia(noticiaId: number) {
    this.router.navigate(['/uma-noticia', noticiaId]);
    this.obterArquivos(noticiaId);
  }

  getImagem(noticiaImagem: string): string | undefined {
    return this.imagemTratamento.getImagemBlob(noticiaImagem);
  }

  editarData(data: string): string {
    if (!data || data.length < 10) {
      console.error('Data inválida:', data);
      return 'Data inválida';
    }

    const ano = data.substring(6, 10);
    const mes = this.transformarMes(data.substring(3, 5));
    const dia = data.substring(0, 2);

    return `${dia} de ${mes} de <strong>${ano}</strong>`;
  }

  transformarMes(mes: string): string {
    const mesEditado = mes.replace('0', '');
    const month = Number(mesEditado);
    const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

    return months[month - 1];
  }

  processarTexto(texto: string): string {
    if (texto) {
      return texto.replace(/<img\b[^>]*>/, (match) => match.replace('>', ' width="60%">'));
    } else {
      return '';
    }
  }

  openPDF(event: MouseEvent, pdf: pdfAnexo): void {
    event.preventDefault();
    this.base64ToPdfService.base64ToBlob(pdf.arquivo);
  }
}
