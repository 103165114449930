<div class="search-results-container">
  <h1>Resultados para: {{ search }}</h1>
  @if (!loading) {
    <mat-list>
      @for (item of searchResults; track item) {
        <mat-list-item class="list-item">
          <header>
            <h2>
              <a [href]="item.url" style="white-space: break-spaces" matLine>{{ item.titulo }}</a>
            </h2>
          </header>
          <p matLine class="description">
            {{ stripHTML(item.texto) | slice: 0 : 350 }}
            @if (item.texto) {
              <span [routerLink]="item.url" matLine class="read-more">Leia mais</span>
            }
          </p>
          <mat-divider></mat-divider>
        </mat-list-item>
      }
    </mat-list>
  } @else {
    <div class="spinner-container">
      <mat-spinner></mat-spinner>
    </div>
  }
</div>

<ng-template #spinner>
  <div class="spinner-container">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
