import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatFormField } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';
import { Subject, finalize, takeUntil } from 'rxjs';
import { HistoricoSolicitacoes } from 'src/app/area-restrita/features/auxilio/saude/shared/models/historico-solicitacoes';
import { ConfirmaDialogData } from 'src/app/area-restrita/shared/components/confirma-dialog/confirma-dialog-data';
import { ConfirmaDialogComponent } from 'src/app/area-restrita/shared/components/confirma-dialog/confirma-dialog.component';
import { WaitLoadingService } from 'src/app/area-restrita/shared/components/wait-loading/wait-loading.service';
import { PaginaVisitadaService } from 'src/app/area-restrita/shared/services/pagina-visitada.service';
import { Page } from 'src/app/shared/models/paginacao/page.model';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { LimiteMensalService } from '../../shared/services/limite-mensal.service';
import { AuxilioStatusEnum } from '../shared/enums/auxilio-status.enum';
import { StatusAuxilio } from '../shared/models/auxilio-saude';
import { LimiteMensalAuxilio } from '../shared/models/limite-mensal.model';
import { TipoAuxilio } from '../shared/models/tipo-auxilio';
import { TipoAuxilioSaude } from '../shared/models/tipo-auxilio-saude';
import { AuxilioSaudeService } from '../shared/services/auxilio-saude.service';
import { MesaAuxilioSaudeService } from '../shared/services/mesa-auxilio-saude.service';
import { SolicitacaoAuxilioSaudeService } from '../shared/services/solicitacao-auxilio-saude.service';

@Component({
  selector: 'app-pedido-auxilio-saude',
  templateUrl: './pedido-auxilio-saude.component.html',
  styleUrl: './pedido-auxilio-saude.component.sass',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatSelect,
    MatOption,
    MatButtonModule,
    MatInput,
    MatButton,
    MatPaginator,
    CurrencyPipe,
    MatIconModule
  ]
})
export class PedidoAuxilioSaudeComponent implements OnInit, OnDestroy {
  @Input() titulo: string;
  private readonly destroy$ = new Subject<void>();
  historicoSolicitacoesResponse: HistoricoSolicitacoes[] = [];
  saldo: number = 0;
  deferido: number = 0;
  meses: string[] = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
  pageEvent: PageEvent;
  length = -1;
  vazio = false;
  pageSize = 10;
  pageNumber = 0;
  pageSizeOptions = [5, 10, 25];
  paginador = false;
  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;
  statusAuxilio: StatusAuxilio[];
  readonly statusAuxilioEnum = AuxilioStatusEnum;
  tiposPrograma: TipoAuxilio[];
  tiposAuxilioSaude: TipoAuxilioSaude[];
  limiteReembolsoResponse: number = 0;

  constructor(
    private solicitacaoAuxilioSaudeService: SolicitacaoAuxilioSaudeService,
    private auxilioSaudeService: AuxilioSaudeService,
    private mesaAuxilioSaudeService: MesaAuxilioSaudeService,
    private limiteMensalService: LimiteMensalService,
    private formBuilder: FormBuilder,
    private toast: ToastService,
    private waitLoadingService: WaitLoadingService,
    private router: Router,
    private paginaService: PaginaVisitadaService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.paginaService.salvaPagina(this.titulo ?? 'Dados do Pedido').subscribe();
    this.obterTipoAuxilio();
    this.obterListaTipoAuxilioSaude();
    this.obterTiposStatus();
  }

  dadosFormulario = this.formBuilder.group({
    status: [''],
    mesAnoSolicitacao: [''],
    programa: [''],
    tipoAuxilioSaude: [''],
    totalSolicitado: '',
    totalDeferido: ''
  });

  buscarPedidos(): void {
    this.waitLoadingService.open();

    this.solicitacaoAuxilioSaudeService
      .obterLista(this.dadosFormulario, this.pageNumber, this.pageSize)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.waitLoadingService.close())
      )
      .subscribe({
        next: (dados: Page<HistoricoSolicitacoes>) => {
          this.historicoSolicitacoesResponse = dados.content;
          this.calculaSaldo();

          this.historicoSolicitacoesResponse.sort((a, b) => {
            if (a.anoReembolso > b.anoReembolso) return -1;
            if (a.anoReembolso < b.anoReembolso) return 1;
            if (a.mesParcela > b.mesParcela) return -1;
            if (a.mesParcela < b.mesParcela) return 1;
            return 0;
          });

          this.length = dados.totalElements;
          this.vazio = dados.empty;
          this.paginador = !dados.empty;
        }
      });
  }

  calculaSaldo(): void {
    console.warn('Atenção!!! Calcula saldo de acordo com o mês corrente, lembre-se disso.');
    this.saldo = this.limiteReembolsoResponse;
    this.deferido = 0;
    const dados = this.historicoSolicitacoesResponse;
    for (const dado of dados) {
      this.deferido += Number(dado.valorDeferidoPge);
    }
    this.saldo -= this.deferido;
  }

  pedido(id: string) {
    this.router.navigate(['area-restrita', 'pedido-auxilio-saude', id]);
  }

  obterTiposStatus() {
    this.mesaAuxilioSaudeService
      .obterListaStatusAuxilio()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (dados: StatusAuxilio[]) => {
          this.statusAuxilio = dados;
        },
        error: (error) => console.error('Erro ao obter dados do serviço', error)
      });
  }

  obterTipoAuxilio() {
    this.solicitacaoAuxilioSaudeService
      .obterTipoAuxilio()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (dados) => {
          this.tiposPrograma = dados;
        },
        error: (error) => console.error('Erro ao obter dados do serviço', error)
      });
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageNumber = e.pageIndex;
    this.buscarPedidos();
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map((str) => +str);
    }
  }

  limparFormulario() {
    this.dadosFormulario.reset({
      status: '',
      mesAnoSolicitacao: '',
      programa: '',
      totalSolicitado: '',
      totalDeferido: ''
    });
    this.length = 0;
    this.pageSize = 10;
    this.pageNumber = 0;
    this.historicoSolicitacoesResponse = [];
    this.paginador = false;
    this.vazio = false;
  }

  obterLimite(): void {
    this.limiteMensalService
      .consultaLimiteMensal(localStorage.getItem('idUsuario'), this.dadosFormulario.get('anoReembolso').value, this.dadosFormulario.get('mesParcela').value)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (dados: LimiteMensalAuxilio) => {
          this.limiteReembolsoResponse = dados.valor;
        },
        error: (error) => console.error('Erro ao obter dados do serviço', error)
      });
  }

  remover(id: string) {
    const data: ConfirmaDialogData = {
      title: 'Cancelar Pedido de Auxílio',
      message: 'Deseja remover o pedido?',
      escondeBotaoNao: false
    };
    const dialogRef = this.dialog.open(ConfirmaDialogComponent, {
      width: '50%',
      data: data
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.waitLoadingService.open();
        this.auxilioSaudeService.cancelarPedidoAuxilioSaude(Number(id)).subscribe({
          next: () => {
            this.toast.info('Pedido cancelado com sucesso');
            this.waitLoadingService.close();
            this.buscarPedidos();
          },
          error: (error) => {
            if (error.status === 409) {
              this.toast.error(error.error);
            } else {
              console.error('erro', error);
            }
            this.waitLoadingService.close();
          }
        });
      }
    });
  }

  obterListaTipoAuxilioSaude(): void {
    this.solicitacaoAuxilioSaudeService.obterListaTipoAuxilioSaude().subscribe((response) => {
      this.tiposAuxilioSaude = response;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
