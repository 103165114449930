import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CategoriaNoticia } from 'src/app/area-aberta/shared/enums/categoria-noticia';
import { Noticias } from 'src/app/shared/models/noticia.interface';
import { Page } from 'src/app/shared/models/paginacao/page.model';
import { NoticiaService } from 'src/app/shared/services/noticia.service';
import { ImagemTratamento } from './../../shared/services/imagem-tratamento';
import { MatButton } from '@angular/material/button';

export interface Tile {
  cols: number;
  rows: number;
}

@Component({
  selector: 'app-noticias-destaque',
  templateUrl: './noticias-destaque.component.html',
  styleUrl: './noticias-destaque.component.sass',
  providers: [ImagemTratamento],
  standalone: true,
  imports: [MatButton]
})
export class NoticiasDestaqueComponent implements OnInit, OnDestroy {
  tiles: Tile[] = [
    { cols: 3, rows: 2 },
    { cols: 1, rows: 1 },
    { cols: 1, rows: 1 },
    { cols: 1, rows: 1 },
    { cols: 1, rows: 1 }
  ];

  cardsObj = [];

  constructor(
    private noticiaService: NoticiaService,
    private router: Router,
    private imagemTratamento: ImagemTratamento
  ) {}

  ngOnInit(): void {
    this.carregarNoticia();
  }

  carregarNoticia(): void {
    this.noticiaService.getNoticia(0, 6, CategoriaNoticia.NOTICIA_AREA_ABERTA).subscribe(
      (response: Page<Noticias>) => {
        const noticia = response.content;
        noticia.forEach((element) => {
          this.cardsObj.push(element);
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getImagem(noticiaImagem: string): string | undefined {
    return this.imagemTratamento.getImagemBlob(noticiaImagem);
  }

  abrirUmaNoticia(noticiaId: number) {
    this.router.navigate(['/uma-noticia', noticiaId]);
  }

  noticiasDiversas(): void {
    this.router.navigate(['/noticias-diversas']);
  }

  ngOnDestroy(): void {
    this.imagemTratamento.revogarUrlBlob();
  }

  calculateColSpan(tile: Tile, index: number): number {
    const totalCols = 5; // Número total de colunas definido no cols do mat-grid-list
    const numTiles = this.tiles.length;
    const remainingCols = totalCols - (index % totalCols); // Número de colunas restantes neste "linha" do grid
    const maxColSpan = Math.min(tile.cols, remainingCols, numTiles - index); // Garantindo que o tile não exceda o número total de colunas ou o número de tiles restantes
    return maxColSpan;
  }
}
