<main class="main" role="main">
  <section>
    <app-separador label="Momento Virtual do Procurador" />
    <br />
    Preencha o formulário abaixo e clique no botão enviar!
    <br />
    <br />
    <table class="ajuste-email">
      <tbody>
        <tr>
          <td class="ajuste-email">Para:</td>
          <td class="colunaInput">
            <input name="txtEmailDestinatario1" type="text" class="form-control" value="conselhopge@sp.gov.br" readonly="readonly" disabled="disabled" />
          </td>
        </tr>
        <tr>
          <td class="ajuste-email">Nome:</td>
          <td class="colunaInput">
            <input name="txtNome" type="text" class="form-control" />
          </td>
        </tr>
        <tr>
          <td>E-mail:</td>
          <td class="colunaInput">
            <input name="txtEmailRemetente" type="text" class="form-control" />
          </td>
        </tr>
        <tr>
          <td>Assunto:</td>
          <td class="colunaInput">
            <input name="txtAssunto" type="text" class="form-control" />
          </td>
        </tr>
        <tr>
          <td>Telefone:</td>
          <td class="colunaInputTelefone">
            <input type="text" class="form-control" id="telefone" mask="(00) 00000-0000" name="telefone" />
          </td>
        </tr>
        <tr>
          <td>Mensagem:</td>
          <td class="colunaInput">
            <textarea name="txtMensagem" class="form-control"></textarea>
            <br />
          </td>
        </tr>
        <tr>
          <td></td>
          <td class="ajuste-email-2">
            <button mat-button class="btn btn-dark me-2" name="btnLimpar" type="submit">Limpar</button>
            <button mat-button class="btn btn-dark me-2" name="btnEnviar" type="submit">Enviar</button>
          </td>
        </tr>
      </tbody>
    </table>
    <br />
  </section>
</main>
