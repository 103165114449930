import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, catchError } from 'rxjs';
import { AuthenticationService } from '../../shared/services/authentication.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  token: string = '';
  authenticationService = inject(AuthenticationService);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const RequestWithToken = request.clone({
      headers: request.headers.set('Authorization', this.authenticationService.getTokenValue() || '')
    });
    return next.handle(RequestWithToken).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status == 401 || err.status == 403) {
          this.authenticationService.logout();
          throw new Error('Error in TokenInterceptor. ' + err.message.toString());
        }
        throw err;
      })
    );
  }
}
