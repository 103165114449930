<div class="container">
  <br />
  <app-separador label="Legislação - Procuradoria Geral do Estado"></app-separador>
  <br />
  <div class="row g-3">
    <form [formGroup]="dadosFormulario" (ngSubmit)="buscar()">
      <div class="d-flex row">
        <div class="col-md-3">
          <mat-label for="tipoLegislacao" class="form-label" id="tipoLegislacao">Tipo:</mat-label>
          <mat-form-field class="mat-form-control">
            <mat-select formControlName="tipoLegislacao">
              @for (s of tipoLegislacao; track s) {
                <mat-option [value]="s.id">{{ s.descricao }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-9">
          <mat-label for="publicacao" class="form-label" id="publicacao">Publicação:</mat-label>
          <mat-form-field class="mat-form-control">
            <input matInput type="text" id="publicacao" formControlName="publicacao" />
          </mat-form-field>
        </div>
      </div>
      <div class="d-flex row">
        <div class="col-md-12">
          <mat-label for="conteudo" class="form-label" id="conteudo"> Palavras no Ato Normativo:</mat-label>
          <mat-form-field class="mat-form-control">
            <input matInput type="text" id="conteudo" formControlName="conteudo" />
          </mat-form-field>
        </div>
      </div>
      <br />
      <div class="d-flex row">
        <div class="col-12 conjunto-botoes">
          <button mat-flat-button color="primary" type="submit" id="buscar" name="buscar" class="btn-dark" [disabled]="!this.dadosFormulario.valid">Buscar</button>
        </div>
      </div>
    </form>
    @if (mostraTabela) {
      <div>
        <br />
        <div class="table-responsive">
          <table class="tabelaPadrao">
            <thead>
              <tr>
                <th class="fw-bold text-center">Publicação</th>
                <th class="fw-bold text-center">Conteudo</th>
              </tr>
            </thead>
            <tbody>
              @for (publicacao of publicacoes; track publicacao) {
                <tr>
                  <td class="text-center">
                    {{ publicacao.publicacao }}
                  </td>
                  <td class="alinhamento" [innerHTML]="publicacao.conteudo | truncateHtml: 350" (click)="openPDF($event, publicacao.id)"></td>
                </tr>
              }
            </tbody>
          </table>
          <!-- Aviso quando a tabela estiver vazia -->
          @if (!publicacoes || publicacoes.length === 0) {
            <div>
              <p class="text-center">Nenhum registro encontrado.</p>
            </div>
          }
        </div>
        <mat-paginator
          #paginator
          class="custom-paginator"
          (page)="handlePageEvent($event)"
          [length]="length"
          [pageSize]="pageSize"
          [disabled]="disabled"
          [showFirstLastButtons]="showFirstLastButtons"
          [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
          [hidePageSize]="hidePageSize"
          [pageIndex]="pageNumber"
          aria-label="Selecionar Página"
        ></mat-paginator>
        <br />
      </div>
    }
    <p>Para consultar os Atos Normativos anteriores a 02/05/2006 <a href="../../../../../assets/pdf/resolucoes-13.pdf" target="_blank">clique aqui.</a></p>
  </div>
</div>
