import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extrairAnoData',
  standalone: true
})
export class ExtrairAnoDataPipe implements PipeTransform {
  transform(value: string): number {
    const parts = value.split('/');
    const year = parseInt(parts[2], 10);

    return year;
  }
}
