<main class="main" role="main">
  <section class="page">
    <div class="flex-container-separador">
      <app-separador label="Precatórios" />
      <div class="flex-spacer-separador"></div>
      <app-separador-email
        label="ATENDIMENTO EXCLUSIVAMENTE POR EMAIL"
        msg1="Envio de Ofícios, Documentos e Solicitações:"
        msg2="protocolopge@sp.gov.br"
        msg3="Dúvidas: precatoriospgeatendimento@sp.gov.br"
        msg4=""
      />
    </div>
    <div class="row">
      <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 the_content">
        <p style="text-align: center">
          Acesse o Portal dos Precatórios <a href="https://www.precatorios.pge.sp.gov.br:8443/ppr" target="_blank" rel="noopener noreferrer"><strong>CLIQUE AQUI</strong></a>
        </p>
        <p>
          INFORMAMOS que continua em andamento a análise e deferimento dos acordos já propostos, e aberta a possibilidade de requerimento de novos acordos, que serão normalmente processados e
          deferidos.
        </p>
        <p>
          Está aberta a possibilidade de requerimento de acordo direto, para fins de antecipação de pagamento mediante desconto, observada a regulamentação dada pela Resolução PGE nº 15, de 17 de
          junho de 2024, e os termos do Edital PGE nº 1, de 29 de julho de 2024.
        </p>
        <p><b>IMPORTANTE:</b></p>
        <p>
          Desde 1º de janeiro de 2010, por determinação da Emenda Constitucional n.º 62/2009, a administração dos precatórios da Fazenda do Estado, bem como de suas autarquias, fundações e
          universidades, passou a ser feita pelo Tribunal de Justiça do Estado, pelo que não constam, no presente portal, as informações dos pagamentos de precatórios que foram efetuados a partir
          daquela data. Constam do presente portal apenas as informações relativas aos precatórios pagos anteriormente, e informações relativas a requisições diretas de pagamento de obrigações de
          pequeno valor.
        </p>
        <p><b>OBSERVAÇÕES:</b></p>
        <ul>
          <li>
            Em caso de dúvida no acesso ao portal de precatórios, consulte o manual do credor&nbsp;<a
              href="http://www.pge.sp.gov.br/precatorios/ManualCredor_PortalPrecat%C3%B3rios.pdf"
              target="_blank"
              rel="noopener noreferrer"
              ><strong>(clique aqui)</strong></a
            >;
          </li>
          <br />
          <li>
            Requisições de pagamento de obrigações de pequeno valor devem ser protocolizadas junto à entidade devedora, juntamente com a documentação pertinente (conta, peças essenciais do processo,
            comprovante de trânsito em julgado), devendo o acompanhamento ser efetuado pelo advogado, nos autos do processo em que expedida a requisição;
          </li>
          <br />
          <li>
            Informes de rendimentos pagos pela PGE (exclusivamente para requisições de pagamento de obrigações de pequeno valor) devem ser obtidos por intermédio do portal de precatórios&nbsp;<a
              href="https://www.precatorios.pge.sp.gov.br:8443/ppr"
              target="_blank"
              rel="noopener noreferrer"
              ><strong>(clique aqui)</strong></a
            >;
          </li>
          <br />
          <li>Informes de andamento processual e previsão de pagamento de precatório devem ser obtidas junto ao tribunal que os expediu;</li>
          <br />
          <li>Informações processuais devem ser obtidas junto ao advogado do processo.</li>
        </ul>
        <br />
        <p>
          <span style="color: red"><b>NOVO!&nbsp;</b></span
          ><b
            ><a routerLink="perguntas-frequentes"><strong>ACORDOS EM PRECATÓRIOS – ACESSO AO SISTEMA E PERGUNTAS FREQUENTES</strong></a></b
          >
        </p>
        <hr />
      </div>
    </div>
  </section>
</main>
