<main class="main" role="main">
  <section class="page">
    <div class="align-button">
      <a (click)="retornaListaComunicados()" (keypress)="retornaListaComunicados()" tabindex="0" mat-button class="btn p-2 m-2 btn-primary custom-width" style="color: white !important">
        Voltar para a lista de Comunicados</a
      >
    </div>
    <div class="only-one">
      <div class="uma-noticia">
        <h1 class="title-noticia">{{ noticia?.titulo }}</h1>
        <p class="data" [innerHTML]="editarData(noticia?.dataPublicacao)"></p>
        <div class="corpo-noticia" [innerHTML]="processarTexto(noticia?.texto)"></div>
        <div class="arquivo">
          <table>
            <tbody>
              @for (item of arquivos; track item; let i = $index) {
                <tr>
                  <td>
                    <a href="#" (click)="openPDF($event, item)"><mat-icon fontIcon="cloud_download"></mat-icon>{{ item.nome }}</a>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
      <div class="mais-noticias">
        <app-separador label="Outras Notícias"></app-separador>
        <div class="noticias-cards">
          <div class="noticia-card">
            @for (item of objNoticias; track item; let i = $index) {
              <mat-card class="card">
                <a (click)="abrirUmaNoticia(item?.id)" (keypress)="abrirUmaNoticia(item?.id)" tabindex="1" target="_blank">
                  <img [src]="getImagem(item?.imagem)" alt="Imagem {{ i + 1 }}" />
                </a>
                <mat-card-content>
                  <p class="titulo" (click)="abrirUmaNoticia(item?.id)" (keypress)="abrirUmaNoticia(item?.id)" tabindex="0">
                    {{ item?.titulo }}
                  </p>
                </mat-card-content>
              </mat-card>
            }
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
