export function transformarData(data: string): string {
  if (data) {
    const ano = data.substring(6, 10);
    const mes = transformarMes(data.substring(3, 5));
    const dia = data.substring(0, 2);
    return `Data: ${dia} de ${mes} de ${ano}`;
  }
  return '';
}

export function transformarMes(mes: string): string {
  const mesEditado = mes.replace('0', '');
  const month = Number(mesEditado);
  const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

  return months[month - 1];
}
