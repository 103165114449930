import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-nas-redes',
  templateUrl: './nas-redes.component.html',
  styleUrl: './nas-redes.component.sass',
  standalone: true
})
export class NasRedesComponent {
  assetsPath = environment.assetsPath;
}
