<main class="main" role="main">
  <section class="page">
    <app-separador label="Certidão Positiva com Efeito de Negativa (Cepen)"></app-separador>
    <div class="row">
      <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 the_content">
        <p>
          Para Certidão Positiva com Efeito de Negativa (Cepen) de débitos inscritos na Dívida Ativa, os pedidos, com os respectivos documentos necessários, deverão ser enviados de forma eletrônica ao
          e-mail do Núcleo competente para sua análise:
        </p>
        <p>– Núcleo Administrativo: <a href="mailto:pge-cepenfiscal@sp.gov.br" target="_blank" rel="noopener noreferrer">pge-cepenfiscal&#64;sp.gov.br</a></p>
        <p>
          Lembramos que, sem os documentos necessários, o seu pedido será devolvido para<br />
          regularização.
        </p>
        <p>Sua notificação será feita no e-mail indicado no pedido.</p>
        <hr />
      </div>
    </div>
  </section>
</main>
