import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { AcessoRapido } from 'src/app/area-aberta/features/home/acesso-rapido/acesso-rapido';
import { Servico } from 'src/app/area-aberta/features/home/servicos/servico.model';
import { ServicoList } from 'src/app/area-aberta/features/home/servicos/servicos';
import { NoticiaService } from 'src/app/shared/services/noticia.service';
import { MenuList } from '../menu-file';
import { SlicePipe } from '@angular/common';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatDivider } from '@angular/material/divider';
import { MatLine } from '@angular/material/core';
import { MatList, MatListItem } from '@angular/material/list';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrl: './search-results.component.sass',
  standalone: true,
  imports: [MatList, MatListItem, MatLine, RouterLink, MatDivider, MatProgressSpinner, SlicePipe]
})
export class SearchResultsComponent implements OnInit {
  search = '';
  loading = false;
  constructor(
    private activeRoute: ActivatedRoute,
    private noticiaService: NoticiaService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe((params) => {
      this.search = params['search'];
      this.noticiaPesquisa();
    });
  }

  @Input() searchResults = [];

  noticiaPesquisa() {
    this.loading = true;
    this.noticiaService.getNoticiaByName(this.search).subscribe(
      (resp) => {
        this.searchResults = resp.map((noticia) => {
          return { ...noticia, url: `uma-noticia/${noticia.id}` };
        });
        this.searchResults = [...this.searchResults, ...this.buscarServicos(), ...this.buscarPaginasInternas(), ...this.buscarAcessoRapido()];
        console.log('this.searchResults', this.searchResults);
        this.loading = false;
      },
      (error) => {
        console.error('Erro em buscar dados ', error);
        this.loading = false;
        this.searchResults = [...this.searchResults, ...this.buscarServicos(), ...this.buscarPaginasInternas(), ...this.buscarAcessoRapido()];
      }
    );
  }

  buscarServicos(): Servico[] {
    return ServicoList.filter((servico) => servico.titulo?.toLowerCase().includes(this.search?.toLowerCase()));
  }

  buscarAcessoRapido() {
    return AcessoRapido.map((acesso) => {
      return {
        titulo: acesso.alt,
        url: acesso.redirect
      };
    }).filter(({ titulo }) => titulo.toLowerCase().includes(this.search?.toLowerCase()));
  }

  buscarPaginasInternas() {
    const todasPaginas = [];

    MenuList.forEach((item) => {
      if (item.children) {
        todasPaginas.push(item.children);
        return item;
      }
      todasPaginas.push(item);
      return item;
    });
    return todasPaginas
      .map((pagina) => {
        return this.pegaTodasChildrens(pagina).flat(1);
      })
      .flat(1)
      .filter(({ titulo }) => titulo.toLowerCase().includes(this.search?.toLowerCase()));
  }

  pegaTodasChildrens(pagina) {
    if (pagina.length) {
      return pagina.map((paginaFilha) => {
        if (paginaFilha.children) {
          return this.pegaTodasChildrens(paginaFilha);
        }

        return {
          titulo: paginaFilha.label,
          url: paginaFilha.redirect
        };
      });
    }

    return [
      {
        titulo: pagina.label,
        url: pagina.redirect
      }
    ];
  }

  stripHTML(fonte: string) {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = fonte;
    return (tmp.textContent || tmp.innerText || '').slice(0, 350);
  }
}
