import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { Page } from 'src/app/shared/models/paginacao/page.model';
import { Globais } from '../../../../../shared/globais/globais';
import { LocalStorageService } from '../../../../../shared/services/local-storage.service';
import { AnoReembolso } from '../models/ano-reembolso';
import { AuxilioSaude, NotaFiscalAnexadaAuxilio } from '../models/auxilio-saude';
import { HistoricoSolicitacoes } from '../models/historico-solicitacoes';
import { TipoAuxilio } from '../models/tipo-auxilio';
import { TipoAuxilioSaude } from '../models/tipo-auxilio-saude';

@Injectable({
  providedIn: 'root'
})
export class SolicitacaoAuxilioSaudeService {
  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService
  ) {}

  obterLista(dadosFormulario: FormGroup, pageIndex: string | number, pageSize: string | number): Observable<Page<HistoricoSolicitacoes>> {
    const token = new String(localStorage.getItem('token')).toString();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token
    });

    const dataRecebida = dadosFormulario?.controls['mesAnoSolicitacao']?.value || '';

    let parametros = new HttpParams()
      .set('idUsuario', this.localStorage.get('idUsuario'))
      .set('idStatus', dadosFormulario?.controls['status']?.value || '')
      .set('totalSolicitado', dadosFormulario?.controls['totalSolicitado']?.value || '')
      .set('totalDeferido', dadosFormulario?.controls['totalDeferido']?.value || '')
      .set('idTipoAuxilio', dadosFormulario?.controls['programa']?.value || '')
      .set('idTipoAuxilioSaude', dadosFormulario?.controls['tipoAuxilioSaude']?.value || '')
      .set('page', pageIndex)
      .set('size', pageSize);
    if (dataRecebida && dataRecebida.trim() !== '') {
      const partesData = dataRecebida.split('-');
      parametros = parametros.set('anoReembolso', partesData[0]);
      parametros = parametros.set('mesParcela', partesData[1]);
    }

    return this.http.get<Page<HistoricoSolicitacoes>>(Globais.urlToken + 'site-pge/api/auxilios/historico', { headers: headers, params: parametros }).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        console.error('Erro durante o envio de dados:', error);
        return throwError('Falha no envio de dados.');
      })
    );
  }

  obterListaTipoAuxilio(): Observable<TipoAuxilio[]> {
    return this.http.get<TipoAuxilio[]>(`${Globais.urlToken}site-pge/api/tipos-auxilios`).pipe(
      catchError((error) => {
        console.error('Erro durante o envio de dados:', error);
        return throwError('Falha no envio de dados.');
      })
    );
  }

  obterListaTipoAuxilioSaude(): Observable<TipoAuxilioSaude[]> {
    return this.http.get<TipoAuxilioSaude[]>(`${Globais.urlToken}site-pge/api/tipos-auxilios-saude`).pipe(
      catchError((error) => {
        console.error('Erro durante o envio de dados:', error);
        return throwError('Falha no envio de dados.');
      })
    );
  }

  obterListaAnoReembolso(): Observable<AnoReembolso[]> {
    return this.http.get<AnoReembolso[]>(`${Globais.urlToken}site-pge/api/auxilios/ano-referente-reembolso`).pipe(
      catchError((error) => {
        console.error('Erro durante o envio de dados:', error);
        return throwError('Falha no envio de dados.');
      })
    );
  }

  enviarformulario(dados: FormGroup): Observable<AuxilioSaude> {
    dados.controls['tipoAuxilio'].setValue(2);
    const token = new String(localStorage.getItem('token')).toString();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token
    });

    const formularioValores = dados.getRawValue();

    return this.http.post<AuxilioSaude>(`${Globais.urlToken}site-pge/api/auxilios`, formularioValores, { headers: headers }).pipe(
      catchError((error) => {
        console.error('Erro durante o envio de dados:', error);
        return throwError(error);
      })
    );
  }

  enviarFormularioRetificado(dados: FormGroup, id: number): Observable<string> {
    dados.controls['tipoAuxilio'].setValue(2);
    const token = new String(localStorage.getItem('token')).toString();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token
    });

    const formularioValores = dados.getRawValue();

    return this.http
      .put(`${Globais.urlToken}site-pge/api/auxilios/saude/${id}/incluir-retificacao`, formularioValores, { headers: headers, responseType: 'text' })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  enviarArquivo(dados: NotaFiscalAnexadaAuxilio[]): Observable<string> {
    const token = new String(localStorage.getItem('token')).toString();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token
    });

    return this.http.post(`${Globais.urlToken}site-pge/api/notas-fiscais-anexadas/batch`, dados, { headers: headers, responseType: 'text' }).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Erro durante o envio de dados:', error);
        return throwError(() => error);
      }),
      finalize(() => {
        console.log('Requisição concluída.');
      })
    );
  }

  enviarArquivoRetificado(dados: NotaFiscalAnexadaAuxilio[]): Observable<string> {
    const token = new String(localStorage.getItem('token')).toString();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token
    });

    return this.http.put(`${Globais.urlToken}site-pge/api/notas-fiscais-anexadas/batch`, dados, { headers: headers, responseType: 'text' }).pipe(
      catchError((error) => {
        console.error('Erro durante o envio de dados:', error);
        return throwError('Falha no envio de dados.');
      }),
      finalize(() => {
        console.log('Requisição concluída.');
      })
    );
  }

  enviarAuxilioDependente(dados): Observable<string> {
    const token = new String(localStorage.getItem('token')).toString();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token
    });

    return this.http
      .post(`${Globais.urlToken}site-pge/api/dependentes-auxilio-saude/incluirDependentesAuxilio`, dados, { headers: headers, responseType: 'text' })
      .pipe(
        catchError((error) => {
          console.error('Erro durante o envio de dados:', error);
          return throwError('Falha no envio de dados.');
        })
      );
  }

  obterTipoAuxilio(): Observable<TipoAuxilio[]> {
    const token = new String(localStorage.getItem('token')).toString();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token
    });

    return this.http.get<TipoAuxilio[]>(`${Globais.urlToken}site-pge/api/tipos-auxilios`, { headers: headers }).pipe(
      catchError((error) => {
        console.error('Erro exclusão do arquivo:', error);
        return throwError('Falha na exclusão do arquivo.');
      })
    );
  }

  obterSomaValorDeferido(idUsuario: number, mes: number, ano: number): Observable<number> {
    const params = new HttpParams().set('idUsuario', idUsuario.toString()).set('ano', ano.toString()).set('mes', mes.toString());

    return this.http.get<number>(`${Globais.urlToken}site-pge/api/auxilios/soma-valor-deferido`, { params });
  }
}
