<div class="container">
  <app-separador label="QUEM SOMOS" />
  <div class="row">
    <p>
      A Procuradoria Geral do Estado de São Paulo (PGE-SP) é uma instituição de caráter permanente do Poder Executivo, vinculada diretamente ao Governador, responsável pelas atividades de advocacia do
      Estado. Com nível hierárquico de Secretaria, tem entre as suas principais atribuições a representação judicial e extrajudicial do Estado e suas autarquias, inclusive as de regime especial,
      exceto as universidades públicas.
    </p>
    <p>
      Além disso, exerce as atividades de consultoria e assessoramento jurídico do Poder Executivo e das entidades autárquicas acima mencionadas, com atribuições que vão desde a orientação dos
      gestores públicos nas contratações realizadas até o assessoramento na implementação de políticas públicas e grandes projetos de infraestrutura, como concessões e construções públicas estaduais.
    </p>
    <p>
      Composta por 3 Subprocuradorias cujas atribuições se exercem em três áreas de atuação: Consultoria Geral, Contencioso Geral e Contencioso Tributário-Fiscal. Estão ainda, entre as atividades a
      cargo da PGE-SP, o controle da dívida ativa estadual, o combate à sonegação fiscal e a realização de procedimentos administrativos (inclusive disciplinares e de indenizações).
    </p>
    <p>Atualmente, além das unidades presentes na Capital, (incluindo a Sede), a PGE-SP possui 12 unidades regionais estrategicamente alocadas no Estado de São Paulo e uma unidade em Brasília.</p>
    <br />
  </div>
</div>
