import { NgClass } from '@angular/common';
import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatNavList } from '@angular/material/list';
import { MatDrawer, MatDrawerContainer, MatDrawerContent } from '@angular/material/sidenav';
import { Router, RouterOutlet } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { MenuNode } from 'src/app/area-restrita/core/layout/menu/MenuNode';
import { MenuSguService } from 'src/app/area-restrita/core/layout/menu/MenuSgu.service';
import { AuthenticationService } from 'src/app/area-restrita/shared/services/authentication.service';
import { LocalStorageService } from 'src/app/area-restrita/shared/services/local-storage.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { MenuItemComponent } from '../menu/menu-item/menu-item.component';
import { PessoaService } from './../../../features/home/shared/services/pessoa.service';

@Component({
  selector: 'app-home-area-restrita',
  templateUrl: './home-area-restrita.component.html',
  styleUrl: './home-area-restrita.component.sass',
  standalone: true,
  imports: [MatIconButton, MatIcon, MatDrawerContainer, MatDrawer, NgClass, MatNavList, MenuItemComponent, MatDrawerContent, RouterOutlet]
})
export class HomeAreaRestritaComponent implements OnInit, OnDestroy {
  nomes = [];
  toggleMenuUsuario() {
    this.menuAtivo = !this.menuAtivo;
    if (this.menuAtivo) {
      this.resetMenu();
    }
  }
  manterMenu() {
    if (this.timeoutHandle) {
      clearTimeout(this.timeoutHandle);
    }
  }
  fecharMenu() {
    this.timeoutHandle = setTimeout(() => {
      this.menuAtivo = false;
    }, 1000);
  }
  private resetMenu() {
    if (this.timeoutHandle) {
      clearTimeout(this.timeoutHandle);
    }
  }
  logoutUsuario() {
    this.authenticationService.logout();
  }
  menuAtivo = false;
  private timeoutHandle: NodeJS.Timeout;
  @ViewChild('menuIcon') menuIcon: ElementRef;
  visibilityStates: Record<number, boolean> = {};
  isMenuOpen = true;
  isShowing = false;
  botaoFechar = false;
  private readonly destroy$ = new Subject<void>();
  idUsuario = this.localS.get('idUsuario');

  menuDataSubscription: Subscription;
  dataSource: MenuNode[] = [];
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private menuSguService: MenuSguService,
    private toastService: ToastService,
    private localS: LocalStorageService,
    private pessoaService: PessoaService
  ) {}

  ngOnInit() {
    this.menuSguService.getMenu();
    this.menuDataSubscription = this.menuSguService.menuData$.subscribe(async (menu) => {
      this.dataSource = menu;
    });
    this.obterNomePessoa();
    this.onOptionSelected();
    this.menuSguService.emitMenuItemclicado$.subscribe(() => {
      this.toggleMenu(true);
    });
  }

  obterNomePessoa() {
    this.pessoaService.obterDadosPessoa(Number(localStorage.getItem('idUsuario'))).subscribe(
      (response) => {
        const responseNome = response.nome.trim();
        if (responseNome.indexOf(',') >= 0) this.nomes = response.nome.split(',');
        else if (responseNome.indexOf(' ') >= 0) this.nomes = response.nome.split(' ');
      },
      (error) => {
        console.log(error);
      }
    );
  }
  ngOnDestroy() {
    if (this.menuDataSubscription) {
      this.menuDataSubscription.unsubscribe();
    }
  }
  isMobileDevice(): boolean {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/android/i.test(userAgent)) {
      return true;
    }

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return true;
    }

    return false;
  }

  logout(route: string) {
    if (route == 'logout-site-pge') this.authenticationService.logout();
  }
  changeRoute(route: string) {
    if (route == 'home-site-pge') return `/area-restrita/home`;
    if (route == 'logout-site-pge') {
      return `/`;
    }
    return `/area-restrita/${route}`;
  }

  mouseenter() {
    if (!this.isMenuOpen) {
      this.isShowing = true;
    }
  }

  mouseleave() {
    if (!this.isMenuOpen) {
      this.isShowing = false;
    }
  }

  toggleMenu(botaoFechar: boolean, click: boolean = false) {
    if (click) {
      this.isMenuOpen = !this.isMenuOpen;
      this.isShowing = this.isMenuOpen;
    } else {
      if (this.isMenuOpen && !botaoFechar) {
        this.isShowing = true;
      } else {
        this.isShowing = false;
      }

      if (this.isMobileDevice()) {
        this.isMenuOpen = !this.isMenuOpen;
      }
    }
  }

  onOptionSelected() {
    if (this.isMobileDevice()) {
      this.isMenuOpen = false;
      this.isShowing = false;
      this.botaoFechar = true;
    } else {
      this.botaoFechar = false;
    }
  }

  alterarSenha() {
    const isRestrito = 'true';
    this.router.navigate(['area-restrita', 'alterar-senha-area-restrita'], {
      queryParams: { isRestrito: isRestrito }
    });
  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.onOptionSelected();
  }

  getPrimeiroESegundoNome(): string {
    if (this.nomes.length > 1) {
      return `${this.nomes[0]} ${this.nomes[1]}`;
    }
    return this.nomes[0];
  }
}
