import { Component, Inject, inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { Toast } from './toast.model';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.sass',
  standalone: true,
  imports: [MatIcon]
})
export class ToastComponent {
  snackBarRef = inject(MatSnackBarRef);

  statusIcon = {
    success: 'done',
    warning: 'warning',
    error: 'cancel',
    info: 'info'
  };
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: Toast) {}
}
