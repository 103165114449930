import { Component } from '@angular/core';
import { FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Regional } from 'src/app/shared/models/regional.interface';

import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { dados } from 'src/app/area-aberta/features/informacao-cidadao/localizar-regionais/dados';
import { MatButton } from '@angular/material/button';
import { MatInput } from '@angular/material/input';
import { MatLabel, MatFormField } from '@angular/material/form-field';
import { SeparadorComponent } from '../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-localizar-regionais',
  templateUrl: './localizar-regionais.component.html',
  styleUrl: './localizar-regionais.component.sass',
  standalone: true,
  imports: [SeparadorComponent, FormsModule, ReactiveFormsModule, MatLabel, MatFormField, MatInput, MatButton, MatPaginator]
})
export class LocalizarRegionaisComponent {
  regionais: Regional[];
  regional: Regional;
  gridUm: boolean = false;
  gridDois: boolean = false;

  pageEvent: PageEvent;
  length = -1;
  vazio = false;
  pageSize = 10;
  pageNumber = 0;
  pageSizeOptions = [5, 10, 25];
  paginador = false;
  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  paginatedRegionais = [];

  constructor(private formBuilder: FormBuilder) {}

  formulario = this.formBuilder.group({
    busca: ['', Validators.required]
  });

  BuscarRegional(): void {
    if (this.formulario.valid) {
      this.gridUm = false;
      this.gridDois = false;
      this.regionais = dados.filter((e) => e.Municipio.toLowerCase().includes(this.formulario.value.busca.toLowerCase()));
      if (this.regionais.length > 0) {
        this.gridUm = true;
        this.length = this.regionais.length;
        this.atualizarPaginacaoRegionais();
      }
    }
  }

  //Métodos temporários, ajustar quando implementar o tabela e backEnd de regionais
  handlePageEvent(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageIndex;
    this.atualizarPaginacaoRegionais();
  }

  atualizarPaginacaoRegionais(): void {
    const inicioIndex = this.pageNumber * this.pageSize;
    const fimIndex = inicioIndex + this.pageSize;
    this.paginatedRegionais = this.regionais.slice(inicioIndex, fimIndex);
  }

  visualizarGridDois(evento: Regional): void {
    this.regional = evento;
    this.gridUm = false;
    this.gridDois = true;
  }

  limparTudo(): void {
    this.gridUm = false;
    this.gridDois = false;
    this.formulario.reset();
    this.paginatedRegionais = [];
    this.length = 0;
    this.pageSize = 10;
    this.pageNumber = 0;
  }
}
