<div class="container-ficha-auxilio">
  <br />
  <h1 class="card-title titulo">
    {{ this.isValidationMode ? "Avaliação da Inscrição" : "Ficha de Inscrição" }}
  </h1>
  <br />
  <div class="col-12 text-danger">
    {{ mensagemErro }}
  </div>
  <br />
  <form>
    <div class="form-group">
      <label for="ususario" class="form-label">
        <span class="text-danger requerido">*</span>
        Usuario:
      </label>
      <div class="mat-full-width mat-disabled output-field" disabled>
        <output>{{ fichaInscricaoCadastro?.nome }}</output>
      </div>
    </div>
    <div class="form-container-row">
      <div class="form-group">
        <label for="rg" class="form-label"><span class="text-danger requerido">*</span>RG:</label>

        <div class="mat-full-width mat-disabled output-field" disabled disabled="true">
          <output>{{ dadosFormulario.rg | mask: "00.000.000-0" }}</output>
        </div>
      </div>
      <div class="form-group">
        <label for="cpf" class="form-label"><span class="text-danger requerido">*</span>CPF:</label>

        <div class="mat-full-width mat-disabled output-field" disabled>
          <output>{{ dadosFormulario.cpf | mask: "000.000.000-00" }}</output>
        </div>
      </div>
      <div class="form-group">
        <label for="locatacao" class="form-label"><span class="text-danger requerido">*</span>Lotação:</label>

        <div class="mat-full-width mat-disabled output-field" disabled>
          <output>{{ dadosFormulario.lotacao }}</output>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label for="Endereco" class="form-label"><span class="text-danger requerido">*</span>Endereço:</label>

      <div class="mat-full-width mat-disabled output-field" disabled>
        <output>{{ dadosFormulario.endereco }}</output>
      </div>
    </div>
    <div class="form-container-row">
      <div class="form-group">
        <label for="cep" class="form-label"><span class="text-danger requerido">*</span>CEP:</label>

        <div class="mat-full-width mat-disabled output-field" disabled>
          <output>{{ dadosFormulario.cep | mask: "00000-000" }}</output>
        </div>
      </div>
      <div class="form-group">
        <label for="cidade" class="form-label"><span class="text-danger requerido">*</span>Cidade:</label>

        <div class="mat-full-width mat-disabled output-field" disabled>
          <output>{{ dadosFormulario.cidade }}</output>
        </div>
      </div>
      <div class="form-group">
        <label for="uf" class="form-label"><span class="text-danger requerido">*</span>UF:</label>

        <div class="mat-full-width mat-disabled output-field" disabled>
          <output>{{ dadosFormulario.uf }}</output>
        </div>
      </div>
    </div>
    <div class="form-container-row">
      <div class="form-group">
        <label for="telefone" class="form-label">Telefone:</label>
        <mat-form-field class="mat-full-width">
          <input matInput type="text" id="telefone" mask="(00) 0000-0000" name="telefone" [(ngModel)]="dadosFormulario.telefone" [disabled]="isValidationMode" />
        </mat-form-field>
      </div>
      <div class="form-group">
        <label for="celular" class="form-label"><span class="text-danger requerido">*</span>Celular:</label>
        <mat-form-field class="mat-full-width">
          <input matInput type="text" class="form-control" id="celular" mask="(00) 00000-0000" name="celular" [disabled]="isValidationMode" [(ngModel)]="dadosFormulario.celular" />
        </mat-form-field>
      </div>
      <div class="form-group">
        <label for="email" class="form-label"><span class="text-danger requerido">*</span>E-mail:</label>
        <mat-form-field class="mat-full-width">
          <input matInput type="email" class="form-control" id="email" name="email" [disabled]="isValidationMode" [(ngModel)]="dadosFormulario.email" />
        </mat-form-field>
      </div>
    </div>
  </form>
  <br />
  <div class="margin-frase">
    <section>
      <mat-checkbox color="primary" id="dependentesImpostoRenda" [(ngModel)]="dadosFormulario.dependentesImpostoRenda" [disabled]="possuiInscricao || isValidationMode"
        ><span class="text-danger requerido">*</span
        ><span class="frase-importante"
          >Declaro que não recebo qualquer auxílio correlato custado pelos cofres públicos, ainda que parcialmente, e que observarei a legislação vigente para fins de declaração anual de imposto sobre
          renda e proventos de qualquer natureza, autorizando o Instituto de Assistência Médica do Servidor do Estado de São Paulo - IAMSPE ou a Associação dos Procuradores do Estado de São Paulo -
          APESP a compartilharem com a Procuradoria Geral do Estado as informações necessárias para o ressarcimento de que tratam as Resoluções PGE 38 e 39, de 17 de dezembro de 2021.</span
        ></mat-checkbox
      >
    </section>
  </div>
  <br />
  <div>Solicito a inclusão dos seguintes dependentes para efeito de reembolso no Auxílio Saúde:</div>
  <br />
  @if (mostrarFrase) {
    <div class="grid row">
      @if (!isValidationMode) {
        <div class="container-cadastrar">
          <div class="text-dependente">Se algum dependente não estiver relacionado abaixo.</div>
          <button mat-button class="button-dependente" (click)="mostrarFormulario = true; mostrarFrase = false">
            Cadastrar Dependente
            <mat-icon>people</mat-icon>
          </button>
        </div>
      }
      <div></div>
    </div>
  }
  <br />
  <div class="table-responsive">
    <div class="container-content-table">
      <table class="tabelaPadrao">
        <thead>
          <tr>
            <th class="titulo tamanho-campo-grid-checkbox"></th>
            <th class="titulo tamanho-campo-grid">Nome</th>
            <th class="titulo tamanho-campo-grid">CPF</th>
            <th class="titulo tamanho-campo-grid">Data Nascimento</th>
            <th class="titulo">Descrição de Parentesco</th>
            <th class="titulo tamanho-campo-grid-excluir">Excluir</th>
          </tr>
        </thead>
        <tbody>
          @for (dependente of dependentes; track dependente; let i = $index) {
            <tr>
              <td class="tamanho-campo-grid-checkbox">
                <input type="checkbox" [(ngModel)]="dependente.checked" (change)="selecaoDependente(dependente)" />
              </td>
              <td class="tamanho-campo-grid">{{ dependente.nome }}</td>
              <td class="tamanho-campo-grid">{{ FormataOutputCPF(dependente.cpfDependente) }}</td>
              <td class="tamanho-campo-grid">{{ dependente.dataNascimento }}</td>
              <td>
                <mat-select [value]="dependente.descricaoDependente">
                  <mat-option [value]="dependente.descricaoDependente">{{ dependente.descricaoDependente }}</mat-option>
                </mat-select>
              </td>
              <td class="tamanho-campo-grid-excluir">
                <mat-icon fontIcon="cancel" class="grid-icon-menor" (click)="removerLinhaDependente(i)"></mat-icon>
              </td>
            </tr>
          }
        </tbody>
      </table>
      @if (mostrarFormulario) {
        <form [formGroup]="formularioDependentes">
          <div formArrayName="passo">
            @for (p of passo?.controls; track p; let i = $index) {
              <table class="tabelaPadrao" [formGroupName]="i">
                <tr>
                  <td class="tamanho-campo-grid-checkbox"></td>
                  <td class="tamanho-campo-grid">
                    <input type="text" class="tamanho-input-dependente" formControlName="nome" />
                  </td>
                  <td class="tamanho-campo-grid">
                    <input type="text" class="tamanho-input-dependente" mask="000.000.000-00" formControlName="cpfDependente" />
                  </td>
                  <td class="tamanho-campo-grid">
                    <input type="date" class="tamanho-input-dependente" formControlName="dataNascimento" placeholder="data" />
                  </td>
                  <td>
                    <mat-select formControlName="descricaoDependente">
                      @for (opcao of opcoesRelacionamento; track opcao) {
                        <mat-option [value]="opcao">{{ opcao }}</mat-option>
                      }
                    </mat-select>
                  </td>
                  <th class="tamanho-campo-grid-excluir"></th>
                </tr>
              </table>
              <div class="text-danger icon-erro">
                @for (erro of mensagensErro; track erro) {
                  @if (
                    erro.campo === "cpfDependente" &&
                    (passo.controls[i].get(erro.campo).touched || passo.controls[i].get(erro.campo).dirty) &&
                    (passo.controls[i].get(erro.campo).errors?.["cpfInvalido"] || passo.controls[i].get(erro.campo).invalid || passo.controls[i].get(erro.campo).value === "")
                  ) {
                    <div>
                      <div class="erro-container">
                        <mat-icon class="icone-erro" fontIcon="{{ erro.icone }}"></mat-icon>
                        {{ erro.mensagem }}
                      </div>
                    </div>
                  }
                  @if (
                    erro.campo === "dataNascimento" &&
                    (passo.controls[i].get(erro.campo).touched || passo.controls[i].get(erro.campo).dirty) &&
                    (passo.controls[i].get(erro.campo).errors?.["dataNascimentoInvalida"] || passo.controls[i].get(erro.campo).value === null || passo.controls[i].get(erro.campo).value === "")
                  ) {
                    <div>
                      <div class="erro-container">
                        <mat-icon class="icone-erro" fontIcon="{{ erro.icone }}"></mat-icon>
                        {{ erro.mensagem }}
                      </div>
                    </div>
                  }
                  @if (
                    erro.campo !== "cpfDependente" &&
                    erro.campo !== "dataNascimento" &&
                    (passo.controls[i].get(erro.campo).touched || passo.controls[i].get(erro.campo).dirty) &&
                    passo.controls[i].get(erro.campo).value === ""
                  ) {
                    <div>
                      <div class="erro-container">
                        <mat-icon class="icone-erro" fontIcon="{{ erro.icone }}"></mat-icon>
                        {{ erro.mensagem }}
                      </div>
                    </div>
                  }
                }
              </div>
            }
          </div>
        </form>
        <div class="botoes-grid">
          <div class="botaoMais">
            <mat-icon fontIcon="add_circle" (click)="adicionarLinhaGrid()"></mat-icon>
          </div>
          <div class="botaoEnvioExcluir">
            <div>
              <button mat-flat-button type="submit" (click)="enviarDadosDependentes()" class="btn btn-dark">Enviar</button>
            </div>
          </div>
        </div>
      }
    </div>
  </div>

  <div>
    @if (selecionados.size > 0) {
      <div>
        <div class="margin-frase">
          <section>
            <mat-checkbox color="primary" id="autorizacaoPlano" [(ngModel)]="dadosFormulario.contratantePlanoSaude"
              ><span class="text-danger requerido">*</span
              ><span class="frase-importante"
                >Declaro que os dependentes não recebem qualquer auxílio correlato custado pelos cofres públicos, ainda que parcialmente, e autorizando o Instituto de Assistência Médica do Servidor do
                Estado de São Paulo - IAMSPE ou a Associação dos Procuradores do Estado de São Paulo - APESP a compartilharem com a Procuradoria Geral do Estado as informações necessárias para o
                ressarcimento de que tratam as Resoluções PGE 38 e 39, de 17 de dezembro de 2021.</span
              ></mat-checkbox
            >
          </section>
        </div>
        <div class="margin-frase">
          <section>
            <mat-checkbox color="primary" id="autorizacaoIAMSPE" [(ngModel)]="dadosFormulario.autorizacaoIAMSPE"
              ><span class="text-danger requerido">*</span
              ><span class="frase-importante"
                >Declaro que não incluí nenhum ascendente (pai, mãe, avô, avó, bisavô ou bisavó) ou agregado (ex-esposa, ex-companheira, sogro, sogra, tio, tia, sobrinho ou sobrinha) para fins de
                ressarcimento. Declaro que os nomes informados são meus dependentes econômicos, sendo que os filhos/enteados menores de 21 anos possuem dependência presumida para fins de ressarcimento
                do auxílio saúde, no âmbito da entidade familiar respectiva.</span
              ></mat-checkbox
            >
          </section>
        </div>
      </div>
      <div class="margin-frase">
        <section>
          <mat-checkbox color="primary" id="possuoDependentes" [(ngModel)]="dadosFormulario.possuoDependentes" [disabled]="isValidationMode"
            ><span class="text-danger requerido">*</span
            ><span class="frase-importante">Declaro que possuo dependentes conforme resolução PGE nº 39/2021, na redação dada pelo artigo 2º da resolução PGE nº 62/2023.</span></mat-checkbox
          >
        </section>
      </div>
    }
  </div>
  <br />
  @if (isValidationMode) {
    <div class="container-avaliacao">
      <div>
        <mat-radio-group class="padding-top24 d-flex flex-column" [(ngModel)]="dadosAvaliacao.idStatus">
          @for (status of auxilioStatusOptions; track status) {
            <mat-radio-button [checked]="status.id === dadosAvaliacao.idStatus" [value]="status.id">{{ status.name }}</mat-radio-button>
          }
        </mat-radio-group>
        @if (!dadosAvaliacao.loading) {
          <button
            mat-button
            type="submit"
            (click)="avaliarPedido(dadosAvaliacao.idStatus)"
            class="button-avaliar"
            [disabled]="[2, 3].indexOf(dadosAvaliacao.idStatus) === -1 || (dadosAvaliacao.idStatus === 3 && (!dadosAvaliacao.justificativa || dadosAvaliacao.justificativa === ''))"
          >
            Avaliar
          </button>
        }
        @if (dadosAvaliacao.loading) {
          <mat-spinner [diameter]="30"></mat-spinner>
        }
      </div>
      <mat-form-field appearance="outline" class="text-justificativa">
        <mat-label>
          @if (dadosAvaliacao.idStatus === 3) {
            <span>*</span>
          }
          Justificativa
        </mat-label>
        <textarea matInput [rows]="3" id="justificativa" name="justificativa" [(ngModel)]="dadosAvaliacao.justificativa"></textarea>
      </mat-form-field>
    </div>
  }

  @if (!isValidationMode) {
    <div class="div-bottom">
      <div class="div-coluna">
        @if (inscricaoIndeferida) {
          <span class="text-danger">Inscrição indeferida pelo Adm. Auxílio Saúde.</span>
        }
        <div>Data da última alteração: {{ dataAlteracao }}</div>
      </div>
      @if (!possuiInscricao) {
        <button mat-flat-button color="primary" type="submit" (click)="enviarDadosFormulario()" class="btn btn-dark">Enviar Solicitação</button>
      }
      @if (possuiInscricao && !inscricaoIndeferida) {
        <button mat-flat-button color="accent" type="submit" (click)="atualizarDadosFichaInscricao()" class="btn btn-dark">Atualizar Inscrição</button>
      }
    </div>
  }
</div>
