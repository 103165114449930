import { ToastService } from 'src/app/shared/toast/toast.service';
import { CurrencyPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatNativeDateModule, MatOption } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInput, MatDatepickerModule, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatTooltip } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { NgxCurrencyDirective } from 'ngx-currency';
import { finalize } from 'rxjs';
import { WaitLoadingService } from 'src/app/area-restrita/shared/components/wait-loading/wait-loading.service';
import { FormatBRLPipe } from 'src/app/area-restrita/shared/pipes/format-brl.pipe';
import { MesPorExtensoPipe } from 'src/app/area-restrita/shared/pipes/mes-por-extenso.pipe';
import { PessoaService } from '../../../home/shared/services/pessoa.service';
import { LimiteMensalService } from '../../shared/services/limite-mensal.service';
import { Perfil, Pessoa } from '../shared/models/auxilio-saude';
import { LimiteMensal, LimiteMensalAuxilio } from '../shared/models/limite-mensal.model';
import { MesaAuxilioSaudeService } from '../shared/services/mesa-auxilio-saude.service';

@Component({
  selector: 'app-limite-mensal-auxilio-saude',
  templateUrl: './limite-mensal-auxilio-saude.component.html',
  styleUrl: './limite-mensal-auxilio-saude.component.sass',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    MatSelect,
    MatOption,
    MatLabel,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatSuffix,
    MatDatepicker,
    MatButton,
    RouterLink,
    MatIcon,
    MatTooltip,
    MatPaginator,
    CurrencyPipe,
    FormatBRLPipe,
    MesPorExtensoPipe,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxCurrencyDirective
  ]
})
export class LimiteMensalAuxilioSaudeComponent implements OnInit {
  today = new Date();
  proximoMes = new Date(this.today.getFullYear(), this.today.getMonth() + 1, 1);
  ultimoDiaMes = new Date(this.proximoMes.getFullYear(), this.proximoMes.getMonth(), 0);

  trocasRecentes: LimiteMensal[] = [];
  disabled = false;
  perfil: Perfil[] = [];
  limiteAtualUser: LimiteMensalAuxilio;
  dadosFormulario: FormGroup;
  pessoa: Pessoa;

  constructor(
    private formBuilder: FormBuilder,
    private waitLoadingService: WaitLoadingService,
    private mesaAuxilioSaudeService: MesaAuxilioSaudeService,
    private limiteMensalService: LimiteMensalService,
    private pessoaService: PessoaService,
    private toastService: ToastService
  ) {
    console.log();
  }

  ngOnInit() {
    this.obterPerfis();

    this.dadosFormulario = this.formBuilder.group({
      perfil: ['', Validators.required],
      valorNovo: ['', Validators.required],
      dataInicio: [this.formatDate(this.proximoMes)],
      valorAtual: [''],
      dataExpiracao: [this.formatDate(this.ultimoDiaMes)],
      idUsuario: [{ id: Number(localStorage.getItem('idUsuario')) }]
    });
    this.dadosFormulario.get('valorAtual')?.disable();
    this.dadosFormulario.get('dataInicio')?.disable();
    this.dadosFormulario.get('dataExpiracao')?.disable();
    this.obterLimiteHistorico();
    this.obterDadosPessoa();
  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${day}/${month}/${year}`;
  }

  changeLimite() {
    this.criarLimiteMensal();
    this.obterLimiteHistorico();
  }

  obterLimite(): void {
    this.limiteMensalService.consultaLimiteMensal(this.pessoa.perfil.id, this.ultimoDiaMes.getFullYear(), this.ultimoDiaMes.getMonth()).subscribe({
      next: (dados: LimiteMensalAuxilio) => {
        this.limiteAtualUser = dados;
        this.dadosFormulario.get('valorAtual')?.setValue(dados.valor);
        console.log(dados);
      },
      error: (error) => console.error('Erro ao obter dados do serviço', error)
    });
  }

  obterLimiteHistorico(): void {
    this.limiteMensalService.consultaHistorico(Number(this.dadosFormulario.get('perfil').value)).subscribe({
      next: (dados: LimiteMensal[]) => {
        this.trocasRecentes = dados;
        console.log(dados);
      },
      error: (error) => console.error('Erro ao obter dados do serviço', error)
    });
  }

  criarLimiteMensal(): void {
    this.limiteMensalService
      .criaLimiteMensal(
        Number(this.dadosFormulario.get('perfil').value),
        this.proximoMes.getFullYear(),
        this.proximoMes.getMonth() + 1,
        Number(localStorage.getItem('idUsuario')),
        Number(this.dadosFormulario.get('valorNovo').value)
      )
      .subscribe({
        next: (status: string) => {
          console.log(status);
          if (status.includes('Sucesso:')) {
            const mensagem = status.replace('Sucesso:', '').trim();
            this.toastService.success(mensagem, 'Aviso do Servidor');
            return;
          } else if (status.includes('Erro:')) {
            const mensagem = status.replace('Erro:', '').trim();
            this.toastService.error(mensagem, 'Erro de Servidor');
          } else if (status.includes('Aviso:')) {
            const mensagem = status.replace('Aviso:', '').trim();
            this.toastService.warning(mensagem, 'Aviso do Servidor');
          } else {
            this.toastService.error(status, 'Resposta Inesperada');
          }
          this.toastService.error('Usuário não encontrado', 'Erro de Servidor');
        }
      });
  }

  obterPerfis() {
    this.waitLoadingService.open();
    this.mesaAuxilioSaudeService
      .obterListaPerfis()
      .pipe(finalize(() => this.waitLoadingService.close()))
      .subscribe({
        next: (dados) => {
          this.perfil = dados.slice(0, 3);
        },
        error: (error) => console.error('Erro ao obter dados do serviço', error)
      });
  }

  obterDadosPessoa() {
    this.pessoaService.obterDadosPessoa(Number(localStorage.getItem('idUsuario'))).subscribe(
      (response: Pessoa) => {
        this.pessoa = response;
        this.dadosFormulario.get('perfil')?.setValue(response.perfil.id);
        this.obterLimite();
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
