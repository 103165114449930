import { Component } from '@angular/core';
import { SeparadorComponent } from '../../../../core/layout/separador/separador.component';
import { LegislacaoList } from './legislacao-corregedoria-file';

@Component({
  selector: 'app-legislacao-corregedoria',
  templateUrl: './legislacao-corregedoria.component.html',
  standalone: true,
  imports: [SeparadorComponent]
})
export class LegislacaoCorregedoriaComponent {
  legislacaoContent = LegislacaoList;
}
