import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ToastComponent } from './toast.component';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  constructor(private _snackBar: MatSnackBar) {}

  success(message: string, title?: string, config?: MatSnackBarConfig) {
    this.openSnackBar(message, title, 'success', config);
  }
  warning(message: string, title?: string, config?: MatSnackBarConfig) {
    this.openSnackBar(message, title, 'warning', config);
  }

  error(message: string, title?: string, config?: MatSnackBarConfig) {
    this.openSnackBar(message, title, 'error', config);
  }

  info(message: string, title?: string, config?: MatSnackBarConfig) {
    this.openSnackBar(message, title, 'info', config);
  }

  private openSnackBar(message: string, title: string, status: string, config?: MatSnackBarConfig) {
    this._snackBar.openFromComponent(ToastComponent, {
      ...config,
      duration: config?.duration || 2000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: [`snack-bar-${status}`],
      data: {
        message,
        status,
        title
      }
    });
  }
}
