<main class="main" role="main">
  <section class="page">
    <app-separador label="Localizar Regionais"></app-separador>
    <div class="container-geral">
      <div class="container-imagem">
        <img class="img-mapa-procuradoria" src="assets/images/mapa-procuradoria-regionais-pge-sp.jpg" alt="Imagem do mapa da procuradoria regionais pge/sp" />
      </div>
      <div class="container-form">
        <form [formGroup]="formulario" (ngSubmit)="BuscarRegional()">
          <div class="container-geral-input-button">
            <div>
              <mat-label for="nome">Digite o nome da cidade, ou apenas parte dele, para obter informação sobre a Procuradoria Regional a que ela pertence.</mat-label>
              <div class="campo-botao">
                <mat-form-field style="margin-right: 3px">
                  <input matInput formControlName="busca" />
                </mat-form-field>
                <button mat-flat-button color="primary" type="submit">Pesquisar</button>
                <button class="limpar" type="button" (click)="limparTudo()">Limpar</button>
              </div>
            </div>
          </div>
        </form>
        <br />
        @if (gridUm) {
          <div class="grid-responsivel">
            <div>
              Resultado da pesquisa por : <strong>{{ formulario.value.busca }}</strong>
            </div>
            <table>
              <thead>
                <tr>
                  <th>Municipio</th>
                  <th>Regional</th>
                  <th>Codigo</th>
                </tr>
              </thead>
              <tbody>
                @for (reg of paginatedRegionais; track reg) {
                  <tr>
                    <td class="formatar-campo">{{ reg.Municipio }}</td>
                    <td>
                      <a class="link" (click)="visualizarGridDois(reg)" (keypress)="visualizarGridDois(reg)" tabindex="0">{{ reg.Regional }}</a>
                    </td>
                    <td>{{ reg.Codigo }}</td>
                  </tr>
                }
              </tbody>
            </table>
            <mat-paginator
              #paginator
              class="custom-paginator"
              (page)="handlePageEvent($event)"
              [length]="length"
              [pageSize]="pageSize"
              [showFirstLastButtons]="showFirstLastButtons"
              [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
              [hidePageSize]="hidePageSize"
              [pageIndex]="pageNumber"
              aria-label="Select page"
            ></mat-paginator>
          </div>
        }
        @if (gridDois) {
          <div class="grid-responsivel">
            <table>
              <thead>
                <tr>
                  <th>Regional</th>
                  <th>Chefe</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ regional.Regional }}</td>
                  <td>{{ regional.Chefe }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        }
      </div>
    </div>
  </section>
</main>
