import { Component } from '@angular/core';
import { SeparadorComponent } from '../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-quem-somos-pge',
  templateUrl: './quem-somos.component.html',
  standalone: true,
  imports: [SeparadorComponent]
})
export class QuemSomosComponent {}
