<app-separador label="NOTÍCIAS DIVERSAS"></app-separador>
<div class="container">
  @for (noticia of objNoticias; track noticia) {
    <div class="news-card" (click)="abrirUmaNoticia(noticia?.id)" (keypress)="abrirUmaNoticia(noticia?.id)" tabindex="0">
      @if (noticia?.imagem) {
        <div class="news-card-image">
          <img [src]="getImagem(noticia?.imagem)" alt="Imagem da Notícia" (click)="abrirUmaNoticia(noticia?.id)" (keypress)="abrirUmaNoticia(noticia?.id)" tabindex="0" />
        </div>
      }
      <div class="news-detail">
        <div class="titulo">{{ noticia?.titulo }}</div>
        <div class="date">{{ noticia?.dataPublicacao }}</div>
        <div class="description">{{ stripHTML(noticia?.texto) }}</div>
      </div>
    </div>
  }
</div>
<div>
  <mat-paginator
    #paginator
    class="custom-paginator"
    (page)="handlePageEvent($event)"
    [length]="length"
    [pageSize]="pageSize"
    [disabled]="disabled"
    [showFirstLastButtons]="showFirstLastButtons"
    [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
    [hidePageSize]="hidePageSize"
    [pageIndex]="pageIndex"
    aria-label="Select page"
  ></mat-paginator>
</div>
