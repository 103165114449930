import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { Noticias } from 'src/app/shared/models/noticia.interface';
import { NoticiaService } from 'src/app/shared/services/noticia.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { ConfirmaDialogData } from './../../../shared/components/confirma-dialog/confirma-dialog-data';

import { Location, NgClass } from '@angular/common';
import { MatButton } from '@angular/material/button';
import { MatNativeDateModule, MatOption } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInput, MatDatepickerModule, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatFormField, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { MatTooltip } from '@angular/material/tooltip';
import { CategoriaNoticia } from 'src/app/area-aberta/shared/enums/categoria-noticia';
import { ConfirmaDialogComponent } from 'src/app/area-restrita/shared/components/confirma-dialog/confirma-dialog.component';
import { Page } from 'src/app/shared/models/paginacao/page.model';

@Component({
  selector: 'app-consultar-noticias',
  templateUrl: './consultar-noticias.component.html',
  styleUrl: './consultar-noticias.component.sass',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    MatSelect,
    MatOption,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatSuffix,
    MatDatepicker,
    MatButton,
    NgClass,
    MatIcon,
    MatTooltip,
    MatPaginator,
    MatDatepickerModule,
    MatNativeDateModule
  ]
})
export class ConsultarNoticiasComponent implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastService,
    private noticiaService: NoticiaService,
    private dialog: MatDialog,
    private location: Location
  ) {}

  mostraTabela: boolean = false;
  noticias: Noticias[] = [];
  paginador = false;

  pageEvent: PageEvent;
  length = 0;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25, 50];

  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;

  categoriaObject = [
    { value: 0, nome: 'Selecione' },
    { value: 1, nome: 'Notícia Área Aberta' },
    { value: 2, nome: 'Notícia Área Restrita' },
    { value: 3, nome: 'Comunicados' },
    { value: 4, nome: 'Credenciamento de contadores' }
  ];

  dadosFormulario = this.formBuilder.group({
    titulo: [''],
    ativo: [''],
    dataPublicacao: [''],
    idAcesso: [null],
    destaque: [''],
    categoria: ['']
  });

  consultar() {
    if (this.dadosFormulario.valid) {
      this.noticiaService
        .consultar(this.dadosFormulario, this.pageIndex, this.pageSize)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (response: Page<Noticias>) => {
            this.noticias = response.content;
            this.length = response.totalElements;
            this.paginador = !response.empty;
            this.mostraTabela = true;
          },
          error: () => console.error
        });
    }
  }

  removerNoticia(idNoticia: number) {
    this.noticiaService
      .remover(idNoticia)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.consultar();
        this.toastr.success('Notícia excluída com sucesso.');
      });
  }

  editarNoticia(id: number) {
    this.router.navigate(['area-restrita', 'editar-noticia', id]);
  }

  criarNoticia() {
    this.router.navigate(['area-restrita', 'criar-noticia']);
  }

  visualizarNoticia(id: number, categoria: number, ativo: boolean) {
    if (!ativo) {
      this.router.navigate(['area-restrita', 'visualizar-noticia', id]);
    } else {
      switch (categoria) {
        case CategoriaNoticia.NOTICIA_AREA_ABERTA:
          this.openUrlInNewTab('/uma-noticia', id);
          break;
        case CategoriaNoticia.COMUNICADOS:
          this.openUrlInNewTab('/informacao-cidadao/comunicado', id);
          break;
        case CategoriaNoticia.CREDENCIAMENTO_DE_CONTADORES:
          this.openUrlInNewTab('/informacao-cidadao/credenciamento-contadores/noticia', id);
          break;
        default:
          this.router.navigate(['area-restrita', 'noticia-home', id]);
          break;
      }
    }
  }

  private openUrlInNewTab(url: string, id: number): void {
    const urlTree = this.router.createUrlTree([url, id]);
    const fullUrl = `${window.location.origin}${this.location.prepareExternalUrl(urlTree.toString())}`;
    window.open(fullUrl, '_blank');
  }

  alternarAtivo(id: number, ativo: boolean) {
    this.noticiaService
      .atualizarAtivo(id, ativo)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.consultar();
          if (ativo == true) {
            this.toastr.success('Noticia ativada sucesso.');
          } else {
            this.toastr.success('Noticia desativada sucesso.');
          }
        },
        (error) => {
          this.toastr.error(error.message);
        }
      );
  }

  limparFormulario() {
    this.dadosFormulario.reset({
      titulo: '',
      ativo: '',
      dataPublicacao: '',
      idAcesso: null,
      destaque: '',
      categoria: ''
    });
    this.length = 0;
    this.pageSize = 10;
    this.pageIndex = 0;
    this.noticias = [];
    this.mostraTabela = false;
    this.paginador = false;
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.consultar();
  }

  openHelp(id: number): void {
    const data: ConfirmaDialogData = {
      title: '',
      message: 'Deseja remover a Notícia?',
      escondeBotaoNao: false
    };
    const dialogRef = this.dialog.open(ConfirmaDialogComponent, {
      width: '20%',
      data: data
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.removerNoticia(id);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
