import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { ComunicacaoErro } from '../models/comunicacao-erro.interface';
import { Globais } from './../../area-restrita/shared/globais/globais';

@Injectable({
  providedIn: 'root'
})
export class ComunicacaoErroService {
  constructor(private http: HttpClient) {}

  incluir(dadosComunicacao: ComunicacaoErro): Observable<ComunicacaoErro> {
    return this.http.post<ComunicacaoErro>(Globais.urlToken + `site-pge/api/comunicar-erro`, dadosComunicacao).pipe(
      catchError((error) => {
        return throwError(() => Error(error.error.message));
      })
    );
  }
}
